import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { SpeciesObject } from '../species-object';

export const SpeciesSearchResponseRows = ResponseRows(
  SpeciesObject.pick({
    _id: true,
    name_common: true,
    name_scientific: true,
    name_combined: true,
    name_combined_html: true,
    population_code: true,
    species_code: true,
    federal_listing_status: true,
  }),
);

export type SpeciesSearchResponseRows = z.infer<
  typeof SpeciesSearchResponseRows
>;
