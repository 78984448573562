import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { CustomerDTO } from '../customers';
import { UserDTO } from '../users';
import { TransectCreditTransactionSourceEnum } from './transect-credit-transaction-source-enum';

export const TransectCreditTransactionDTO = z.object({
  _id: z.string().uuid(),
  user: UserDTO,
  user__id: z.string().uuid(),
  customer: CustomerDTO.pick({
    _id: true,
    name: true,
  }).optional(),
  customer__id: z.string().uuid(),
  amount: z.number().optional(),
  source: z.nativeEnum(TransectCreditTransactionSourceEnum),
  source__id: z.string(),
  created_at: DateSchema.nullish(),
  updated_at: DateSchema.nullish(),
  deleted_at: DateSchema.nullish(),
});

export type TransectCreditTransactionDTO = z.infer<
  typeof TransectCreditTransactionDTO
>;
