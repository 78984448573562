import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { TransectButtonsModule } from '@transect-nx/ui';
import { UnrecoverableStateDialogComponent } from './components/unrecoverable-state-dialog/unrecoverable-state-dialog.component';

@NgModule({
  declarations: [UnrecoverableStateDialogComponent],
  imports: [CommonModule, TransectButtonsModule, MatDialogModule],
})
export class UnrecoverableStateDialogModule {}
