import { z } from 'zod';

const Position = z.array(z.number());

export const PointGeometrySchema = z.object({
  type: z.literal('Point'),
  coordinates: Position,
});
export type PointGeometrySchema = z.infer<typeof PointGeometrySchema>;

export const MultiPointGeometrySchema = z.object({
  type: z.literal('MultiPoint'),
  coordinates: z.array(Position),
});
export type MultiPointGeometrySchema = z.infer<typeof MultiPointGeometrySchema>;

export const LineStringGeometrySchema = z.object({
  type: z.literal('LineString'),
  coordinates: z.array(Position),
});
export type LineStringGeometrySchema = z.infer<typeof LineStringGeometrySchema>;

export const MultiLineStringGeometrySchema = z.object({
  type: z.literal('MultiLineString'),
  coordinates: z.array(z.array(Position)),
});
export type MultiLineStringGeometrySchema = z.infer<
  typeof MultiLineStringGeometrySchema
>;

export const PolygonGeometrySchema = z.object({
  type: z.literal('Polygon'),
  coordinates: z.array(z.array(Position)),
});
export type PolygonGeometrySchema = z.infer<typeof PolygonGeometrySchema>;

export const MultiPolygonGeometrySchema = z.object({
  type: z.literal('MultiPolygon'),
  coordinates: z.array(z.array(z.array(Position))),
});
export type MultiPolygonGeometrySchema = z.infer<
  typeof MultiPolygonGeometrySchema
>;

export const CoordinatesSchema = z.union([
  Position,
  z.array(Position),
  z.array(z.array(Position)),
  z.array(z.array(z.array(Position))),
]);

export type CoordinatesSchema = z.infer<typeof CoordinatesSchema>;

export const GeometrySchema = z
  .object({
    type: z.enum([
      'Point',
      'LineString',
      'Polygon',
      'MultiPoint',
      'MultiLineString',
      'MultiPolygon',
      'GeometryCollection',
    ]),
    coordinates: CoordinatesSchema.nullish(),
  })
  .passthrough();

export type GeometrySchema = z.infer<typeof GeometrySchema>;

export const GeometryCollectionSchema = z.object({
  type: z.enum(['GeometryCollection']),
  geometries: z.array(
    z.union([
      PointGeometrySchema,
      MultiPointGeometrySchema,
      PolygonGeometrySchema,
      MultiPolygonGeometrySchema,
      LineStringGeometrySchema,
      MultiLineStringGeometrySchema,
    ]),
  ),
});

export type GeometryCollectionSchema = z.infer<typeof GeometryCollectionSchema>;

export const GeometriesOrCollectionSchema = z.union([
  PointGeometrySchema,
  MultiPointGeometrySchema,
  PolygonGeometrySchema,
  MultiPolygonGeometrySchema,
  LineStringGeometrySchema,
  MultiLineStringGeometrySchema,
  GeometryCollectionSchema,
]);

export type GeometriesOrCollectionSchema = z.infer<
  typeof GeometriesOrCollectionSchema
>;

export const SafeGeometrySchema = z.preprocess(
  (val) => (typeof val === 'string' ? JSON.parse(val) : val),
  GeometrySchema,
);
export type SafeGeometrySchema = z.infer<typeof SafeGeometrySchema>;

export const FeatureCollectionSchema = z.object({
  type: z.literal('FeatureCollection'),
  features: z.array(
    z.object({
      id: z.union([z.string(), z.number()]).optional(),
      type: z.literal('Feature'),
      geometry: GeometrySchema,
      properties: z.any().optional(),
    }),
  ),
  properties: z.any().optional(),
});

export type FeatureCollectionSchema = z.infer<typeof FeatureCollectionSchema>;
