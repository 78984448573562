import { z } from 'zod';
export const IndexRegulationLocationsPayload = z.object({
  regulation__id: z.string().uuid(),
  fields_essentials: z.preprocess(
    (val) => val === 'true',
    z.boolean().optional(),
  ),
  with_geometry: z.preprocess((val) => val === 'true', z.boolean().optional()),
  regulation_location_ids: z
    .preprocess(
      (val) => (typeof val === 'string' ? [val] : val),
      z.array(z.string()).optional(),
    )
    .optional(),
});
export type IndexRegulationLocationsPayload = z.infer<
  typeof IndexRegulationLocationsPayload
>;
