import { versionInfo } from './version-info';

export type AppName = 'backend' | 'mvt-server';

export function getVersionHeader(appName: AppName): {
  field: string;
  value: string;
} {
  switch (appName) {
    case 'backend':
      return {
        field: 'Transect-Backend-Version',
        value: versionInfo.hash ?? 'dev',
      };
    case 'mvt-server':
      return {
        field: 'Transect-Mvt-Server-Version',
        value: versionInfo.hash ?? 'dev',
      };
    default:
      throw new Error(`Version for "${appName}" is undefined.`);
  }
}
