import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ts-map-control-accordion',
  templateUrl: './map-control-accordion.component.html',
  styleUrls: ['./map-control-accordion.component.scss'],
})
export class MapControlAccordionComponent implements OnInit {
  @Input() width = 373;

  @Input() position: {
    top?: number | string;
    left?: number | string;
    right?: number | string;
    bottom?: number | string;
  } = { top: 0, left: 0 };

  constructor() {}

  ngOnInit(): void {
    for (const [key, val] of Object.entries(this.position)) {
      const stringValue = typeof val === 'number' ? `${val}px` : val;

      this.position[key] = stringValue;
    }
  }
}
