import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { TransectFile } from '../../transect-file/transect-file';

export const PaginatedUserResponseRows = PaginatedResponseRows(
  z
    .object({
      _id: z.string().uuid(),
      firstname: z.string().nullable(),
      lastname: z.string().nullable(),
      fullname: z.string().nullable(),
      active: z.boolean().default(false),
      email: z.string().email(),
      role: z.string().nullable(),
      provider: z.string().nullable(),
      organization: z.string().nullable(),
      title: z.string().nullable(),
      photo_gcs: TransectFile.nullable(),
      profile: z
        .object({
          phone: z.string().nullish(),
          address1: z.string().nullish(),
          city: z.string().nullish(),
          state: z.string().nullish(),
          zip: z.string().nullish(),
        })
        .nullable(),
      created_at: DateSchema,
      updated_at: DateSchema,
      date_last_logged_in: DateSchema.nullable(),
      invoicing_allowed: z.boolean().default(false),
      subscription_active: z.boolean().default(false),
      pilot_active: z.boolean().default(false),
      customers: z.any().nullable(),
    })
    .transform((val) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { customers, ...valWithoutCustomers } = val;
      return {
        ...valWithoutCustomers,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        customersNew: customers,
      };
    }),
);

export type PaginatedUserResponseRows = z.infer<
  typeof PaginatedUserResponseRows
>;
