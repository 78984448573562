import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const CustomerInviteObject = z.object({
  _id: z.string().uuid(),
  role: z.string().optional().nullable(),
  status: z.string().optional().nullable(),
  email: z.string().email().optional().nullable(),
  created_at: DateSchema,
  updated_at: DateSchema,
  customer__id: z.string().uuid(),
  creator__id: z.string().uuid(),
});
