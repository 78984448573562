import { z } from 'zod';
import { CatalogItem } from '../catalog-item';
import { ResponseRows } from '../../../utils/response-rows';

export const SearchCatalogItemDTO = CatalogItem.extend({
  catalog_group: z
    .object({
      _id: z.string().uuid(),
      name: z.string().optional(),
      master: z.boolean(),
      active: z.boolean(),
    })
    .optional()
    .nullable(),
});

export type SearchCatalogItemDTO = z.infer<typeof SearchCatalogItemDTO>;

export const SearchCatalogItemsDTO = ResponseRows(SearchCatalogItemDTO);

export type SearchCatalogItemsDTO = z.infer<typeof SearchCatalogItemsDTO>;
