import { z } from 'zod';
import { AddonEntityType } from './index-dto';

export const PricingRequestPayload = z.object({
  entityId: z.string().uuid(),
  entityType: z.nativeEnum(AddonEntityType),
});
export type PricingRequestPayload = z.infer<typeof PricingRequestPayload>;

export const Pricing = z.object({
  unable: z.boolean().optional(),
  _id: z.string().uuid().optional(),
  description: z.string().optional(),
  unsupported: z.boolean().optional(),
  sla: z.string().optional(),
  shpo: z.number().optional(),
  options: z
    .object({
      key: z.string(),
      quantity: z.number(),
      total: z.number(),
      description: z.string(),
    })
    .array()
    .optional(),
});
export type Pricing = z.infer<typeof Pricing>;
