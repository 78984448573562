import { z } from 'zod';
import { ParcelCollectionStatus } from '../models';

export const ParcelCollectionStatusPayloadDTO = z.object({
  parcel_collection__id: z.string().uuid(),
  object__id: z.string().uuid(),
  object_type: z.enum(['projects', 'parcel_filters']),
  status: z.nativeEnum(ParcelCollectionStatus),
  combine_by_owner: z.boolean(),
});

export type ParcelCollectionStatusPayloadDTO = z.infer<
  typeof ParcelCollectionStatusPayloadDTO
>;
