<transect-nx-dialog-modal
  title="Transect Trial Agreement"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
  [showCloseButton]="(isComplete$ | async) === true"
>
</transect-nx-dialog-modal>
<ng-template #contentTemplate>
  <div
    class="scrollable-content"
    tsDetectScrollToEnd
    (scrollEnd)="handleScrollToEnd()"
  >
    <div class="content-container">
      <div class="agreement-content text-shades-black">
        <div class="mb-20">
          <span class="title-medium">TRANSECT TRIAL AGREEMENT</span>
        </div>
        <div>
          <span class="body-medium"
            >This Transect Trial Agreement (“Agreement”) reflects the terms
            between the company signing this Agreement (“Client”) and Transect,
            Inc., a Delaware for-profit corporation (“Transect”). Transect and
            Client are also each referred to as a “Party” and collectively as
            the “Parties.”</span
          >
        </div>
        <div>
          <span class="label-large">WHEREAS, </span>
          <span class="body-medium"
            >Transect is in the business of providing its customers with access
            to on-demand environmental due diligence tools and services through
            its online software - including environmental reports, surveys,
            assessments, and summaries of real property (the “Platform”);
            and</span
          >
        </div>
        <div>
          <span class="label-large">WHEREAS, </span>
          <span class="body-medium"
            >Client desires that Transect provide Client access to the
            Platform;</span
          >
        </div>
        <div>
          <span class="label-large">NOW, THEREFORE, </span>
          <span class="body-medium"
            >in consideration of the mutual promises set forth in this
            Agreement, the adequacy and sufficiency of which are hereby
            acknowledged, the Parties agree as follows:</span
          >
        </div>
        <div>
          <div>
            <span class="body-medium">1) </span>
            <span class="label-large">SUBSCRIPTION SERVICES. </span>
          </div>
          <span class="body-medium"
            >This section is redacted for Trial users, as it is not applicable.
          </span>
        </div>
        <div>
          <div>
            <span class="body-medium">2) </span>
            <span class="label-large">AUTHORIZED USERS. </span>
          </div>
          <span class="body-medium"
            >Transect will provide login credentials for the Platform to the
            person or persons designated and paid for by Client (“Authorized
            Users”). Login credentials may not be shared with any persons who
            are not Authorized Users, and Client is responsible for the security
            of Client’s login credentials. Client must keep passwords and all
            login information confidential and use reasonable efforts to prevent
            unauthorized access to the Platform. Client shall ensure that all
            Authorized Users comply with the terms and conditions of this
            Agreement. Client shall also ensure that it has obtained the consent
            of each Authorized User to provide their name, email address and any
            other information reasonably required to administer the Subscription
            Services. Transect may access, store and process that information in
            the United States or any other country.
          </span>
        </div>
        <div>
          <span class="body-medium">3) </span>
          <span class="label-large">CLIENT INFORMATION. </span>
          <span class="body-medium"
            >Client is responsible for all the information, content, and
            materials that Client submits, uploads, or otherwise makes available
            to Transect through the Platform (“Client Information”). Client is
            solely responsible for the legality, accuracy, reliability, and
            appropriateness of such Client Information, and is prohibited from
            submitting or uploading any information that may infringe upon or
            misappropriate a third party’s trademark, copyright, or other
            intellectual property, or information which violates any state,
            federal, or international law. Transect and Client agree that Client
            Information is provided on a proprietary and confidential basis and
            that Transect shall not disclose Client Information to any
            third-party persons, companies, or organizations, except where
            required by law. Transect may monitor and use data related to
            Client’s use of the Platform (“Usage Data”) and may compile
            statistical and performance information related to Usage Data and
            the operation of the Platform. Transect shall retain all rights in
            the Usage Data and related statistical and performance information.
          </span>
        </div>
        <div>
          <span class="body-medium">4) </span>
          <span class="label-large">PLATFORM MODIFICATIONS. </span>
          <span class="body-medium"
            >Transect reserves the right to make changes to the Platform, and
            the content provided through the Platform, at any time and without
            notice to Client.</span
          >
        </div>
        <div>
          <div>
            <span class="body-medium">5) </span>
            <span class="label-large">SERVICES NOT INCLUDED. </span>
            <span class="body-medium"
              >This Agreement does not include any services that require a site
              inspection, material sampling, or laboratory testing- such as
              Phase 1 Environmental Site Assessments. If Client desires any of
              those services, the Parties must execute a separate written
            </span>
          </div>
        </div>
        <div>
          <span class="body-medium">6) </span>
          <span class="label-large">INTELLECTUAL PROPERTY RIGHTS. </span>
          <span class="body-medium"
            >Client shall have a limited, non-transferable, non-exclusive right
            to the environmental reports, information, surveys, assessments, and
            summaries of real property that are provided to Client through the
            Subscription Services, subject to the limitations of this Agreement.
            Nevertheless, the Platform, including (a) all past, present and
            future versions of the Platform; (b) the material, content and
            information provided through the Platform; (c) all trademarks,
            service marks, trade names, logos, and domain names, and (d) all
            graphics, text, images, audio, videos, designs, compilation, user
            interfaces, artwork, machine learning content, copyrightable
            material (including source and object code), and other materials,
            including without limitations the design, structure, “look and feel”
            and arrangement of such content contained on the Platform
            (collectively, the “Platform Content”) are owned by Transect or its
            third-party licensors, as applicable, and are protected by
            intellectual property law. Transect is (and shall be) the sole and
            exclusive owner of all right, title, and interest (throughout the
            world) in and to all the Platform Content, including, but not
            limited to, development of any reports. Any reproduction,
            distribution, modification, retransmission, or publication of any
            copyrighted material is strictly prohibited without the express
            written consent of Transect.
          </span>
        </div>
        <div>
          <span class="body-medium">7) </span>
          <span class="label-large">PROHIBITED USES. </span>
          <span class="body-medium">
            Client expressly agrees that Client shall not, and will not permit
            any user or third party to, directly or indirectly:
          </span>
          <ul>
            <li>
              reverse engineer, decompile, disassemble, hack, interfere with,
              copy, translate, or disrupt the Platform or the Platform Content,
              or otherwise attempt to discover the object code, source code or
              underlying ideas or algorithms of the Platform or Platform
              Content;
            </li>
            <li>
              modify, translate, or create derivative works based on any element
              of the Platform Content;
            </li>
            <li>
              rent, lease, distribute, sell, resell, assign, or otherwise
              transfer its rights to use the Platform;
            </li>
            <li>
              permit any person that is not an Authorized User to access or use
              the Platform;
            </li>
            <li>remove any proprietary notices from the Platform Content;</li>
            <li>
              use the Platform to store or transmit any content that may
              infringe upon or misappropriate a third party’s trademark,
              copyright, or other intellectual property, or violate any law;
            </li>
            <li>
              use the Platform in order to build a similar or competitive
              product or service, or copy any ideas, features, functions, or
              graphics of the Platform;
            </li>
            <li>
              introduce any open-source software into the Platform or attempt to
              scrape results;
            </li>
            <li>
              upload to, or transmit from, the Platform any data, file,
              software, or link that contains or redirects to a virus, Trojan
              horse, worm, or other harmful component or a technology that
              unlawfully accesses or downloads content or information stored
              within the Platform or on the hardware of Transect or any third
              party;
            </li>
            <li>
              send altered or false source-identifying information, including
              "spoofing" or "phishing"; or
            </li>
            <li>
              attempt to gain unauthorized access to the Platform or Transect’s
              related systems or networks or to defeat, avoid, bypass, remove,
              deactivate, or otherwise circumvent any software protection or
              monitoring mechanisms of the Platform.
            </li>
          </ul>
          <span class="body-medium">
            If Client or any Authorized User violates any portion of this
            paragraph, Client’s right to access the Platform and Platform
            Content shall immediately terminate, and Client shall immediately
            cease use of the Platform and destroy any downloaded or printed
            Platform Content. In addition, Client shall be liable to Transect
            for any and all damages that result from such violation(s).
          </span>
        </div>

        <div>
          <span class="body-medium">8) </span>
          <span class="label-large">SUSPENSION OF ACCESS. </span>
          <span class="body-medium"
            >Transect may suspend Client’s access to the Platform if it
            suspects, rightly or wrongly, that Client has breached Paragraph 7
            of this Agreement or has violated any applicable laws. Client
            expressly agrees that Transect will not be liable to Client or any
            third party if Transect exercises the foregoing suspension right.
            Additionally, Client agrees to fully assist Transect in
            investigating any potential violations of law or of Paragraph 7
            until such time as Transect can conclude its investigation and
            determine that no violations are ongoing. </span
          >
        </div>
        <div>
          <span class="body-medium">9) </span>
          <span class="label-large"
            >DISCLAIMER OF WARRANTIES RE: PLATFORM.
          </span>
          <span class="body-medium"
            >Transect endeavors to furnish accurate reports, surveys,
            assessments, and/or summaries that are free from material errors or
            omissions. However, the information that Transect utilizes in
            generating its Platform content is derived from various third-party
            sources, such as public and private databases, records, and files,
            and Transect cannot independently verify the correctness or
            completeness of such information. As a result, Transect cannot
            guarantee the accuracy of such third-party information or that the
            Platform content represents the full scope of available information.
            Transect makes no representations, warranties or guarantees as to
            the quality, suitability, truth, accuracy or completeness of the
            Platform content, or that the quality and reliability of the
            Platform content will meet Client’s expectations or requirements.
            Any report provided through the Platform may contain additional
            information regarding the limitations or qualifications that pertain
            to that report. It is expressly understood by Client that the
            content provided through the Platform is provided for informational
            purposes only, and on an “AS IS” basis. The use of (and access to)
            the Platform and its content is at Client’s sole risk without any
            express or implied warranty of any kind. TRANSECT MAKES NO WARRANTY
            WHATSOEVER WITH RESPECT TO ITS SERVICES, INCLUDING ANY (a) WARRANTY
            OF MERCHANTABILITY; (b) WARRANTY OF FITNESS FOR A PARTICULAR
            PURPOSE; OR (c) WARRANTY OF TITLE; WHETHER EXPRESS OR IMPLIED BY
            LAW, AND THE SAME ARE EXPRESSLY WAIVED AND DISCLAIMED BY CLIENT.
            Transect shall not be liable to Client any loss or damage that may
            incur as a result of any reliance by Client on the accuracy,
            completeness or suitability of the Platform content.
          </span>
        </div>
        <div>
          <span class="body-medium">10) </span>
          <span class="label-large"
            >DISCLAIMER OF WARRANTIES RE: SOLAR PULSE.
          </span>
          <span class="body-medium"
            >This paragraph shall apply to Service Plans that include Transect’s
            Solar Pulse feature. Solar Pulse is an artificial intelligence (AI)
            tool that relies on machine learning to assess public sentiment
            towards solar energy development by analyzing various public and
            private data sources. Artificial intelligence and machine learning
            are new and rapidly evolving fields of technology that have not
            fully matured. Given the current limitations of machine learning,
            Solar Pulse may result in content that does not accurately reflect
            the true nature of solar energy development. Moreover, the
            information that Solar Pulse utilizes is derived from various
            third-party sources, such as public and private databases, records,
            and news articles, and Transect cannot independently verify the
            correctness or completeness of such information. As a result,
            Transect cannot guarantee the accuracy of Solar Pulse content and
            Client should not rely on Solar Pulse content as a sole source of
            truth or factual information, or as a substitute for professional
            advice. Transect makes no representations, warranties or guarantees
            as to the quality, suitability, truth, accuracy or completeness of
            Solar Pulse content, or that the quality and reliability of Solar
            Pulse content will meet Client’s expectations or requirements. It is
            expressly understood by Client that the content provided through
            Solar Pulse is provided for informational purposes only, and on an
            “AS IS” basis. The use of (and access to) Solar Pulse and its
            content is at Client’s sole risk without any express or implied
            warranty of any kind. Transect shall not be liable to Client for any
            loss or damage that may incur as a result of any reliance by Client
            on the accuracy, completeness or suitability of Solar Pulse content.
            TRANSECT MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO SOLAR PULSE,
            INCLUDING ANY (a) WARRANTY OF MERCHANTABILITY; (b) WARRANTY OF
            FITNESS FOR A PARTICULAR PURPOSE; (c) WARRANTY OF SUITABILITY; OR
            (d) WARRANTY OF TITLE; WHETHER EXPRESS OR IMPLIED BY LAW, AND THE
            SAME ARE EXPRESSLY WAIVED AND DISCLAIMED BY CLIENT. ADDITIONALLY,
            TRANSECT DOES NOT WARRANT THAT SOLAR PULSE CONTENT WILL BE
            UNINTERRUPTED, ACCURATE OR ERROR FREE, OR THAT ANY CONTENT WILL BE
            SECURE, PRESERVED, OR NOT ALTERED. Solar Pulse content is considered
            “Platform Content” for purposes of this Agreement.</span
          >
        </div>
        <div>
          <span class="body-medium">11) </span>
          <span class="label-large">THIRD PARTY RELIANCE. </span>
          <span class="body-medium"
            >All content, documents, reports, surveys, assessments,
            recommendations, findings, summaries, letters, drawings,
            specifications, and all Platform Content (collectively “documents”),
            which are created, furnished, or produced by Transect under this
            Agreement are instruments of their service and are provided for
            Client’s internal use only. Such documents are not intended to be
            relied upon by any third-party persons or organizations. Transect
            fully disclaims all forms of liability from any third-party reliance
            claims based on the documents, and Client agrees to indemnify and
            hold Transect harmless from such third-party claims.  </span
          >
        </div>
        <div>
          <span class="body-medium">12) </span>
          <span class="label-large">INDEMNIFICATION. </span>
          <span class="body-medium"
            >Client shall defend, hold harmless, and indemnify Transect and its
            officers, directors, employees, contractors, agents, affiliates,
            successors, and assigns from and against all losses, damages,
            liabilities, deficiencies, lawsuits, actions, claims, judgments,
            interest, awards, penalties, fines, costs, or expenses of whatever
            kind (including attorneys’ fees) which arise out of or result from
            (a) Client’s use of the Platform; (b) Client’s reliance on the
            Platform Content; or (c) any third-party’s reliance on the Platform
            Content that was provided by Client or Client’s representatives.
            This indemnification shall survive the termination of this
            Agreement.</span
          >
        </div>
        <div>
          <span class="body-medium">13) </span>
          <span class="label-large">LIMITATION OF LIABILITY. </span>
          <span class="body-medium"
            >IN NO EVENT SHALL TRANSECT BE LIABLE TO CLIENT OR TO ANY THIRD
            PARTY FOR ANY LOSS OF USE, REVENUE, PROFIT, OR BUSINESS, OR FOR ANY
            CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE
            DAMAGES REGARDLESS OF WHETHER SUCH DAMAGE WAS FORESEEABLE, UNLESS
            SUCH DAMAGES RESULT FROM TRANSECT’S WILLFUL MISCONDUCT. IN NO EVENT
            SHALL TRANSECT’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO
            THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF
            CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, EXCEED THE
            AGGREGATE AMOUNTS PAID BY CLIENT TO TRANSECT FOR THE SUBSCRIPTION
            SERVICE DURING THE PREVIOUS 24 MONTHS. ADDITIONALLY, IN NO EVENT
            SHALL TRANSECT’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO
            SOLAR PULSE EXCEED TEN DOLLARS ($10). These allocations of risk
            shall apply regardless of the causes of action or legal theories
            that are pled or asserted.</span
          >
        </div>
        <div>
          <span class="body-medium">14) </span>
          <span class="label-large">TERMINATION. </span>
          <span class="body-medium"
            >This Agreement will become effective when signed by Client and
            shall continue until terminated by either Party, except that the
            terms of Paragraphs 1(b), 7, 9, 10, 11, 12, 13 and 15 shall all
            survive termination of this Agreement. Transect may terminate this
            Agreement upon written notice to Client at any time and for any
            reason. If Transect terminates this Agreement for reasons other than
            Client’s breach of this Agreement, then Transect shall refund Client
            on a pro-rated basis for any remaining (future) period of a Service
            Plan that has been prepaid by Client. If the Subscription Service is
            terminated by Client prior to the end of its Billing Period, Client
            shall continue to have access to the Platform until the end of that
            Billing Period. Thereafter, Client shall no longer have access to
            the Platform or any of Client’s information contained within the
            Platform, and Client shall have no right to demand that Transect
            provide Client with such information.</span
          >
        </div>
        <div>
          <span class="body-medium">15) </span>
          <span class="label-large">MISCELLANEOUS. </span>
        </div>
        <div>
          <span class="body-medium">a) </span>
          <span class="label-large">Incorporated Policies. </span>
          <span class="body-medium"
            >This Agreement incorporates Transect’s Privacy Policy and its
            Website Terms of Service, which can each be found on Transect’s
            website at transect.com.  </span
          >
        </div>
        <div>
          <span class="body-medium">b) </span>
          <span class="label-large">Governmental Use. </span>
          <span class="body-medium"
            >This Agreement is not enforceable between Transect and any
            governmental entity unless a mutually acceptable written addendum is
            executed contemporaneously with this Agreement. </span
          >
        </div>
        <div>
          <span class="body-medium">c) </span>
          <span class="label-large">Use of Client Name. </span>
          <span class="body-medium"
            >Transect may use and disclose Client’s name, logo, and length of
            business relationship on its website, promotional materials, or any
            other medium for the advertisement of Transect’s services. Client
            may revoke its consent for Transect to use such information at any
            time upon written notice.  </span
          >
        </div>
        <div>
          <span class="body-medium">d) </span>
          <span class="label-large">Retention of Information. </span>
          <span class="body-medium"
            > Transect shall not be obligated to maintain any Client
            information, documents, materials, or files after termination of
            Client’s Subscription Service.
          </span>
        </div>
        <div>
          <span class="body-medium">e) </span>
          <span class="label-large">No Assignment. </span>
          <span class="body-medium"
            >Client may not assign any part of this Agreement to any other
            person or organization.</span
          >
        </div>

        <div>
          <span class="body-medium">f) </span>
          <span class="label-large">Amendments. </span>
          <span class="body-medium"
            >No changes or alterations to this Agreement shall be binding upon
            either Party unless executed in a writing signed by that
            Party.</span
          >
        </div>

        <div>
          <span class="body-medium">g) </span>
          <span class="label-large">Severability. </span>
          <span class="body-medium"
            >In the event any provision or provisions of this Agreement is held
            to be invalid or unenforceable by any court of law or otherwise, the
            remaining provisions of this Agreement shall nevertheless continue
            to be valid and enforceable as though the invalid or unenforceable
            parts had not been included therein.</span
          >
        </div>

        <div>
          <span class="body-medium">h) </span>
          <span class="label-large">Waiver Reservation. </span>
          <span class="body-medium"
            >The waiver by either Party of a breach of any provision of this
            Agreement shall not operate or be construed as a waiver of any other
            breach.</span
          >
        </div>

        <div>
          <span class="body-medium">i) </span>
          <span class="label-large">Force Majeure. </span>
          <span class="body-medium"
            >Transect shall not be responsible for any delay in performance or
            failure to meet its obligations under this Agreement to the extent
            caused, directly or indirectly, by Client’s failure to reasonably
            cooperate, or any event beyond Transect’s reasonable control
            including, but not limited to fire, flood, severe weather,
            earthquake, pandemic, strike, labor shortages, power failures, civil
            disturbances, embargo, war, or any other acts of god.</span
          >
        </div>

        <div>
          <span class="body-medium">j) </span>
          <span class="label-large">Governing Law. </span>
          <span class="body-medium"
            >This Agreement shall be governed by the laws of the State of Texas
            without regard to its conflicts of laws principles.</span
          >
        </div>

        <div>
          <span class="body-medium">k) </span>
          <span class="label-large">Venue for Disputes. </span>
          <span class="body-medium"
            >If a dispute or claim arises out of this Agreement or any
            amendments to this Agreement, the Parties will attempt to settle the
            dispute between each other. If unsuccessful, the Parties agree to
            settle any such dispute or claim through mediation offered by the
            American Arbitration Association (AAA). The mediation shall be held
            within 30 days of the demand for mediation, and costs of the
            mediation shall be borne equally by the parties. Notwithstanding
            anything to the contrary above, the Parties agree that the mediation
            proceedings shall be held in Travis County, Texas. If the Parties
            are unable to resolve the matter through mediation, absent any
            contrary agreement in writing between the parties, either party may
            institute litigation in a court of law, and it is agreed that no
            offers, communications, presentations or evidence made or presented
            in the course of the mediation shall be admissible in the
            litigation. Any lawsuits that arise from this Agreement shall be
            litigated in the state or federal courts in Travis County, Texas.
            The prevailing party in the litigation shall be entitled to recover
            reasonable attorney’s fees, court costs, and expenses.</span
          >
        </div>

        <div>
          <span class="body-medium">l) </span>
          <span class="label-large">Entire Agreement. </span>
          <span class="body-medium"
            >Except for the terms and conditions of each Service Plan selected
            by Client, this Agreement constitutes the entire agreement of the
            Parties with respect to the subject matter contained herein, and
            supersedes all prior understandings, agreements, representations,
            and warranties, both written and oral, regarding such subject
            matter. Nothing in this Agreement shall be construed to give any
            rights or benefits in this Agreement to anyone other than Client and
            Transect, and all duties and responsibilities undertaken pursuant to
            this Agreement are for the sole and exclusive benefit of Client and
            Transect and not for the benefit of any other party.</span
          >
        </div>
      </div>
      <form
        class="form-container"
        [formGroup]="freeTrialAgreementForm"
        tsFormLoader
        [loaderState]="completing$ | async"
      >
        <div class="form-field-container">
          <mat-checkbox formControlName="agreed" required>
            I agree to the Transect Trial Agreement
          </mat-checkbox>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="(isComplete$ | async) === false; else downloadPdf">
    <button
      transect-nx-button
      [color]="(saveButtonColor$ | async) ?? 'primary'"
      buttonStyle="contained"
      size="md"
      tsBtnLoader
      [loaderState]="(inProgress$ | async) === true"
      (click)="handleSaveClick()"
      [disabled]="(canSave$ | async) === false"
    >
      > Continue
    </button>
    <span class="body-small text-shades-gray-dark"
      >Please review the whole Agreement in order to continue.</span
    >
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>
