import { z } from 'zod';
import { GeometriesOrCollectionSchema } from '../../../utils/geometry-object-schema';

export const ReportPartFeatureDTO = z.object({
  _id: z.string().uuid(),
  properties: z.object({}).passthrough().deepPartial().nullable(),
  geometry: GeometriesOrCollectionSchema,
  type: z.literal('Feature'),
});

export type ReportPartFeatureDTO = z.infer<typeof ReportPartFeatureDTO>;
