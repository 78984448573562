import { z } from 'zod';

export enum CustomerUserRole {
  USER = 'user',
  ADMIN = 'admin',
}

export enum CustomerInviteStatus {
  INVITED = 'invited',
}

/** Body for post / */
export const CustomerInviteCreateControllerBody = z.object({
  role: z.nativeEnum(CustomerUserRole),
  email: z.string().email(),
  status: z.nativeEnum(CustomerInviteStatus),
  creator__id: z.string().uuid(),
  customer__id: z.string().uuid(),
});

export type CustomerInviteCreateControllerBody = z.infer<
  typeof CustomerInviteCreateControllerBody
>;
