<transect-nx-flyover-modal
  [contentTemplate]="template$ | async"
  [isOpen]="isOpen$ | async"
  [minWidth]="minWidth$ | async"
  [maxWidth]="maxWidth$ | async"
  [shouldCloseOnBackdropClick]="shouldCloseOnBackdropClick$ | async"
  (isOpenChange)="handleOpenChange($event)"
  (backdropClick)="handleBackdropClicked()"
  (navigatingAway)="handleClose()"
>
</transect-nx-flyover-modal>
