import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { CatalogItem } from '../catalog-item';
import { ReferenceDTO } from '../reference';

export const CatalogReference = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema.optional().nullable(),
  updated_at: DateSchema.optional().nullable(),
  deleted_at: DateSchema.optional().nullable(),
  catalog_item: CatalogItem.deepPartial().optional(),
  reference: ReferenceDTO.deepPartial().optional(),
});

export type CatalogReference = z.infer<typeof CatalogReference>;
