import { z } from 'zod';
import { DateAsString, DateSchema } from '../../utils/date-schema';
import { FeatureCollectionSchema } from '../../utils/geometry-object-schema';
import { stringToJSON } from '../../utils/string-to-json';
import { safeNumber, stringToNumber } from '../../utils/string-to-number';
import { AssessmentLevels } from '../report-part-features';

export enum ReportPartType {
  PARCEL = 'parcel',
  TRANSPORTATION = 'transportation',
  GEOGRAPHY = 'geography',
  SPECIES_LOCATION = 'species_location',
  STATE_SPECIES_LOCATION = 'state_species_location',
  HYDROGRAPHY = 'hydrography',
  SOIL = 'soil',
  CATALOG_LOCATION = 'catalog_location',
  REGULATION_LOCATION = 'regulation_location',
  GEOLOGY = 'geology',
  FIRM_PANEL = 'firm_panel',
  FLOOD_HAZARD = 'flood_hazard',
  ECOREGION = 'ecoregion',
  PROTECTED_AREA = 'protected_area',
  CONTOUR = 'contour',
  ENVIRONMENTAL_COMPLIANCE = 'environmental_compliance',
  WETLAND = 'wetland',
  SLOPE = 'slope',
  SLOPE2 = 'slope2',
  LAND_COVER = 'landcover',
  WATERSHED = 'watershed',
  ASPECT = 'aspect',
  INTERCONNECTION_DATA = 'interconnection_data',
}

export enum ReportPartStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  ERROR = 'error',
  TRIGGERS_ASSESSED = 'triggersAssessed',
  RESET = 'reset',
  PENDING_REGENERATION = 'pending-regeneration',
  TIMEOUT = 'timeout',
}

export const RegulationAssessmentOverrides = z.object({
  notes: z.string().nullish(),
  adminOverridden: z.boolean().nullish(),
  overridden_by: z.string().uuid().nullish(),
  overridden_date: DateAsString.nullish(),
  overridden_role: z.string().nullish(),
  value: z.string().nullish(),
});

export type RegulationAssessmentOverrides = z.infer<
  typeof RegulationAssessmentOverrides
>;

export const ReportPartDTO = z.object({
  _id: z.string().uuid(),
  type: z.nativeEnum(ReportPartType),
  status: z.nativeEnum(ReportPartStatus),
  progress: z.preprocess(
    (val) => stringToNumber(val as string),
    z.number().min(0).max(100),
  ),
  epoch: safeNumber.default(0),
  summary: z.any(),
  assessments: z.any(),
  data: z.any(),
  errors: z.any(),
  feature_collection: FeatureCollectionSchema.partial(),
  report__id: z.string().uuid().nullable(),
  execution_start: DateSchema.nullable(),
  execution_end: DateSchema.nullable(),
  created_at: DateSchema.nullable(),
  updated_at: DateSchema.nullable(),
  deleted_at: DateSchema.nullable(),
});

export type ReportPartDTO = z.infer<typeof ReportPartDTO>;
