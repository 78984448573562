import { Component } from '@angular/core';

@Component({
  selector: 'transect-nx-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  // No logic here for now, as this component is only used for styling.
}
