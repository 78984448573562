<div class="skeleton-composite-loading">
  <div
    *ngFor="let item of [].constructor(count)"
    class="skeleton-composite-item"
  >
    <ts-skeleton-circle-loading
      [diameter]="diameter"
      [ngStyle]="shouldCenterAlignCircle ? { 'align-self': 'center' } : {}"
    ></ts-skeleton-circle-loading>
    <ts-skeleton-line-loading
      [height]="lineHeight"
      [count]="lineCount"
      [lineGap]="0.6"
    ></ts-skeleton-line-loading>
  </div>
</div>
