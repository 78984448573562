export enum Purpose {
  STAFF_GEOSPATIAL_IMPORT = 'staff-geospatial-import',
  COMPANY_LOGO = 'company-logo',
  STAFF_REFERENCE_IMPORT = 'staff-reference-import',
  REPORT_ADD_ON_DELIVERABLE = 'report-add-on-deliverable',
  PROJECT_IMPORT = 'project-import',
  USER_CATALOG_ITEMS = 'user-catalog-items',
  SPECIES_PROFILE_IMAGE = 'species-profile-image',
  PROFILE_PHOTO = 'profile-photo',
  TRANSECT_EXPORT = 'transect-export',
  FORM = 'form',
  SOLAR_REGULATION = 'solar-regulation',
  CUSTOMER_FILE = 'customer-file',
}
