import { z } from 'zod';

export const ProjectUpsellInformationDTO = z
  .object({
    buildable_area_percentage: z.number().nullable(),
    permits_count: z.number().nullable(),
    species_level: z.enum(['high', 'moderate', 'low']).nullable(),
    solar_sentiment: z.enum(['positive', 'negative']).nullable(),
    regulations_count: z.number().nullable(),
  })
  .nullable();

export type ProjectUpsellInformationDTO = z.infer<
  typeof ProjectUpsellInformationDTO
>;
