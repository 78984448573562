import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TransectButtonsModule } from '../transect-buttons/transect-buttons.module';
import { FlyoverBottomControlsComponent } from './components/flyover-bottom-controls/flyover-bottom-controls.component';
import { FlyoverContentComponent } from './components/flyover-content/flyover-content.component';
import { FlyoverHeaderControlsComponent } from './components/flyover-header-controls/flyover-header-controls.component';
import { FlyoverHeaderComponent } from './components/flyover-header/flyover-header.component';
import { FlyoverModalTemplateComponent } from './components/flyover-modal-template/flyover-modal-template.component';
import { FlyoverModalComponent } from './flyover-modal.component';

@NgModule({
  declarations: [
    FlyoverModalComponent,
    FlyoverHeaderComponent,
    FlyoverHeaderControlsComponent,
    FlyoverContentComponent,
    FlyoverBottomControlsComponent,
    FlyoverModalTemplateComponent,
  ],
  imports: [CommonModule, OverlayModule, TransectButtonsModule],
  exports: [
    FlyoverModalComponent,
    FlyoverHeaderComponent,
    FlyoverHeaderControlsComponent,
    FlyoverContentComponent,
    FlyoverBottomControlsComponent,
    FlyoverModalTemplateComponent,
  ],
})
export class FlyoverModalModule {}
