import { z } from 'zod';

const CatalogGroup = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
});

const CatalogItem = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  geography: z
    .object({
      _id: z.string().uuid(),
      name: z.string().nullable(),
      abbreviation: z.string().nullable(),
    })
    .nullable(),
  category: z.string().nullable(),
  catalog_group: CatalogGroup.deepPartial(),
});

export const ReportPartCatalogItemDTO = CatalogItem;

export type ReportPartCatalogItemDTO = z.infer<typeof ReportPartCatalogItemDTO>;
