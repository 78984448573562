import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormLegalAgreementAllExemptionsResponseDTO,
  FormLegalAgreementExemptionResponseDTO,
  FormTypeKey,
} from '@transect-nx/data-transfer-objects';
import { map } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FormLegalAgreementExemptionApiService {
  private readonly BASE_URL = `${environment.apiUrl}/form-legal-agreement-exemptions`;
  constructor(private http: HttpClient) {}

  /**
   * fetches form type exemption for the customer of the logged in user
   */
  fetchFormTypeExemption(formTypeKey: FormTypeKey) {
    return this.http
      .get<FormLegalAgreementExemptionResponseDTO>(
        `${this.BASE_URL}/form-types/${formTypeKey}`,
      )
      .pipe(
        map((response) =>
          FormLegalAgreementExemptionResponseDTO.parse(response),
        ),
      );
  }

  /**
   * fetches all form type exemptions for the customer of the logged in user
   */
  fetchAllFormTypeExemptions() {
    return this.http
      .get<FormLegalAgreementAllExemptionsResponseDTO>(
        `${this.BASE_URL}/form-types`,
      )
      .pipe(
        map((response) =>
          FormLegalAgreementAllExemptionsResponseDTO.parse(response),
        ),
      );
  }
}
