import { z } from 'zod';
import { OrderEnum } from '../../../utils/order-enum';
import { SortableReportMapLayerProperties } from '../report-map-object';
export const ReportMapListPayload = z
  .object({
    page: z
      .string()
      .default('1')
      .transform((val, ctx) => {
        const parsedAsInt = parseInt(val);
        if (isNaN(parsedAsInt)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `'page must be an integer`,
          });
        }
        return parsedAsInt;
      }),
    pageSize: z
      .string()
      .default('100')
      .transform((val, ctx) => {
        const parsedAsInt = parseInt(val);
        if (isNaN(parsedAsInt)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `'pageSize' must be an integer`,
          });
        }
        return parsedAsInt;
      }),
    sortBy: z
      .preprocess(
        (val) => (typeof val === 'string' ? [val] : val),
        z.array(SortableReportMapLayerProperties.default('created_at')),
      )
      .default(['created_at']),
    order: z
      .preprocess(
        (val) => (typeof val === 'string' ? [val] : val),
        z.array(OrderEnum.default('desc')),
      )
      .default(['desc']),
    report__id: z.string(),
  })
  .strict();
export type ReportMapListPayload = z.infer<typeof ReportMapListPayload>;
