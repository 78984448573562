import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartHydrographyFeatureDTO } from '../models/report-part-hydrography-feature.dto';

export const PaginatedReportPartHydrographyFeaturesDTO = PaginatedResponseRows(
  ReportPartHydrographyFeatureDTO,
);

export type PaginatedReportPartHydrographyFeaturesDTO = z.infer<
  typeof PaginatedReportPartHydrographyFeaturesDTO
>;
