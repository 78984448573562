import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { TransectButtonsModule } from '@transect-nx/ui';
import { ForcedUpdateDialogComponent } from './components/unrecoverable-state-dialog/forced-update-dialog.component';

@NgModule({
  declarations: [ForcedUpdateDialogComponent],
  imports: [
    CommonModule,
    TransectButtonsModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
})
export class ForcedUpdateDialogModule {}
