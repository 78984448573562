import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { CustomerInviteObject } from '../customer-invite-object';

export const CustomerInviteDTO = CustomerInviteObject;

export type CustomerInviteDTO = z.infer<typeof CustomerInviteDTO>;

export const CustomerInviteResponseRows = ResponseRows(CustomerInviteDTO);
export type CustomerInviteResponseRows = z.infer<
  typeof CustomerInviteResponseRows
>;
