import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InProgressRequestDTO } from '@transect-nx/data-transfer-objects';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InProgressRequestsApiService {
  private readonly baseEndPoint = `${environment.apiUrl}/in-progress-requests`;

  constructor(private readonly http: HttpClient) {}

  getAllInProgressRequests() {
    return this.http.get<InProgressRequestDTO[]>(`${this.baseEndPoint}/all`);
  }
}
