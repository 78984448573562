import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecommendationModule } from '../recommendation/recommendation.module';
import { ListStatusClassPipe } from './pipes/list-status-class.pipe';
import { SpeciesListItemComponent } from './species-list-item.component';

export { SpeciesListItemComponent };

@NgModule({
  imports: [CommonModule, RecommendationModule],
  declarations: [SpeciesListItemComponent, ListStatusClassPipe],
  exports: [SpeciesListItemComponent],
})
export class SpeciesListItemModule {}
