import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { ReportPartType } from '../report-part';

export enum ReportExportType {
  PDF = 'pdf',
  KML = 'kml',
  TERABASE = 'kml-tb',
  WATERS_SAMPLE = 'pdf-wr',
  MINI_REPORT = 'pdf-mr',
  LINEAR_DATA = 'linear-data',
}

export enum ReportExportFileType {
  PDF = 'pdf',
  KML = 'kml',
  SHP = 'shp',
  DXF = 'dxf',
  ZIP = 'zip',
  XLSX = 'xlsx',
}

export const ReportExportLinearDataOptions = z
  .object({
    version: z.number(),
    configuration: z.array(
      z.object({
        data: z.object({
          key: z.nativeEnum(ReportPartType),
        }),
      }),
    ),
  })
  .nullish();
export type ReportExportLinearDataOptions = z.infer<
  typeof ReportExportLinearDataOptions
>;

export const ReportExportOptions = z
  .object({
    version: z.number(),
    configuration: z.array(z.any()),
    meta: z
      .object({
        includeNote: z.boolean().optional(),
      })
      .optional(),
  })
  .nullish();
export type ReportExportOptions = z.infer<typeof ReportExportOptions>;

export const ReportExportsPayload = z.object({
  type: z.nativeEnum(ReportExportType),
  file_type: z.nativeEnum(ReportExportFileType),
  name: z.string().optional(),
  report__id: z.string().uuid().optional(),
  recipient_emails: z.string().optional(),
  options: ReportExportOptions,
});
export type ReportExportsPayload = z.infer<typeof ReportExportsPayload>;

export const ReportExportsDTO = ReportExportsPayload.extend({
  _id: z.string().uuid(),
  status: z.string(),
  url: z.string().nullable(),
  url_expires_at: z.string().nullable(),
  gcs_url: z.string().nullable(),
  creator__id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullable(),
});
export type ReportExportsDTO = z.infer<typeof ReportExportsDTO>;
