import { z } from 'zod';
import { ResponseRows } from '../../utils/response-rows';
import { RegulationPermitDTO } from './regulation-permit-dto';
import { PermitDTO } from '../permit';
import { RegulationDTO } from '../regulation/regulation-dto';

export const RegulationPermitsWithLoadedPermitsDTO = PermitDTO.extend({
  regulation_permit: RegulationPermitDTO,
});
export type RegulationPermitsWithLoadedPermitsDTO = z.infer<
  typeof RegulationPermitsWithLoadedPermitsDTO
>;

export const RegulationPermitsWithLoadedRegsDTO = RegulationDTO.extend({
  regulation_permit: RegulationPermitDTO,
});
export type RegulationPermitsWithLoadedRegsDTO = z.infer<
  typeof RegulationPermitsWithLoadedRegsDTO
>;

export const RegulationPermitsDTO = z.union([
  RegulationPermitsWithLoadedPermitsDTO,
  RegulationPermitsWithLoadedRegsDTO,
]);
export type RegulationPermitsDTO = z.infer<typeof RegulationPermitsDTO>;

export const RegulationPermitsIndexDTO = ResponseRows(RegulationPermitsDTO);
export type RegulationPermitsIndexDTO = z.infer<
  typeof RegulationPermitsIndexDTO
>;
