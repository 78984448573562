import { z } from 'zod';
import { SpeciesLocationCreateControllerBody } from './create-controller';

export const SpeciesLocationUpdateControllerBody =
  SpeciesLocationCreateControllerBody.omit({
    geometry: true,
    species__id: true,
  });

export type SpeciesLocationUpdateControllerBody = z.infer<
  typeof SpeciesLocationUpdateControllerBody
>;

export const SpeciesLocationBulkUpdateControllerBody = z.object({
  ids: z.array(z.string().uuid()),
  attribute: z.string(),
  value: z.unknown(),
});

export type SpeciesLocationBulkUpdateControllerBody = z.infer<
  typeof SpeciesLocationBulkUpdateControllerBody
>;
