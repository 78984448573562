import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ResponseRows } from '../../../utils/response-rows';
import { ReportConcernLevel } from '../report.dto';

const ReportContext = z.object({
  expert_review: z.string().nullish(),
  federal_funds: z.string().nullish(),
  project_type: z.string().nullish(),
  project_subtype: z.string().nullish(),
  construction_type: z.string().nullish(),
  plan_to_impact_waters: z.string().nullish(),
});

export const ReportIndexDTO = z
  .object({
    mapbox_static_url: z.string().nullish(),
    isComplete: z.boolean(),
    _id: z.string().uuid(),
    name: z.string(),
    type: z.string(),
    status: z.string(),
    status_review: z.string(),
    project__id: z.string().uuid().nullish(),
    created_at: DateSchema,
    context: ReportContext,
    concern_levels: z
      .object({
        waters: ReportConcernLevel,
        species: ReportConcernLevel,
        state_species: ReportConcernLevel,
        protected_area: ReportConcernLevel,
        environmental_compliance: ReportConcernLevel,
      })
      .nullish(),
    review_completed_at: DateSchema.nullable(),
    project_invite_count: z.preprocess(
      (val) => (typeof val === 'string' ? Number(val) : val),
      z.number(),
    ),
    project_user_count: z.preprocess(
      (val) => (typeof val === 'string' ? Number(val) : val),
      z.number(),
    ),
    report_parts_complete_pct: z.preprocess(
      (val) => (typeof val === 'string' ? Number(val) : val),
      z.number(),
    ),
    report_internal_comments: z.preprocess(
      (val) => (typeof val === 'string' ? Number(val) : val),
      z.number(),
    ),
    project: z
      .object({
        _id: z.string().uuid(),
        name: z.string(),
        client_identifier: z.string().nullish(),
        geographies_states: z
          .array(
            z.object({
              state: z.string(),
              counties: z.array(z.string()),
              abbreviation: z.string(),
              geography__id: z.string().uuid(),
            }),
          )
          .nullish(),
        customer: z
          .object({
            account_name: z.string(),
            terms_accepted: z.boolean(),
            _id: z.string().uuid(),
            name: z.string(),
            firstname: z.string(),
            lastname: z.string(),
            terms_accepted_date: DateSchema.nullish(),
          })
          .nullish(),
      })
      .nullish(),
    creator: z
      .object({
        fullname: z.string(),
        public: z.object({
          firstname: z.string(),
          lastname: z.string(),
          fullname: z.string(),
        }),
        firstname: z.string(),
        lastname: z.string(),
        _id: z.string().uuid(),
      })
      .nullish(),
    assignee: z
      .object({
        fullname: z.string(),
        public: z.object({
          firstname: z.string(),
          lastname: z.string(),
          fullname: z.string(),
        }),
        firstname: z.string(),
        lastname: z.string(),
        _id: z.string().uuid(),
      })
      .nullish(),
    order: z
      .object({
        _id: z.string().uuid(),
        billing: z.object({
          email: z.string().nullish(),
          phone: z.string().nullish(),
          method: z.string().nullish(),
          lastname: z.string().nullish(),
          firstname: z.string().nullish(),
        }),
        total: z.number().nullish(),
        created_at: DateSchema,
        updated_at: DateSchema,
      })
      .nullish(),
    report_sample: z
      .object({
        _id: z.string().uuid(),
        email: z.string(),
      })
      .nullish(),
    review_requested_at: DateSchema,
  })
  .deepPartial();

export type ReportIndexDTO = z.infer<typeof ReportIndexDTO>;

export const ReportIndexResponseDTO = ResponseRows(ReportIndexDTO);
export type ReportIndexResponseDTO = z.infer<typeof ReportIndexResponseDTO>;
