import { z } from 'zod';
import { DateSchema } from '../utils/date-schema';

export enum InProgressRequestType {
  BUILDABLE_AREA = 'buildableArea',
  CSV_EXPORT = 'csvExport',
  KML_EXPORT = 'kmlExport',
  PDF_EXPORT = 'pdfExport',
  REPORT = 'report',
}

export const InProgressRequestDTO = z.object({
  _id: z.string().uuid(),
  type: z.nativeEnum(InProgressRequestType),
  name: z.string(),
  description: z.string(),
  status: z.string(),
  progressPercentage: z.number().nullish(),
  downloadUrl: z.string().nullish(),
  createdAt: DateSchema,
  context: z
    .object({
      projectId: z.string().optional(),
      reportConcernLevel: z.any().optional(),
    })
    .optional(),
});

export type InProgressRequestDTO = z.infer<typeof InProgressRequestDTO>;
