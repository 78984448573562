import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecommendationComponent } from './recommendation.component';

@NgModule({
  imports: [CommonModule],
  declarations: [RecommendationComponent],
  exports: [RecommendationComponent],
})
export class RecommendationModule {}
