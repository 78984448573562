<transect-nx-dialog-modal
  title="ALTA Scope of Work"
  [footer]="true"
  [contentTemplate]="contentTemplate"
  [footerTemplate]="footerTemplate"
  [controlMargin]="true"
>
</transect-nx-dialog-modal>

<ng-template #contentTemplate>
  <div
    class="content-container"
    tsDetectScrollToEnd
    (scrollEnd)="handleScrollToEnd()"
  >
    <ts-alta-scope-of-work-form
      [displaySelectedOptions]="selectedOptions"
      [disabled]="true"
    ></ts-alta-scope-of-work-form>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="(isComplete$ | async) === false; else downloadPdf">
    <button
      transect-nx-button
      [color]="(saveButtonColor$ | async) ?? 'primary'"
      buttonStyle="contained"
      size="md"
      (click)="handleSaveClick()"
      [disabled]="(canSave$ | async) === false"
      tsBtnLoader
      [loaderState]="inProgress$ | async"
    >
      Save
    </button>
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>
