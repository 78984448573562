import { z } from 'zod';
export const PublishEventPayload = z.object({
  action: z.string(),
  association__id: z.string().uuid().optional(),
  category: z.string(),
  label: z.string().optional(),
  value: z.number().optional(),
  user__id: z.string().uuid().optional(),
  metadata: z.object({}).passthrough().optional(),
});
export type PublishEventPayload = z.infer<typeof PublishEventPayload>;
