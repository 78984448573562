import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import {
  FormModalInputData,
  IFormModalService,
} from '../../models/form-modal.service';
import { FormUserFreeTrialAgreementComponent } from './form-user-free-trial-agreement.component';

@Injectable({
  providedIn: 'root',
})
export class FormUserFreeTrialAgreementService implements IFormModalService {
  private userFreeTrialAgreementRef: MatDialogRef<FormUserFreeTrialAgreementComponent>;

  constructor(private dialog: MatDialog) {}

  open(data: FormModalInputData) {
    if (this.userFreeTrialAgreementRef) {
      this.userFreeTrialAgreementRef.close({});
    }

    this.userFreeTrialAgreementRef = this.dialog.open<
      FormUserFreeTrialAgreementComponent,
      FormModalInputData
    >(FormUserFreeTrialAgreementComponent, {
      width: '36vw',
      height: '70vh',
      hasBackdrop: true,
      disableClose: !Boolean(data.completedOn),
      autoFocus: false,
      panelClass: 'form-user-free-trial-agreement',
      data,
    });

    return this.userFreeTrialAgreementRef.afterClosed().pipe(take(1));
  }
}
