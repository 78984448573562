import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const ParcelProperties = z.object({
  dba: z.string(),
  gba: z.number(),
  expr1: z.union([z.string(), z.number()]),
  geo_id: z.string(),
  houses: z.number(),
  map_id: z.string(),
  number: z.string(),
  propid: z.string(),
  school: z.string(),
  account: z.string(),
  cvttxcd: z.string(),
  exempts: z.string(),
  hood_cd: z.string(),
  prop_id: z.string(),
  tot_gba: z.number(),
  website: z.string(),
  acctnumb: z.string(),
  agent_cd: z.string(),
  cnvyname: z.string(),
  dba_name: z.string(),
  deed_num: z.string(),
  deed_seq: z.string(),
  detached: z.number(),
  entities: z.string(),
  interest: z.union([z.string(), z.number()]),
  numrooms: z.number(),
  r_number: z.string(),
  schltxcd: z.string(),
  tax_year: z.number(),
  taxunits: z.string(),
  deed_date: z.string(),
  is_exempt: z.union([z.string(), z.number()]),
  nbhd_code: z.string(),
  nghbrhdcd: z.string(),
  abstract_n: z.string(),
  account_nu: z.string(),
  adjusted_p: z.number(),
  cnttxblval: z.number(),
  exemptions: z.string(),
  file_numbe: z.string(),
  homestead_: z.string(),
  legal_acre: z.number(),
  ll_gissqft: z.number(),
  location_c: z.string(),
  membership: z.string(),
  prvassdval: z.number(),
  prvtxblval: z.number(),
  timber_use: z.number(),
  user_code1: z.string(),
  user_code2: z.string(),
  user_code3: z.string(),
  user_code4: z.string(),
  user_code5: z.string(),
  user_code6: z.string(),
  prop_val_yr: z.number(),
  abs_subdv_cd: z.string(),
  association_: z.string(),
  cleanmailadd: z.string(),
  deed_book_id: z.string(),
  mail_country: z.string(),
  market_value: z.number(),
  owner_tax_yr: z.number(),
  prop_type_cd: z.string(),
  tract_or_lot: z.string(),
  appraised_val: z.number(),
  jurisdictions: z.string(),
  legal_acreage: z.number(),
  mail_address3: z.string(),
  pct_ownership: z.number(),
  timber_market: z.number(),
  prop_type_desc: z.string(),
  state_date_acq: z.string(),
  land_homesite_val: z.number(),
  legallocationcode: z.string(),
  next_appraisal_dt: z.string(),
  imprv_homesite_val: z.number(),
  instrumenttypecode: z.string(),
  instrumenttypedesc: z.string(),
  lbcs_activity_desc: z.string(),
  lbcs_function_desc: z.string(),
  totalassessedvalue: z.number(),
  land_non_homesite_val: z.number(),
  imprv_non_homesite_val: z.number(),
});

export const ReportPartParcelFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: ParcelProperties.deepPartial(),
  geometry: GeometrySchema,
  numunits: z.number().nullable(),
  sourcedate: DateSchema.nullable(),
  improvval: z.number().nullable(),
  struct: z.boolean().nullable(),
  lbcs_ownership: z.number().nullable(),
  mail_addno: z.string().nullable(),
  urbanization: z.string().nullable(),
  lbcs_function: z.number().nullable(),
  dpv_status: z.string().nullable(),
  geoid: z.string().nullable(),
  county: z.string().nullable(),
  sqft: z.number().nullable(),
  parcelnumb: z.string().nullable(),
  numstories: z.number().nullable(),
  mail_state2: z.string().nullable(),
  ll_uuid: z.string().nullable(),
  lbcs_structure: z.number().nullable(),
  lot: z.string().nullable(),
  owntype: z.string().nullable(),
  census_tract: z.string().nullable(),
  census_blockgroup: z.string().nullable(),
  owner: z.string().nullable(),
  zoning: z.string().nullable(),
  saddpref: z.string().nullable(),
  sourceagent: z.string().nullable(),
  mail_unit: z.string().nullable(),
  saddno: z.string().nullable(),
  recrdareano: z.number().nullable(),
  original_address: z.string().nullable(),
  agval: z.number().nullable(),
  gisacre: z.number().nullable(),
  parvaltype: z.string().nullable(),
  path: z.string().nullable(),
  mail_addsttyp: z.string().nullable(),
  zoning_description: z.string().nullable(),
  address: z.string().nullable(),
  dpv_codes: z.string().nullable(),
  yearbuilt: z.number().nullable(),
  usps_vacancy_date: DateSchema.nullable(),
  usps_vacancy: z.string().nullable(),
  structstyle: z.string().nullable(),
  rdi: z.string().nullable(),
  ll_stable_id: z.string().nullable(),
  mail_address2: z.string().nullable(),
  lbcs_activity: z.number().nullable(),
  ownlast: z.string().nullable(),
  ll_gisacre: z.number().nullable(),
  dpv_type: z.string().nullable(),
  careof: z.string().nullable(),
  saddstr: z.string().nullable(),
  szip: z.string().nullable(),
  mail_urbanization: z.string().nullable(),
  mail_addpref: z.string().nullable(),
  census_block: z.string().nullable(),
  ownfrst: z.string().nullable(),
  plat: z.string().nullable(),
  structno: z.number().nullable(),
  subowntype: z.string().nullable(),
  book: z.string().nullable(),
  lbcs_site: z.number().nullable(),
  cass_errorno: z.string().nullable(),
  legaldesc: z.string().nullable(),
  address_source: z.string().nullable(),
  dpv_notes: z.string().nullable(),
  saddstsuf: z.string().nullable(),
  scity: z.string().nullable(),
  subsurfown: z.string().nullable(),
  ogc_fid: z.number().nullable(),
  parval: z.number().nullable(),
  address2: z.string().nullable(),
  state2: z.string().nullable(),
  subdivision: z.string().nullable(),
  location_name: z.string().nullable(),
  mail_zip: z.string().nullable(),
  mail_addstr: z.string().nullable(),
  owner3: z.string().nullable(),
  qoz: z.string().nullable(),
  ll_updated_at: DateSchema.nullable(),
  owner4: z.string().nullable(),
  city: z.string().nullable(),
  landval: z.number().nullable(),
  usecode: z.string().nullable(),
  owner2: z.string().nullable(),
  saleprice: z.number().nullable(),
  page: z.string().nullable(),
  sunit: z.string().nullable(),
  recrdareatx: z.string().nullable(),
  usedesc: z.string().nullable(),
  saledate: DateSchema.nullable(),
  block: z.string().nullable(),
  reviseddate: DateSchema.nullable(),
  multistruct: z.boolean().nullable(),
  qoz_tract: z.string().nullable(),
  sourceurl: z.string().nullable(),
  neighborhood: z.string().nullable(),
  mailadd: z.string().nullable(),
  saddsttyp: z.string().nullable(),
  sourceref: z.string().nullable(),
  taxamt: z.number().nullable(),
  mail_city: z.string().nullable(),
  mail_addstsuf: z.string().nullable(),
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('parcel'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartContourFeatureDTO = z.infer<
  typeof ReportPartParcelFeatureDTO
>;
