import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import _map from 'lodash/map';
import { environment } from '../../environments/environment';
import { CommentObjectLatestViewsPayload } from '@transect-nx/data-transfer-objects';

@Injectable({
  providedIn: 'root',
})
export class CommentObjectLatestViewsService {
  private readonly baseEndPoint: string = `${environment.apiUrl}/comment-object-latest-views`;

  constructor(private http: HttpClient) {}

  createUnreadCommentEntry(payload: CommentObjectLatestViewsPayload) {
    return this.http.put(`${this.baseEndPoint}`, payload);
  }

  deleteCommentObject(payload: CommentObjectLatestViewsPayload) {
    return this.http.delete(`${this.baseEndPoint}`, { body: payload });
  }
}
