import { PulseTechnology } from '@transect-nx/models';
import { z } from 'zod';

export const PulseRegulationRequestDTO = z.object({
  projectId: z.string().uuid().nullish(),
  parcelFilterId: z.string().uuid().nullish(),
  geojsonString: z.string().nullish(),
  technology: z.nativeEnum(PulseTechnology),
  fetchExpiredMoratoriums: z.boolean().default(false),
});

export type PulseRegulationRequestDTO = z.infer<
  typeof PulseRegulationRequestDTO
>;
