import { z } from 'zod';

export const ReportPartHabitatCharacteristic = z.object({
  _id: z.string(),
  name: z.string(),
  automated: z.boolean().nullable(),
  public: z.boolean().nullable(),
  overridden: z.boolean(),
  override: z.enum(['present', 'not-present', 'undetermined']).nullable(),
});

export type ReportPartHabitatCharacteristic = z.infer<
  typeof ReportPartHabitatCharacteristic
>;
