import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  CommentObjectType,
  CommentsResponseRowsDTO,
} from '@transect-nx/data-transfer-objects';
import moment from 'moment';
import { BehaviorSubject, Subject, switchMap, takeUntil } from 'rxjs';
import { CommentWithEditing } from '../../../models/comment';
import { AlertService } from '../../../services/alert.service';
import { AuthService } from '../../../services/auth.service';
import { CommentsApiService } from '../../../services/backend-api/comments-api.service';
import { CommentObjectLatestViewsService } from '../../../services/comment-object-latest-views.service';
import { select, updateState } from '../../../utils/state-management';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

interface CommentBoxState {
  isLoadingComments: boolean;
  isCommentsLoaded: boolean;
  isSavingComment: boolean;
  commentsList: CommentWithEditing[];
}

const initialState: CommentBoxState = {
  isLoadingComments: true,
  isCommentsLoaded: false,
  isSavingComment: false,
  commentsList: [],
};

@Component({
  selector: 'ts-comment-box-v2',
  templateUrl: './comment-box-v2.component.html',
  styleUrls: ['./comment-box-v2.component.scss'],
})
export class CommentBoxV2Component implements OnInit, OnChanges, OnDestroy {
  @ViewChild('commentsSection') commentsSection!: ElementRef<HTMLElement>;
  @ViewChild('commentInput') commentInput!: ElementRef<HTMLElement>;

  @Input() readonly = true;
  @Input() objectType!: CommentObjectType;
  @Input() objectId!: string;
  @Input() defaultLength = 200;
  @Input() commentTemplateButtonsTemplate?: TemplateRef<HTMLElement>;
  @Output() commentsCountEvent = new EventEmitter<number>();

  private state = new BehaviorSubject<CommentBoxState>(initialState);

  isLoadingComments$ = select(this.state, (state) => state.isLoadingComments);
  isCommentsLoaded$ = select(this.state, (state) => state.isCommentsLoaded);
  isSavingComment$ = select(this.state, (state) => state.isSavingComment);
  commentsList$ = select(this.state, (state) => state.commentsList);
  currentCommentText = '';

  userOrNull$ = this.authService.userOrNull$;

  loadingCommentsAction = new Subject<void>();
  destroyAction = new Subject<void>();
  resetAction = new Subject<void>();

  loadingCommentsEffect$ = this.loadingCommentsAction.pipe(
    switchMap(() => {
      return this.commentsApiService
        .fetchComments(this.objectType, this.objectId)
        .pipe(takeUntil(this.resetAction));
    }),
    takeUntil(this.destroyAction),
  );

  constructor(
    private dialog: MatDialog,
    private alertService: AlertService,
    private authService: AuthService,
    private commentObjectLatestViewsService: CommentObjectLatestViewsService,
    private commentsApiService: CommentsApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.loadingCommentsEffect$.subscribe({
      next: (result: CommentsResponseRowsDTO) => {
        updateState(this.state, {
          isLoadingComments: false,
          commentsList: result.rows,
        });
      },
      error: (error) => {
        this.alertService.showError(error);
        updateState(this.state, { isLoadingComments: false });
      },
    });

    if (!this.state.getValue().isCommentsLoaded) {
      this.loadComments();
      updateState(this.state, { isCommentsLoaded: true });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objectId && !changes.objectId.firstChange) {
      this.loadComments();
    }
  }

  ngOnDestroy(): void {
    this.destroyAction.next();
  }

  saveComment(): void {
    updateState(this.state, { isSavingComment: true });
    const commentToPost = {
      object__id: this.objectId,
      object__type: this.objectType,
      properties: {
        text: this.currentCommentText,
      },
    };

    this.commentsApiService.postComment(commentToPost).subscribe(
      (comment: CommentWithEditing) => {
        this.currentCommentText = '';
        const updatedCommentsList = [
          ...this.state.getValue().commentsList,
          comment,
        ];
        updateState(this.state, {
          commentsList: updatedCommentsList,
          isSavingComment: false,
        });
        this.commentsCountEvent.emit(updatedCommentsList.length);
        this.alertService.showSuccess(
          'The comment has been saved successfully.',
        );
      },
      (error) => {
        this.alertService.showError(error);
        updateState(this.state, { isSavingComment: false });
      },
    );

    this.commentObjectLatestViewsService
      .deleteCommentObject({
        object__id: this.objectId,
        object__type: this.objectType,
      })
      .subscribe();
  }

  handleEnterClickOnComment(event: Event) {
    event.preventDefault();
    this.saveComment();
  }

  updateComment(comment: CommentWithEditing): void {
    comment.updatingComment = true;
    const currentCommentList = this.state.getValue().commentsList;
    const commentIndex = currentCommentList.findIndex(
      (c) => c._id === comment._id,
    );
    currentCommentList[commentIndex] = comment;
    updateState(this.state, {
      commentsList: currentCommentList,
    });
    if (comment._id && comment.updatedComment) {
      this.commentsApiService
        .patchComment(comment._id, comment.updatedComment)
        .subscribe(
          () => {
            if (comment.properties) {
              comment.properties.text = comment.updatedComment;
            }
            comment.updatingComment = false;
            comment.isEditing = false;
            currentCommentList[commentIndex] = comment;
            updateState(this.state, {
              commentsList: currentCommentList,
            });
            this.cdr.detectChanges();
            this.alertService.showSuccess(
              'The comment has been updated successfully.',
            );
          },
          (error) => {
            this.alertService.showError(error);
            comment.updatingComment = false;
          },
        );
    }
  }

  loadComments(): void {
    updateState(this.state, { isLoadingComments: true });
    this.loadingCommentsAction.next();
  }

  editComment(comment: CommentWithEditing): void {
    comment.isEditing = true;
    comment.updatedComment = comment?.properties?.text;
  }

  deleteComment(commentToDelete: CommentWithEditing): void {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          message: 'Are you sure you want to delete this comment?',
        },
      },
    );
    confirmationDialogRef.afterClosed().subscribe((performAction) => {
      if (performAction) {
        commentToDelete.deletingComment = true;
        if (commentToDelete._id) {
          this.commentsApiService.deleteComment(commentToDelete._id).subscribe(
            () => {
              const updatedCommentsList = [
                ...this.state.getValue().commentsList,
              ].filter((c) => c._id !== commentToDelete._id);

              updateState(this.state, {
                commentsList: updatedCommentsList,
              });

              this.commentsCountEvent.emit(updatedCommentsList.length);
              this.alertService.showSuccess(
                'The comment has been deleted successfully.',
              );
            },
            (error) => {
              this.alertService.showError(error);
              commentToDelete.deletingComment = false;
            },
          );
        }
      }
    });
  }

  cancelEditComment(comment: CommentWithEditing): void {
    comment.isEditing = false;
    comment.updatedComment = undefined;
  }

  getFromNow(date: string | Date | moment.Moment): string {
    return moment(date).fromNow();
  }

  setCurrentComment(text: string) {
    this.currentCommentText = text;
    this.commentInput.nativeElement.focus();
  }

  reset() {
    this.currentCommentText = '';
    this.resetAction.next();
  }
}
