import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const CustomerUserObject = z.object({
  _id: z.string().uuid(),
  role: z.string().optional().nullable(),
  created_at: DateSchema,
  updated_at: DateSchema,
  customer__id: z.string().uuid(),
  user__id: z.string().uuid(),
});
