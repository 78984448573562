import { z } from 'zod';

export const LandownerBatchDataPropertyAddressRequestDTO = z.object({
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});

export type LandownerBatchDataPropertyAddressRequestDTO = z.infer<
  typeof LandownerBatchDataPropertyAddressRequestDTO
>;

export const LandownerBatchDataNameRequestDTO = z.object({
  first: z.string().optional(),
  last: z.string().optional(),
});

export type LandownerBatchDataNameRequestDTO = z.infer<
  typeof LandownerBatchDataNameRequestDTO
>;

export const LandownerBatchDataMailingAddressRequestDTO = z.object({
  street: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});

export type LandownerBatchDataMailingAddressRequestDTO = z.infer<
  typeof LandownerBatchDataMailingAddressRequestDTO
>;

export const LandownerBatchDataRequestDTO = z.object({
  propertyAddress: LandownerBatchDataPropertyAddressRequestDTO.optional(),
  name: LandownerBatchDataNameRequestDTO.optional(),
  mailingAddress: LandownerBatchDataMailingAddressRequestDTO.optional(),
  apn: z.string().optional().optional(),
  countyFipsCode: z.string().optional(),
  county: z.string().optional(),
  state: z.string().optional(),
  requestId: z.string(),
});

export type LandownerBatchDataRequestDTO = z.infer<
  typeof LandownerBatchDataRequestDTO
>;

export const LandownerBatchDataRequestsDTO = z.object({
  requests: z.array(LandownerBatchDataRequestDTO),
  options: z.object({
    showRequests: z.boolean(),
  }),
});

export type LandownerBatchDataRequestsDTO = z.infer<
  typeof LandownerBatchDataRequestsDTO
>;
