import { z } from 'zod';

export const SpeciesReferencesIndexQueryDTO = z.object({
  species__id: z.string().nullish(),
});

export type SpeciesReferencesIndexQueryDTO = z.infer<
  typeof SpeciesReferencesIndexQueryDTO
>;

export const SpeciesReferencesAttachBodyDTO = z.object({
  species__id: z.string(),
  reference__id: z.string(),
});

export type SpeciesReferencesAttachBodyDTO = z.infer<
  typeof SpeciesReferencesAttachBodyDTO
>;

export const DestroySpeciesReferenceParams = z.object({
  speciesId: z.string(),
  referenceId: z.string(),
});

export type DestroySpeciesReferenceParams = z.infer<
  typeof DestroySpeciesReferenceParams
>;
