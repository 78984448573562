import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { take } from 'rxjs';
import {
  FormModalInputData,
  IFormModalService,
} from '../../../models/form-modal.service';
import { FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkComponent } from '../components/form-threatened-and-endangered-species-survey-scope-of-work/form-threatened-and-endangered-species-survey-scope-of-work.component';

@Injectable()
export class FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkModalService
  implements IFormModalService
{
  constructor(private dialog: MatDialog) {}

  open(data: FormModalInputData) {
    const ref = this.dialog.open<
      FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkComponent,
      FormModalInputData
    >(FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkComponent, {
      width: '1040px',
      height: '90vh',
      panelClass: 'threatened-and-endangered-form-modal',
      data,
    });
    return ref.afterClosed().pipe(take(1));
  }
}
