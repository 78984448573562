import { z } from 'zod';
import {
  DateFilter,
  NumberFilter,
  StringSetFilter,
  TextFilter,
} from '../../../utils/aggrid-filters';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { jsonSchema } from '../../../utils/string-to-json';

export const CustomerControllerPaginatedTreeBody = z.object({
  search: z.string().nullish(),
  groupKeys: jsonSchema(z.array(z.string().uuid())),
  filterModel: jsonSchema(
    z.object({
      name: TextFilter.optional(),
      domain: TextFilter.optional(),
      customer_catalog_enabled: StringSetFilter.optional(),
      vision_enabled: StringSetFilter.optional(),
      shared_portfolio: StringSetFilter.optional(),
      subscription_active: StringSetFilter.optional(),
      subscription_start_date: DateFilter.optional(),
      subscription_end_date: DateFilter.optional(),
      count_ordered_reports: NumberFilter.optional(),
      count_instant_parcel_filter_exports: NumberFilter.optional(),
      pilot_active: StringSetFilter.optional(),
      pilot_states: StringSetFilter.optional(),
      pilot_start_date: DateFilter.optional(),
      pilot_end_date: DateFilter.optional(),
      invoicing_allowed: StringSetFilter.optional(),
      count_ordered_add_ons: NumberFilter.optional(),
      count_projects: NumberFilter.optional(),
      count_customer_users: NumberFilter.optional(),
      created_at: DateFilter.optional(),
      updated_at: DateFilter.optional(),
      subscription_map_access: StringSetFilter.optional(),
      subscription_custom_map_layers_upload: StringSetFilter.optional(),
    }),
  ).optional(),
  sortModel: sortModel.optional(),
  page: pageNumber,
  pageSize: pageSize.default('100'),
});

export type CustomerControllerPaginatedTreeBody = z.infer<
  typeof CustomerControllerPaginatedTreeBody
>;
