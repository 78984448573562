import { z } from 'zod';

export const Send2FAControllerBody = z.object({
  email: z.string().email(),
});

export type Send2FAControllerBody = z.infer<typeof Send2FAControllerBody>;

export const Verify2FAControllerRequest = z.object({
  headers: z.object({
    'user-agent': z.string(),
    'x-forwarded-for': z.string().nullish(),
  }),
  socket: z
    .object({
      remoteAddress: z.string(),
    })
    .nullish(),
  body: z.object({
    email: z.string().email(),
    token: z.string().length(6),
    keepMeSignedIn: z.boolean().default(false),
  }),
});

export type Verify2FAControllerRequest = z.infer<
  typeof Verify2FAControllerRequest
>;
