import { z } from 'zod';
import { ProjectDTO } from '../project.dto';

export const ProjectControllerCreateBody = ProjectDTO.pick({
  name: true,
  created_from: true,
  customer__id: true,
  description: true,
  client_identifier: true,
  context: true,
  cleanup_weekly: true,
  geometry: true,
  preferences: true,
}).extend({
  for_a_client: z.boolean().default(false),
  from_nda_id: z.string().nullish(),
  parcel_filter_id: z.string().uuid().nullish(),
  properties: z.object({ fileName: z.string().optional() }).optional(),
});

export type ProjectControllerCreateBody = z.infer<
  typeof ProjectControllerCreateBody
>;
