import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'transect-nx-recommendation',
  templateUrl: './recommendation.component.html',
  styleUrls: ['./recommendation.component.scss'],
})
export class RecommendationComponent implements AfterViewInit {
  @Input() recommendation?: string;

  @ViewChild('recommendationDiv')
  recommendationDiv!: ElementRef<HTMLDivElement>;
  isTruncated = false;
  isExpanded = false;

  ngAfterViewInit() {
    return Promise.resolve().then(() => {
      const clientHeight = this.recommendationDiv?.nativeElement?.clientHeight;
      const scrollHeight = this.recommendationDiv?.nativeElement?.scrollHeight;
      this.isTruncated = scrollHeight > clientHeight;
    });
  }
}
