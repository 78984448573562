import { z } from 'zod';

export enum UpgradeRequestType {
  CUSTOM_DATA_LAYER = 'custom_data_layer',
  MAP_ACCESS = 'map_access',
  PARCEL_FILTER_EXPORT = 'parcel_filter_export',
  REPORT = 'report',
}

export const SendRequestUpgradeBody = z.object({
  type: z.nativeEnum(UpgradeRequestType),
  reason: z.string().nullish(),
});

export type SendRequestUpgradeBody = z.infer<typeof SendRequestUpgradeBody>;
