import { z } from 'zod';

export const ReportExportPayloadDTO = z.object({
  report_export__id: z.string().uuid(),
});

export type ReportExportPayloadDTO = z.infer<typeof ReportExportPayloadDTO>;

export const ReportExportIndexPayloadDTO = z.object({
  report_export__id: z.string().uuid(),
  index: z.number(),
  key: z.enum(['pdf-mr', 'pdf-wr', 'cover-page', 'footer-page']).optional(),
});

export type ReportExportIndexPayloadDTO = z.infer<
  typeof ReportExportIndexPayloadDTO
>;
