import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ResponseRows } from '../../../utils/response-rows';

export const SpeciesLocationDTO = z.object({
  _id: z.string().uuid(),
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  geometry: z.any(),
  type: z.string().optional().nullable(),
  exclusion_area: z.boolean().default(false),
  geographies_states: z
    .object({
      abbreviation: z.string(),
      state: z.string(),
      geography__id: z.string().uuid(),
      counties: z.string().array().optional(),
    })
    .array()
    .nullable(),
  occurrence_potential: z.string().optional().nullable(),
  primary: z.boolean().default(false),
  updated_at: DateSchema,
  reference: z
    .object({
      _id: z.string().uuid(),
      title: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
});

export type SpeciesLocationDTO = z.infer<typeof SpeciesLocationDTO>;

export const SpeciesLocationsResponseRows = ResponseRows(SpeciesLocationDTO);

export type SpeciesLocationsResponseRows = z.infer<
  typeof SpeciesLocationsResponseRows
>;
