import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartWetlandFeatureDTO } from '../models/report-part-wetland-feature.dto';

export const PaginatedReportPartWetlandFeaturesDTO = PaginatedResponseRows(
  ReportPartWetlandFeatureDTO,
);

export type PaginatedReportPartWetlandFeaturesDTO = z.infer<
  typeof PaginatedReportPartWetlandFeaturesDTO
>;
