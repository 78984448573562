import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[tsDetectScrollToEnd]',
})
export class DetectScrollToEndDirective implements AfterViewInit {
  @Output() scrollEnd = new EventEmitter<void>();

  /**
   * The scrollThreshold property determines the maximum number of pixels remaining
   * in the scroll container before the 'scrollEnd' event is emitted. This threshold
   * is necessary to account for potential fractional pixel differences, rounding
   * errors, and variations across different browsers and devices.
   *
   * When calculating the remaining scroll distance, there can be small fractional
   * differences between the calculated value and the actual element dimensions due
   * to subpixel rendering techniques, high-resolution displays, and floating-point
   * precision limitations. These fractional differences can cause the scroll detection
   * logic to be off by a fraction of a pixel, leading to the 'scrollEnd' event not
   * being emitted or being emitted prematurely.
   *
   * By introducing a small threshold value, we can ensure that the 'scrollEnd' event
   * is emitted reliably when the scroll reaches the end, even if there are minor
   * fractional pixel differences in the calculations.
   *
   * The recommended range for the scrollThreshold value is between 2 and 5 pixels.
   * A lower value (e.g., 2 pixels) provides more precise detection but may be
   * susceptible to fractional pixel issues, especially on high-resolution displays
   * or when zoomed in/out. A higher value (e.g., 5 pixels) offers more tolerance
   * for fractional pixel differences but may trigger the 'scrollEnd' event slightly
   * earlier than the actual end of scroll.
   *
   * In this implementation, we've set the scrollThreshold to a value of 3 pixels,
   * which strikes a good balance between precision and tolerance for most scenarios.

   */
  private readonly scrollThreshold = 3; // Pixels

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const element = this.el.nativeElement as HTMLElement;
    if (element.scrollHeight <= element.clientHeight) {
      // No scrollable content, emit scrollEnd event immediately. Do not trust the first scrollEnd emit if we want to support dynamic content
      this.scrollEnd.emit();
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    const element = this.el.nativeElement as HTMLElement;
    const scrollRemaining =
      element.scrollHeight - element.scrollTop - element.clientHeight;

    if (scrollRemaining <= this.scrollThreshold) {
      this.scrollEnd.emit();
    }
  }
}
