import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { filter, map, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import { FormModalInputData } from '../../models/form-modal.service';

@Component({
  selector: 'ts-form-marketplace-agreement',
  templateUrl: './form-marketplace-agreement.component.html',
  styleUrls: ['./form-marketplace-agreement.component.scss'],
})
export class FormMarketplaceAgreementComponent implements OnInit, OnDestroy {
  private readonly destroyAction = new Subject<void>();

  marketplaceAgreementForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    company_title: new UntypedFormControl('', [Validators.required]),
    date: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
  });

  get formStateService() {
    return this.data.stateService;
  }

  canSave$ = this.formStateService.canSave$;
  isComplete$ = this.formStateService.isComplete$;
  inProgress$ = this.formStateService.inProgress$;
  completing$ = this.formStateService.completing$;
  isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  saveButtonColor$ = this.formStateService.saveButtonColor$;

  constructor(
    private dialogRef: MatDialogRef<FormMarketplaceAgreementComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormModalInputData,
  ) {}

  ngOnInit(): void {
    if (this.formStateService.stateValue.formValue) {
      this.marketplaceAgreementForm.patchValue(
        this.formStateService.stateValue.formValue,
        { emitEvent: false },
      );
      this.formStateService.dispatchFormValidAction(
        !this.marketplaceAgreementForm.invalid,
      );
    }

    if (this.formStateService.stateValue.isComplete) {
      this.marketplaceAgreementForm.disable();
    }

    this.formStateService.formValue$
      .pipe(
        take(1),
        tap((formValue) => {
          this.marketplaceAgreementForm.patchValue(formValue, {
            emitEvent: false,
          });
        }),
      )
      .subscribe();

    this.marketplaceAgreementForm.valueChanges
      .pipe(
        filter(() => this.marketplaceAgreementForm.dirty),
        switchMap((formValue: FormDTO['contents']) => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.marketplaceAgreementForm.valid,
            formValue,
            this.data.formTypeId,
          );
        }),
        takeUntil(this.destroyAction),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyAction.next();
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleSaveClick() {
    if (!this.marketplaceAgreementForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close();
    });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}
