<span *ngIf="isDisabled && disabledMessage?.length">{{ disabledMessage }}</span>
<mat-select
  *ngIf="!(isDisabled && disabledMessage?.length)"
  (selectionChange)="change($event)"
  [(value)]="params.value"
  [disabled]="isDisabled"
>
  <mat-option
    *ngFor="let option of options"
    [value]="option.value"
    [disabled]="option.disabled"
  >
    {{ option.name }}
  </mat-option>
</mat-select>
