import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TransectAPIErrorCodes } from '@transect-nx/models';

interface TransectAPIErrorConfig<T = unknown> {
  title?: string;
  detail?: string;
  code?: string;
  error?: T;
  headers?: HttpHeaders;
  status?: number;
  statusText?: string;
  url?: string;
}

export class TransectAPIError extends HttpErrorResponse {
  /** A brief, human-readable message about the error */
  readonly title: string;

  /** A human-readable explanation of the error that is safe to display to users */
  readonly detail: string;

  /** A unique, Transect assigned identifier for the error */
  readonly code: string | TransectAPIErrorCodes;

  constructor(init?: TransectAPIErrorConfig) {
    super(init);

    this.title = init.title;
    this.detail = init.detail;
    this.code = init.code;
  }

  static fromHttpErrorResponse(response: HttpErrorResponse): TransectAPIError {
    if (
      response.error &&
      typeof response.error === 'object' &&
      'title' in response.error &&
      'detail' in response.error &&
      'code' in response.error
    ) {
      const { title, detail, code } = response.error as {
        title: string;
        detail: string;
        code: string;
      };

      return new TransectAPIError({
        ...response,
        title: title,
        detail: detail,
        code: code,
        error: 'TransectAPIError',
      });
    }

    if (
      response.error &&
      typeof response.error === 'object' &&
      'message' in response.error
    ) {
      const { message } = response.error as { message: string };
      return TransectAPIError.unknownError({
        ...response,
        title: message,
      });
    }

    return TransectAPIError.unknownError({ ...response });
  }

  static unknownError(init?: TransectAPIErrorConfig): TransectAPIError {
    return new TransectAPIError({
      ...init,
      title: init.title || 'Unexpected error',
      detail:
        init.detail ||
        'An unexpected error has occurred. If the problem persists, please contact Transect Customer Service.',
      code: init.code || 'UNEXPECTED',
    });
  }
}
