import { z } from 'zod';

const isString = (val: any): val is string => {
  return typeof val === 'string';
};
export const stringToNumber = (val: string) => {
  return val && parseFloat(val);
};

export const safeNumber = z.preprocess((val: unknown) => {
  if (val && isString(val)) {
    return stringToNumber(val);
  }
  return val;
}, z.number());
