import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartTransportationFeatureDTO } from '../models/report-part-transportation-feature.dto';

export const PaginatedReportPartTransportationFeaturesDTO =
  PaginatedResponseRows(ReportPartTransportationFeatureDTO);

export type PaginatedReportPartTransportationFeaturesDTO = z.infer<
  typeof PaginatedReportPartTransportationFeaturesDTO
>;
