import { Inject, Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { FormTypeKey } from '@transect-nx/data-transfer-objects';
import {
  FormModalInputData,
  IFormModalService,
} from '../../models/form-modal.service';
import { FormStateTokenMap } from '../../services/transect-form-state-factory.service';
import { FormCeiiNdaComponent } from './form-ceii-nda.component';
import { TransectFormStateService } from '../../services/transect-form-state.service';
import { FormTypesService } from '../../services/backend-api/form-types.service';
import { FormsApiService } from '../../services/backend-api/forms.service';
import { of } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { FormLegalAgreementExemptionApiService } from '../../services/backend-api/form-legal-agreement-exemption-api.service';

@Injectable()
export class FormCeiiNdaService implements IFormModalService {
  private ceiiNdaModalRef: MatDialogRef<FormCeiiNdaComponent>;

  formType$ = this.formTypesService
    .getFormTypeByKey(FormTypeKey.CEII_NDA)
    .pipe(shareReplay(1));

  formTypeExempted$ = this.formType$.pipe(
    switchMap((formType) => {
      return this.formLegalAgreementExemptionApiService.fetchFormTypeExemption(
        formType.key,
      );
    }),
    map((response) => response.exempted),
    shareReplay(1),
  );

  form$ = this.formType$.pipe(
    switchMap((formType) => {
      if (formType) {
        return this.formService.getFormByFormTypeId(formType._id);
      }

      return of(null);
    }),
    take(1),
  );

  private setSavedFormState$ = this.authService.userObserver$.pipe(
    switchMap(() => this.form$),
    tap((form) => {
      this.transectFormStateService.resetToInitialState();

      if (form?._id) {
        this.transectFormStateService.dispatchBackendFormStateAction(
          form._id,
          form.contents,
          !!form.completed_on,
        );
      }
    }),
    shareReplay(),
    map(() => {
      return;
    }),
  );

  constructor(
    private dialog: MatDialog,
    @Inject(FormStateTokenMap[FormTypeKey.CEII_NDA])
    private transectFormStateService: TransectFormStateService,
    private formTypesService: FormTypesService,
    private formService: FormsApiService,
    private authService: AuthService,
    private formLegalAgreementExemptionApiService: FormLegalAgreementExemptionApiService,
  ) {}

  private setupForm() {
    this.transectFormStateService.registerEffects();
  }

  private teardownForm() {
    this.transectFormStateService.unRegisterEffects();
  }

  open() {
    if (this.ceiiNdaModalRef) {
      this.ceiiNdaModalRef.close({});
    }

    return this.setSavedFormState$.pipe(
      switchMap(() => this.formType$),
      take(1),
      switchMap((formType) => {
        this.setupForm();

        this.ceiiNdaModalRef = this.dialog.open<
          FormCeiiNdaComponent,
          FormModalInputData
        >(FormCeiiNdaComponent, {
          width: '36vw',
          height: '70vh',
          hasBackdrop: true,
          disableClose: true,
          panelClass: 'form-marketplace-agreement-modal',
          data: {
            formTypeId: formType._id,
            stateService: this.transectFormStateService,
          },
        });

        return this.ceiiNdaModalRef.afterClosed().pipe(
          take(1),
          tap(() => {
            this.teardownForm();
          }),
          map((val) => Boolean(val)),
        );
      }),
    );
  }
}
