import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

const ReferenceAssociation = z.object({
  name: z.string().nullable(),
  entity__id: z.string().uuid(),
  entity_type: z.enum([
    'species',
    'species_locations',
    'regulations',
    'regulation_locations',
    'permits',
    'catalog_items',
    'catalog_locations',
  ]),
  created_at: DateSchema,
  updated_at: DateSchema,
});

export const ReferenceAssociationDTO = ReferenceAssociation;
export type ReferenceAssociationDTO = z.infer<typeof ReferenceAssociationDTO>;
