import { z } from 'zod';

export const GetImportedLocationsQuery = z.object({
  limit: z.preprocess(
    (val) => (typeof val === 'string' ? parseInt(val, 10) : 10),
    z.number(),
  ),
  import__id: z.string().uuid().optional().nullable(),
});

export type GetImportedLocationsQuery = z.infer<
  typeof GetImportedLocationsQuery
>;
