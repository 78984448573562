import { z } from 'zod';
import { FileObject } from '../file-object';

export const FileUpdateControllerBody = FileObject.omit({
  _id: true,
  creator__id: true,
  creator: true,
  created_at: true,
  updated_at: true,
  deleted_at: true,
  reference__id: true,
});

export type FileUpdateControllerBody = z.infer<typeof FileUpdateControllerBody>;
