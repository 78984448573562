import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { stringToNumber } from '../../utils/string-to-number';

export const CatalogGroup = z.object({
  _id: z.string().uuid(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  preview: z.any().nullable(),
  bbox: z.any().nullable(),
  properties: z.any().nullable(),
  active: z.boolean().default(true),
  master: z.boolean().nullable(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema,
});

export const SearchCatalogGroupsDTO = z.object({
  _id: z.string().optional().nullable(),
  name: z.string().optional().nullable(),
  limit: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .optional()
    .nullable(),
});

export type SearchCatalogGroupsDTO = z.infer<typeof SearchCatalogGroupsDTO>;

export const CreateCatalogGroupDTO = CatalogGroup.deepPartial().extend({
  name: z.string(),
});

export type CreateCatalogGroupDTO = z.infer<typeof CreateCatalogGroupDTO>;

export const UpdateCatalogGroupDTO = CreateCatalogGroupDTO.omit({
  _id: true,
});

export type UpdateCatalogGroupDTO = z.infer<typeof UpdateCatalogGroupDTO>;
