import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartEnvironmentalComplianceDTO } from '../models/report-part-environmental-compliance.dto';

export const PaginatedReportPartEnvironmentalComplianceDTO =
  PaginatedResponseRows(ReportPartEnvironmentalComplianceDTO);

export type PaginatedReportPartEnvironmentalComplianceDTO = z.infer<
  typeof PaginatedReportPartEnvironmentalComplianceDTO
>;
