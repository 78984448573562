import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MapService } from '../../../map/services/map.service';
import { debounceTime, filter, Subject, takeUntil } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { MarketplaceAltaScopeDTO } from '@transect-nx/data-transfer-objects';

@Component({
  selector: 'ts-alta-scope-of-work-form',
  templateUrl: './alta-scope-of-work-form.component.html',
  styleUrls: ['./alta-scope-of-work-form.component.scss'],
  providers: [MapService],
})
export class AltaScopeOfWorkFormComponent implements OnInit, OnDestroy {
  @Input() displaySelectedOptions: MarketplaceAltaScopeDTO;
  @Input() disabled = false;

  @Output() selectedOptions$ = new EventEmitter<MarketplaceAltaScopeDTO>();
  altaScopeOfWorkUIForm = this.formBuilder.group({
    monumentsPlaced: [false],
    addressesProvided: [false],
    floodZoneClassification: [false],
    grossLandArea: [false],
    verticalRelief: [false],
    zoningClassification: [false],
    zoningSetback: [false],
    exteriorBuildingDimensions: [false],
    groundLevelExteriorFootprint: [false],
    specifiedAreasFootprint: [false],
    measuredBuildingHeight: [false],
    substantialFeatures: [false],
    parkingDetails: [false],
    divisionWalls: [false],
    utilitiesEvidence: [false],
    utilitiesPlansProvided: [false],
    utilitiesMarkingsCoordinated: [false],
    governmentalSurveyRequirements: [false],
    adjoiningOwnersNames: [false],
    nearestStreetDistance: [false],
    orthophotography: [false],
    recentConstructionWork: [false],
    streetRightOfWayChanges: [false],
    offsiteEasementsPlottable: [false],
    professionalLiabilityInsurance: [false],
    engineeringDesignSurvey: [''],
  });

  private destroy$ = new Subject<void>();

  ngOnInit() {
    this.altaScopeOfWorkUIForm.valueChanges
      .pipe(
        debounceTime(100),
        filter(() => this.altaScopeOfWorkUIForm.dirty),
        takeUntil(this.destroy$),
      )
      .subscribe((value: MarketplaceAltaScopeDTO) => {
        this.selectedOptions$.emit(value);
      });

    this.altaScopeOfWorkUIForm.patchValue(this.displaySelectedOptions, {
      emitEvent: false,
    });
  }

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displaySelectedOption) {
      this.altaScopeOfWorkUIForm.patchValue(this.displaySelectedOptions, {
        emitEvent: false,
      });
      this.selectedOptions$.emit(this.displaySelectedOptions);
    }
  }
}
