import { z } from 'zod';

const FalsyComplexity = z.object({
  isTooComplex: z.literal(false),
  reason: z.null(),
});

const TruthyComplexity = z.object({
  isTooComplex: z.literal(true),
  reason: z.string(),
});

export const GetOrdersIdCheckGeometryComplexityResponseDTO = z.union([
  FalsyComplexity,
  TruthyComplexity,
]);

export type GetOrdersIdCheckGeometryComplexityResponseDTO = z.infer<
  typeof GetOrdersIdCheckGeometryComplexityResponseDTO
>;
