<div
  class="skeleton-line-loading"
  [ngStyle]="{ gap: lineGap + 'rem', width: width + '%' }"
>
  <div
    *ngFor="let item of [].constructor(count)"
    class="skeleton-line-item"
    [ngStyle]="{ height: height + 'rem' }"
  ></div>
</div>
