import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { UserDTO } from '../../users';
import { CommentsDTO } from './comments-dto';

export const CommentsResponseRowsDTO = ResponseRows(
  CommentsDTO.omit({
    creator: true,
  }).extend({
    creator: UserDTO.deepPartial().optional(),
    objectName: z.string().nullish(),
    creator__id: z.string().uuid().nullish(),
    report__id: z.string().uuid().nullish(),
  }),
);
export type CommentsResponseRowsDTO = z.infer<typeof CommentsResponseRowsDTO>;
