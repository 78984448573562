import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormTypeDTO, FormTypeKey } from '@transect-nx/data-transfer-objects';
import { map, Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormsApiService } from './forms.service';

@Injectable({
  providedIn: 'root',
})
export class FormTypesService {
  private readonly baseEndPoint = `${environment.apiUrl}/form-types`;

  constructor(
    private http: HttpClient,
    private formsApiService: FormsApiService,
  ) {}

  getFormTypeByKey(key: FormTypeDTO['key']): Observable<FormTypeDTO> {
    return this.http.get<FormTypeDTO>(`${this.baseEndPoint}/${key}`);
  }

  isFormOfThisTypeCompletedByUser(
    key: FormTypeKey,
  ): Observable<{ isComplete: boolean; formType: FormTypeDTO }> {
    return this.getFormTypeByKey(key).pipe(
      switchMap((formType) => {
        return this.formsApiService.getFormByFormTypeId(formType._id).pipe(
          map((form) => {
            return {
              isComplete: Boolean(form?.completed_on),
              formType,
            };
          }),
        );
      }),
    );
  }
}
