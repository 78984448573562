import { z } from 'zod';

export const LandownerBatchDataPublishEventPayload = z.object({
  landownerContactOrderId: z.string(),
  parcelUniqueIdentifiers: z.array(z.string()),
});

export type LandownerBatchDataPublishEventPayload = z.infer<
  typeof LandownerBatchDataPublishEventPayload
>;
