import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { ResponseRows } from '../../utils/response-rows';

export const RegulationReferenceDTO = z.object({
  _id: z.string().uuid(),
  reference__id: z.string().uuid(),
  regulation__id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});
export type RegulationReferenceDTO = z.infer<typeof RegulationReferenceDTO>;
export const RegulationReferenceResponseRows = ResponseRows(
  RegulationReferenceDTO,
);
export type RegulationReferenceResponseRows = z.infer<
  typeof RegulationReferenceResponseRows
>;

export const RegulationReferenceOnlyDTO = z.object({
  _id: z.string().uuid(),
  title: z.string(),
});
export type RegulationReferenceOnlyDTO = z.infer<
  typeof RegulationReferenceOnlyDTO
>;
export const RegulationReferenceOnlyResponseRows = ResponseRows(
  RegulationReferenceOnlyDTO,
);
export type RegulationReferenceOnlyResponseRows = z.infer<
  typeof RegulationReferenceOnlyResponseRows
>;
