import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboButtonComponent } from './combo-button.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MenuModule } from '../menu/menu.module';

@NgModule({
  declarations: [ComboButtonComponent],
  imports: [CommonModule, OverlayModule, MenuModule],
  exports: [ComboButtonComponent],
})
export class ComboButtonModule {}
