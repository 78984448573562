import { z } from 'zod';

/** Query params for path /species/:id */
export const SpeciesShowControllerQuery = z
  .object({
    preview_only: z
      .enum(['true', 'false'])
      .default('false')
      .transform((val) => Boolean(JSON.parse(val))),
  })
  .strict();
export type SpeciesShowControllerQuery = z.infer<
  typeof SpeciesShowControllerQuery
>;
