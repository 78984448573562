import { z } from 'zod';
import { CustomerCatalogItem } from '../customer-catalog-item';

export const CreateCustomerCatalogItemRequestDTO =
  CustomerCatalogItem.deepPartial().extend({
    catalog_item__id: z.string().uuid(),
    customer__id: z.string().uuid(),
  });

export type CreateCustomerCatalogItemRequestDTO = z.infer<
  typeof CreateCustomerCatalogItemRequestDTO
>;
