import { UserDTO } from '../../users/user-object';
import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { ReportMapDTO } from '../report-map-object';
import { RelationSchema } from '../../../utils/relation';

export const ReportMapIndexDTO = ReportMapDTO.extend({
  creator: RelationSchema(UserDTO),
});
export type ReportMapIndexDTO = z.infer<typeof ReportMapDTO>;
export const ReportMapIndexResponseRows = ResponseRows(ReportMapIndexDTO);
export type ReportMapIndexResponseRows = z.infer<
  typeof ReportMapIndexResponseRows
>;
