import { z } from 'zod';

/** Route params for path /species/:id/* representing :id */
export const SpeciesIdControllerParams = z
  .object({
    id: z.string().uuid(),
  })
  .strict();
export type SpeciesIdControllerParams = z.infer<
  typeof SpeciesIdControllerParams
>;

export * from './index-controller';
export * from './search-controller';
export * from './show-controller';
export * from './create-controller';
export * from './update-controller';
