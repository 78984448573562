import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartSpeciesDTO } from '../models/report-part-species.dto';

export const PaginatedReportPartSpeciesDTO =
  PaginatedResponseRows(ReportPartSpeciesDTO);

export type PaginatedReportPartSpeciesDTO = z.infer<
  typeof PaginatedReportPartSpeciesDTO
>;
