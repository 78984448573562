import { number, z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { CustomerDTO } from '../customer.dto';

export const CustomerSearchDTO = ResponseRows(CustomerDTO).merge(
  z.object({
    page: z.number().default(1),
    pageSize: z.number().default(50),
    totalPages: z.number().default(0),
  }),
);

export type CustomerSearchDTO = z.infer<typeof CustomerSearchDTO>;
