import { z } from 'zod';

export const UpdateCustomerAccountDTO = z.object({
  name: z.string(),
  domain: z.string().nullable(),
  terms_accepted: z.boolean().optional(),
  shared_portfolio: z.boolean(),
  all_collaborator: z.boolean(),
  shared_site_selections: z.boolean().optional(),
  logo_gcs: z.object({}).passthrough().nullish(),
});
export type UpdateCustomerAccountDTO = z.infer<typeof UpdateCustomerAccountDTO>;
