import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartCatalogLocationFeatureDTO } from '../models/report-part-catalog-location-feature.dto';

export const PaginatedReportPartCatalogLocationFeaturesDTO =
  PaginatedResponseRows(ReportPartCatalogLocationFeatureDTO);

export type PaginatedReportPartCatalogLocationFeaturesDTO = z.infer<
  typeof PaginatedReportPartCatalogLocationFeaturesDTO
>;
