import { z } from 'zod';

export enum AuditLogOperation {
  Create = 'create',
  Created = 'created',
  Insert = 'insert',
  INSERT = 'INSERT',
  Update = 'update',
  UPDATE = 'UPDATE',
  Delete = 'delete',
  DELETE = 'DELETE',
}

export const AuditLogDTO = z.object({
  _id: z.string().uuid(),
  operation: z.nativeEnum(AuditLogOperation),
  columns_changed: z.string(),
  user__id: z.string().uuid().nullish(),
  timestamp: z.string(),
});

export type AuditLogDTO = z.infer<typeof AuditLogDTO>;
