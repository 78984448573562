<div class="modal-header" [class.control-margin]="controlMargin">
  <div>
    <h2 mat-dialog-title class="headline-small">{{ title }}</h2>
    <h3 *ngIf="description" class="body-medium text-shades-gray-dark">
      {{ description }}
    </h3>
  </div>
  <button
    *ngIf="showCloseButton"
    transect-nx-button
    aria-label="close dialog"
    mat-dialog-close
    [iconOnly]="true"
    size="sm"
  >
    <i class="far fa-lg fa-times"></i>
  </button>
</div>

<mat-dialog-content
  [class.control-margin]="controlMargin"
  *ngIf="!contentTemplate"
>
  <ng-content></ng-content>
</mat-dialog-content>
<ng-container *ngIf="contentTemplate" [ngTemplateOutlet]="contentTemplate">
</ng-container>

<ng-container *ngIf="footer">
  <div
    class="footer dialog-actions"
    [class.control-margin]="controlMargin"
    *ngIf="!footerTemplate"
  >
    <button
      *ngIf="showSaveButton"
      transect-nx-button
      buttonStyle="contained"
      class="save-button"
      tsBtnLoader
      [disabled]="disableSave"
      (click)="onSaveClick()"
    >
      {{ saveButtonTitle ? saveButtonTitle : 'Save' }}
    </button>
    <button
      *ngIf="showCancelButton"
      buttonStyle="outlined"
      transect-nx-button
      class="cancel-button"
      tsBtnLoader
      [disabled]="disableCancel"
      (click)="onCancelClick()"
    >
      {{ cancelButtonTitle ? cancelButtonTitle : 'Cancel' }}
    </button>
  </div>
  <div
    class="footer"
    *ngIf="footerTemplate"
    [class.control-margin]="controlMargin"
  >
    <ng-container [ngTemplateOutlet]="footerTemplate"></ng-container>
  </div>
</ng-container>
