import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../utils/paginated-response-rows';

export const AddOnControllerMyOrdersQuery = z.object({
  search: z.string().nullish(),
  page: pageNumber,
  pageSize: pageSize.default('50'),
  sortModel: sortModel.nullish(),
});

export type AddOnControllerMyOrdersQuery = z.infer<
  typeof AddOnControllerMyOrdersQuery
>;
