import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const CustomerCatalogItem = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema.optional().nullable(),
  updated_at: DateSchema.optional().nullable(),
  deleted_at: DateSchema.optional().nullable(),
  customer: z
    .object({
      _id: z.string().uuid(),
      name: z.string().optional(),
      projects: z.any().optional(),
      customer_users: z.any().optional(),
      user: z.any().optional(),
      catalog_items: z.any().optional(),
    })
    .optional(),
});

export type CustomerCatalogItem = z.infer<typeof CustomerCatalogItem>;
