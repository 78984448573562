import { z } from 'zod';
import { ResponseRows } from '../../utils/response-rows';
import { GeographyDTO } from './geography-dto';

export const GeographyIndexDTO = ResponseRows(GeographyDTO);
export type GeographyIndexDTO = z.infer<typeof GeographyIndexDTO>;

const GeographyLight = z.object({
  _id: z.string().uuid(),
  type: z.string().nullable(),
  name: z.string().nullable(),
});

export const GeographyLightDTO = GeographyLight.extend({
  parent: GeographyLight.optional().nullable(),
  abbreviation: z.string().nullable(),
  state_species_supported: z.boolean().nullable(),
});
export type GeographyLightDTO = z.infer<typeof GeographyLightDTO>;

export const GeographyLightResponseRowsDTO = ResponseRows(GeographyLight);
export type GeographyLightResponseRowsDTO = z.infer<
  typeof GeographyLightResponseRowsDTO
>;
export const GeographyIndexLightDTO = ResponseRows(GeographyLightDTO);
export type GeographyIndexLightDTO = z.infer<typeof GeographyIndexLightDTO>;
