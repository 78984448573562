import { z } from 'zod';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
export const CreateRegulationLocationsPayload = z.object({
  description: z.string().nullable(),
  geography__id: z.string().uuid().nullish(),
  geometry: GeometrySchema.nullable(),
  name: z.string().nullable(),
  regulation__id: z.string().uuid().nullable(),
  reference__id: z.string().uuid().nullable(),
});
export type CreateRegulationLocationsPayload = z.infer<
  typeof CreateRegulationLocationsPayload
>;
