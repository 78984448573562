import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, take } from 'rxjs';
import {
  FormModalInputData,
  IFormModalService,
} from '../../../models/form-modal.service';
import { FormMarketplaceSiteAccessAuthorizationComponent } from '../components/form-marketplace-site-access-authorization/form-marketplace-site-access-authorization.component';

@Injectable()
export class FormMarketplaceSiteAccessAuthorizationModalService
  implements IFormModalService
{
  constructor(private dialog: MatDialog) {}

  open<R = unknown>(data: FormModalInputData): Observable<R> {
    const ref = this.dialog.open<
      FormMarketplaceSiteAccessAuthorizationComponent,
      FormModalInputData,
      R
    >(FormMarketplaceSiteAccessAuthorizationComponent, {
      width: '1040px',
      height: '90vh',
      panelClass: 'site-access-authorization-form-modal',
      data,
    });
    return ref.afterClosed().pipe(take(1));
  }
}
