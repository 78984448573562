<mat-dialog-content class="mat-typography">
  <div class="center">
    <img
      src="../../../../../assets/images/golden-cheeked-warbler.png"
      alt="Transect"
      height="136"
      width="136"
    />
  </div>

  <div class="app-update-detail">
    <strong class="title-medium">A new version is available.</strong>
    <p>
      Please wait while the app is being updated. The page will reload once the
      update is complete.
    </p>

    <mat-progress-bar mode="indeterminate"></mat-progress-bar>

    <p class="too-long" *ngIf="updateIsTakingTooLong$ | async">
      It's taking longer than expected. You may want to try manually refreshing
      the page.
    </p>

    <div class="failed-update" *ngIf="failedToUpdate$ | async">
      <p>
        An unexpected error occurred trying to update the application. Please
        try refreshing the page.
      </p>
      <span>
        If the problem persists
        <a href="mailto:support@transect.com" target="_blank">contact support</a
        >.
      </span>
    </div>
  </div>
</mat-dialog-content>
