import { z } from 'zod';
import { ReportPartHabitatCharacteristic } from '../report-part-habitat-characteristic';

export const GetReportPartHabitatCharacteristicsDTO = z
  .array(ReportPartHabitatCharacteristic)
  .optional();

export type GetReportPartHabitatCharacteristicsDTO = z.infer<
  typeof GetReportPartHabitatCharacteristicsDTO
>;
