import { z } from 'zod';

export const GetPublicCreditPacksResponseDTO = z.array(
  z.object({
    _id: z.string().uuid(),
    credit_amount: z.number(),
    price: z.number(),
  }),
);

export type GetPublicCreditPacksResponseDTO = z.infer<
  typeof GetPublicCreditPacksResponseDTO
>;

export const PurchaseCreditPackResponseDTO = z.object({
  _id: z.string().uuid(),
  credit_amount: z.number(),
});

export type PurchaseCreditPackResponseDTO = z.infer<
  typeof PurchaseCreditPackResponseDTO
>;
