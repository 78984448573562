import { z } from 'zod';
import { ReportAddOnOfferingDTO } from '../report-add-on-offering.dto';

export const ReportAddOnOfferingByKeyResponseDTO =
  ReportAddOnOfferingDTO.extend({
    public_marketplace_enabled: z.boolean(),
    in_app_marketplace_enabled: z.boolean(),
    is_on_notify_list: z.boolean(),
    banner_html: z.string().nullable(),
  });

export type ReportAddOnOfferingByKeyResponseDTO = z.infer<
  typeof ReportAddOnOfferingByKeyResponseDTO
>;
