import { z } from 'zod';
import { TransectCreditTransactionSourceEnum } from '../transect-credit-transaction-source-enum';

export const CreateTransectCreditTransactionDTO = z.object({
  amount: z.number(),
  source: z.nativeEnum(TransectCreditTransactionSourceEnum).optional(),
  source__id: z.string().optional(),
  user_id: z.string().optional(),
  customer_id: z.string().optional(),
});

export type CreateTransectCreditTransactionDTO = z.infer<
  typeof CreateTransectCreditTransactionDTO
>;
