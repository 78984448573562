import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FormDTO,
  GetMyFormsQueryParams,
  GetMyFormsResponse,
  UpdateFormPayload,
} from '@transect-nx/data-transfer-objects';
import { saveAs } from 'file-saver';
import {
  catchError,
  EMPTY,
  expand,
  filter,
  map,
  Observable,
  of,
  switchMap,
  throwError,
  timer,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { TransectAPIError } from '../../models/transect-api-error';

@Injectable({
  providedIn: 'root',
})
export class FormsApiService {
  private readonly baseEndPoint = `${environment.apiUrl}/forms`;

  constructor(private http: HttpClient) {}

  downloadAsPdf(formId: string) {
    const request$ = this.http.get<{ signedUrl: string; name: string } | null>(
      `${this.baseEndPoint}/${formId}/pdf`,
    );

    const downloadBlob$ = (signedUrl: string, name: string) =>
      this.http
        .get(signedUrl, {
          responseType: 'blob',
        })
        .pipe(map((blob) => ({ blob, name })));

    return request$.pipe(
      expand((response, index) => {
        if (index > 20) {
          return throwError(
            () =>
              new Error(
                'The PDF is taking longer than expected to generate. Please try again later.',
              ),
          );
        }

        if (!response) {
          return timer(5000).pipe(switchMap(() => request$));
        }

        return EMPTY;
      }),
      filter(
        (response): response is { signedUrl: string; name: string } =>
          !!response,
      ),
      switchMap((response) => downloadBlob$(response.signedUrl, response.name)),
      map((result) => {
        if (result?.blob) {
          saveAs(result.blob, result.name);
        }

        return;
      }),
    );
  }

  getMyForms(params: GetMyFormsQueryParams): Observable<GetMyFormsResponse> {
    const httpParams = new HttpParams({
      fromObject: params,
    });
    return this.http
      .get<GetMyFormsResponse>(`${this.baseEndPoint}/mine`, {
        params: httpParams,
      })
      .pipe(map((response) => GetMyFormsResponse.parse(response)));
  }

  getFormByFormTypeId(
    formTypeId: string,
    userId?: string,
  ): Observable<FormDTO> {
    return this.http
      .get<FormDTO>(`${this.baseEndPoint}/${formTypeId}`, {
        params: {
          ...(userId && { userId }),
        },
      })
      .pipe(
        catchError((error: Error) => {
          if (
            error instanceof TransectAPIError &&
            error.code === 'NotFoundException'
          ) {
            return of(null);
          }
          return throwError(() => error);
        }),
      );
  }

  createForm(payload: UpdateFormPayload): Observable<FormDTO> {
    return this.http.post<FormDTO>(`${this.baseEndPoint}`, payload);
  }

  updateForm(
    id: string,
    payload: UpdateFormPayload['contents'],
  ): Observable<FormDTO> {
    return this.http.put<FormDTO>(`${this.baseEndPoint}/${id}`, payload);
  }

  markFormComplete(id: string): Observable<FormDTO> {
    return this.http.patch<FormDTO>(
      `${this.baseEndPoint}/${id}/mark-complete`,
      {},
    );
  }
}
