import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { ReportMapConfiguration } from '../report-map-configuration/report-map-configuration';
import { UserDTO } from '../users';
export const ReportMapDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  report__id: z.string().uuid(),
  creator__id: z.string().uuid(),
  configuration: ReportMapConfiguration,
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullable(),
});
export type ReportMapDTO = z.infer<typeof ReportMapDTO>;
/** The report map layer properties that support sorting. */
export const SortableReportMapLayerProperties = z.enum(['created_at']);
export type SortableReportMapLayerProperties = z.infer<
  typeof SortableReportMapLayerProperties
>;
