import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const TagDTO = z.object({
  _id: z.string().uuid(),
  name: z.string({ required_error: 'Tag name is required' }),
  creator__id: z.string().uuid(),
  customer__id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});

export type TagDTO = z.infer<typeof TagDTO>;
