import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { ReportAddOnOfferingDTO } from '../report-add-on-offering';
import { MarketplaceAltaScopeDTO } from '../marketplace-product-category/marketplace-alta-scope-of-work-options.dto';
import { FormDTO } from '../form';
import { UserDTO } from '../users';

export enum ReportAddOnStatus {
  PENDING = 'pending',
  OUT_FOR_BID = 'out_for_bid',
  USER_REVIEW_ORDER = 'user_review_order',
  BID_ACCEPTED = 'bid_accepted',
  IN_PROGRESS = 'in_progress',
  CANCELLED = 'cancelled',
  REJECTED = 'rejected',
  COMPLETE = 'complete',
}

export const AddOnScopeOfWork = z.object({
  details: z.string().nullish(),
  project_type: z.string().nullish(),
  additional_details_for_request_quote: z.string().nullish(),
  options_selected: z.union([z.string(), MarketplaceAltaScopeDTO]).nullish(),
  intercon_selected_authority: z.string().nullish(),
  desired_project_size: z.number().nullish(),
  desired_intercon_voltage: z.number().nullish(),
  intercon_inverter_ratio: z.string().default('1.20'),
  intercon_connection_type: z.enum(['transmission', 'distribution']).nullish(),
  project_subtype: z.string().nullish(),
});
export type AddOnScopeOfWork = z.infer<typeof AddOnScopeOfWork>;

export const ReportAddOnDTO = z.object({
  _id: z.string().uuid(),
  status: z.nativeEnum(ReportAddOnStatus),
  status_reason: z.string().nullish(),
  due_by: DateSchema.nullable(),
  calculated_due_by: DateSchema.nullish(),
  option: z.string(),
  share_parcels: z.boolean().nullable(),
  price: z.number().nullable(),
  invoice_sent: z.boolean().nullable(),
  invoice_sent_on: DateSchema.nullable(),
  contractor: z.string().nullable(),
  contractor_website: z.string().nullable(),
  quote_timeline: z.string().nullable(),
  sent_to_contractor: z.boolean().nullable(),
  sent_to_contractor_on: DateSchema.nullable(),
  owner__id: z.string().nullable(),
  coupon_code: z.string().nullable(),
  order_num: z.number(),
  quote_received_date: DateSchema.nullish(),
  completed_at: DateSchema.nullable(),
  creator__id: z.string(),
  report__id: z.string().uuid().nullish(),
  project__id: z.string().uuid().nullish(),
  report_add_on_offering__id: z.string(),
  report_add_on_offering: ReportAddOnOfferingDTO.partial().optional(),
  scope_of_work: AddOnScopeOfWork.nullish(),
  stripe_payment_intent_id: z.string().nullish(),
  due_date_changed: z.boolean().nullish(),
  forms: z.array(FormDTO).nullish(),
  created_at: DateSchema,
  expert_notes: z.string().nullish(),
  expert_notes_added_by_user: UserDTO.partial().nullish(),
});

export type ReportAddOnDTO = z.infer<typeof ReportAddOnDTO>;

export const GeographiesState = z.object({
  state: z.string().nullable(),
  abbreviation: z.string().nullable(),
  geography__id: z.string().nullable(),
});

export type GeographiesState = z.infer<typeof GeographiesState>;
