import { z } from 'zod';
import { BuildableAreaCriteriaParamsDTO } from '../parcel-filters';
import { ProjectContext } from '../projects';

export const VisibleColumnDTO = z.object({
  key: z.string(),
  label: z.string(),
  icon: z.string().optional(),
  isFixed: z.boolean().optional(),
});

export type VisibleColumnDTO = z.infer<typeof VisibleColumnDTO>;

export const EmailNotificationPreferencesDTO = z.object({
  emails_with_downloads: z.boolean(),
  emails_when_ready: z.boolean(),
});

export type EmailNotificationPreferencesDTO = z.infer<
  typeof EmailNotificationPreferencesDTO
>;

export const UserPreferencesDTO = z.object({
  sharing_notifications: z.boolean().optional(),
  completion_notifications: z.boolean().optional(),
  timeout_notifications: z.boolean().optional(),
  news_notifications: z.boolean().optional(),
  monitor_notifications: z.boolean().optional(),
  site_selection_pending_dialog: z.boolean().nullish(),
  show_unread_notifications_only: z.boolean().nullish(),
  downloadKML: z.boolean().optional(),
  downloadPDF: z.boolean().optional(),
  vision_location: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .optional(),
  dont_show_ghost_waters_info_box: z.boolean().optional(),
  dont_show_ba_generation_info_box: z.boolean().optional(),
  context: ProjectContext.passthrough().optional(),
  buildable_area_criteria_params: BuildableAreaCriteriaParamsDTO.nullish(),
  risk_at_a_glance: z.boolean().optional(),
  email_notifications: EmailNotificationPreferencesDTO.optional(),
  project_list_columns: z.array(VisibleColumnDTO).optional(),
  buildable_area_layer_template_id: z
    .union([
      z.string().uuid(),
      z
        .string()
        .optional()
        .refine((data) => data === '', 'Must be an empty string or UUID'),
    ])
    .nullish(),
  pdf_export_template_id: z.string().uuid().nullish(),
  dont_show_distribution_lines_banner: z.boolean().optional(),
  dont_show_project_comparison_info_modal: z.boolean().optional(),
});

export type UserPreferencesDTO = z.infer<typeof UserPreferencesDTO>;
