import { z } from 'zod';

export const MarketplaceProductCategoryDTO = z.object({
  _id: z.string().uuid(),
  key: z.string(),
  label: z.string(),
  description: z.string().nullish(),
});

export type MarketplaceProductCategoryDTO = z.infer<
  typeof MarketplaceProductCategoryDTO
>;
