import { z } from 'zod';

export const PermitReferencesIndexQueryDTO = z.object({
  permit__id: z.string(),
});

export type PermitReferencesIndexQueryDTO = z.infer<
  typeof PermitReferencesIndexQueryDTO
>;

export const PermitReferencesCreateBodyDTO = z.object({
  permit__id: z.string(),
  reference__id: z.string(),
});

export type PermitReferencesCreateBodyDTO = z.infer<
  typeof PermitReferencesCreateBodyDTO
>;
