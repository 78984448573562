import { z } from 'zod';

export const MarketplaceAltaScopeDTO = z.object({
  monumentsPlaced: z.boolean().default(false),
  addressesProvided: z.boolean().default(false),
  floodZoneClassification: z.boolean().default(false),
  grossLandArea: z.boolean().default(false),
  verticalRelief: z.boolean().default(false),
  zoningClassification: z.boolean().default(false),
  zoningSetback: z.boolean().default(false),
  exteriorBuildingDimensions: z.boolean().default(false),
  groundLevelExteriorFootprint: z.boolean().default(false),
  specifiedAreasFootprint: z.boolean().default(false),
  measuredBuildingHeight: z.boolean().default(false),
  substantialFeatures: z.boolean().default(false),
  parkingDetails: z.boolean().default(false),
  divisionWalls: z.boolean().default(false),
  utilitiesEvidence: z.boolean().default(false),
  utilitiesPlansProvided: z.boolean().default(false),
  utilitiesMarkingsCoordinated: z.boolean().default(false),
  governmentalSurveyRequirements: z.boolean().default(false),
  adjoiningOwnersNames: z.boolean().default(false),
  nearestStreetDistance: z.boolean().default(false),
  orthophotography: z.boolean().default(false),
  recentConstructionWork: z.boolean().default(false),
  streetRightOfWayChanges: z.boolean().default(false),
  offsiteEasementsPlottable: z.boolean().default(false),
  professionalLiabilityInsurance: z.boolean().default(false),
  engineeringDesignSurvey: z.string().default(''),
});

export type MarketplaceAltaScopeDTO = z.infer<typeof MarketplaceAltaScopeDTO>;
