import { z } from 'zod';
import { TransectCreditTransactionDTO } from '../transect-credit-transaction-dto';
export const TransectCreditTransactionResponseDTO =
  TransectCreditTransactionDTO.pick({
    _id: true,
    user: true,
    customer: true,
    amount: true,
    source: true,
    source__id: true,
    created_at: true,
  }).deepPartial();

export type TransectCreditTransactionResponseDTO = z.infer<
  typeof TransectCreditTransactionResponseDTO
>;
