import { z } from 'zod';
import {
  DateFilter,
  NumberFilter,
  StringSetFilter,
  TextFilter,
} from '../../../utils/aggrid-filters';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { jsonSchema } from '../../../utils/string-to-json';

export const GetImportsControllerQueryParams = z.object({
  search: z.string().optional(),
  filterModel: jsonSchema(
    z.object({
      name: TextFilter.optional(),
      status: StringSetFilter.optional(),
      srid: NumberFilter.optional(),
      feature_count: NumberFilter.optional(),
      size: NumberFilter.optional(),
      creator__id: StringSetFilter.optional(),
      created_at: DateFilter.optional(),
      updated_at: DateFilter.optional(),
      'creator.fullname': StringSetFilter.optional(),
    }),
  ).optional(),
  sortModel: sortModel.optional(),
  page: pageNumber,
  pageSize: pageSize.default('50'),
});

export type GetImportsControllerQueryParams = z.infer<
  typeof GetImportsControllerQueryParams
>;
