import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarouselComponent } from './carousel.component';
import { CarouselItemComponent } from './components/carousel-item/carousel-item.component';
import { TransectButtonsModule } from '../transect-buttons/transect-buttons.module';

@NgModule({
  declarations: [CarouselComponent, CarouselItemComponent],
  imports: [CommonModule, TransectButtonsModule],
  exports: [CarouselComponent, CarouselItemComponent],
})
export class CarouselModule {}
