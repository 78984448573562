import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { freeTrialUserGuard } from './guards/free-trial-user.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'portal/dashboard',
  },
  {
    path: '',
    loadChildren: () =>
      import('./layouts/center-layout/center-layout.module').then(
        (m) => m.CenterLayoutModule,
      ),
  },
  {
    path: 'pdf',
    loadChildren: () =>
      import('./features/pdf/pdf.module').then((m) => m.PdfModule),
  },
  {
    path: 'report-sample',
    loadChildren: () =>
      import('./features/report-sample/report-sample.module').then(
        (m) => m.ReportSampleModule,
      ),
  },
  {
    path: 'mini-report',
    loadChildren: () =>
      import(
        './features/mini-reports-onboarding/mini-reports-onboarding.module'
      ).then((m) => m.MiniReportsOnboardingModule),
  },
  {
    path: 'portal',
    loadChildren: () =>
      import('./layouts/portal-layout/portal-layout.module').then(
        (m) => m.PortalLayoutModule,
      ),
  },
  {
    path: '1-click',
    loadChildren: () =>
      import('./features/1-click/1-click.module').then((m) => m.OneClickModule),
  },
  {
    path: 'bulk-project-site-creation',
    canActivate: [AuthGuard, freeTrialUserGuard],
    loadComponent: () =>
      import(
        './modules/bulk-project-site-creation/bulk-project-site-creation.component'
      ).then((mod) => mod.BulkProjectSiteCreationComponent),
  },
  {
    path: 'acceptable-use-policy',
    pathMatch: 'full',
    redirectTo: 'portal/static/acceptable-use-policy',
  },
  {
    path: 'customer-terms',
    pathMatch: 'full',
    redirectTo: 'portal/static/customer-terms',
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    redirectTo: 'portal/static/privacy-policy',
  },
  {
    path: 'user-terms',
    pathMatch: 'full',
    redirectTo: 'portal/static/user-terms',
  },
  {
    path: 'terms-of-service',
    pathMatch: 'full',
    redirectTo: 'portal/static/erms-of-service',
  },
  {
    path: 'website-terms',
    pathMatch: 'full',
    redirectTo: 'portal/static/website-terms',
  },
  {
    path: '**',
    loadChildren: () =>
      import('./layouts/lost-page/lost-page.module').then(
        (m) => m.LostPageModule,
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
