import { z } from 'zod';
export const ReportAddOnOfferingIndexParams = z.object({
  order: z
    .enum(['asc', 'desc', 'ASC', 'DESC'])
    .optional()
    .default('asc')
    .transform((val) => val.toUpperCase()),
  page: z
    .string()
    .optional()
    .default('1')
    .transform((val, ctx) => {
      const parsedAsInt = parseInt(val);
      if (isNaN(parsedAsInt)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `'page must be an integer`,
        });
      }
      return parsedAsInt;
    }),
  pageSize: z
    .string()
    .optional()
    .default('100')
    .transform((val, ctx) => {
      const parsedAsInt = parseInt(val);
      if (isNaN(parsedAsInt)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `'pageSize' must be an integer`,
        });
      }
      return parsedAsInt;
    }),
  sortBy: z.string().optional().default('name'),
  filterKeys: z.array(z.string()).optional().default([]),
});
export type ReportAddOnOfferingIndexParams = z.input<
  typeof ReportAddOnOfferingIndexParams
>;
