import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import { FormAltaScopeOfWorkComponent } from './components/form-alta-scope-of-work/form-alta-scope-of-work.component';
import {
  FormModalInputData,
  IFormModalService,
} from '../../models/form-modal.service';

@Injectable()
export class FormAltaScopeOfWorkService implements IFormModalService {
  private altaScopeOfWorkModalRef: MatDialogRef<FormAltaScopeOfWorkComponent>;

  constructor(private dialog: MatDialog) {}

  open(data: FormModalInputData) {
    if (this.altaScopeOfWorkModalRef) {
      this.altaScopeOfWorkModalRef.close({});
    }

    this.altaScopeOfWorkModalRef = this.dialog.open<
      FormAltaScopeOfWorkComponent,
      FormModalInputData
    >(FormAltaScopeOfWorkComponent, {
      disableClose: true,
      data: data,
    });

    return this.altaScopeOfWorkModalRef.afterClosed().pipe(take(1));
  }
}
