import { z } from 'zod';

export const PostStripePaymentsCreatePaymentIntentBodyDTO = z.object({
  amount: z.number(),
  addOnId: z.string().uuid(),
});

export type PostStripePaymentsCreatePaymentIntentBodyDTO = z.infer<
  typeof PostStripePaymentsCreatePaymentIntentBodyDTO
>;
