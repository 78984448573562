import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const GeographyProperties = z.object({
  lsy: z.string(),
  name: z.string(),
  aland: z.number(),
  geoid: z.string(),
  awater: z.number(),
  stusps: z.string(),
  cdsessn: z.number(),
  classfp: z.string(),
  placens: z.string(),
  statens: z.string(),
  countyns: z.string(),
  funcstat: z.string(),
  intptlat: z.string(),
  intptlon: z.string(),
  namelsad: z.string(),
});

export const ReportPartGeographyFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: GeographyProperties.deepPartial(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  type: z.string().nullable(),
  abbreviation: z.string().nullable(),
  mtfcc: z.string().nullable(),
  gnis: z.string().nullable(),
  geoid: z.string().nullable(),
  fips_class: z.string().nullable(),
  fips: z.string().nullable(),
  geometry: GeometrySchema,
  intersection_aoi: GeometrySchema.nullable(),
  intersection_buffer: GeometrySchema.nullable(),
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('geography'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartGeographyFeatureDTO = z.infer<
  typeof ReportPartGeographyFeatureDTO
>;
