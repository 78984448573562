import { z } from 'zod';
import { ReportAnnotationCreationType } from '../annotation-creation-type';

export const GetAnnotationsQuery = z.object({
  page: z
    .string()
    .optional()
    .nullish()
    .transform((arg) => arg && parseInt(arg, 10)),
  pageSize: z
    .string()
    .optional()
    .nullish()
    .transform((arg) => arg && parseInt(arg, 10)),
  creationTypes: z
    .string()
    .optional()
    .nullish()
    .transform(
      (arg) => arg && (JSON.parse(arg) as ReportAnnotationCreationType[]),
    ),
  fields: z
    .string()
    .optional()
    .nullish()
    .transform((arg) => arg && (JSON.parse(arg) as string[])),
});

export type GetAnnotationsQuery = z.infer<typeof GetAnnotationsQuery>;
