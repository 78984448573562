import { z } from 'zod';
import { stringToNumber } from '../../../utils/string-to-number';
import { ProjectTypeDTO } from '../../projects/project-type.dto';

export const PatchReportContextBodyDTO = z.object({
  expert_review: z.enum(['y', 'n']).optional(),
  federal_funds: z.enum(['y', 'n', 'unknown']).optional(),
  project_type: ProjectTypeDTO.optional(),
  project_subtype: z.string().optional(),
  project_type_description: z.string().optional(),
  construction_type: z.string().optional(),
  plan_to_impact_waters: z.enum(['y', 'n', 'unknown']).optional(),
  project_size_megawatts: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .optional(),
  construction_year: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .optional(),
  meets_labor_requirements: z.enum(['y', 'n']).optional(),
  meets_domestic_content_requirements: z.enum(['y', 'n']).optional(),
  total_project_cost: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .nullish(),
  estimated_power_output: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .nullish(),
});

export type PatchReportContextBodyDTO = z.infer<
  typeof PatchReportContextBodyDTO
>;
