import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { TransectFile } from './../../../models/transect-file';
import { FileUploadService } from './../../../services/file-upload.service';
import { BehaviorSubject, NEVER, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, switchMap, tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'ts-transect-file-image',
  templateUrl: './transect-file-image.component.html',
  styleUrls: ['./transect-file-image.component.scss'],
})
export class TransectFileImageComponent implements AfterViewInit {
  @Input() set transectFile(value: Partial<TransectFile> | undefined | null) {
    this.transectFileChanged.next(value ?? null);
  }
  @Input() width: string | null = null;
  @Input() height: string | null = null;
  @Input() imgClass = '';
  @Output() loading = new EventEmitter<boolean>();
  isLoading = false;
  @Output() loaded = new EventEmitter();
  transectFileChanged = new BehaviorSubject<Partial<TransectFile> | null>(null);
  url$?: Observable<string>;
  constructor(private fileUploadService: FileUploadService) {}

  ngAfterViewInit(): void {
    this.url$ = this.transectFileChanged.pipe(
      filter((val) => !!val),
      tap(() => {
        this.isLoading = true;
        this.loading.emit(this.isLoading);
      }),
      switchMap((currentFile) => {
        if (!currentFile?._id) {
          this.loaded.next(true);
          this.loading.next(false);

          return NEVER;
        }

        return this.fileUploadService.getFileUrl(currentFile._id).pipe(
          catchError(() => {
            this.loaded.next(true);
            this.loading.next(false);
            return NEVER;
          }),
        );
      }),
      map((url: { signedUrl: string }) => url.signedUrl),
      tap(() => {
        this.isLoading = false;
        this.loading.emit(this.isLoading);
      }),
    );
  }

  handleOnImageLoad(): void {
    timer(500).subscribe({
      next: () => {
        this.loaded.next(true);
      },
    });
  }
}
