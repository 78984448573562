import { z } from 'zod';

/** Body for post / */
export const OrderCreateControllerBody = z.object({
  billing: z
    .object({
      user__id: z.string().uuid(),
      firstname: z.string(),
      lastname: z.string(),
      phone: z.string().nullish(),
      email: z.string().email(),
      address_line1: z.string().nullish(),
      address_city: z.string().nullish(),
      address_state: z.string().nullish(),
      address_zip: z.string().nullish(),
      customer__id: z.string().uuid().nullish(),
      method: z.string(),
    })
    .nullish(),
  products: z
    .object({
      monitoring: z.object({
        active: z.boolean().default(false),
        price: z.number().default(0),
      }),
      report: z.object({
        active: z.boolean().default(false),
        price: z.number().default(0),
      }),
    })
    .nullish(),
  project: z
    .object({
      _id: z.string().uuid(),
      creator__id: z.string().uuid().nullish(),
      customer__id: z.string().uuid().nullish(),
      context: z
        .object({
          buffer_width_feet: z.number().nullish(),
        })
        .nullish(),
    })
    .passthrough(),
  project__id: z.string().uuid(),
  parent_report_id: z.string().uuid().nullish(),
  total: z.number().default(0),
});

export type OrderCreateControllerBody = z.infer<
  typeof OrderCreateControllerBody
>;
