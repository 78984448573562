import { z } from 'zod';
import { pageNumber, pageSize } from '../../../utils/paginated-response-rows';

export const RegulationControllerSearchQueryParams = z.object({
  _id: z.preprocess((val) => val || null, z.string().uuid().nullish()),
  name: z.string().nullish(),
  general: z.string().nullish(),
  sortBy: z.string().default('name'),
  order: z.enum(['asc', 'desc']).default('asc'),
  page: pageNumber,
  pageSize: pageSize.default('100'),
});

export type RegulationControllerSearchQueryParams = z.infer<
  typeof RegulationControllerSearchQueryParams
>;
