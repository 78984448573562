import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TransectButtonsModule } from '../transect-buttons/transect-buttons.module';
import { PushoverContentComponent } from './components/pushover-content/pushover-content.component';
import { PushoverSlideInWindowComponent } from './components/pushover-slide-in-window/pushover-slide-in-window.component';
import { PushoverWindowComponent } from './pushover-window.component';
import { PushoverWindowHeaderComponent } from './components/pushover-window-header/pushover-window-header.component';
import { PushoverButtonsComponent } from './components/pushover-buttons/pushover-buttons.component';

@NgModule({
  declarations: [
    PushoverWindowComponent,
    PushoverContentComponent,
    PushoverSlideInWindowComponent,
    PushoverWindowHeaderComponent,
    PushoverButtonsComponent,
  ],
  imports: [CommonModule, TransectButtonsModule, MatTooltipModule],
  exports: [
    PushoverWindowComponent,
    PushoverContentComponent,
    PushoverSlideInWindowComponent,
    PushoverWindowHeaderComponent,
    PushoverButtonsComponent,
  ],
})
export class PushoverWindowModule {}
