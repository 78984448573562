import { z } from 'zod';
import { DateAsString, DateSchema } from '../../../utils/date-schema';

export const ReportStatusDTO = z.object({
  _id: z.string().uuid(),
  updated_at: DateSchema,
  progress: z.number(),
  status: z.string(),
  user_refresh_date: DateSchema.nullish(),
  report_parts: z.array(
    z.object({
      _id: z.string().uuid(),
      type: z.string(),
      status: z.string(),
      progress: z.number(),
      updated_at: DateAsString,
    }),
  ),
});

export type ReportStatusDTO = z.infer<typeof ReportStatusDTO>;
