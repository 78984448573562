import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ReferenceDTO } from '../reference.dto';

export const ReferencesUpdateResponseDTO = ReferenceDTO.extend({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});

export type ReferencesUpdateResponseDTO = z.infer<
  typeof ReferencesUpdateResponseDTO
>;
