<ng-container *ngIf="(view.state.isInitializingUser$ | async) === false">
  <router-outlet></router-outlet>
</ng-container>

<div
  [class.visible]="
    (view.state.isLoadingRoute$ | async) ||
    (view.state.isInitializingUser$ | async)
  "
  class="loading-container"
>
  <div class="loading-body">
    <img aria-label="transect logo" src="../assets/images/logo.svg" />
    <div class="slider">
      <div class="line"></div>
      <div class="subline inc"></div>
      <div class="subline dec"></div>
    </div>
  </div>
</div>

<ts-app-flyover></ts-app-flyover>

<ng-container *ngFor="let action$ of view.actions">
  <ng-container *ngIf="action$ | async"></ng-container>
</ng-container>
