import { z } from 'zod';

export const UserCreateControllerBody = z.object({
  email: z.string().email(),
  isPublicUser: z.boolean(),
  firstname: z.string(),
  lastname: z.string(),
  industry__id: z.string().uuid(),
  password: z.string(),
  profile: z.object({
    phone: z.string().nullable(),
  }),
});

export type UserCreateControllerBody = z.infer<typeof UserCreateControllerBody>;
