const ReportParts = [
  {
    value: 'aspect',
    name: 'aspect',
  },
  {
    value: 'catalog_location',
    name: 'catalog_location',
  },
  {
    value: 'contour',
    name: 'contour',
  },
  {
    value: 'ecoregion',
    name: 'ecoregion',
  },
  {
    value: 'environmental_compliance',
    name: 'environmental_compliance',
  },
  {
    value: 'firm_panel',
    name: 'firm_panel',
  },
  {
    value: 'flood_hazard',
    name: 'flood_hazard',
  },
  {
    value: 'geography',
    name: 'geography',
  },
  {
    value: 'geology',
    name: 'geology',
  },
  {
    value: 'hydrography',
    name: 'hydrography',
  },
  {
    value: 'landcover',
    name: 'landcover',
  },
  {
    value: 'parcel',
    name: 'parcel',
  },
  {
    value: 'protected_area',
    name: 'protected_area',
  },
  {
    value: 'regulation_location',
    name: 'regulation_location',
  },
  {
    value: 'report_catalog_location',
    name: 'report_catalog_location',
  },
  {
    value: 'slope2',
    name: 'slope2',
  },
  {
    value: 'soil',
    name: 'soil',
  },
  {
    value: 'species_location',
    name: 'species_location',
  },
  {
    value: 'state_species_location',
    name: 'state_species_location',
  },
  {
    value: 'transportation',
    name: 'transportation',
  },
  {
    value: 'watershed',
    name: 'watershed',
  },
  {
    value: 'wetland',
    name: 'wetland',
  },
  {
    value: 'interconnection_data',
    name: 'interconnection_data',
  },
];

export const States = [
  { name: 'Alabama', value: 'AL' },
  { name: 'Alaska', value: 'AK' },
  { name: 'Arizona', value: 'AZ' },
  { name: 'Arkansas', value: 'AR' },
  { name: 'California', value: 'CA' },
  { name: 'Colorado', value: 'CO' },
  { name: 'Connecticut', value: 'CT' },
  { name: 'Delaware', value: 'DE' },
  { name: 'District of Columbia', value: 'DC' },
  { name: 'Florida', value: 'FL' },
  { name: 'Georgia', value: 'GA' },
  { name: 'Hawaii', value: 'HI' },
  { name: 'Idaho', value: 'ID' },
  { name: 'Illinois', value: 'IL' },
  { name: 'Indiana', value: 'IN' },
  { name: 'Iowa', value: 'IA' },
  { name: 'Kansas', value: 'KS' },
  { name: 'Kentucky', value: 'KY' },
  { name: 'Louisiana', value: 'LA' },
  { name: 'Maine', value: 'ME' },
  { name: 'Maryland', value: 'MD' },
  { name: 'Massachusetts', value: 'MA' },
  { name: 'Michigan', value: 'MI' },
  { name: 'Minnesota', value: 'MN' },
  { name: 'Mississippi', value: 'MS' },
  { name: 'Missouri', value: 'MO' },
  { name: 'Montana', value: 'MT' },
  { name: 'Nebraska', value: 'NE' },
  { name: 'Nevada', value: 'NV' },
  { name: 'New Hampshire', value: 'NH' },
  { name: 'New Jersey', value: 'NJ' },
  { name: 'New Mexico', value: 'NM' },
  { name: 'New York', value: 'NY' },
  { name: 'North Carolina', value: 'NC' },
  { name: 'North Dakota', value: 'ND' },
  { name: 'Ohio', value: 'OH' },
  { name: 'Oklahoma', value: 'OK' },
  { name: 'Oregon', value: 'OR' },
  { name: 'Pennsylvania', value: 'PA' },
  { name: 'Rhode Island', value: 'RI' },
  { name: 'South Carolina', value: 'SC' },
  { name: 'South Dakota', value: 'SD' },
  { name: 'Tennessee', value: 'TN' },
  { name: 'Texas', value: 'TX' },
  { name: 'Utah', value: 'UT' },
  { name: 'Vermont', value: 'VT' },
  { name: 'Virginia', value: 'VA' },
  { name: 'Washington', value: 'WA' },
  { name: 'West Virginia', value: 'WV' },
  { name: 'Wisconsin', value: 'WI' },
  { name: 'Wyoming', value: 'WY' },
];

export const ENERGY_COMMUNITIES_LAYER_CATALOG_ID =
  'bc347edb-12d6-4b32-8419-d76f1f6890ad';

export const SCHEDULE_DEMO_URL = 'https://www.transect.com/2024-get-a-demo';

export const AppConstants = {
  ReportParts,
  States,
  SCHEDULE_DEMO_URL,
};
