import { z } from 'zod';

export const GetReportAddOnsIdInvoiceDetailsResponseDTO = z.object({
  /** The amount of the subtotal in cents */
  subtotalAmount: z.number(),
  /** The total amount discounted from the subtotal */
  discountAmount: z.number().nullish(),
  taxes: z.array(
    z.object({
      /** The amount of tax in cents */
      amount: z.number(),
      percentage: z.string().optional(),
      description: z.string().nullish(),
      title: z.string().optional(),
    }),
  ),
  /** The total amount of the tax in cents */
  taxAmount: z.number(),
  /** The total amount of the invoice in cents */
  totalAmount: z.number(),
});

export type GetReportAddOnsIdInvoiceDetailsResponseDTO = z.infer<
  typeof GetReportAddOnsIdInvoiceDetailsResponseDTO
>;
