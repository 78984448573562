import { z } from 'zod';
import { CriticalFailureTypes, SlackItemType } from '@transect-nx/models';
export const SlackMessagePayload = z.discriminatedUnion('item_type', [
  z.object({
    item_type: z.literal(SlackItemType.MINI_REPORTS),
    context: z.object({ email: z.string().email() }),
    item_id: z.string().uuid(),
  }),
  z.object({
    item_type: z.literal(SlackItemType.PROJECT),
    item_id: z.string(),
    context: z.undefined().optional(),
  }),
  z.object({
    item_type: z.literal(SlackItemType.REPORT),
    item_id: z.string(),
    context: z.undefined().optional(),
  }),
  z.object({
    item_type: z.literal(SlackItemType.SITE_SELECTION_REQUEST),
    item_id: z.string(),
    context: z.undefined().optional(),
  }),
  z.object({
    item_type: z.literal(SlackItemType.USER),
    item_id: z.string(),
    context: z.undefined().optional(),
  }),
  z.object({
    item_type: z.literal(SlackItemType.CRITICAL_ALERTS),
    item_id: z.string().uuid(),
    context: z
      .object({
        failure_type: z.nativeEnum(CriticalFailureTypes),
        report_name: z.string().optional(),
        user_email: z.string().email().optional(),
        status: z.string().optional(),
      })
      .optional(),
  }),
  z.object({
    item_type: z.literal(SlackItemType.UNKNOWN),
    item_id: z.string(),
    context: z.undefined().optional(),
  }),
]);

export type SlackMessagePayload = z.infer<typeof SlackMessagePayload>;
