import { z } from 'zod';
import { ArrayOrString } from '../../../utils/array-or-string';
import { stringToBoolean } from '../../../utils/string-to-boolean';
export const ParcelFilterParcelsParams = z.object({
  sortModel: z.string(),
  includeWatersConcernLevel: stringToBoolean,
  pageSize: z.preprocess(
    (val) => (typeof val === 'string' ? parseInt(val, 10) : val),
    z.number().nullish(),
  ),
  page: z.preprocess(
    (val) => (typeof val === 'string' ? parseInt(val, 10) : val),
    z.number().nullish(),
  ),
  excludedParcelIds: ArrayOrString,
  search: z.string(),
  combineParcelsByOwner: stringToBoolean.default('false'),
});
export type ParcelFilterParcelsParams = z.infer<
  typeof ParcelFilterParcelsParams
>;
