import { z } from 'zod';

export const SolarRegulationsRequestVerificationNotesDTO = z.object({
  reportId: z.string(),
  notes: z.string(),
});

export type SolarRegulationsRequestVerificationNotesDTO = z.infer<
  typeof SolarRegulationsRequestVerificationNotesDTO
>;
