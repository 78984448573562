import { z } from 'zod';
import { SpeciesLocationObject } from '../species-location-object';

export const SpeciesLocationNoGeometryDTO = SpeciesLocationObject.omit({
  geometry: true,
});

export type SpeciesLocationNoGeometryDTO = z.infer<
  typeof SpeciesLocationNoGeometryDTO
>;
