<transect-nx-dialog-modal
  title="Threatened and Endangered Species Survey Scope of Work"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
>
</transect-nx-dialog-modal>

<ng-template #contentTemplate>
  <div
    class="content-container"
    tsDetectScrollToEnd
    (scrollEnd)="handleScrollToEnd()"
  >
    <ts-add-on-threatened-and-endangered-species-step-details
      [(displayOption)]="view.state.viewedOption"
      [disabled]="view.state.isComplete$ | async"
    ></ts-add-on-threatened-and-endangered-species-step-details>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container
    *ngIf="(view.state.isComplete$ | async) === false; else downloadPdf"
  >
    <button
      transect-nx-button
      [color]="view.state.saveButtonColor$ | async"
      buttonStyle="contained"
      size="md"
      (click)="handleSaveClick()"
      [disabled]="(view.state.canSave$ | async) === false"
      tsBtnLoader
      [loaderState]="view.state.inProgress$ | async"
    >
      Save
    </button>
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="view.state.isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>

<ng-container *ngFor="let action$ of view.actions">
  <ng-container *ngIf="action$ | async"></ng-container>
</ng-container>
