import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetectScrollToEndDirective } from './detect-scroll-to-end.directive';

@NgModule({
  declarations: [DetectScrollToEndDirective],
  imports: [CommonModule],
  exports: [DetectScrollToEndDirective],
})
export class DetectScrollToEndModule {}
