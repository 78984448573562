import { z } from 'zod';

export const CreateRegulatorPayload = z.object({
  name: z.string(),
  acronym: z.string().nullish(),
  parent__id: z.string().nullable(),
  geography__id: z.string().uuid().nullish(),
  jurisdiction: z.string(),
  status_expert: z.preprocess((val) => (val ? val : 'in_progress'), z.string()),
  active: z.boolean().optional(),
  region: z.string().nullable(),
  owner__id: z.string().nullable(),
  profile: z
    .object({
      website: z.string().nullish(),
      address1: z.string(),
      city: z.string(),
      state: z.string(),
      zip: z.string(),
      logo_url: z.string().nullish(),
      twitter: z.string().nullish(),
      facebook: z.string().nullish(),
      linkedin: z.string().nullish(),
      instagram: z.string().nullish(),
      youtube: z.string().nullish(),
      email: z.string().nullish(),
      phone: z.string().nullish(),
      fax: z.string().nullish(),
    })
    .optional(),
});
export type CreateRegulatorPayload = z.infer<typeof CreateRegulatorPayload>;
