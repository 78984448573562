import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import {
  TransectPlanKey,
  UserPreferencesDTO,
} from '@transect-nx/data-transfer-objects';
import { addDays, isBefore, startOfDay } from 'date-fns';
import {
  BehaviorSubject,
  EMPTY,
  forkJoin,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  clearLoginAttemptInfo,
  consumeCode,
  createCode,
  getLoginAttemptInfo,
  resendCode,
} from 'supertokens-web-js/recipe/passwordless';
import Session, { BooleanClaim } from 'supertokens-web-js/recipe/session';
import { environment } from '../../environments/environment';
import { Customer } from '../models/customer';
import { TransectAPIError } from '../models/transect-api-error';
import { BasicUserProfile, User } from '../models/user';
import { AlertService } from './alert.service';
import { UserApiService } from './backend-api/user.service';

declare global {
  interface Window {
    analytics: {
      track: (eventName: string, options?: object) => void;
      identify: (id: string, options?: object) => void;
      page: () => void;
    };
    hj: any;
    pendo: any;
    pdfReady: boolean;
    // HubSpot hsConversationsSettings
    hsConversationsSettings: {
      identificationToken: string;
      identificationEmail: string | undefined;
    };
    hsConversationsOnReady: (() => void)[];
    HubSpotConversations: {
      clear: (params: { resetWidget: boolean }) => void;
      widget: {
        open: () => void;
        remove: () => void;
        refresh: () => void;
      };
    };
  }
}

export enum LogoutType {
  SESSION_EXPIRED,
  USER,
  DEACTIVATED_USER,
  BLOCKED_USER,
}

type LoginResponse = {
  token?: string;
  refresh_token?: string;
  visitor_identification_token?: string;
  two_fa_enabled?: boolean;
};

export const SecondFactorClaim = new BooleanClaim({
  id: '2fa-completed',
  refresh: async () => {
    // no-op
  },
});

export const SecondFactorRequiredClaim = new BooleanClaim({
  id: '2fa-required',
  refresh: async () => {
    // no-op
  },
});

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user$ = new BehaviorSubject<User | null>(null);
  private _userPreferences$ = new BehaviorSubject<UserPreferencesDTO | null>(
    null,
  );

  /** Emits the current user if set; otherwise waits for the user to be set. */
  userObserver$ = this._user$
    .asObservable()
    .pipe(filter((user): user is User => Boolean(user)));

  userPreferences$ = this._userPreferences$.asObservable();

  /** Emits the current user even if null. */
  userOrNull$ = this._user$.asObservable();

  isLoggedIn$ = this.userOrNull$.pipe(
    switchMap((user) =>
      from(this.isTokenSet()).pipe(map((isTokenSet) => ({ isTokenSet, user }))),
    ),
    map(({ isTokenSet, user }) => Boolean(user) && isTokenSet),
  );

  redirectUrl: UrlTree | null = null;
  isAdmin = false;

  isAdmin$: Observable<boolean> = this.userObserver$.pipe(
    map((user) => user?.role === 'admin'),
  );

  isFreeOrFreeTrialUser$: Observable<boolean> = this.userObserver$.pipe(
    map((user) => {
      return (
        user?.transect_plan_name === TransectPlanKey.Free ||
        user?.transect_plan_name === TransectPlanKey.SelfServeFreeTrial
      );
    }),
  );

  freeOrFreeTrialExpirationDate$: Observable<Date | null> =
    this.userObserver$.pipe(
      map((user) => {
        const customer = user.customer ?? user.customers?.[0];
        const expirationDate = customer?.free_trial_expiration_date;

        return expirationDate ? new Date(expirationDate) : null;
      }),
    );

  /**
   * If the free_trial_expiration_date is set to 2024-10-20.
   * On 2024-10-21, the customer will expire.
   */
  isCustomerExpired$: Observable<boolean> =
    this.freeOrFreeTrialExpirationDate$.pipe(
      map((expirationDate) => {
        const today = startOfDay(new Date());
        const expirationThreshold = startOfDay(addDays(expirationDate, 1));
        return !isBefore(today, expirationThreshold);
      }),
    );

  private logoutAction = new BehaviorSubject<LogoutType | null>(null);

  onLogout$ = this.logoutAction.asObservable();

  onSessionExpired$ = this.logoutAction.pipe(
    map((logoutType) => logoutType === LogoutType.SESSION_EXPIRED),
  );

  onDeactivatedAccount$ = this.logoutAction.pipe(
    map((logoutType) => logoutType === LogoutType.DEACTIVATED_USER),
  );

  onBlockedAccount$ = this.logoutAction.pipe(
    map((logoutType) => logoutType === LogoutType.BLOCKED_USER),
  );

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private userService: UserApiService,
  ) {}

  /**
   * @deprecated please user the userObserver$ instead
   */
  get currentUser(): User | null {
    if (!this._user$.getValue()) {
      return null;
    }

    return this._user$.getValue();
  }

  get currentUserBasicProfile(): BasicUserProfile | null {
    if (!this.currentUser?._id) {
      return null;
    }

    return {
      _id: this.currentUser._id,
      firstname: this.currentUser.firstname,
      lastname: this.currentUser.lastname,
      fullname: this.currentUser.fullname,
      role: this.currentUser.role,
    };
  }

  get currentCustomerId() {
    return this.currentUser?.customers &&
      this.currentUser?.customers?.length > 0
      ? this.currentUser.customers[0]._id
      : null;
  }

  get currentCustomer(): Customer | null {
    return this.currentUser?.customers && this.currentUser.customers?.length > 0
      ? this.currentUser.customers[0]
      : null;
  }

  get showSiteSelectionPendingDialog(): boolean {
    return this.currentUser?.preferences?.site_selection_pending_dialog ?? true;
  }

  loginAsUser(userId: string): Observable<User | null> {
    localStorage.removeItem('isInGhostMode');
    return this.userService.loginAs(userId).pipe(
      tap(() => {
        localStorage.setItem('isInGhostMode', 'true');
      }),
      switchMap(() => this.fetchCurrentUser()),
    );
  }

  setUserPreferences(preferences: UserPreferencesDTO | null) {
    this._userPreferences$.next(preferences);
  }

  checkIfAdmin() {
    return this.userOrNull$.pipe(map((user) => user?.role === 'admin'));
  }

  incrementUserCreatedProjectsCount(): void {
    const user = this.currentUser;
    if (user) {
      this._user$.next({
        ...user,
        projects_created_count: (user?.projects_created_count ?? 0) + 1,
      });
    }
  }

  fetchCurrentUser(): Observable<User | null> {
    return from(this.isTokenSet()).pipe(
      switchMap((isTokenSet) =>
        isTokenSet ? this.userService.fetchMe() : of(null),
      ),
      tap((user) => {
        if (user?._id !== this._user$.getValue()?._id) {
          this._user$.next(user);
        }
      }),
      catchError((error) => {
        this.alertService.showError(error);
        throw error;
      }),
    );
  }

  navigateToApp() {
    return this.fetchCurrentUser().pipe(
      switchMap((user) => {
        if (this.redirectUrl) {
          return this.router.navigateByUrl(this.redirectUrl);
        } else {
          if (user?.role === 'public-user') {
            return this.router.navigate(['portal/marketplace/my-orders']);
          } else {
            return this.router.navigate(['portal/dashboard']);
          }
        }
      }),
      tap(() => {
        this.redirectUrl = null;
      }),
      catchError((error) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.logout().pipe(switchMap(() => throwError(() => error)));
      }),
    );
  }

  login(email: string, password: string) {
    const login$ = this.http
      .post<{
        status: 'OK';
        user: User;
        fetchResponse: Response;
      }>(`${environment.apiUrl}/auth/signin`, {
        formFields: [
          {
            id: 'email',
            value: email,
          },
          {
            id: 'password',
            value: password,
          },
        ],
      })
      .pipe(
        switchMap(() => {
          return forkJoin({
            two_fa_completed: Session.getClaimValue({
              claim: SecondFactorClaim,
            }),
            two_fa_required: Session.getClaimValue({
              claim: SecondFactorRequiredClaim,
            }),
          });
        }),
        switchMap(({ two_fa_completed, two_fa_required }) => {
          if (!two_fa_completed && two_fa_required) {
            return from(
              this.router.navigate(['two-factor-authentication'], {
                queryParams: {
                  email,
                },
                skipLocationChange: true,
              }),
            ).pipe(switchMap(() => EMPTY));
          }

          return of(null);
        }),
      );

    return from(this.isTokenSet()).pipe(
      switchMap((isTokenSet) => (isTokenSet ? of(null) : login$)),
      switchMap(() => this.navigateToApp()),
    );
  }

  signUp(data: {
    firstname: string;
    lastname: string;
    isPublicUser?: boolean;
    email: string;
    password: string;
    industry__id: string;
    profile: {
      phone?: string;
    };
    organization?: string;
    title?: string;
  }) {
    return this.http
      .post<
        | {
            status: 'OK';
            user: User;
          }
        | {
            status: 'FIELD_ERROR';
            formFields: {
              id: string;
              error: string;
            }[];
          }
        | {
            status: 'SIGN_UP_NOT_ALLOWED';
            reason: string;
          }
      >(`${environment.apiUrl}/auth/signup`, {
        formFields: [
          {
            id: 'email',
            value: data.email,
          },
          {
            id: 'password',
            value: data.password,
          },
          {
            id: 'firstname',
            value: data.firstname,
          },
          {
            id: 'lastname',
            value: data.lastname,
          },
          {
            id: 'industry__id',
            value: data.industry__id,
          },
          {
            id: 'phone',
            value: data.profile?.phone ?? '',
          },
          {
            id: 'isPublicUser',
            value: !!data.isPublicUser,
          },
          {
            id: 'organization',
            value: data.organization ?? null,
          },
          {
            id: 'title',
            value: data.title ?? null,
          },
        ],
      })
      .pipe(
        map((response) => {
          if (response.status === 'FIELD_ERROR') {
            throw new TransectAPIError({
              code: response.status,
              detail: response.formFields?.[0].error,
            });
          } else if (response.status === 'SIGN_UP_NOT_ALLOWED') {
            throw new TransectAPIError({
              code: response.status,
              detail: response.reason,
            });
          } else {
            void this.router.navigate(['/portal/dashboard']);
            return response.user;
          }
        }),
      );
  }

  logout(
    redirectToLogin: boolean = true,
    logoutType: LogoutType = LogoutType.USER,
  ) {
    return from(Session.signOut()).pipe(
      map(() => {
        const userToLogout = this._user$.getValue();

        this._user$.next(null);
        return userToLogout;
      }),
      switchMap((userToLogout) => {
        if (redirectToLogin) {
          if (userToLogout?.role === 'public-user') {
            return this.router.navigate(['/portal/marketplace']);
          }

          return this.router.navigate(['/login']);
        }

        return of(null);
      }),
      finalize(() => {
        this.logoutAction.next(logoutType);
      }),
    );
  }

  isTokenSet() {
    return Session.doesSessionExist();
  }

  async shouldShowSecondFactor() {
    const doesSessionExist = await Session.doesSessionExist();

    if (!doesSessionExist) {
      return false;
    }

    const accessTokenPayload = await this.getTokenPayload();

    if (!accessTokenPayload.email) {
      return false;
    }

    const secondFactorCompleted = await Session.getClaimValue({
      claim: SecondFactorClaim,
    });

    const secondFactorRequired = await Session.getClaimValue({
      claim: SecondFactorRequiredClaim,
    });

    return secondFactorRequired && !secondFactorCompleted;
  }

  recoverPassword(email: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/recover`, {
      email,
    });
  }

  resetPassword(token: string, newPassword: string): Observable<any> {
    return this.http.post(`${environment.apiUrl}/auth/reset/${token}`, {
      newPassword,
    });
  }

  async hasInitialOTPBeenSent() {
    return (await getLoginAttemptInfo()) !== undefined;
  }

  send2FAToken(email: string) {
    return from(this.hasInitialOTPBeenSent()).pipe(
      switchMap((hasInitialOTPBeenSent) => {
        if (hasInitialOTPBeenSent) {
          return of(null);
        }

        return createCode({
          email,
        });
      }),
      tap((response) => {
        if (response === null) {
          return;
        }

        if (response.status !== 'OK') {
          throw new TransectAPIError({
            code: response.status,
            detail: 'Failed to send 2FA code',
            status: response.fetchResponse.status,
          });
        }
      }),
      map(() => {
        return;
      }),
    );
  }

  resend2FAToken() {
    return from(resendCode()).pipe(
      take(1),
      switchMap((response) => {
        if (response.status === 'RESTART_FLOW_ERROR') {
          return from(clearLoginAttemptInfo()).pipe(
            switchMap(() =>
              throwError(() => {
                void this.router.navigate(['/login']);
                return new TransectAPIError({
                  code: response.status,
                  detail: 'Login failed. Please try again.',
                  status: response.fetchResponse.status,
                });
              }),
            ),
          );
        }

        return of(response);
      }),
    );
  }

  verify2FAToken(otp: string) {
    return from(
      consumeCode({
        userInputCode: otp,
      }),
    ).pipe(
      switchMap((response) => {
        if (response.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
          throw new TransectAPIError({
            code: response.status,
            detail: `Wrong OTP! Please try again. Number of attempts left: ${
              response.maximumCodeInputAttempts -
              response.failedCodeInputAttemptCount
            }`,
            status: response.fetchResponse.status,
          });
        }

        if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
          throw new TransectAPIError({
            code: response.status,
            detail: `Old OTP entered. Please regenerate a new one and try again.`,
            status: response.fetchResponse.status,
          });
        }

        return from(clearLoginAttemptInfo()).pipe(
          switchMap(() => {
            if (response.status !== 'OK') {
              void this.router.navigate(['/login']);
              return throwError(() => {
                return new TransectAPIError({
                  code: response.status,
                  detail: 'Login failed. Please try again.',
                  status: response.fetchResponse.status,
                });
              });
            }

            return this.router.navigate(
              ['two-factor-authentication', 'verification-successful'],
              {
                skipLocationChange: true,
              },
            );
          }),
        );
      }),
    );
  }

  deleteUser(id: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.apiUrl}/protected/users/${id}`,
    );
  }

  updateProfilePhoto(user: User) {
    const currentUserValue = this._user$.getValue();
    this._user$.next({ ...currentUserValue, photo_gcs: user.photo_gcs });
  }

  /** Updates the current user's preferences and emits the new preferences on the user observer. */
  updatePreferences(userPreferences: UserPreferencesDTO) {
    return this.userService.updateMyPreferences(userPreferences).pipe(
      tap(() => {
        this._user$.next({
          ...this.currentUser,
          preferences: {
            ...this.currentUser?.preferences,
            ...userPreferences,
          },
        });
      }),
    );
  }

  getTokenPayload() {
    return Session.getAccessTokenPayloadSecurely() as Promise<{
      email?: string;
    }>;
  }
}
