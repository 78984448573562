import { Injectable } from '@angular/core';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import _isEqual from 'lodash/isEqual';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
  NEVER,
  of,
  pipe,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {
  returnFromAction$,
  TransectStateService,
} from '../utils/state-management';
import { AlertService } from './alert.service';
import { FormsApiService } from './backend-api/forms.service';
import { ReportAddOnApiService } from './backend-api/report-add-on-api.service';

export type State = {
  canSaveForm: boolean;
  scrolledToEnd: boolean;
  isComplete: boolean;
  completing: boolean;
  isFormValid: boolean;
  backendFormId?: string;
  formValue?: FormDTO['contents'];
  persisting: boolean;
  isFetchingPdf: boolean;
};

export type PersistAction = {
  formValue: FormDTO['contents'];
  formTypeId: string;
  addOnId?: string;
};

@Injectable()
export class TransectFormStateService extends TransectStateService<State> {
  private readonly persistFormAction = new Subject<PersistAction>();

  private readonly completeFormAction = new Subject<void>();
  private readonly downloadPdfAction = new Subject<void>();

  private readonly destroyAction = new Subject<void>();

  private readonly catchError$ = pipe(
    catchError((error) => {
      this.updateState({
        completing: false,
        persisting: false,
        isFetchingPdf: false,
      });
      this.alertService.showError(error);
      return NEVER;
    }),
  );

  isFetchingPdf$ = this.state.pipe(
    map((state) => state.isFetchingPdf),
    distinctUntilChanged(),
  );

  completing$ = this.state.pipe(
    map((state) => state.completing),
    distinctUntilChanged(),
  );

  inProgress$ = this.state.pipe(
    map((state) => state.persisting || state.completing),
    distinctUntilChanged(),
  );

  formValue$ = this.state.pipe(
    map((state) => state.formValue),
    distinctUntilChanged(),
  );

  backendFormId$ = this.state.pipe(
    map((state) => state.backendFormId),
    distinctUntilChanged(),
  );

  isComplete$ = this.state.pipe(
    map((state) => state.isComplete),
    distinctUntilChanged(),
  );

  scrolledToEnd$ = this.state.pipe(
    map((state) => state.scrolledToEnd),
    distinctUntilChanged(),
  );

  canSave$ = this.state.pipe(
    map((state) => {
      return (
        state.isFormValid &&
        state.scrolledToEnd &&
        !state.isComplete &&
        !state.persisting
      );
    }),
    distinctUntilChanged(),
  );

  saveButtonColor$ = this.canSave$.pipe(
    map<boolean, 'primary' | 'muted'>((canSave) =>
      canSave ? 'primary' : 'muted',
    ),
    distinctUntilChanged(),
  );

  persistFormEffect$ = this.persistFormAction.pipe(
    debounceTime(200),
    distinctUntilChanged((prev, curr) => {
      return _isEqual(prev.formValue, curr.formValue);
    }),
    tap(() => {
      this.updateState({ persisting: true });
    }),
    concatMap((action) => {
      if (this.stateValue.backendFormId) {
        return this.formsApiService
          .updateForm(this.stateValue.backendFormId, action.formValue)
          .pipe(this.catchError$);
      }

      return this.formsApiService
        .createForm({
          contents: action.formValue,
          form_type__id: action.formTypeId,
        })
        .pipe(
          tap((form) => {
            this.updateState({ backendFormId: form._id });
          }),
          switchMap((form) => {
            if (action.addOnId) {
              return this.reportAddOnApiService.associateFormWithAddOn(
                action.addOnId,
                form._id,
              );
            }
            return of(form);
          }),
          this.catchError$,
        );
    }),
    tap(() => {
      this.updateState({ persisting: false });
    }),
    shareReplay(1),
  );

  completeFormEffect$ = this.completeFormAction.pipe(
    tap(() => this.updateState({ completing: true })),
    switchMap(() => {
      return this.formsApiService.markFormComplete(
        this.stateValue.backendFormId,
      );
    }),
    tap(() =>
      this.updateState({
        isComplete: true,
        completing: false,
      }),
    ),
    this.catchError$,
    shareReplay(1),
  );

  downloadPdfEffect$ = this.downloadPdfAction.pipe(
    tap(() => {
      this.updateState({ isFetchingPdf: true });
    }),
    switchMap(() => {
      return this.formsApiService.downloadAsPdf(this.stateValue.backendFormId);
    }),
    tap(() => {
      this.updateState({ isFetchingPdf: false });
    }),
    this.catchError$,
    shareReplay(1),
  );

  constructor(
    private formsApiService: FormsApiService,
    private alertService: AlertService,
    private reportAddOnApiService: ReportAddOnApiService,
  ) {
    super({
      canSaveForm: false,
      scrolledToEnd: false,
      isFormValid: false,
      isComplete: false,
      completing: false,
      persisting: false,
      isFetchingPdf: false,
    });
  }

  dispatchPdfDownloadAction() {
    this.downloadPdfAction.next();
    return returnFromAction$(this.downloadPdfEffect$);
  }

  dispatchCompleteFormAction() {
    this.completeFormAction.next();
    return returnFromAction$(this.completeFormEffect$);
  }

  dispatchScrolledToEndAction() {
    this.updateState({
      scrolledToEnd: true,
    });
  }

  dispatchFormValidAction(isFormValid: boolean) {
    this.updateState({
      isFormValid,
    });
  }

  dispatchFormValueChangeAction(
    isFormValid: boolean,
    formValue: FormDTO['contents'],
    formTypeId: string,
    addOnId?: string,
  ) {
    this.updateState({
      formValue,
      isFormValid,
    });

    this.persistFormAction.next({
      addOnId,
      formTypeId,
      formValue,
    });
    return returnFromAction$(this.persistFormEffect$);
  }

  dispatchBackendFormIdAction(backendFormId: string) {
    this.updateState({
      backendFormId,
    });
  }

  /**
   * this method sets the starting state of the form from the database
   * @param backendFormId the form id stored in the database
   * @param formValue the contents of the form
   * @param isComplete whether the form is complete or not
   */
  dispatchBackendFormStateAction(
    backendFormId: string,
    formValue: FormDTO['contents'],
    isComplete: boolean,
  ) {
    this.updateState({
      backendFormId,
      formValue,
      isComplete,
    });
  }

  registerEffects(): void {
    this.persistFormEffect$.pipe(takeUntil(this.destroyAction)).subscribe();
    this.completeFormEffect$.pipe(takeUntil(this.destroyAction)).subscribe();
    this.downloadPdfEffect$.pipe(takeUntil(this.destroyAction)).subscribe();
  }

  unRegisterEffects(): void {
    this.destroyAction.next();
  }
}
