import { z } from 'zod';

/**
 * Defines a Zod schema that represents either a string or an array of strings.
 * If the provided value is a string, it transforms it into an array with that string as its only element.
 * If the provided value is an array, it returns the array as-is.
 * If no value or a null/undefined value is provided, it returns the value without transformation.
 * The resulting type after using this schema is an optional array of strings.
 *
 * @example
 * ArrayOrString.parse("hello") -> ["hello"]
 * ArrayOrString.parse(["hello", "world"]) -> ["hello", "world"]
 * ArrayOrString.parse(undefined) -> undefined
 *
 * @type {z.ZodOptional<z.ZodTransformer<z.ZodUnion<[z.ZodString, z.ZodArray<z.ZodString>]>, string[], string[]>>}
 */
export const ArrayOrString = z
  .union([z.string(), z.array(z.string())])
  .transform((val) => {
    if (!val) {
      return val;
    }
    if (Array.isArray(val)) {
      return val;
    }
    return [val];
  })
  .optional();

/**
 * Type inferred from the ArrayOrString Zod schema.
 * Represents an optional array of strings.
 *
 * @typedef {string[] | undefined} ArrayOrString
 */
export type ArrayOrString = z.infer<typeof ArrayOrString>;
