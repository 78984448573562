import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ResponseRows } from '../../../utils/response-rows';
import { ReferenceDTO } from '../reference.dto';

export const IndexReferenceDTO = ReferenceDTO.extend({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  broken_links_count: z.number(),
});

export const ReferencesIndexResponseDTO = ResponseRows(IndexReferenceDTO);
export type ReferencesIndexResponseDTO = z.infer<
  typeof ReferencesIndexResponseDTO
>;
