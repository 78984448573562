import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import {
  ReportPartActiveRegulationDTO,
  ReportPartRegulationDTO,
} from '../models/report-part-regulation.dto';

export const PaginatedReportPartRegulationsDTO = PaginatedResponseRows(
  ReportPartRegulationDTO,
);

export type PaginatedReportPartRegulationsDTO = z.infer<
  typeof PaginatedReportPartRegulationsDTO
>;

export const PaginatedReportPartActiveRegulationsDTO = PaginatedResponseRows(
  ReportPartActiveRegulationDTO,
);

export type PaginatedReportPartActiveRegulationsDTO = z.infer<
  typeof PaginatedReportPartActiveRegulationsDTO
>;
