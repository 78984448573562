import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ReferenceDTO } from '../reference.dto';

export const ReferencesShowResponseDTO = ReferenceDTO.extend({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});

export type ReferencesShowResponseDTO = z.infer<
  typeof ReferencesShowResponseDTO
>;
