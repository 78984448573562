import { AssessmentLevels } from './models';

export enum MonitoringDataPointCategory {
  FEDERAL_AND_STATE_PERMITS = 'federal_and_state_permits',
  FEDERAL_AND_STATE_REGULATIONS = 'federal_and_state_regulations',
  SPECIES_DATA = 'species_data',
  REPORT_STATS = 'report_stats',
}

export enum MonitoringDataPointKey {
  PERMIT_ADDED_REMOVED = 'permit_added_removed',
  PERMIT_TIMELINE = 'permit_timeline',
  REGULATION_ADDED_REMOVED = 'regulation_added_removed',
  SPECIES_ADDED_REMOVED = 'species_added_removed',
  SPECIES_CONCERN_LEVEL = 'species_concern_level',
  STATE_SPECIES_ADDED_REMOVED = 'state_species_added_removed',
  STATE_SPECIES_CONCERN_LEVEL = 'state_species_concern_level',
  PERMIT_REGULATIONS = 'permit_regulations',
  REGULATION_PERMITS = 'regulation_permits',
  REPORT_GENERATED_AT = 'report_generated_at',
}

export interface MonitoringStatus {
  active: boolean;
  user: string;
  report: string;
}

interface BaseChangelog<
  Key extends MonitoringDataPointKey,
  Category extends MonitoringDataPointCategory,
  Log extends { value: NonNullable<object>; changes: object | null },
> {
  calculated_at: Date;
  category: Category;
  key: Key;
  log: Log;
}

export type PermitAddRemoveChangelog = BaseChangelog<
  MonitoringDataPointKey.PERMIT_ADDED_REMOVED,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS,
  {
    value: {
      active_permits: { _id: string; name: string; jurisdiction: string }[];
    };
    changes: {
      permits_added: { _id: string; name: string; jurisdiction: string }[];
      permits_removed: { _id: string; name: string; jurisdiction: string }[];
    } | null;
  }
>;

export type PermitRegulationsChangelog = BaseChangelog<
  MonitoringDataPointKey.PERMIT_REGULATIONS,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS,
  {
    value: {
      permit_regulations: {
        _id: string;
        name: string;
        jurisdiction: string;
        regulations: {
          _id: string;
          name: string;
        }[];
      }[];
    };
    changes: {
      permit_regulations: {
        _id: string;
        name: string;
        jurisdiction: string;
        regulations_added: {
          _id: string;
          name: string;
        }[];
        regulations_removed: {
          _id: string;
          name: string;
        }[];
      }[];
    } | null;
  }
>;

export type PermitTimelineChangelog = BaseChangelog<
  MonitoringDataPointKey.PERMIT_TIMELINE,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_PERMITS,
  {
    value: {
      permit_timelines: {
        _id: string;
        name: string;
        jurisdiction: string;
        timeline: string;
      }[];
    };
    changes: {
      permit_timelines_changed: {
        _id: string;
        name: string;
        jurisdiction: string;
        new_timeline: string;
        old_timeline: string;
      }[];
    } | null;
  }
>;

export type RegulationAddRemoveChangelog = BaseChangelog<
  MonitoringDataPointKey.REGULATION_ADDED_REMOVED,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS,
  {
    value: {
      active_regulations: { _id: string; name: string; jurisdiction: string }[];
    };
    changes: {
      regulations_added: { _id: string; name: string; jurisdiction: string }[];
      regulations_removed: {
        _id: string;
        name: string;
        jurisdiction: string;
      }[];
    } | null;
  }
>;

export type RegulationPermitsChangelog = BaseChangelog<
  MonitoringDataPointKey.REGULATION_PERMITS,
  MonitoringDataPointCategory.FEDERAL_AND_STATE_REGULATIONS,
  {
    value: {
      regulation_permits: {
        _id: string;
        name: string;
        jurisdiction: string;
        permits: { _id: string; name: string }[];
      }[];
    };
    changes: {
      regulation_permits: {
        _id: string;
        name: string;
        jurisdiction: string;
        permits_added: { _id: string; name: string }[];
        permits_removed: { _id: string; name: string }[];
      }[];
    } | null;
  }
>;

export type SpeciesAddedRemovedChangelog = BaseChangelog<
  MonitoringDataPointKey.SPECIES_ADDED_REMOVED,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: { species: { _id: string; name: string }[] };
    changes: {
      species_added: { _id: string; name: string }[];
      species_removed: { _id: string; name: string }[];
    } | null;
  }
>;

export type SpeciesConcernLevelChangelog = BaseChangelog<
  MonitoringDataPointKey.SPECIES_CONCERN_LEVEL,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: {
      species: { _id: string; name: string; assessment: AssessmentLevels }[];
    };
    changes: {
      species_concern_levels_changed: {
        _id: string;
        name: string;
        new_assessment: AssessmentLevels;
        old_assessment: AssessmentLevels;
      }[];
    } | null;
  }
>;

export type StateSpeciesAddedRemovedChangelog = BaseChangelog<
  MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: { species: { _id: string; name: string }[] };
    changes: {
      species_added: { _id: string; name: string }[];
      species_removed: { _id: string; name: string }[];
    } | null;
  }
>;

export type StateSpeciesConcernLevelChangelog = BaseChangelog<
  MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL,
  MonitoringDataPointCategory.SPECIES_DATA,
  {
    value: {
      species: { _id: string; name: string; assessment: AssessmentLevels }[];
    };
    changes: {
      species_concern_levels_changed: {
        _id: string;
        name: string;
        new_assessment: AssessmentLevels;
        old_assessment: AssessmentLevels;
      }[];
    } | null;
  }
>;

export type ReportGeneratedAtChangelog = BaseChangelog<
  MonitoringDataPointKey.REPORT_GENERATED_AT,
  MonitoringDataPointCategory.REPORT_STATS,
  {
    value: {
      generated_at: string;
    };
    changes: {
      old_generated_at: string;
      new_generated_at: string;
    } | null;
  }
>;

export type ChangeLog =
  | PermitAddRemoveChangelog
  | PermitTimelineChangelog
  | RegulationAddRemoveChangelog
  | SpeciesAddedRemovedChangelog
  | SpeciesConcernLevelChangelog
  | PermitRegulationsChangelog
  | RegulationPermitsChangelog
  | ReportGeneratedAtChangelog
  | StateSpeciesAddedRemovedChangelog
  | StateSpeciesConcernLevelChangelog;

export type ReportMonitoredDataPointChangelogMap = {
  [K in MonitoringDataPointKey]: {
    [MonitoringDataPointKey.PERMIT_ADDED_REMOVED]: PermitAddRemoveChangelog;
    [MonitoringDataPointKey.PERMIT_TIMELINE]: PermitTimelineChangelog;
    [MonitoringDataPointKey.REGULATION_ADDED_REMOVED]: RegulationAddRemoveChangelog;
    [MonitoringDataPointKey.SPECIES_ADDED_REMOVED]: SpeciesAddedRemovedChangelog;
    [MonitoringDataPointKey.SPECIES_CONCERN_LEVEL]: SpeciesConcernLevelChangelog;
    [MonitoringDataPointKey.STATE_SPECIES_ADDED_REMOVED]: StateSpeciesAddedRemovedChangelog;
    [MonitoringDataPointKey.STATE_SPECIES_CONCERN_LEVEL]: StateSpeciesConcernLevelChangelog;
    [MonitoringDataPointKey.PERMIT_REGULATIONS]: PermitRegulationsChangelog;
    [MonitoringDataPointKey.REGULATION_PERMITS]: RegulationPermitsChangelog;
    [MonitoringDataPointKey.REPORT_GENERATED_AT]: ReportGeneratedAtChangelog;
  }[K];
};

export enum UserReportMonitorCheckpointType {
  CHANGELOG_VIEW = 'changelog_view',
  NEW_UPDATES_FOUND = 'new_updates_found',
  MONTHLY_RECAP = 'monthly_recap',
}
