import { z } from 'zod';
export const ReportMapConfiguration = z.lazy(() => {
  return z.object({
    version: z.number(),
    layers: z.array(SerializedReportMapLayer),
    baseLayer: z.union([z.string().optional(), z.any().optional()]),
  });
});
export type ReportMapConfiguration = z.infer<typeof ReportMapConfiguration>;

export const ReportMapLayerKey = z.string();
export type ReportMapLayerKey = z.infer<typeof ReportMapLayerKey>;

export interface SerializedReportMapLayerInterface {
  key?: ReportMapLayerKey;
  labeled?: boolean;
  featureIds?: string[];
  children?: SerializedReportMapLayer[];
}
export const SerializedReportMapLayer: z.ZodType<SerializedReportMapLayerInterface> =
  z.lazy(() => {
    return z.object({
      key: ReportMapLayerKey,
      labeled: z.boolean(),
      featureIds: z.array(z.string()).optional(),
      children: z.array(SerializedReportMapLayer).optional(),
    });
  });
export type SerializedReportMapLayer = z.infer<typeof SerializedReportMapLayer>;
