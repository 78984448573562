import { z } from 'zod';
import { DateAsString, DateSchema } from '../../../utils/date-schema';
import {
  GeometrySchema,
  PointGeometrySchema,
} from '../../../utils/geometry-object-schema';
import { ReportConcernLevel, ReportContext, ReportPlugin } from '../report.dto';

const PassthroughEmptyObject = z.object({}).passthrough();

const ReportAnnotationSchema = z.object({
  _id: z.string().uuid().optional(),
  type: z.string(),
  name: z.string(),
  description: z.string().optional(),
  presence: z.boolean().optional(),
  object__type: z.string().optional(),
  object__id: z.string().optional(),
  active: z.boolean().optional(),
  priority: z.boolean().optional(),
  admin: z.object({
    is_ghost_water: z.boolean().optional(),
    is_exclusion_zone: z.boolean().optional(),
  }),
  geometry: GeometrySchema.optional(),
  creation_type: z.enum(['automated', 'user', 'admin']).optional(),
  validation: z.string().optional(),
  report__id: z.string().uuid().optional(),
  creator__id: z.string().uuid().optional(),
  created_at: DateAsString.optional(),
  updated_at: DateAsString.optional(),
});

const GeographiesStatesSchema = z.array(
  z.object({
    state: z.string(),
    counties: z.array(z.string()).nullish(),
    abbreviation: z.string(),
    geography__id: z.string().uuid(),
  }),
);

export const ReportProject = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  customer: z
    .object({
      _id: z.string().uuid(),
      name: z.string(),
    })
    .nullish(),
});

export const ReportUpdatedDTO = z.object({
  isComplete: z.boolean(),
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  content: z
    .object({
      executive_summary_v3: z.boolean().nullish(),
      executive_summary_override_enabled: z.boolean().nullish(),
      notes: z.string().nullish(),
      expert_notes: z.string().nullish(),
      executive_summary_overrides: z.preprocess(
        (val) => (val === null ? [] : val),
        z.array(
          z.object({
            level: z.enum(['high', 'moderate', 'low']),
            section: z.string(),
            additional: z.string().nullish(),
            reason: z.string().nullish(),
          }),
        ),
      ),
    })
    .deepPartial()
    .passthrough()
    .nullish(),
  parent_report_id: z.string().uuid().nullish(),
  name: z.string(),
  type: z.string(),
  aoi: GeometrySchema.nullish(),
  center: PointGeometrySchema.nullish(),
  buffer: GeometrySchema.nullish(),
  drawn_objects: GeometrySchema.nullish(),
  status: z.string(),
  status_review: z.enum(['no_review', 'complete', 'pending']),
  progress: z.number(),
  report_annotations: z.array(ReportAnnotationSchema).nullish(),
  version: z
    .string()
    .regex(
      /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
    ),
  properties: z
    .object({
      aoi: z
        .object({
          area: z
            .object({
              acres: z.number().optional(),
              feet: z.number().optional(),
              miles: z.number().optional(),
            })
            .optional(),
          length: z
            .object({
              feet: z.number().optional(),
              miles: z.number().optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .passthrough()
    .nullish(),
  context: ReportContext,
  settings: z
    .object({
      buffer_width_miles: z.number().nullish(),
    })
    .passthrough(),
  errors: PassthroughEmptyObject.nullish(),
  geographies_states: GeographiesStatesSchema,
  is_customizable: z.boolean(),
  waters_analyzed: z.boolean().nullish(),
  waters_analyzed_date: z.string().nullish(),
  is_sales_demo: z.boolean(),
  state_species_supported: z.boolean().nullish(),
  notification_report_completed: DateSchema.nullish(),
  notification_review_completed: DateSchema.nullish(),
  date_first_completed: DateSchema.nullish(),
  simplified_aoi: z.string().nullish(),
  simplified_aoi_description: z.string().nullish(),
  concern_levels: z
    .object({
      waters: ReportConcernLevel,
      species: ReportConcernLevel,
      state_species: ReportConcernLevel,
      protected_area: ReportConcernLevel,
      environmental_compliance: ReportConcernLevel,
    })
    .nullish(),
  additional_notes: z.string().nullish(),
  report_sample__id: z.string().uuid().nullish(),
  review_completed_at: DateSchema.nullish(),
  project__id: z.string().uuid().nullish(),
  trim_geography__id: z.string().uuid().nullish(),
  order__id: z.string().uuid().nullish(),
  creator__id: z.string().uuid().nullish(),
  executive_summary_override_enabled: z.boolean(),
  plugins: z.array(ReportPlugin).nullish(),
  creator: z.preprocess(
    (val) => (typeof val === 'string' ? { _id: val } : val),
    z
      .object({
        _id: z.string().uuid(),
        fullname: z.string().nullish(),
        firstname: z.string().nullish(),
        lastname: z.string().nullish(),
      })
      .nullish(),
  ),
  assigned_to: z.string().uuid().nullish(),
  project: ReportProject.nullish(),
  review_requested_at: DateSchema,
  user_refresh_date: DateSchema.nullish(),
});

export type ReportUpdatedDTO = z.infer<typeof ReportUpdatedDTO>;
