import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormCeiiNdaComponent } from './form-ceii-nda.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {
  DialogModalModule,
  TransectButtonsModule,
  FormsModule as TransectFormsModule,
} from '@transect-nx/ui';

import { BtnLoaderModule } from '../../shared/directives/btn-loader/btn-loader.module';
import { SharedModule } from '../../shared/shared.module';
import { DetectScrollToEndModule } from '../detect-scroll-to-end/detect-scroll-to-end.module';
import { FormCeiiNdaService } from './form-ceii-nda.service';
import { FormCeiiNdaProvider } from '../../services/transect-form-state-factory.service';

@NgModule({
  declarations: [FormCeiiNdaComponent],
  imports: [
    CommonModule,
    FormsModule,
    TransectFormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    DialogModalModule,
    TransectButtonsModule,
    DetectScrollToEndModule,
    SharedModule,
    BtnLoaderModule,
  ],
  providers: [FormCeiiNdaService, FormCeiiNdaProvider],
  exports: [FormCeiiNdaComponent],
})
export class FormCeiiNdaModule {}
