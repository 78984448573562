import { z } from 'zod';

export const RecoverPasswordControllerBody = z.object({
  email: z.string().email(),
});

export type RecoverPasswordControllerBody = z.infer<
  typeof RecoverPasswordControllerBody
>;

export const LoginControllerBody = z.object({
  email: z.string().email(),
  password: z.string(),
  keepMeSignedIn: z.boolean().nullish(),
});

export type LoginControllerBody = z.infer<typeof LoginControllerBody>;
