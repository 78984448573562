import { z } from 'zod';

/** Date schema that will preprocess and convert a string date to a Date object. */
export const DateSchema = z.preprocess((arg) => {
  if (typeof arg === 'string' || arg instanceof Date) {
    return new Date(arg);
  }

  return arg;
}, z.date());

export type DateSchema = z.infer<typeof DateSchema>;

export const DateAsString = DateSchema.transform((val) => val.toISOString());
