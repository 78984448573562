import { z } from 'zod';

export enum TransectPlanKey {
  NoAccess = 'no-access',
  Free = 'free',
  Pro = 'pro',
  AssessOnly = 'assess_only',
  SelfServeFreeTrial = 'self_serve_free_trial',
}

export const TransectPlanDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  key: z.nativeEnum(TransectPlanKey),
});
export type TransectPlanDTO = z.infer<typeof TransectPlanDTO>;

export const TransectPlansArrayDTO = TransectPlanDTO.array();
export type TransectPlansArrayDTO = z.infer<typeof TransectPlansArrayDTO>;
