import {
  AllGeoJSON,
  Feature,
  FeatureCollection,
  Geometries,
  Geometry,
  GeometryCollection,
  MultiPolygon,
  Polygon,
} from '@turf/turf';
import { ProjectTypeValue } from '../services/project-context.service';
import { Nda } from './nda';
import { LocalPermit, Permit } from './permit';
import { GeographyState, Project } from './project';
import { Reference } from './reference';
import {
  KmlExportConfig,
  PdfExportConfig,
  ReportExport,
} from './report-export';
import { BasicUserProfile } from './user';
import { Substation } from './substation';

export type ReportPartTypes =
  | 'aspect'
  | 'catalog_location'
  | 'contour'
  | 'ecoregion'
  | 'environmental_compliance'
  | 'firm_panel'
  | 'flood_hazard'
  | 'geography'
  | 'geology'
  | 'hydrography'
  | 'landcover'
  | 'parcel'
  | 'protected_area'
  | 'regulation_location'
  | 'report_catalog_location'
  | 'slope'
  | 'slope2'
  | 'soil'
  | 'species_location'
  | 'state_species_location'
  | 'transportation'
  | 'watershed'
  | 'wetland';
export type ReportCompletionStatus = 'complete' | 'pending';

export type ReportReviewStatus = 'no_review' | 'complete' | 'pending';

export type FinancialIncentivesContext<ProjectCost extends string | number> = {
  project_size_megawatts?: number;
  construction_year?: number;
  meets_labor_requirements?: 'y' | 'n';
  meets_domestic_content_requirements?: 'y' | 'n';
  total_project_cost?: ProjectCost | null;
  estimated_power_output?: number | null;
};
export interface Report {
  _id?: string;
  version?: string;
  is_customizable?: boolean;
  state_species_supported?: boolean;
  aoi?: Polygon | MultiPolygon;
  buffer?: Polygon | MultiPolygon;
  center?: any;
  properties?: {
    aoi?: {
      area?: {
        acres: number;
        feet: number;
        miles: number;
      };
      length?: {
        miles: number;
        feet: number;
      };
    };
  };
  context?: {
    construction_type?: string;
    expert_review?: 'y' | 'n';
    federal_funds?: 'y' | 'n' | 'unknown';
    plan_to_impact_waters?: 'y' | 'n' | 'unknown';
    project_type_description?: string;
    project_subtype?: string;
    project_type?: ProjectTypeValue;
    mixed_use_subtype?: string[];
  } & FinancialIncentivesContext<number>;
  concern_levels?: {
    environmental_compliance: ConcernLevel;
    protected_area: ConcernLevel;
    species: ConcernLevel;
    waters: ConcernLevel;
    state_species: ConcernLevel;
  };
  geographies_states?: GeographyState[];
  project?: Project;
  status?: ReportCompletionStatus;
  status_review?: ReportReviewStatus;
  content?: {
    notes?: string;
  };
  exports?: ReportExport<PdfExportConfig | KmlExportConfig>[];
  report_annotations?: ReportAnnotation[];
  report_parts?: ReportPart[];
  created_at?: string;
  updated_at?: string;
  mapbox_static_url?: string;
  date_first_completed?: string;
  additional_notes?: string;
  drawn_objects?: AllGeoJSON;
  nda?: Nda;
  parent_report_id?: string;
  waters_analyzed?: boolean;
  waters_analyzed_date?: Date;
  review_completed_at?: string;
  review_requested_at?: string;
  user_refresh_date?: string;
  user_refresh?: boolean;
}

export interface ConcernLevel {
  public?: 'low' | 'moderate' | 'high';
  automated?: 'low' | 'moderate' | 'high';
  note?: string;
  user_overridden_value?: string;
  user_overridden_note?: string;
  user_overridden_by?: string;
  user_overridden_date?: string;
}

export interface ReportStatus {
  mapbox_static_url: string;
  report_parts: {
    progress: number;
    status: string;
    type: string;
    _id: string;
  }[];
  status: string;
  _id: string;
}

export interface ReportPartMetadata {
  _id?: string;
  updated_at?: string;
  status: 'complete' | 'pending' | 'reset';
  progress: number;
}

export interface ReportMetadata {
  _id?: string;
  updated_at?: string;
  status?: ReportCompletionStatus;
  report_parts?: ReportPartMetadata[];
}

export interface ReportPart<
  Summary extends object = any,
  Properties extends object = any,
> {
  assessments?: {
    version?: number;
    habitat?: Record<
      string,
      {
        public?: boolean;
        automated?: boolean;
        override?: boolean;
        overriden?: boolean; // we  have a spelling mistake in the data itself
      }
    >;
    concern?: {
      [key: string]: {
        notes?: string;
        value?: string | null;
        overrideReason?: string;
        userData?: {
          user: BasicUserProfile;
          value: string;
          reason: string;
        };
      };
    };
    permit_overrides?: {
      [_id: string]: {
        notes?: string;
        overridden_by?: string;
        overridden_date?: string;
        overridden_role?: string;
        value?: string | null;
        adminOverridden?: boolean;
      };
    };
    local_permit_overrides?: {
      [_id: string]: {
        notes?: string;
        overridden_by?: string;
        overridden_date?: string;
        overridden_role?: string;
        value?: string | null;
        adminOverridden?: boolean;
      };
    };
    regulation_overrides?: {
      [_id: string]: {
        notes?: string;
        overridden_by?: string;
        overridden_date?: string;
        overridden_role?: string;
        value?: string | null;
        adminOverridden?: boolean;
      };
    };
  };
  created_at?: string;
  data?: {
    execution_time?: number;
    [key: string]: any;
    references?: Reference[];
    substations?: Substation[];
    objects?: {
      _id?: string;
      name?: string;
      category?: string;
      jurisdiction?: string;
      display_criteria?: string;
      effective_code?: boolean;
      catalog_group?: {
        name?: string;
      };
      children?: { _id?: string }[];
      nameAcronymBack?: string;
      name_common?: string;
      name_scientific?: string;
      habitat_characteristics?: string[];
      assessment?: {
        decision_tree?: string[];
        level_public?: string;
        level_automated?: string;
        level_reason?: string[];
        effective_status_public?: string;
        effective_status_automated?: string;
        v2?: {
          assessment?: string;
          stages?: {
            name?: string;
            values?: { [key: string]: string };
          }[];
        };
      };
      parent__id?: string;
      topic?: string;
    }[];
    objects2?: {
      name_common?: string;
      effective_code_description?: string;
      active_triggers?: { name?: string }[];
    }[];
    permits?: Permit[];
    local_permits?: LocalPermit[];
  };
  errors?: any[];
  feature_collection?: FeatureCollection<
    Geometry | GeometryCollection,
    Properties
  >;
  features?: Feature[];
  progress?: number;
  report__id?: string;
  status?: 'complete' | 'pending' | 'reset';
  summary?: Summary;
  type?: ReportPartTypes;
  updated_at?: string;
  working?: any;
  _id?: string;
}

export interface ReportAnnotation {
  _id?: string;
  type?: string;
  name: string;
  description?: string;
  presence?: boolean;
  object__type?: string;
  object__id?: string;
  active?: boolean;
  priority?: boolean;
  admin: {
    is_ghost_water?: boolean;
    is_exclusion_zone?: boolean;
  };
  geometry?: Geometries | GeometryCollection;
  creation_type?: 'automated' | 'user' | 'admin';
  validation?: string | null;
  report__id?: string;
  creator__id?: string;
  created_at?: string;
  updated_at?: string;
}

export type ReportPartName =
  | 'species'
  | 'environmental_compliance'
  | 'protected_area'
  | 'waters'
  | 'state_species';

export type OverrideOperation =
  | ReportPartName
  | 'permit-status-override'
  | 'regulation-status-override'
  | 'species-concern-level-override';

export type OverrideRegulationEntity = {
  _id: string;
  name: string;
  effectiveStatus: string;
};

export type OverrideConcernLevelEntity = {
  concernLevelName: string;
  previousConcernLevel: ConcernLevel;
};

export type OverrideSpeciesConcernLevelEntity = {
  _id: string;
  newLevel: string;
  previousLevel: string;
  commonName: string;
  scientificName: string;
  reason?: string;
  overriddenBy?: BasicUserProfile;
};

export type OverridePermitEntity = {
  _id: string;
  name: string;
  effectiveStatus: string;
};

export enum SpeciesAssessmentLevel {
  SPECIES_OF_CONCERN = 'species_of_concern',
  SPECIES_NOT_LIKELY_TO_OCCUR = 'not_likely_to_occur',
  SPECIES_MAY_OCCUR = 'may_occur',
  SPECIES_LIKELY_TO_OCCUR = 'likely_to_occur',
}

export type ParcelSummaryOwnerInfo = {
  maxOwner?: Partial<{
    name: string;
    totalAreaInAcres: number;
    parcelCount: number;
  }>;
  distinctOwnerCount?: number;
};

export type ParcelSummary = {
  custom?: {
    ownerInfo?: ParcelSummaryOwnerInfo;
  };
};

export type CatalogLocationSummary = {
  custom: {
    nearestDistance?: CatalogLocationSummaryNearestDistance;
  };
};

export type CatalogLocationSummaryNearestDistance = {
  nearestSubStation: number | null;
  nearestTransmissionLine: number | null;
};

export type CrossingsSummary = {
  wetlandCrossingsCount: number | null;
  waterwayCrossingsCount: number | null;
  roadCrossingsCount: number | null;
};
