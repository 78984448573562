import { Component, Input } from '@angular/core';

@Component({
  selector: 'ts-skeleton-circle-loading',
  templateUrl: './skeleton-circle-loading.component.html',
  styleUrls: ['./skeleton-circle-loading.component.scss'],
})
export class SkeletonCircleLoadingComponent {
  @Input() diameter = 4;

  constructor() {}
}
