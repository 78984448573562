import { z } from 'zod';
import { ParcelFilterDTO } from '../parcel-filter.dto';

export const FetchParcelFilterResponseDTO = ParcelFilterDTO.extend({
  _id: z.string().uuid(),
  name: z.string(),
});
export type FetchParcelFilterResponseDTO = z.infer<
  typeof FetchParcelFilterResponseDTO
>;
