import { z } from 'zod';
import { pageNumber, pageSize } from '../../utils/paginated-response-rows';
export const GetBlacklistedDomainsResponseDTO = z.array(
  z.object({
    _id: z.string().uuid(),
    domain: z.string().max(255),
  }),
);

export type GetBlacklistedDomainsResponseDTO = z.infer<
  typeof GetBlacklistedDomainsResponseDTO
>;

export const GetBlacklistedDomainsQueryDTO = z.object({
  pageSize: pageSize.optional(),
  page: pageNumber.optional(),
  search: z.string().optional(),
});

export type GetBlacklistedDomainsQueryDTO = z.infer<
  typeof GetBlacklistedDomainsQueryDTO
>;

export const CreateBlacklistedDomainDTO = z.object({
  domain: z.string().min(1).max(255),
});

export type CreateBlacklistedDomainDTO = z.infer<
  typeof CreateBlacklistedDomainDTO
>;

export const UpdateBlacklistedDomainDTO = z.object({
  domain: z.string().min(1).max(255),
});

export type UpdateBlacklistedDomainDTO = z.infer<
  typeof UpdateBlacklistedDomainDTO
>;
