import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable, of, pipe, throwError } from 'rxjs';
import {
  catchError,
  delay,
  mergeMap,
  retryWhen,
  scan,
  take,
} from 'rxjs/operators';
import { TransectAPIError } from '../models/transect-api-error';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const retry = pipe(
      mergeMap((response: HttpErrorResponse, retries) => {
        if (response.error && 'code' in response.error) {
          const { code } = response.error as { code: string };

          if (code === 'MAINTENANCE') {
            void this.router.navigate(['/maintenance']);
            return EMPTY;
          }

          if (response.status === 403 && code === 'INVALID_SHADOW') {
            localStorage.removeItem('isInGhostMode');
          }
        }

        if (response.status === 0 && retries > 4) {
          return EMPTY;
        }

        if (
          (response.status > 500 && response.status <= 599) ||
          response.status === 0
        ) {
          // Retry after 20 milliseconds with exponential backoff
          const exponentialBackoff = Math.pow(20, retries + 1);
          return of(response).pipe(delay(exponentialBackoff), take(1));
        }

        return throwError(() => response);
      }),
      scan((acc, error) => {
        if (acc > 2 && error.status !== 0) {
          throw error;
        }
        return acc + 1;
      }, 1),
    );

    return next.handle(request).pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) => {
        return errors.pipe(retry);
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => TransectAPIError.fromHttpErrorResponse(error));
      }),
    );
  }
}
