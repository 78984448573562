import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import Rollbar, { LogArgument } from 'rollbar';
import { ZodError } from 'zod';
import { TransectAPIError } from '../models/transect-api-error';
import { RollbarService } from '../services/rollbar.service';
import { VersionService } from '../services/version.service';
declare const newrelic: any;

class RollbarError extends Error {
  rollbarContext: object | undefined;

  constructor(message: string, name: string, rollbarContext?: object) {
    super(message);
    this.name = name;
    this.rollbarContext = rollbarContext;
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(Injector) private readonly injector: Injector,
    @Inject(RollbarService) private rollbar: Rollbar,
    private versionService: VersionService,
  ) {}

  handleError(error: unknown): void {
    if (error instanceof ZodError) {
      this.versionService.checkBackendApiForceUpdate().subscribe();
    }

    // Always log the error
    console.error(error);
    if (typeof newrelic !== 'undefined' && newrelic) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      newrelic.noticeError(error);
    }
    if (error instanceof Error) {
      this.handleClientError(error);
    } else if (
      error instanceof HttpErrorResponse ||
      error instanceof TransectAPIError
    ) {
      this.handleServerError(error);
    } else {
      this.rollbar.error(error as LogArgument);
    }
  }

  handleClientError(error: Error): void {
    this.rollbar.error(error);
  }

  handleServerError(error: HttpErrorResponse | TransectAPIError): void {
    // The original stack trace is not included by Angular with
    // the HttpErrorResponse, but include as much metadata as possible
    const message = error.message;
    const context: {
      status: number | null;
      detail?: string | null;
      url: string | null;
      statusText: string | null;
      title?: string | null;
      code?: string | null;
    } = {
      status: error.status,
      statusText: error.statusText,
      url: error.url,
    };

    if (error instanceof TransectAPIError) {
      context.title = error.title;
      context.detail = error.detail;
      context.code = error.code;
    }

    const frontendError = new Error(message);

    this.rollbar.error(frontendError, context);
  }
}
