import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const FirmPanelProperties = z.object({
  gfid: z.string(),
  panel: z.string(),
  pcomm: z.string(),
  scale: z.string(),
  suffix: z.string(),
  firm_id: z.string(),
  st_fips: z.string(),
  base_typ: z.string(),
  dfirm_id: z.string(),
  eff_date: z.string(),
  firm_pan: z.string(),
  objectid: z.number(),
  pre_date: z.string(),
  panel_typ: z.string(),
  pnp_reason: z.string(),
  shape_area: z.number(),
  source_cit: z.string(),
  version_id: z.string(),
  shape_length: z.number(),
});

export const ReportPartFirmPanelFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: FirmPanelProperties.deepPartial(),
  geometry: GeometrySchema,
  source__id: z.string().uuid(),

  source_cit: z.string().nullable(),
  has_intersection_aoi: z.boolean(),
  has_intersection_buffer: z.boolean(),
  eff_date: DateSchema.nullable(),
  firm_pan: z.string().nullable(),

  transect_feature_type: z.literal('firm_panel'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartFirmPanelFeatureDTO = z.infer<
  typeof ReportPartFirmPanelFeatureDTO
>;
