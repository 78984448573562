import { z } from 'zod';

export const SpeciesLocationOnlyGeometryDTO = z.object({
  _id: z.string().uuid(),
  geometry: z.any(),
});

export type SpeciesLocationOnlyGeometryDTO = z.infer<
  typeof SpeciesLocationOnlyGeometryDTO
>;
