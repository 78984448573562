import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ExecutiveSummaryCardComponent } from './executive-summary-concern-card';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';

@NgModule({
  declarations: [ExecutiveSummaryCardComponent],
  imports: [CommonModule, OverlayModule, MatTabsModule],
  exports: [ExecutiveSummaryCardComponent],
})
export class ExecutiveSummaryCardModule {}
