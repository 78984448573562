import { z } from 'zod';
import { DateSchema } from './date-schema';

export const FilterModelCondition = z.object({
  dateFrom: DateSchema.optional(),
  dateTo: DateSchema.optional(),
  filterType: z.enum(['text', 'set', 'date']),
  values: z.array(z.string()).optional(),
  type: z
    .enum([
      'contains',
      'equals',
      'greaterThan',
      'lessThan',
      'notEqual',
      'inRange',
    ])
    .optional(),
  filter: z.string().optional(),
});

export type FilterModelCondition = z.infer<typeof FilterModelCondition>;

export const FilterModelSchema = FilterModelCondition.extend({
  operator: z.enum(['AND', 'OR']).optional(),
  condition1: FilterModelCondition.optional(),
  condition2: FilterModelCondition.optional(),
});

export type FilterModelSchema = z.infer<typeof FilterModelSchema>;
