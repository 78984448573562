import { z } from 'zod';

export enum UserTemplateType {
  EXCLUSION_LAYERS = 'exclusion-layers',
  KML = 'kml',
  PDF = 'pdf',
}

export const UserTemplateObject = z.object({
  _id: z.string().nullish(),
  type: z.nativeEnum(UserTemplateType).nullable(),
  name: z.string().nullable(),
  options: z.array(z.object({}).passthrough()).nullable(),
});
