import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartParcelFeatureDTO } from '../models/report-part-parcel-feature.dto';

export const PaginatedReportPartParcelFeaturesDTO = PaginatedResponseRows(
  ReportPartParcelFeatureDTO,
);

export type PaginatedReportPartParcelFeaturesDTO = z.infer<
  typeof PaginatedReportPartParcelFeaturesDTO
>;
