<mat-dialog-content class="mat-typography">
  <div class="center">
    <img
      src="../../../../../assets/images/golden-cheeked-warbler.png"
      alt="Transect"
      height="136"
      width="136"
    />
  </div>

  <div class="app-update-detail">
    <strong class="title-medium">Oops! An error occurred.</strong>
    <p>
      Please refresh the page. If the problem persists, reach out to
      <a class="body-medium" href="mailto:support@transect.com"
        >support@transect.com</a
      >
      for assistance.
    </p>

    <ng-container *ngIf="reason">
      <strong class="title-medium">Error Details:</strong>
      <p class="error">{{ reason }}</p>
    </ng-container>
  </div>

  <div class="center">
    <button
      transect-nx-button
      color="primary"
      buttonStyle="contained"
      (click)="refreshApp()"
    >
      Refresh
    </button>
  </div>
</mat-dialog-content>
