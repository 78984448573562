import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

function uuidv4(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // tslint:disable-next-line:no-bitwise
    const r = (Math.random() * 16) | 0;

    // tslint:disable-next-line:no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;

    return v.toString(16);
  });
}

@Directive({
  selector: '[tsBtnLoader]',
})
export class BtnLoaderDirective implements OnInit, OnChanges {
  @Input()
  @HostBinding('disabled')
  loaderState = false;

  uid?: string;

  constructor(
    private targetEl: ElementRef<Element>,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.uid = 'loading-container-' + uuidv4();
    const loadingIcon = this.renderer.createElement('i') as HTMLElement;

    this.renderer.setStyle(
      loadingIcon,
      'display',
      this.loaderState ? 'inline-block' : 'none',
    );

    this.renderer.addClass(loadingIcon, 'fad');
    this.renderer.addClass(loadingIcon, 'fa-spinner-third');
    this.renderer.addClass(loadingIcon, 'spin-animation');
    this.renderer.setStyle(loadingIcon, 'padding', '0px 0.2em');
    this.renderer.addClass(loadingIcon, this.uid);

    this.renderer.appendChild(this.targetEl.nativeElement, loadingIcon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loaderState) {
      const container = this.targetEl.nativeElement;
      const loadingIcon = container.querySelector(`.${this.uid}`);
      if (loadingIcon) {
        this.renderer.setStyle(
          loadingIcon,
          'display',
          this.loaderState ? 'inline-block' : 'none',
        );
      }
    }
  }
}
