import { Component, Input } from '@angular/core';

@Component({
  selector: 'ts-skeleton-composite-loading',
  templateUrl: './skeleton-composite-loading.component.html',
  styleUrls: ['./skeleton-composite-loading.component.scss'],
})
export class SkeletonCompositeLoadingComponent {
  @Input() shouldCenterAlignCircle = false;
  @Input() count = 1;
  @Input() diameter = 4;
  @Input() lineCount = 1;
  @Input() lineHeight = 0.8;

  constructor() {}
}
