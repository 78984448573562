import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';

export const CatalogLocationDTO = z.object({
  _id: z.string().uuid().nullable(),
  active: z.boolean().default(true),
  catalog_item__id: z.string().uuid().nullable(),
  created_at: DateSchema,
  deleted_at: DateSchema.nullable(),
  updated_at: DateSchema,
  description: z.string().nullable(),
  geometry: z.any().nullable(),
  name: z.string().nullable(),
  properties: z
    .record(
      z.string(),
      z.union([z.string(), z.number(), z.object({}).passthrough()]).nullable(),
    )
    .nullable(),
  reference__id: z.string().uuid().nullable(),
});
export type CatalogLocationDTO = z.infer<typeof CatalogLocationDTO>;
