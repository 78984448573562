import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import { filter, Subject, switchMap, takeUntil } from 'rxjs';
import { FormModalInputData } from '../../models/form-modal.service';
import { TransectFormStateService } from '../../services/transect-form-state.service';

export type FormPhase1EsaAmendmentInputData = {
  addOnId: string;
  formTypeId: string;
  readOnly: boolean;
  stateService: TransectFormStateService;
};
@Component({
  selector: 'ts-form-marketplace-phase1-esa-amendment',
  templateUrl: './form-marketplace-phase1esa-amendment.component.html',
  styleUrls: ['./form-marketplace-phase1esa-amendment.component.scss'],
})
export class FormMarketplacePhase1EsaAmendmentComponent
  implements OnInit, OnDestroy
{
  private readonly destroyAction = new Subject<void>();

  amendmentForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
  });

  canSave$ = this.formStateService.canSave$;
  completing$ = this.formStateService.completing$;
  inProgress$ = this.formStateService.inProgress$;
  isComplete$ = this.formStateService.isComplete$;
  isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  saveButtonColor$ = this.formStateService.saveButtonColor$;

  get formStateService() {
    return this.data.stateService;
  }

  constructor(
    private dialogRef: MatDialogRef<FormPhase1EsaAmendmentInputData>,
    @Inject(MAT_DIALOG_DATA)
    public data: FormModalInputData,
  ) {}

  ngOnInit(): void {
    if (this.formStateService.stateValue.formValue) {
      this.amendmentForm.patchValue(
        this.formStateService.stateValue.formValue,
        { emitEvent: false },
      );
      this.formStateService.dispatchFormValidAction(
        !this.amendmentForm.invalid,
      );
    }

    if (this.formStateService.stateValue.isComplete || this.data.readOnly) {
      this.amendmentForm.disable();
    }

    this.amendmentForm.valueChanges
      .pipe(
        filter(() => this.amendmentForm.dirty),
        switchMap((formValue: FormDTO['contents']) => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.amendmentForm.valid,
            formValue,
            this.data.formTypeId,
            this.data.addOnId,
          );
        }),
        takeUntil(this.destroyAction),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyAction.next();
  }

  handleSaveClick() {
    if (!this.amendmentForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close();
    });
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}
