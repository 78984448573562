import { z } from 'zod';

export const FileControllerQueryParams = z
  .object({
    project__id: z.string().optional().nullable(),
    relation_type: z.string().optional().nullable(),
    relation_id: z.string().optional().nullable(),
  })
  .strict()
  .refine((data) => {
    if (data.relation_id && !data.relation_type) {
      return false;
    }
    if (!data.relation_id && data.relation_type) {
      return false;
    }

    return true;
  }, 'Relation Id or Relation Type is missing.');

export type FileControllerQueryParams = z.infer<
  typeof FileControllerQueryParams
>;
