import { z } from 'zod';
import { ReportContext, ReportPlugin } from '../report.dto';

enum ReportStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  TIMEOUT = 'timeout',
  ARCHIVED = 'archived',
}

export enum ReportReviewStatus {
  PENDING = 'pending',
  COMPLETE = 'complete',
  NO_REVIEW = 'no_review',
}

export const PatchReportUpdateBodyDTO = z
  .object({
    additional_notes: z.string().nullish(),
    context: ReportContext.deepPartial(),
    status: z.nativeEnum(ReportStatus),
    status_review: z.nativeEnum(ReportReviewStatus),
    type: z.string(),
    assigned_to: z.string().uuid().nullish(),
    project: z.string().uuid(),
    creator: z.string().uuid(),
    settings: z
      .object({
        buffer_width_miles: z.number(),
      })
      .deepPartial(),
    trim_geography__id: z.string().uuid().nullish(),
    executive_summary_override_enabled: z.boolean(),
    plugins: z.array(ReportPlugin).nullish(),
    content: z
      .object({
        executive_summary_v3: z.boolean(),
        executive_summary_override_enabled: z.boolean(),
        notes: z.string().nullish(),
        expert_notes: z.string().nullish(),
        executive_summary_overrides: z.preprocess(
          (val) => (val === null ? [] : val),
          z.array(
            z.object({
              level: z.enum(['high', 'moderate', 'low']),
              section: z.string(),
              additional: z.string().nullish(),
              reason: z.string(),
            }),
          ),
        ),
      })
      .deepPartial(),
  })
  .deepPartial();

export type PatchReportUpdateBodyDTO = z.infer<typeof PatchReportUpdateBodyDTO>;
