<transect-nx-dialog-modal
  title="Phase1 ESA Amendment Agreement"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
>
</transect-nx-dialog-modal>

<ng-template #contentTemplate>
  <form
    class="form-container"
    [formGroup]="amendmentForm"
    tsDetectScrollToEnd
    (scrollEnd)="handleScrollToEnd()"
    tsFormLoader
    [loaderState]="completing$ | async"
  >
    <div class="heading">
      PHASE 1 AMENDMENT TO MARKETPLACE SERVICES AGREEMENT
    </div>
    <div class="body">
      <p>
        This PHASE 1 AMENDMENT TO MARKETPLACE SERVICES AGREEMENT (“Amendment”)
        is made between Transect, Inc., a Delaware for-profit corporation
        (“Transect”), and the signatory to this Amendment (“Client”) as of the
        date of Client's signature. Transect and Client may be referred to
        herein individually as a “Party” and collectively as the “Parties.”
      </p>
      <p>
        <b>WHEREAS</b>, Client and Transect have previously entered into that
        certain Marketplace Services Agreement (“Agreement”) regarding
        environment consulting and reporting services; and
      </p>
      <p>
        <b>WHEREAS</b>, Client desires that Transect also provide one or more
        Phase 1 Environmental Site Assessments (“Phase 1 ESAs”) as a part of its
        services;
      </p>
      <p>
        <b>NOW, THEREFORE,</b> in consideration of the mutual promises set forth
        in this Amendment, in addition to those contained in the Agreement, the
        Parties hereby agree as follows:
      </p>
      <p>
        <b>1) Site of Inspection</b>
        <br />
        <span>
          The “Site” is the area of land (real property) upon which Client
          requests a Phase 1 ESA to be conducted. The physical description of
          the Site must be provided by Client when requesting the Phase 1 ESA.
        </span>
      </p>
      <p>
        <b>2) Hazards</b>
        <br />
        <span
          >Client shall notify Transect of any information about the existence
          or suspected existence of biological pollutants, hazardous materials,
          oil, or asbestos at the Site, including the air, soil, and water.
          Client expressly understands and agrees that Client has a continued
          obligation to promptly notify Consultant if any such information is
          learned or discovered, both before and after any Site inspection.
          Client further understands that the discovery of toxic or hazardous
          materials will constitute a changed condition in the Services and will
          require a revised Service Quote for the Services. If toxic or
          hazardous substances are discovered on a Site, Client agrees to
          indemnify Transect for all cost, expenses, and liabilities relating to
          any delay in the Services, reduction of the Site's value, or any
          personal injury or property damages from exposure to toxic or
          hazardous substances.</span
        >
      </p>
      <p>
        <b>3) Toxic Substances. </b>
        <br />
        <span
          >It is understood and agreed that Consultants are not, and have no
          responsibility as, handlers, generators, operators, treaters, storers,
          transporters, or disposers of hazardous or toxic substances found or
          identified at a Site. It shall be Client's obligation to undertake or
          arrange for the handling, removal, treatment, storage, transportation,
          and disposal of any hazardous substances found or identified at a
          Site. If the Site is not owned by Client, it is Client's
          responsibility to inform the Site owner of the discovery (or suspected
          discovery) of toxic or hazardous materials.</span
        >
      </p>
      <p>
        <b>4) Biological Pollutants. </b>
        <br />
        <span
          >It is understood and agreed that Consultants will not conduct any
          investigation, detection, prevention, or assessment of any biological
          pollutants at any Site. The term “biological pollutants” includes, but
          is not limited to molds, fungi, spores, bacteria, viruses, and/or any
          of their byproducts. Client agrees that Transect will have no
          liability for any claim regarding bodily injury or property damage
          alleged to arise from, or to be caused by, the presence of (or
          exposure to) any biological pollutants. Client also agrees that
          Transect shall have no liability for any claims alleging a failure to
          investigate, detect, prevent, assess, or make recommendations for
          preventing, controlling, or abating biological pollutants. Client
          shall defend, indemnify, and hold Transect harmless from any
          third-party claim for damages alleged to arise from, or to be caused
          by, the presence of (or exposure to) any biological pollutants.</span
        >
      </p>
      <p>
        <b>5) Drilling & Testing. </b>
        <br />
        <span
          >This Amendment does not include any services or provisions related to
          drilling, geotechnical surveys, or soil testing. For any of those
          services to be provided to Client, Transect and Client must first
          execute a separate written agreement which includes terms specifically
          addressing such services.</span
        >
      </p>
      <p>
        <b>6) Expiration of Phase 1 ESAs.</b>
        <br />
        <span
          >Each Phase 1 ESA (including all related reports, findings, summaries,
          recommendations, and assessments thereto) shall expire 180 days after
          the date that the Phase 1 ESA was issued to Client. No person or
          organization (whether Client or other third party) may rely on any
          reports, findings, summaries, recommendations, and/or assessments
          relating to a Phase 1 ESA that was issued (dated) more than 180 days
          prior.</span
        >
      </p>
      <p>
        <b>7) Third-Party Restrictions.</b>
        <br />
        <span
          >All Phase 1 ESA documents, surveys, assessments, recommendations,
          findings, letters, drawings, and specifications (collectively
          “documents”) which are created, furnished, or produced by Transect
          and/or Consultants under this Amendment are instruments of their
          service. Unless Transect specifically approves a third-party
          authorization, any Phase 1 ESA documents are provided for the sole and
          exclusive use and reliance of the Client, without any intended or
          contemplated third-party beneficiaries, and Client shall not
          disseminate, convey, or provide any documents (in whole or in part) to
          any other person or organization. To obtain authorization for the use
          of Phase 1 ESA documents by a third party, (i) Client must notify
          Transect about the need for third party authorization when making a
          Service Request for that Phase 1 ESA, and (ii) Transect must provide
          written confirmation that the requested third-party beneficiary is
          approved to utilize such documents. No third-party authorization shall
          be presumed, implied, or inferred by Client or any third party, and
          such authorization is only valid upon Transect's written confirmation
          of each and every intended third party. Any reuse of documents by
          third parties without prior written approval by Transect is strictly
          prohibited.</span
        >
      </p>
      <p>
        <b>8) Indemnification.</b>
        <br />
        <span
          >To the fullest extent allowable by law, Client shall defend, hold
          harmless, and indemnify Transect and its officers, directors,
          employees, contractors, agents, affiliates, successors, and assigns
          from and against all losses, damages, liabilities, deficiencies,
          lawsuits, actions, claims, judgments, interest, awards, penalties,
          fines, costs, or expenses of whatever kind (including attorneys' fees)
          which arise out of or result from (i) bodily injury or death of any
          person due, at least in part, to Client's acts, omissions, or conduct;
          (ii) damage to real or personal property due, at least in part, to
          Client's acts, omissions, or conduct; (iii) the performance of any
          Phase 1 ESA which is attributable to either a breach by Client of its
          obligations or the acts, omissions, or conduct of Client (or anyone
          acting under Client's direction or control); (iv) the reliance on an
          expired Phase 1 ESA by any person or organization, or (v) any
          third-party reliance on a Transect document when such third party was
          not previously authorized by Transect.</span
        >
      </p>
      <p>
        <b>9) Limitation of Liability. </b>
        <br />
        <span
          >IN NO EVENT SHALL TRANSECT BE LIABLE TO CLIENT OR TO ANY THIRD PARTY
          FOR ANY LOSS OF USE, REVENUE, PROFIT, OR BUSINESS, OR FOR ANY
          CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL OR PUNITIVE
          DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT OR WARRANTY, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, REGARDLESS OF
          WHETHER SUCH DAMAGE WAS FORESEEABLE, EXCEPT IF SUCH DAMAGES RESULT
          FROM TRANSECT'S WILLFUL MISCONDUCT. ADDITIONALLY, IN NO EVENT SHALL
          TRANSECT'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS
          AMENDMENT, WHETHER THROUGH BREACH OF CONTRACT OR WARRANTY, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, EXCEED THE
          AGGREGATE AMOUNTS PAID BY CLIENT TO TRANSECT FOR THE SPECIFIC SERVICE
          REQUEST WHICH FORMS THE BASIS OF THE LIABILITY.
        </span>
      </p>
      <p>
        This allocation of risks shall apply regardless of the causes of action
        or legal theory, plead or asserted. If Client prefers not to limit
        Transect's professional liability to the amounts paid by Client under a
        Service Request, Transect will adjust this limitation if Client agrees
        to pay for this waiver at a negotiated fee that is approved in writing
        by Transect. Client's written request for this option must be made
        before Client submits a Service Request for which Client desires a new
        liability limitation.
      </p>
      <p>
        <b>10) Termination. </b>
        <br />
        <span
          >This Amendment will become effective when signed by Client and will
          continue until terminated by either Party in writing.</span
        >
      </p>
      <p>
        <b>11) Miscellaneous. </b>
        <br />
      </p>
      <p>
        a) All terms, definitions, agreements, restrictions, and covenants
        contained in the Marketplace Services Agreement shall continue to apply
        to this Amendment, except where they directly conflict with this
        Amendment. If any provision in the Agreement is inconsistent with this
        Amendment, then this Amendment shall control.
      </p>
      <p>
        b) Amendment Changes. No changes or alterations to this Amendment shall
        be binding upon either Party unless executed in a writing signed by both
        Parties.
      </p>
      <p>
        c) Severability. In the event any provision or provisions of this
        Amendment is held to be invalid or unenforceable by any court of law or
        otherwise, the remaining provisions shall nevertheless continue to be
        valid and enforceable as though the invalid or unenforceable parts had
        not been included therein.
      </p>
      <p>
        d) Governing Law. This Amendment shall be governed by the laws of the
        State of Texas without regard to its conflicts of laws principles.
      </p>
      <p>
        e) Entire Agreement. This Amendment constitutes the entire agreement of
        the Parties with respect to the Phase 1 ESA subject matter contained
        herein, and supersedes all prior and contemporaneous understandings,
        agreements, representations, and warranties, both written and oral,
        regarding that subject matter. Nothing under this Amendment shall be
        construed to give any rights or benefits in this Amendment to anyone
        other than Client and Transect, and all duties and responsibilities
        undertaken pursuant to this Amendment are for the sole and exclusive
        benefit of Client and Transect and not for the benefit of any other
        party.
      </p>
      <p>
        IN WITNESS WHEREOF, Transect has offered and approved this Agreement,
        and Client now executes this Agreement through its authorized
        representative.
      </p>
    </div>
    <div class="control">
      <span class="label">Name</span>
      <input
        transect-nx-input
        placeholder="Name"
        formControlName="name"
        required
      />
    </div>
  </form>
</ng-template>

<ng-template #footerTemplate>
  <ng-container *ngIf="(isComplete$ | async) === false; else downloadPdf">
    <button
      transect-nx-button
      [color]="(saveButtonColor$ | async) ?? 'primary'"
      buttonStyle="contained"
      size="md"
      (click)="handleSaveClick()"
      [disabled]="(canSave$ | async) === false"
      tsBtnLoader
      [loaderState]="inProgress$ | async"
    >
      Save
    </button>
    <span class="body-small text-shades-gray-dark"
      >Please fill out and scroll all the way to the bottom to save</span
    >
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>
