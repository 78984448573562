import { z } from 'zod';
import { FormTypeKey } from '../../models';
import { DateSchema } from '../../utils/date-schema';

// getMyForms BEGIN

export const GetMyFormsQueryParams = z.object({
  page: z
    .preprocess((val) => {
      if (typeof val === 'string') {
        return Number(val);
      }

      return val;
    }, z.number())
    .default(1),
  pageSize: z
    .preprocess((val) => {
      if (typeof val === 'string') {
        return Number(val);
      }

      return val;
    }, z.number())
    .default(25),
  search: z.string().optional(),
});

export type GetMyFormsQueryParams = z.infer<typeof GetMyFormsQueryParams>;

export const GetMyFormsResponse = z.array(
  z.object({
    _id: z.string().uuid(),
    form_type__id: z.string().uuid(),
    creator__id: z.string().uuid(),
    completed_by__id: z.string().uuid().nullish(),
    contents: z.record(
      z.string(),
      z.union([z.string(), z.number(), z.boolean(), DateSchema]).nullish(),
    ),
    form_type: z.object({
      _id: z.string().uuid(),
      key: z.nativeEnum(FormTypeKey),
      name: z.string(),
    }),
    completed_on: DateSchema.nullable(),
    created_at: DateSchema,
  }),
);

export type GetMyFormsResponse = z.infer<typeof GetMyFormsResponse>;

// getMyForms END
