import { z } from 'zod';

export const DeactivateUserWithTransferOwnership = z.object({
  userId: z.string(),
  newOwnerId: z.string(),
  operation: z.literal('deactivate-and-transfer-ownership'),
});
export type DeactivateUserWithTransferOwnership = z.infer<
  typeof DeactivateUserWithTransferOwnership
>;

export const DeactivateUserWithNoReAssignment = z.object({
  userId: z.string(),
  operation: z.literal('deactivate-only'),
});
export type DeactivateUserWithNoReAssignment = z.infer<
  typeof DeactivateUserWithNoReAssignment
>;

export const DeactivateUserDTO = z.discriminatedUnion('operation', [
  DeactivateUserWithTransferOwnership,
  DeactivateUserWithNoReAssignment,
]);

export type DeactivateUserDTO = z.infer<typeof DeactivateUserDTO>;
