import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransectDataCardHeaderComponent } from './data-card-header.component';

@NgModule({
  declarations: [TransectDataCardHeaderComponent],
  imports: [CommonModule],
  exports: [TransectDataCardHeaderComponent],
})
export class TransectDataCardHeaderModule {}
