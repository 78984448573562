import moment from 'moment';
import { z } from 'zod';
import { FilterModelSchema } from '../../../utils';
import { OrderEnum } from '../../../utils/order-enum';
import { SortableSpeciesProperties } from '../species-object';

const FilterModelDate = z.object({
  type: z.enum(['equals', 'greaterThan', 'lessThan', 'notEqual', 'inRange']),
  dateFrom: z.preprocess(
    (val) => (typeof val === 'string' ? moment(val).toDate() : val),
    z.date(),
  ),
  dateTo: z
    .preprocess(
      (val) => (typeof val === 'string' ? moment(val).toDate() : val),
      z.date(),
    )
    .nullable()
    .optional(),
});

/** Query params for path /species */
export const SpeciesIndexControllerQuery = z
  .object({
    parentId: z.optional(
      z.preprocess(
        (val) => (val === 'null' ? null : val),
        z.string().uuid(`'parent__id' must be a UUID.`).nullable(),
      ),
    ),
    page: z
      .string()
      .default('1')
      .transform((val, ctx) => {
        const parsed = parseInt(val);
        if (isNaN(parsed)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `'page' must be an integer.`,
          });
        }
        return parsed;
      }),
    pageSize: z
      .string()
      .default('100')
      .transform((val, ctx) => {
        const parsed = parseInt(val);
        if (isNaN(parsed)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `'pageSize' must be an integer.`,
          });
        }
        return parsed;
      }),
    sortModel: z
      .preprocess(
        (val) => (typeof val === 'string' ? JSON.parse(val) : val),
        z.array(
          z.object({
            colId: SortableSpeciesProperties.default('name_scientific'),
            sort: OrderEnum.default('asc'),
          }),
        ),
      )
      .optional(),
    filterModel: z
      .preprocess(
        (val) => (typeof val === 'string' ? JSON.parse(val) : val),
        z.object({
          review_by: FilterModelDate.optional(),
          updated_at: FilterModelDate.optional(),
          created_at: FilterModelDate.optional(),
          name_combined: z.any().optional(),
          federal_listing_status: z.any().optional(),
          group: z.any().optional(),
          region: z.any().optional(),
          geographies_states: z.any().optional(),
        }),
      )
      .optional(),
    name_combined: z.string().optional(),
    federal_listing_status: z
      .preprocess(
        (val) => (typeof val === 'string' ? JSON.parse(val) : val),
        z.array(z.string()),
      )
      .optional(),
    group: z
      .preprocess(
        (val) => (typeof val === 'string' ? JSON.parse(val) : val),
        z.array(z.string()),
      )
      .optional(),
    region: z
      .preprocess(
        (val) => (typeof val === 'string' ? JSON.parse(val) : val),
        z.array(z.string()),
      )
      .optional(),
    states: z
      .preprocess(
        (val) => (typeof val === 'string' ? JSON.parse(val) : val),
        z.array(z.string()),
      )
      .optional(),
  })
  .strict();
export type SpeciesIndexControllerQuery = z.infer<
  typeof SpeciesIndexControllerQuery
>;
