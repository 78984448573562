import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppFlyoverComponent } from './app-flyover.component';
import { AppFlyoverStateService } from './app-flyover-state.service';
import { FlyoverModalModule } from '@transect-nx/ui';

@NgModule({
  declarations: [AppFlyoverComponent],
  imports: [CommonModule, FlyoverModalModule],
  providers: [AppFlyoverStateService],
  exports: [AppFlyoverComponent],
})
export class AppFlyoverModule {}
