import { animate, style, transition, trigger } from '@angular/animations';

export const ExpandToggleAnimation = [
  trigger('expandToggle', [
    transition(':enter', [
      style({ height: 0, overflow: 'hidden' }),
      animate('100ms ease-out', style({ height: '*', overflow: 'hidden' })),
    ]),
    transition(':leave', [
      style({ height: '*', overflow: 'hidden' }),
      animate('100ms ease-in', style({ height: 0, overflow: 'hidden' })),
    ]),
  ]),
];
