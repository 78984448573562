import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { ReferenceDTO } from '../../reference';
import { CatalogLocationDTO } from './catalog-location-dto';

export const CatalogLocationWithReferenceDTO = CatalogLocationDTO.pick({
  _id: true,
  name: true,
  description: true,
  created_at: true,
  updated_at: true,
  geometry: true,
}).extend({
  reference: ReferenceDTO.pick({
    _id: true,
    title: true,
  }).nullable(),
});
export const CatalogLocationIndexRows = ResponseRows(
  CatalogLocationWithReferenceDTO,
);
export type CatalogLocationIndexRows = z.infer<typeof CatalogLocationIndexRows>;

export const CatalogLocationGeometryIndexRows = ResponseRows(
  CatalogLocationDTO.pick({
    _id: true,
    geometry: true,
  }),
);
export type CatalogLocationGeometryIndexRows = z.infer<
  typeof CatalogLocationGeometryIndexRows
>;
