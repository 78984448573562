<div class="alta-table-container">
  <span class="title-medium text-shades-black">Table A</span>
  <div class="body-medium text-shades-gray-dark">
    For an ALTA/NSPS Land Title Survey, clients and surveyors can agree on which
    options to include, their wording, and costs. Extra items are added as
    20(a), 20(b), etc., and must be clearly explained. Engineering design
    surveys, despite certain exceptions, can be negotiated as an additional
    option. All choices are based on mutual agreements and specific guidelines.
    <div class="check-items-text">
      Check the items you want included in this survey:
    </div>
  </div>

  <form [formGroup]="altaScopeOfWorkUIForm" class="check-boxes-container">
    <label class="checkbox-item">
      <mat-checkbox formControlName="monumentsPlaced"></mat-checkbox>
      <span>1.</span>
      <span class="checkbox-text">
        Monuments placed (or a reference monument or witness to the corner) at
        all major corners of the boundary of the surveyed property, unless
        already marked or referenced by existing monuments or witnesses in close
        proximity to the corner.
      </span>
    </label>

    <span class="danger-text">
      (This item is subject to local jurisdictions that have specific
      requirements for setting monuments such as California and Washington. The
      setting of monuments in these states may require a plat submittal review
      and approval process, which would require additional negotiations.)
    </span>

    <label class="checkbox-item">
      <mat-checkbox formControlName="addressesProvided"></mat-checkbox>
      <span> 2. </span>
      <span class="checkbox-text">
        Address(es) of the surveyed property if disclosed in documents provided
        to or obtained by the surveyor or observed while conducting the
        fieldwork.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="floodZoneClassification"></mat-checkbox>
      <span> 3. </span>
      <span class="checkbox-text">
        Flood zone classification (with proper annotation based on federal Flood
        Insurance Rate Maps or the state or local equivalent) depicted by scaled
        map location and graphic plotting only.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="grossLandArea"></mat-checkbox>
      <span>4.</span>
      <span class="checkbox-text">
        Gross land area (and other areas if specified by the client).
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="verticalRelief"></mat-checkbox>
      <span>5.</span>
      <span class="checkbox-text">
        Vertical relief with the source of information (e.g., ground survey,
        aerial map), contour interval, datum, and originating benchmark
        identified when appropriate.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="zoningClassification"></mat-checkbox>
      <span>6.</span>
      <span class="checkbox-text">
        (a) If the current zoning classification, setback requirements, the
        height and floor space area restrictions, and parking requirements
        specific to the surveyed property are set forth in a zoning report or
        letter provided to the surveyor by the client or the client’s designated
        representative, list the above items on the plat or map and identify the
        date and source of the report or letter.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="zoningSetback"> </mat-checkbox>
      <span>6.</span>
      <span class="checkbox-text">
        (b) If the zoning setback requirements specific to the surveyed property
        are set forth in a zoning report or letter provided to the surveyor by
        the client or the client’s designated representative, and if those
        requirements do not require an interpretation by the surveyor,
        graphically depict the building setback requirements on the plat or map.
        Identify the date and source of the report or letter.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="exteriorBuildingDimensions"></mat-checkbox>
      <span>7.</span>
      <span class="checkbox-text">
        (A) Exterior dimensions of all buildings at ground level.
      </span>
    </label>

    <span class="non-checkbox-text"> (B) Square footage of: </span>

    <label class="checkbox-item non-checkbox-text">
      <mat-checkbox
        formControlName="groundLevelExteriorFootprint"
      ></mat-checkbox>
      <span></span>
      <span class="checkbox-text">
        (1) exterior footprint of all buildings at ground level.
      </span>
    </label>

    <label class="checkbox-item non-checkbox-text">
      <mat-checkbox formControlName="specifiedAreasFootprint"></mat-checkbox>
      <span></span>
      <span class="checkbox-text">
        (2) other areas as specified by the client.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="measuredBuildingHeight"></mat-checkbox>
      <span class="checkbox-text">
        (C) Measured height of all buildings above grade at a location specified
        by the client. If no location is specified, the point of measurement
        shall be identified.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="substantialFeatures"> </mat-checkbox>
      <span>8.</span>
      <span class="checkbox-text">
        Substantial features observed in the process of conducting the fieldwork
        (in addition to the improvements and features required pursuant to
        Section 5 above) (e.g., parking lots, billboards, signs, swimming pools,
        landscaped areas, substantial areas of refuse).
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="parkingDetails"></mat-checkbox>
      <span>9.</span>
      <span class="checkbox-text">
        Number and type (e.g., disabled, motorcycle, regular and other marked
        specialized types) of clearly identifiable parking spaces on surface
        parking areas, lots and in parking structures. Striping of clearly
        identifiable parking spaces on surface parking areas and lots.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="divisionWalls"></mat-checkbox>
      <span>10.</span>
      <span class="checkbox-text">
        As designated by the client, a determination of the relationship and
        location of certain division or party walls with respect to adjoining
        properties.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="utilitiesEvidence"></mat-checkbox>
      <span>11.</span>
      <span class="checkbox-text">
        Evidence of underground utilities existing on or serving the surveyed
        property (in addition to the observed evidence of utilities required
        pursuant to Section 5.E.iv.) as determined by:
      </span>
    </label>

    <span class="determined-by-text"> Determined by: </span>
    <mat-checkbox
      class="determined-by-checkbox"
      formControlName="utilitiesPlansProvided"
    >
      (a) plans and/or reports provided by client (with reference as to the
      sources of information).
    </mat-checkbox>
    <mat-checkbox
      class="determined-by-checkbox"
      formControlName="utilitiesMarkingsCoordinated"
    >
      (b) markings coordinated by the surveyor pursuant to a private utility
      locate request
    </mat-checkbox>

    <span class="danger-text determined-by-text">
      (Note: ASM/NDDS will provide observed evidence of utilities located on the
      surveyed property as indicated in Section 5.E.iv. of the Standards. This
      2021 Table A, Item must be understood and negotiated prior to engagement.)
    </span>

    <label class="checkbox-item">
      <mat-checkbox
        formControlName="governmentalSurveyRequirements"
      ></mat-checkbox>
      <span>12.</span>
      <span class="checkbox-text">
        As specified by the client, Governmental Agency survey-related
        requirements (e.g., HUD surveys, surveys for leases on Bureau of Land
        Management managed lands). The relevant survey requirements are to be
        provided by the client or client’s designated representative.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="adjoiningOwnersNames"></mat-checkbox>
      <span>13.</span>
      <span class="checkbox-text">
        Names of adjoining owners according to current tax records. If more than
        one owner, identify the first owner’s name listed in the tax records
        followed by “et al.”
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="nearestStreetDistance"></mat-checkbox>
      <span>14.</span>
      <span class="checkbox-text">
        As specified by the client, distance to the nearest intersecting street.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="orthophotography"> </mat-checkbox>
      <span>15.</span>
      <span class="checkbox-text">
        Rectified orthophotography, photogrammetric mapping, remote sensing,
        airborne/mobile laser scanning and other similar products, tools or
        technologies as the basis for showing the location of certain features
        (excluding boundaries) where ground measurements are not otherwise
        necessary to locate those features to an appropriate and acceptable
        accuracy relative to a nearby boundary. The surveyor must (a) discuss
        the ramifications of such methodologies (e.g., the potential precision
        and completeness of the data gathered thereby) with the insurer, lender,
        and client prior to the performance of the survey, and (b) place a note
        on the face of the survey explaining the source, date, precision, and
        other relevant qualifications of any such data.</span
      >
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="recentConstructionWork"></mat-checkbox>
      <span>16.</span>
      <span class="checkbox-text">
        Evidence of recent earth moving work, building construction, or building
        additions observed in the process of conducting the fieldwork.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="streetRightOfWayChanges"></mat-checkbox>
      <span>17.</span>
      <span class="checkbox-text">
        Proposed changes in street right of way lines, if such information is
        made available to the surveyor by the controlling jurisdiction. Evidence
        of recent street or sidewalk construction or repairs observed in the
        process of conducting the fieldwork.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox formControlName="offsiteEasementsPlottable"></mat-checkbox>
      <span>18.</span>
      <span class="checkbox-text">
        Pursuant to Sections 5 and 6 (and applicable selected Table A items,
        excluding Table A item 1), include as a part of the survey any plottable
        offsite (i.e., appurtenant) easements or servitudes disclosed in
        documents provided to or obtained by the surveyor.
      </span>
    </label>

    <label class="checkbox-item">
      <mat-checkbox
        formControlName="professionalLiabilityInsurance"
      ></mat-checkbox>
      <span>19.</span>
      <span class="checkbox-text">
        Professional Liability Insurance policy obtained by the surveyor in the
        minimum amount of $1,000,000.00 to be in effect throughout the contract
        term. Certificate of Insurance to be furnished upon request, but this
        item shall not be addressed on the face of the plat or map.
      </span>
    </label>

    <div>
      <div class="non-checkbox-text">
        <span>20.</span>
        <span class="checkbox-text">
          If an engineering design survey is desired as part of an ALTA/NSPS
          Land Title Survey, such services should be negotiated under Table A,
          Item 20.
        </span>
      </div>
      <div class="text-area-container">
        <mat-form-field appearance="outline" floatLabel="never">
          <textarea
            matInput
            cdkTextareaAutosize
            formControlName="engineeringDesignSurvey"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="2"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
