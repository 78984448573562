import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { RegulationDTO } from '../regulation-dto';
export const RegulationResponseRows = ResponseRows(
  RegulationDTO.pick({
    _id: true,
    name: true,
    acronym: true,
    nameAcronymBack: true,
  }),
);
export type RegulationResponseRows = z.infer<typeof RegulationResponseRows>;
