import { z } from 'zod';

export const ContestedSolarFarmDTO = z.object({
  _id: z.string().uuid(),
  project_name: z.string(),
  developer: z.string().nullable(),
  estimated_capacity: z.number().nullable(),
  estimated_size: z.number().nullable(),
  description: z.string().nullable(),
  sources: z.array(z.string()).nullable().default([]),
});

export type ContestedSolarFarmDTO = z.infer<typeof ContestedSolarFarmDTO>;
