<div class="scope-of-work-container">
  <span> Choose an appropriate scope for your order </span>

  <div class="option-controls-container">
    <button
      [disabled]="disabled || scopeOfWorkForm.disabled"
      transect-nx-button
      buttonStyle="outlined"
      (click)="navigateLeft()"
    >
      <i class="fas fa-chevron-left text-shades-gray-dark"></i>
    </button>

    <span>Option {{ displayOption }}</span>

    <button
      [disabled]="disabled || scopeOfWorkForm.disabled"
      transect-nx-button
      buttonStyle="outlined"
      (click)="navigateRight()"
    >
      <i class="fas fa-chevron-right text-shades-gray-dark"></i>
    </button>
  </div>

  <ng-container *ngIf="displayOption === 1" id="option1">
    <div class="desktop-habitat-text-container">
      <div class="label-large text-shades-black">
        Desktop Habitat Assessment Review and Technical Memo
      </div>
      <div class="body-small text-shades-gray-dark">
        Review available state and federal information regarding threatened and
        endangered species with a known range which includes the project area,
        as well as other resources including U.S. Geological Survey (USGS)
        topographic maps, National Wetlands Inventory (NWI) maps, and historical
        imagery.
      </div>

      <div class="body-small text-shades-black">Note:</div>
      <div>
        * The desktop review does not include a site visit or presence/absence
        surveys.
      </div>
      <div class="body-small text-shades-gray-dark">
        * A technical memorandum of the desktop review as it relates to state
        and federal listed T&E species will be prepared and signed by a
        qualified professional.
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="displayOption === 2" id="option2">
    <div class="desktop-habitat-text-container">
      <div class="label-large text-shades-black">
        <div>Onsite Habitat Assessment and Reporting</div>
      </div>

      <div class="body-small text-shades-gray-dark">
        <span class="label-large text-shades-gray-dark"
          >Onsite Habitat Assessment:
        </span>
        <ul>
          <li>
            Review state and federal information on T&E species in the project
            area.
          </li>
          <li>
            Conduct site visits to assess for suitable T&E species habitats.
          </li>
        </ul>
      </div>

      <div class="body-small text-shades-gray-dark">
        <span class="label-large text-shades-gray-dark">Documentation:</span>
        <ul>
          <li>Record all plant communities within the project area.</li>
          <li>
            Collect photographs and GPS locations of potential T&E species
            habitats.
          </li>
          <li>For bats, mark individual habitat trees only if requested.</li>
          <li>Document incidental observations of T&E species.</li>
          <li>
            Note: The assessment doesn't confirm the presence/absence of
            species.
          </li>
        </ul>
      </div>

      <div class="body-small text-shades-gray-dark">
        <span class="label-large text-shades-gray-dark">
          Technical Report Preparation:
        </span>
        <ul>
          <li>Discuss state and federal T&E species and their habitats.</li>
          <li>Include onsite habitat assessment results.</li>
          <li>
            Provide recommendations for species potentially in the project area.
          </li>
          <li>Attach relevant mapping and site photographs.</li>
          <li>
            Ensure the report is sufficient for submission to the USFWS and/or
            local wildlife agencies for T&E species coordination.
          </li>
        </ul>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="displayOption === 3" id="option3">
    <div class="desktop-habitat-text-container">
      <div class="label-large text-shades-black">
        <div>Presence / Absence Survey and Reporting</div>
      </div>

      <div class="body-small text-shades-gray-dark">
        <span class="label-large text-shades-gray-dark"
          >Presence / Absence Survey:
        </span>
        <ul>
          <li>
            Develop a study plan based on desktop/onsite habitat assessments and
            wildlife agency coordination.
          </li>
          <li>
            Coordination and approval from federal/state/local wildlife agencies
            required before surveys.
          </li>
          <li>
            Surveys often necessitate a professional with a USFWS Section 10 (a)
            recovery, scientific, or enhancement permit.
          </li>
        </ul>
      </div>

      <div class="body-small text-shades-gray-dark">
        <span class="label-large text-shades-gray-dark">Reporting:</span>
        <ul>
          <li>Prepare a technical report including:</li>
          <ul>
            <li>Project description.</li>
            <li>Survey methods.</li>
            <li>Habitat assessment details.</li>
            <li>Counts of species detections or absence.</li>
          </ul>
        </ul>
      </div>
    </div>
  </ng-container>

  <button
    transect-nx-button
    tsBtnLoader
    [buttonStyle]="
      (selectedOption$ | async) === displayOption ? 'contained' : 'outlined'
    "
    (click)="selectOption()"
    [disabled]="disabled || scopeOfWorkForm.disabled"
  >
    Select
  </button>
</div>
