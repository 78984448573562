import { z } from 'zod';
import { SolarRegulationDTO } from '../solar-regulation-dto';

export const CreateSolarRegulationResponse = SolarRegulationDTO.pick({
  _id: true,
});

export type CreateSolarRegulationResponse = z.infer<
  typeof CreateSolarRegulationResponse
>;
