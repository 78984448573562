import { z } from 'zod';
import { GeometriesOrCollectionSchema } from '../../utils/geometry-object-schema';

export const CreateMiniReportBodyDTO = z.object({
  email: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  title: z.string(),
  company: z.string(),
  industry: z.string(),
  geometry: GeometriesOrCollectionSchema,
});

export type CreateMiniReportBodyDTO = z.infer<typeof CreateMiniReportBodyDTO>;
