import { z } from 'zod';
import { ResponseRows } from '../../../utils/response-rows';
import { SpeciesObject } from '../species-object';

export const SpeciesIndexDTO = SpeciesObject.extend({
  geography: z
    .object({
      _id: z.string().uuid(),
      name: z.string(),
      type: z.string(),
      abbreviation: z.string(),
    })
    .nullable(),
  owner: z
    .object({
      _id: z.string().uuid(),
      fullname: z.string(),
    })
    .nullable(),
}).partial();

export const SpeciesIndexResponseRows = ResponseRows(SpeciesIndexDTO);
export type SpeciesIndexDTO = z.infer<typeof SpeciesIndexDTO>;
export type SpeciesIndexResponseRows = z.infer<typeof SpeciesIndexResponseRows>;
