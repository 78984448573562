<label
  [matTooltip]="togglingTooltip ?? ''"
  [matTooltipDisabled]="!toggling"
  [matTooltipPosition]="togglingTooltipPosition ?? 'below'"
>
  <input
    aria-label="toggle"
    [attr.aria-label]="ariaLabel"
    type="checkbox"
    [attr.data-testId]="ariaLabel"
    [ngModel]="toggled"
    (ngModelChange)="onModelChange($event)"
    [disabled]="disabled"
  />

  <div
    matRipple
    matRippleColor="rgba(189, 210, 212, 0.3)"
    [ngClass]="{
      'icon-only': iconOnly,
      disabled: disabled,
      toggled: toggled,
      'left-group': group === 'left',
      'center-group': group === 'center',
      'right-group': group === 'right',
      'size-xs': size === 'xs',
      'size-s': size === 'sm',
      'size-m': size === 'md',
      'size-l': size === 'lg',
      'btn-contained': buttonStyle === 'contained',
      'btn-flat': buttonStyle === 'flat',
      'btn-outlined': buttonStyle === 'outlined'
    }"
    [style]="{
      width: customSize ? customSize.width + 'px' : null,
      height: customSize ? customSize.height + 'px' : null,
      fontSize: customSize ? customSize.fontSize + 'px' : null,
      color: toggled ? onStyleColor : offStyleColor
    }"
    class="toggle-button"
  >
    <ng-content *ngIf="!toggling"></ng-content>
    <ng-content *ngIf="toggled && !toggling" select="[on]"></ng-content>
    <ng-content *ngIf="!toggled && !toggling" select="[off]"></ng-content>
    <ng-content *ngIf="toggling" select="[toggling]"></ng-content>
  </div>
</label>
