import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const CreateVisionProjectFolderDTO = z.object({
  folderId: z.string().uuid(),
  projectIds: z.preprocess(
    (val) => val && JSON.parse(val as string),
    z.array(z.string().uuid()).min(1),
  ),
});

export type CreateVisionProjectFolderDTO = z.infer<
  typeof CreateVisionProjectFolderDTO
>;

export const CreateVisionProjectFolderResponseDTO = z.array(
  z.object({
    _id: z.string(),
    folder__id: z.string(),
    project__id: z.string(),
    creator__id: z.string().nullable(),
    created_at: DateSchema,
    updated_at: DateSchema,
  }),
);

export type CreateVisionProjectFolderResponseDTO = z.infer<
  typeof CreateVisionProjectFolderResponseDTO
>;

export const DeleteVisionProjectFolderRequestDTO = z.object({
  projectId: z.string().uuid(),
  folderId: z.string().uuid(),
});

export type DeleteVisionProjectFolderRequestDTO = z.infer<
  typeof DeleteVisionProjectFolderRequestDTO
>;
