import { z } from 'zod';
import { ParcelFilterDTO } from '../parcel-filter.dto';

export const CreateParcelFilterDTO = ParcelFilterDTO.extend({
  geographies: z.array(z.string().uuid()).nullable().optional(),
  creator: z
    .object({
      _id: z.string().uuid(),
      fullname: z.string(),
    })
    .optional(),
});
export type CreateParcelFilterDTO = z.infer<typeof CreateParcelFilterDTO>;
