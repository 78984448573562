import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ParcelFilterDTO } from '../parcel-filter.dto';

export const UpdateParcelFilterResponseDTO = ParcelFilterDTO.extend({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});
export type UpdateParcelFilterResponseDTO = z.infer<
  typeof UpdateParcelFilterResponseDTO
>;
