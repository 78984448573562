import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { DropDownComponent } from './dropdown.component';

@NgModule({
  declarations: [DropDownComponent],
  imports: [CommonModule, OverlayModule],
  exports: [DropDownComponent],
})
export class DropdownModule {}
