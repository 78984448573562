import { z } from 'zod';
import { AddonEntityType } from './index-dto';

export const PurchasePayload = z.object({
  params: z.object({
    entityId: z.string().uuid(),
    entityType: z.nativeEnum(AddonEntityType),
    option: z.string().optional(),
    share_parcels: z.boolean().nullish(),
    coupon_code: z.string().nullish(),
    project_type: z.string().optional(),
    project_sub_type: z.string().optional(),
  }),
});
export type PurchasePayload = z.infer<typeof PurchasePayload>;
