import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'transect-nx-split-screen',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './split-screen.component.html',
  styleUrls: ['./split-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplitScreenComponent implements OnInit {
  @Input() leftPercentage = 40;
  @Input() rightPercentage = 60;

  leftFlex!: string;
  rightFlex!: string;

  ngOnInit() {
    this.setFlexValues();
  }

  private setFlexValues() {
    const total = this.leftPercentage + this.rightPercentage;
    this.leftFlex = `${(this.leftPercentage / total) * 100}%`;
    this.rightFlex = `${(this.rightPercentage / total) * 100}%`;
  }
}
