import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DeactivateUserDTO,
  UpdatePreferencesDTO,
  UserCreateControllerBody,
} from '@transect-nx/data-transfer-objects';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResponseRows } from '../../models/response-rows';
import { BasicUserProfile, User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private readonly baseEndPoint = `${environment.apiUrl}/users`;
  private readonly baseAdminEndPoint = `${environment.apiUrl}/protected/users`;

  constructor(private http: HttpClient) {}

  loginAs(userId: string): Observable<void> {
    return this.http.get<void>(`${this.baseAdminEndPoint}/login-as/${userId}`);
  }

  fetchMe() {
    return this.http.get<User>(`${this.baseEndPoint}/me`);
  }

  sendVerificationEmail(): Observable<void> {
    return this.http.post<void>(
      `${this.baseEndPoint}/send-verification-email`,
      {},
    );
  }

  sendVerificationTokenEmail(email: string): Observable<void> {
    return this.http.post<void>(
      `${this.baseEndPoint}/send-verification-token-email`,
      {
        email: email,
      },
    );
  }

  createUser(user: UserCreateControllerBody): Observable<void> {
    return this.http.post<void>(`${this.baseEndPoint}`, user);
  }

  fetchUserBasicProfile(userId: string): Observable<BasicUserProfile> {
    return this.http.get<BasicUserProfile>(
      `${this.baseEndPoint}/basic/${userId}`,
    );
  }

  /**
   * This is *ADMIN* only! Do not use if a regular user will be accessing it.
   * If you only need basic insensitive fields then use {@link fetchUserBasicProfile} instead.
   * @param userId
   * @returns
   */
  fetchUser(userId: string): Observable<User> {
    return this.http.get<User>(`${this.baseAdminEndPoint}/${userId}`);
  }

  getUsers(
    search: string,
    customerId?: string | null,
  ): Observable<ResponseRows<User>> {
    let params = new HttpParams({ fromObject: { search } });

    if (customerId) {
      params = params.append('customerId', customerId);
    }

    return this.http.get<ResponseRows<User>>(
      `${this.baseAdminEndPoint}/search`,
      {
        params,
      },
    );
  }

  getAdmins(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseAdminEndPoint}/admins`);
  }

  getAdminsMap(): Observable<Map<string, User>> {
    return this.getAdmins().pipe(
      map(
        (admins) =>
          new Map(
            admins
              .filter((admin) => Boolean(admin._id))
              .map((admin) => [admin._id, admin]),
          ),
      ),
    );
  }

  searchUsers(params?: {
    search?: string;
    _id?: string;
    role?: string;
    includeDeletedUsers?: boolean;
    page?: number;
    pageSize?: number;
    sortModel?: { colId: string; sort: 'asc' | 'desc' }[];
  }): Observable<ResponseRows<User>> {
    return this.http.get<ResponseRows<User>>(
      `${this.baseAdminEndPoint}/search`,
      {
        params: {
          search: params?.search ?? '',
          _id: params?._id ?? '',
          role: params?.role ?? '',
          includeDeletedUsers: params?.includeDeletedUsers ?? false,
          page: params?.page?.toString() ?? '',
          limit: params?.pageSize?.toString() ?? '',
          sortModel: params?.sortModel ? JSON.stringify(params?.sortModel) : '',
        },
      },
    );
  }

  updateUser(user: any): Observable<User> {
    return this.http.patch<User>(`${this.baseEndPoint}`, user);
  }

  adminUpdateUser(id: string, user: any): Observable<User> {
    return this.http.patch<User>(`${this.baseAdminEndPoint}/${id}`, user);
  }

  updatePassword(oldPassword: string, newPassword: string): Observable<void> {
    return this.http.put<void>(`${this.baseEndPoint}/password`, {
      oldPassword,
      newPassword,
    });
  }

  /** Calls a backend API to update the user's preferences. */
  updatePreferences(
    userId: string,
    preferences: UpdatePreferencesDTO,
  ): Observable<UpdatePreferencesDTO> {
    return this.http.patch<UpdatePreferencesDTO>(
      `${this.baseAdminEndPoint}/${userId}/preferences`,
      preferences,
    );
  }

  updateMyPreferences(preferences: UpdatePreferencesDTO) {
    return this.http.patch<UpdatePreferencesDTO>(
      `${this.baseEndPoint}/preferences`,
      preferences,
    );
  }

  cleanupMyAccount() {
    const url = `${this.baseEndPoint}/reset`;
    return this.http.patch<void>(url, {});
  }

  cleanupAccount(userId: string) {
    const url = `${this.baseAdminEndPoint}/reset/${userId}`;
    return this.http.patch(url, {});
  }

  deactivateUser(body: DeactivateUserDTO) {
    const url = `${this.baseEndPoint}/deactivate`;
    return this.http.patch<void>(url, body);
  }

  adminDeactivateUser(body: DeactivateUserDTO) {
    const url = `${this.baseAdminEndPoint}/deactivate`;
    return this.http.patch<void>(url, body);
  }

  activateUser(userId: string) {
    const url = `${this.baseEndPoint}/activate`;
    return this.http.patch<void>(url, {
      userId,
    });
  }

  adminActivateUser(userId: string) {
    const url = `${this.baseAdminEndPoint}/activate`;
    return this.http.patch<void>(url, {
      userId,
    });
  }
}
