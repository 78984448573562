import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY, NEVER, Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { TransectAPIError } from '../models/transect-api-error';
import { AuthService, LogoutType } from '../services/auth.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const storedMagicLink = sessionStorage.getItem('magic-link');
    const magicLink =
      this.route.snapshot.queryParamMap.get('magic-link') ?? storedMagicLink;

    if (magicLink) {
      if (!storedMagicLink) {
        sessionStorage.setItem('magic-link', magicLink);
      }

      const magicLinkRequest = request.clone({
        headers: request.headers.set('Magic-link', magicLink),
      });

      return next.handle(magicLinkRequest);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.status === 401 ||
          (error instanceof TransectAPIError &&
            error.code === 'TokenExpiredError')
        ) {
          return this.authService
            .logout(true, LogoutType.SESSION_EXPIRED)
            .pipe(switchMap(() => EMPTY));
        } else if (
          error instanceof TransectAPIError &&
          error.code === 'DEACTIVATED_USER'
        ) {
          return this.authService
            .logout(true, LogoutType.DEACTIVATED_USER)
            .pipe(switchMap(() => EMPTY));
        } else if (
          error instanceof TransectAPIError &&
          error.code === 'BLOCKED_USER'
        ) {
          return this.authService
            .logout(true, LogoutType.BLOCKED_USER)
            .pipe(switchMap(() => EMPTY));
        }

        return throwError(() => error);
      }),
    );
  }
}
