import { z } from 'zod';

export const ProjectUserDTO = z.object({
  firstname: z.string().nullable(),
  lastname: z.string().nullable(),
  fullname: z.string().nullable(),
  email: z.string().email(),
  _id: z.string().uuid(),
  project_access: z.object({
    _id: z.string().uuid(),
    role: z.enum(['owner', 'collaborator', 'viewer', 'tester']),
  }),
  customer: z
    .object({
      name: z.string(),
    })
    .nullish(),
});

export type ProjectUserDTO = z.infer<typeof ProjectUserDTO>;
