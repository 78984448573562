<transect-nx-dialog-modal
  title="Site Access Authorization"
  [footer]="true"
  [footerTemplate]="footerTemplate"
  [contentTemplate]="contentTemplate"
>
</transect-nx-dialog-modal>
<ng-template #contentTemplate>
  <div class="main-container">
    <form
      class="form-container"
      [formGroup]="view.state.authorizationForm"
      tsDetectScrollToEnd
      (scrollEnd)="handleScrollToEnd()"
      tsFormLoader
      [loaderState]="view.state.completing$ | async"
    >
      <div class="heading">
        Completion of this form authorizes a subcontractor of Transect to access
        the site identified below and in the Exhibit A map to complete site
        reconnaissance or environmental fieldwork in support of environmental
        services purchased from the Transect Marketplace. Services will be
        conducted in accordance with applicable industry standards and/or
        regulatory requirements. The surveyor who performs the fieldwork has the
        experience and technical expertise to complete the site survey per those
        standards and requirements.
      </div>
      <div class="control">
        <span class="label"
          >Name of the Owner or Owner's Agent authorizing access.</span
        >
        <input
          transect-nx-input
          placeholder="Full Name"
          formControlName="ownerOrOwnerAgentName"
          required
        />
      </div>
      <div class="control">
        <span class="label">
          The date from which access to the property is granted, specifically
          for the purpose of conducting a Site Reconnaissance as part of a:
          Phase 1 ESA, Wetland Delineation, T&E Species Survey, Geotechnical
          Survey, ALTA Survey, or other environmental studies requiring field
          work.
        </span>
        <mat-form-field appearance="outline">
          <input
            matInput
            [matDatepicker]="propertyAccessDate"
            formControlName="propertyAccessDate"
            required
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="propertyAccessDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #propertyAccessDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="heading">Approval Information:</div>
      <div class="control">
        <span class="label">
          Name of the user or user's agent approving the authorization.
        </span>
        <input
          transect-nx-input
          placeholder="Full Name"
          formControlName="userOrUserAgentName"
          required
        />
      </div>
      <div class="control">
        <span class="label"> Title of the approver. </span>
        <input
          transect-nx-input
          placeholder="Full Name"
          formControlName="approverTitle"
          required
        />
      </div>
      <div class="control">
        <span class="label"> Date of approval </span>
        <div class="control">
          <span class="label">
            The date from which access to the property is granted, specifically
            for the purpose of conducting a Site Reconnaissance as part of a:
            Phase 1 ESA, Wetland Delineation, T&E Species Survey, Geotechnical
            Survey, ALTA Survey, or other environmental studies requiring field
            work.
          </span>
          <mat-form-field appearance="outline">
            <input
              matInput
              [matDatepicker]="approvalDate"
              formControlName="approvalDate"
              required
            />
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
              matSuffix
              [for]="approvalDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #approvalDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="heading">Additional Information:</div>
      <div class="control">
        <span class="label">
          Instructions on how to access the property and any additional site
          access contacts (name, phone, email).
        </span>
        <input
          transect-nx-input
          placeholder="Comments"
          formControlName="specialInstructions"
          required
        />
      </div>
      <div class="control">
        <span class="label">
          Included Parcel Identification Numbers (PIN)​​​​.
        </span>
        <input
          transect-nx-input
          formControlName="parcelIdentificationNumber"
          [placeholder]="view.state.loadingPlaceholderText"
          required
        />
      </div>
      <div class="contact-container">
        <span class="body-small text-shades-gray-dark">
          Please contact
          <a href="email:marketplace@transect.com">marketplace@transect.com</a>
          if you have any questions.
        </span>
      </div>
    </form>
    <ts-form-marketplace-site-map
      class="map-container"
      [projectId]="data.projectId"
      [projectGeometry]="data.projectGeometry"
    ></ts-form-marketplace-site-map>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <ng-container
    *ngIf="(view.state.isComplete$ | async) === false; else downloadPdf"
  >
    <button
      transect-nx-button
      [color]="view.state.saveButtonColor$ | async"
      buttonStyle="contained"
      size="md"
      (click)="handleSaveClick()"
      [disabled]="(view.state.canSave$ | async) === false"
      tsBtnLoader
      [loaderState]="view.state.inProgress$ | async"
    >
      Save
    </button>
    <span class="body-small text-shades-gray-dark"
      >Please fill out and scroll all the way to the bottom to save</span
    >
  </ng-container>

  <ng-template #downloadPdf>
    <button
      transect-nx-button
      buttonStyle="contained"
      size="md"
      (click)="handleDownloadPdfClick()"
      tsBtnLoader
      [loaderState]="view.state.isFetchingPdf$ | async"
    >
      Download PDF
    </button>
    <span class="date-text" *ngIf="data?.completedOn"
      >Completed on {{ data?.completedOn | date : 'MM/dd/yy' }}</span
    >
  </ng-template>
</ng-template>

<ng-container *ngFor="let action$ of view.actions">
  <ng-container *ngIf="action$ | async"></ng-container>
</ng-container>
