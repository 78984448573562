import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  filter,
  Subject,
  takeUntil,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { CheckForUpdateService } from '../../../../services/check-for-update.service';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'ts-forced-update-dialog',
  templateUrl: './forced-update-dialog.component.html',
  styleUrls: ['./forced-update-dialog.component.scss'],
})
export class ForcedUpdateDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();

  // Trigger "waiting too long" message if not reloading within 120 seconds
  updateIsTakingTooLong$ = timer(120000).pipe(map(() => true));

  private failedToUpdate = new BehaviorSubject(false);
  protected failedToUpdate$ = this.failedToUpdate.asObservable();

  constructor(private checkForUpdateService: CheckForUpdateService) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    this.checkForUpdateService.isNewVersionReady$
      .pipe(
        filter((isReady) => isReady),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        window.location.reload();
      });

    this.checkForUpdateService
      .pollUpdateAvailability(5000)
      .pipe(
        tap((updateReady) => {
          if (updateReady) {
            window.location.reload();
          }
        }),
        catchError((error) => {
          this.failedToUpdate.next(true);

          return throwError(() => error as unknown);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }
}
