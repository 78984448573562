import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommentsResponseRowsDTO } from '@transect-nx/data-transfer-objects';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Comment } from '../../../models/comment';
import { AlertService } from '../../../services/alert.service';
import { AuthService } from '../../../services/auth.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

type CommentWithEditing = Comment & {
  objectName?: string;
  isEditing?: boolean;
  updatedComment?: string;
  updatingComment?: boolean;
  deletingComment?: boolean;
  readMore?: boolean;
};

@Component({
  selector: 'ts-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss'],
})
export class CommentBoxComponent implements OnInit, OnChanges {
  @ViewChild('commentsContainer') commentsContainer?: ElementRef<HTMLElement>;
  @Input() readonly = true;
  @Input() collapsable = true;
  @Input() shouldDisplayHeader = true;
  @Input() defaultLength = 200;
  @Input() objectType?: string;
  @Input() objectId?: string | null;
  @Input() isObjectProtected = false;

  isCommentBoxOpen = new BehaviorSubject<boolean>(true);
  loadingComment = true;
  comments: CommentWithEditing[] = [];
  comment?: string;
  savingComment = false;
  isCommentsLoaded = false;

  expanded$ = this.isCommentBoxOpen.pipe(
    map((isOpened) => {
      return isOpened || !this.collapsable;
    }),
  );

  userOrNull$ = this.authService.userOrNull$;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private alertService: AlertService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.isCommentBoxOpen.subscribe((isOpen) => {
      if (isOpen && !this.isCommentsLoaded) {
        this.loadComments();
        this.isCommentsLoaded = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.objectId &&
      !changes.objectId.firstChange &&
      this.isCommentBoxOpen.value
    ) {
      this.loadComments();
    }
  }

  saveComment(): void {
    this.savingComment = true;
    const commentToPost = {
      object__id: this.objectId,
      object__type: this.objectType,
      properties: {
        text: this.comment,
      },
    };
    this.http.post(`${environment.apiUrl}/comments`, commentToPost).subscribe(
      (comment: CommentWithEditing) => {
        this.comments.push(comment);
        this.comment = '';
        this.savingComment = false;
        this.showSuccessMessage('The comment has been saved successfully.');
      },
      () => {
        this.showDefaultError();
        this.savingComment = false;
      },
    );
  }

  updateComment(comment: CommentWithEditing): void {
    comment.updatingComment = true;
    this.http
      .patch(`${environment.apiUrl}/comments/${comment._id}`, {
        properties: {
          text: comment.updatedComment,
        },
      })
      .subscribe(
        () => {
          if (comment.properties) {
            comment.properties.text = comment.updatedComment;
          }

          comment.updatingComment = false;
          comment.isEditing = false;
          this.showSuccessMessage('The comment has been updated successfully.');
        },
        () => {
          this.showDefaultError();
          comment.updatingComment = false;
        },
      );
  }

  loadComments(): void {
    this.loadingComment = true;
    let commentUrl = `${environment.apiUrl}/comments?order=asc&object__type=${this.objectType}`;
    if (this.objectId) {
      commentUrl += `&object__id=${this.objectId}`;
    }
    this.http.get<CommentsResponseRowsDTO>(commentUrl).subscribe(
      (result: CommentsResponseRowsDTO) => {
        this.loadingComment = false;
        this.comments = result.rows as CommentWithEditing[];
      },
      () => {
        this.showDefaultError();
        this.loadingComment = false;
      },
    );
  }

  refreshComments(event: Event): void {
    this.loadComments();
    event.stopPropagation();
  }

  scrollToBottom(event: Event): void {
    event.stopPropagation();
    const element = this.commentsContainer?.nativeElement;
    if (element) {
      element.scrollTop = Math.max(
        0,
        element.scrollHeight - element.offsetHeight,
      );
    }
  }

  editComment(comment: CommentWithEditing): void {
    comment.isEditing = true;
    comment.updatedComment = comment.properties?.text;
  }

  deleteComment(commentToDelete: CommentWithEditing): void {
    const confirmationDialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          message: 'Are you sure you want to delete this comment?',
        },
      },
    );
    confirmationDialogRef.afterClosed().subscribe((performAction) => {
      if (performAction) {
        commentToDelete.deletingComment = true;
        const index = this.comments.findIndex(
          (comment) => comment._id === commentToDelete._id,
        );
        this.http
          .delete(`${environment.apiUrl}/comments/${commentToDelete._id}`)
          .subscribe(
            () => {
              this.showSuccessMessage(
                'The comment has been deleted successfully.',
              );
              this.comments.splice(index, 1);
            },
            () => {
              this.showDefaultError();
              commentToDelete.deletingComment = false;
            },
          );
      }
    });
  }

  cancelEditComment(comment: CommentWithEditing): void {
    comment.isEditing = false;
    comment.updatedComment = undefined;
  }

  navigateToObject(objectId: string): void {
    // TODO
    // const parentState = this.router.globals.$current.parent.name;
    // const url = this.router.stateService.href(`${parentState}.edit`, {
    //   id: objectId,
    // });
    // window.open(url, '_blank', 'noopener');
  }

  getFromNow(date: string | Date | moment.Moment): string {
    return moment(date).fromNow();
  }

  private showSuccessMessage(msg: string): void {
    this.alertService.showSuccess(msg);
  }

  private showDefaultError(): void {
    this.alertService.showError(
      'An unknown error has occured. Please try again later.',
    );
  }
}
