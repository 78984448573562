export const versionInfo: {
  dirty?: boolean | null;
  raw?: string | null;
  hash?: string | null;
  distance?: string | null;
  tag?: string | null;
  semver?: string | null;
  suffix?: string | null;
  semverString?: string | null;
  branchName?: string | null;
} = (() => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-unsafe-call
    return require('../../git-version.json') as {
      dirty?: boolean | null;
      raw?: string | null;
      hash?: string | null;
      distance?: string | null;
      tag?: string | null;
      semver?: string | null;
      suffix?: string | null;
      semverString?: string | null;
      branchName?: string | null;
    };
  } catch {
    // In dev the file might not exist:
    return { tag: 'v0.0.0', hash: 'dev', branchName: 'unknown' };
  }
})();
