import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransectFileImageComponent } from './transect-file-image/transect-file-image.component';

@NgModule({
  declarations: [TransectFileImageComponent],
  imports: [CommonModule],
  exports: [TransectFileImageComponent],
})
export class TransectFileImageModule {}
