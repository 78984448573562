import { z } from 'zod';

export const PostStripePaymentsCreateSetupIntentBodyDTO = z.object({
  addOnId: z.string().uuid(),
  address: z.object({
    name: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    address: z.object({
      line1: z.string(),
      line2: z.string().nullable(),
      city: z.string(),
      state: z.string(),
      postal_code: z.string(),
      country: z.string(),
    }),
    phone: z.string().optional(),
  }),
});

export type PostStripePaymentsCreateSetupIntentBodyDTO = z.infer<
  typeof PostStripePaymentsCreateSetupIntentBodyDTO
>;
