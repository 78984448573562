import { z } from 'zod';
import { LandownerBatchDataResponseDTO } from './landowner-batch-data-response.dto';

export const LandownerBatchDataSkipTraceResponse = z.object({
  data: z.object({
    status: z.object({
      text: z.string().optional(),
      message: z.string().optional(),
      code: z.number().optional(),
    }),
    results: LandownerBatchDataResponseDTO,
  }),
});

export type LandownerBatchDataSkipTraceResponse = z.infer<
  typeof LandownerBatchDataSkipTraceResponse
>;
