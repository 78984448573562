<div class="upgrade-request-dialog-container">
  <div>
    <img class="image" alt="Upgrade Report" [src]="data.imageUrl" />
  </div>

  <div>
    <div class="title">Request an upgrade</div>

    <div class="text-shades-gray-dark body-large">{{ data.description }}</div>
    <ng-container *ngIf="(isFreeOrFreeTrialUser$ | async) === false">
      <div class="reason-field-container">
        <form [formGroup]="reasonForm">
          <transect-nx-form-field>
            <input
              transect-nx-input
              formControlName="reason"
              [placeholder]="data.reasonPlaceHolder"
              aria-label="Reason"
            />
          </transect-nx-form-field>
        </form>
      </div>

      <div>
        <button
          transect-nx-button
          buttonStyle="contained"
          [disabled]="sendingRequest || requestSent"
          (click)="sendUpgradeRequest()"
        >
          {{ requestSent ? 'Request sent' : 'Send request' }}
        </button>

        <div *ngIf="requestSent" class="next-steps">
          Someone will reach out to you within 1 business day to discuss next
          steps.
        </div>
      </div>
    </ng-container>

    <div *ngIf="isFreeOrFreeTrialUser$ | async" class="reason-field-container">
      <a
        transect-nx-button
        buttonStyle="contained"
        href="https://www.transect.com/2024-get-a-demo?hsCtaTracking=bc6226fc-cf49-4789-b458-6723f59896ae%7C0af3e12e-ad2a-4dec-859b-cf6eadd321ed"
      >
        Send request
      </a>
    </div>

    <div class="dots-grid">
      <img height="63" alt="dots" src="../../../../assets/images/dots.svg" />
    </div>

    <div class="partial-circle">
      <div></div>
    </div>
  </div>
</div>
