import { z } from 'zod';
import { ReportPartHabitatCharacteristic } from '../report-part-habitat-characteristic';

export const UpdateHabitatCharacteristicDTO =
  ReportPartHabitatCharacteristic.pick({
    override: true,
  });

export type UpdateHabitatCharacteristicDTO = z.infer<
  typeof UpdateHabitatCharacteristicDTO
>;
