<div class="snackbar-container">
  <i
    *ngIf="isError"
    class="fas fa-exclamation-circle alert-icon"
    data-cy="snackbar-error"
  ></i>
  <i
    *ngIf="isSuccess"
    class="fas fa-check-circle alert-icon"
    data-cy="snackbar-success"
  ></i>
  <i
    *ngIf="isWarn"
    class="fas fa-exclamation-triangle alert-icon"
    data-cy="snackbar-warn"
  ></i>

  <div
    class="content"
    [class.push-content]="dismissible"
    data-cy="snackbar-alert-content"
  >
    <span *ngIf="title" class="title">{{ title }}</span>
    <span class="message" data-cy="span-message" [innerHTML]="message"></span>

    <span matSnackBarActions>
      <a
        class="custom-link"
        matSnackBarAction
        (click)="snackbarRef.dismissWithAction()"
      >
        {{ actionLinkText }}
      </a>
    </span>
  </div>

  <button
    transect-nx-button
    *ngIf="dismissible"
    buttonStyle="flat"
    [iconOnly]="true"
    (click)="onDismiss()"
    size="sm"
  >
    <i class="fas fa-times"></i>
  </button>
</div>
