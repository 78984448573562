import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { GeographyState } from '../geographies/geography-state';
import { TransectFile } from '../transect-file';

export enum FederalListingStatus {
  CANDIDATE = 'Candidate',
  ENDANGERED = 'Endangered',
  EXPERIMENTAL_POPULATION_NON_ESSENTIAL = 'Experimental Population, Non-Essential',
  EXTINCTION = 'Extinction',
  NOT_LISTED = 'Not Listed',
  ORIGINAL_DATA_IN_ERROR_ACT_AMENDMENT = 'Original Data in Error - Act Amendment',
  ORIGINAL_DATA_IN_ERROR_ERRONEOUS_DATA = 'Original Data in Error - Erroneous Data',
  ORIGINAL_DATA_IN_ERROR_NEW_INFO_DISCOVERED = 'Original Data in Error - New Information Discovered',
  ORIGINAL_DATA_IN_ERROR_NOT_LISTABLE_ENTITY = 'Original Data in Error - Not a listable entity',
  ORIGINAL_DATA_IN_ERROR_TAXONOMIC_REVISION = 'Original Data in Error - Taxonomic Revision',
  PRE_ACT_DELISTING_OR_CLEARANCE_REMOVAL = 'Pre-Act Delisting (or clearance--removal from the Lists)',
  PROPOSED_ENDANGERED = 'Proposed Endangered',
  PROPOSED_EXPERIMENTAL_POPULATION_NON_ESSENTIAL = 'Proposed Experimental Population, Non-Essential',
  PROPOSED_SIMILARITY_OF_APPEARANCE_TO_A_THREATENED_TAXON = 'Proposed Similarity of Appearance to a Threatened Taxon',
  PROPOSED_THREATENED = 'Proposed Threatened',
  RECOVERY = 'Recovery',
  RESOLVED_TAXON = 'Resolved Taxon',
  SIMILARITY_OF_APPEARANCE_TO_A_THREATENED_TAXON = 'Similarity of Appearance to a Threatened Taxon',
  SPECIES_OF_CONCERN = 'Species of Concern',
  STATUS_UNDEFINED = 'Status Undefined',
  THREATENED = 'Threatened',
  UNDER_REVIEW = 'Under Review',
  UNDER_REVIEW_IN_THE_CANDIDATE_OR_PETITION_PROCESS = 'Under Review in the Candidate or Petition Process',
}

export enum SpeciesType {
  V = 'V',
  P = 'P',
  I = 'I',
}

export enum HabitatCharacteristics {
  CAVES_KARST = 'caves_karst',
  MOUNTAINS = 'mountains',
  FOREST_MIXED = 'forest_mixed',
  OPEN_OCEAN = 'open_ocean',
  CANYONLAND = 'canyonland',
  WETLAND_WOODY = 'wetland_woody',
  SPRINGS = 'springs',
  CROPS = 'crops',
  BEACH_DUNE = 'beach_dune',
  PONDS = 'ponds',
  COASTAL_PRAIRIE = 'coastal_prairie',
  SCRUB_SHRUB = 'scrub_shrub',
  WETLAND_FRESHWATER_EMERGENT = 'wetland_freshwater_emergent',
  LAKES = 'lakes',
  VERNAL_POOL = 'vernal_pool',
  FOREST_DECIDUOUS = 'forest_deciduous',
  MAJOR_RIVER = 'major_river',
  DESERT_SCRUB = 'desert_scrub',
  EASEMENT_ROADSIDE = 'easement_roadside',
  NATIVE_PRAIRIE = 'native_prairie',
  GEOLOGY = 'geology',
  BAY_ESTUARY = 'bay_estuary',
  PRAIRIE_POTHOLE = 'prairie_pothole',
  RANGELAND = 'rangeland',
  PEAK_CLIFFS = 'peaks_cliffs',
  FOOTHILLS = 'foothills',
  SLOPES = 'slopes',
  CANDIDATE = 'Candidate',
  FOREST_EVERGREEN = 'forest_evergreen',
  CREEK_STREAM = 'creek_stream',
  DISTRIBUTED_AREAS = 'disturbed_areas',
  ALPINE_MEADOW = 'alpine_meadow',
  DESERT_SAND_DUNES = 'desert_sand_dunes',
  GRASSLAND = 'grassland',
  DESERT = 'desert',
  FLOODPLAINS = 'floodplains',
  TIDAL_WETLAND = 'tidal_wetland',
  RIPARIAN_WOODLAND = 'riparian_woodland',
  VALLEYS = 'valleys',
}

export const SpeciesObject = z.object({
  _id: z.string().uuid(),
  name_combined: z.string().nullable(),
  name_combined_html: z.string().nullable(),
  tsn: z.number().nullable(),
  name_common: z.string().nullable(),
  name_scientific: z.string().nullable(),
  population_code: z.string().nullable(),
  federal_listing_status: z.nativeEnum(FederalListingStatus).nullable(),
  geography_listing_status: z.string().nullable(),
  geography_statutorily_protected: z.boolean().nullable(),
  habitat_characteristics: z.array(z.nativeEnum(HabitatCharacteristics)),
  group: z.string().nullable(),
  geographies_states: z.array(GeographyState).optional(),
  primary_states: z.array(GeographyState).optional(),
  eo_states: z.array(GeographyState).optional(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.nullable(),
  review_by: DateSchema.nullable(),
  region: z.string().nullable(),
  status_expert: z.string().nullable(),
  spatial_data_sufficient: z.boolean(),
  spatial_data_sufficient_reason: z.string().nullable(),
  geography__id: z.string().uuid().nullable(),
  parent__id: z.string().uuid().nullable(),
  owner__id: z.string().uuid().nullable(),
  species_location_count: z.number().optional(),
  variation_count: z.number().optional(),
  species_code: z.string().nullable(),
  profile_image: TransectFile.partial().nullish(),
});

/** The species properties that support sorting. */
export const SortableSpeciesProperties = z.enum([
  'name_scientific',
  'name_common',
  'created_at',
  'updated_at',
  'review_by',
]);
export type SortableSpeciesProperties = z.infer<
  typeof SortableSpeciesProperties
>;
