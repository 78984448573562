import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ParcelFilterDTO } from '../parcel-filter.dto';

export const CreateParcelFilterResponseDTO = ParcelFilterDTO.extend({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});
export type CreateParcelFilterResponseDTO = z.infer<
  typeof CreateParcelFilterResponseDTO
>;
