<div class="upgrade-container">
  <button
    transect-nx-button
    buttonStyle="flat"
    [iconOnly]="true"
    size="md"
    mat-dialog-close
    class="close-modal-btn"
  >
    <i id="close-icon" class="far fa-times"></i>
  </button>

  <img src="assets/images/clipboard.svg" />

  <h1>Get the full Transect experience!</h1>

  <div class="separator"></div>

  <div class="feature-list-item">
    <i class="fa-light fa-file-chart-column"></i>
    <p>
      <strong>Run Unlimited Reports</strong> - Get in-depth assessments of your
      sites in minutes.
    </p>
  </div>

  <div class="feature-list-item">
    <i class="fa-light fa-download"></i>
    <p>
      <strong>Full Export Capabilities</strong> - Export complete landowner
      info, map layers, report PDFs and more
    </p>
  </div>

  <div class="feature-list-item">
    <i class="fa-light fa-user-graduate"></i>
    <p>
      <strong>Access to Environmental Experts</strong> - Leverage over 120 years
      of combined environmental consulting expertise.
    </p>
  </div>

  <div class="separator"></div>

  <div class="much-more">And much more!</div>
</div>
<ng-container *ngIf="isFreeOrFreeTrialUser$ | async; else contactSales">
  <div class="contact-sales">
    <button
      transect-nx-button
      buttonStyle="contained"
      size="lg"
      (click)="handleRequestUpgradeClick()"
    >
      Request Upgrade
    </button>
  </div>
</ng-container>

<ng-template #contactSales>
  <div class="contact-sales">
    <a
      [href]="contactSalesLink"
      transect-nx-button
      buttonStyle="contained"
      size="lg"
    >
      Contact Sales
    </a>
  </div>
</ng-template>
