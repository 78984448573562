import { z } from 'zod';
import { SolarRegulationDTO } from '../solar-regulation-dto';
export const SolarRegulationEditPayload = SolarRegulationDTO.pick({
  verification_status: true,
  verification_assignee: true,
  verification_notes: true,
  summary: true,
  date_term: true,
  document_title: true,
  expiration_date: true,
  geography: true,
}).partial({ verification_assignee: true });
export type SolarRegulationEditPayload = z.infer<
  typeof SolarRegulationEditPayload
>;
