import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartSpeciesLocationFeatureDTO } from '../models/report-part-species-location-feature.dto';

export const PaginatedReportPartSpeciesLocationFeaturesDTO =
  PaginatedResponseRows(ReportPartSpeciesLocationFeatureDTO);

export type PaginatedReportPartSpeciesLocationFeaturesDTO = z.infer<
  typeof PaginatedReportPartSpeciesLocationFeaturesDTO
>;
