import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'transect-nx-dialog-modal',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss'],
})
export class DialogModalComponent {
  @Input() title!: string;
  @Input() controlMargin = false;
  @Input() description!: string;
  @Input() footer!: boolean;
  @Input() contentTemplate: TemplateRef<HTMLElement> | null = null;
  @Input() footerTemplate: TemplateRef<HTMLElement> | null = null;
  @Input() showSaveButton!: boolean;
  @Input() showCancelButton!: boolean;
  @Input() saveButtonTitle!: string;
  @Input() cancelButtonTitle!: string;
  @Input() showCloseButton = true;
  @Input() width!: number;
  @Input() disableSave!: boolean;
  @Input() disableCancel!: boolean;
  @Output() saveClicked = new EventEmitter();
  @Output() cancelClicked = new EventEmitter();

  onSaveClick(): void {
    this.saveClicked.emit();
  }

  onCancelClick(): void {
    this.cancelClicked.emit();
  }
}
