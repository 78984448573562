import { z } from 'zod';
import { DateSchema } from './date-schema';

export const TextFilter = z.object({
  filterType: z.literal('text').nullish(),
  type: z.literal('contains'),
  filter: z.string().nullish(),
});
export type TextFilter = z.infer<typeof TextFilter>;

export const NumberFilter = z.object({
  filterType: z.literal('number'),
  type: z.enum(['equals', 'greaterThan', 'lessThan', 'notEqual', 'inRange']),
  filter: z.number().nullish(),
  filterTo: z.number().nullish(),
});

export type NumberFilter = z.infer<typeof NumberFilter>;

export const SetFilterBase = z.object({
  filterType: z.literal('set'),
});

export type SetFilterBase = z.infer<typeof SetFilterBase>;

export const StringSetFilter = SetFilterBase.extend({
  values: z.array(z.string()),
});

export type StringSetFilter = z.infer<typeof StringSetFilter>;

export const DateFilter = z.object({
  filterType: z.literal('date'),
  type: z.enum(['equals', 'greaterThan', 'lessThan', 'notEqual', 'inRange']),
  dateFrom: DateSchema.nullish(),
  dateTo: DateSchema.nullish(),
});

export type DateFilter = z.infer<typeof DateFilter>;
