import { z } from 'zod';
import { ParcelCollectionStatus } from './controller-inputs';
import {
  LandownerContactStatus,
  LandownerBatchDataPersonDTO,
} from '../landowner-contact';

export const ParcelCollectionDTO = z.object({
  _id: z.string().uuid(),
  epoch: z.number(),
  combine_by_owner: z.boolean(),
  object__id: z.string().uuid().nullish(),
  object__type: z.enum(['projects', 'parcel_filters']).nullish(),
  status: z.nativeEnum(ParcelCollectionStatus),
  total_parcel_buildable_acreage: z.number(),
  total_parcel_acreage: z.number(),
  owners_count: z.number(),
  nearest_substation: z
    .object({
      distance: z.number(),
      max_volt: z.number().nullable(),
      min_volt: z.number().nullable(),
    })
    .nullable(),
  nearest_transmission_line: z
    .object({
      owner: z.string().nullable(),
      voltage: z.number().nullable(),
      distance: z.number(),
    })
    .nullable(),
  total_parcel_count: z.number(),
  low_parcel_count: z
    .object({
      waters: z.number(),
      environmental_compliance: z.number(),
      species: z.number(),
      state_species: z.number(),
      protected_area: z.number(),
    })
    .nullish(),
  med_parcel_count: z
    .object({
      waters: z.number(),
      environmental_compliance: z.number(),
      species: z.number(),
      state_species: z.number(),
      protected_area: z.number(),
    })
    .nullish(),
  high_parcel_count: z
    .object({
      waters: z.number(),
      environmental_compliance: z.number(),
      species: z.number(),
      state_species: z.number(),
      protected_area: z.number(),
    })
    .nullish(),
});

export type ParcelCollectionDTO = z.infer<typeof ParcelCollectionDTO>;
