import { z } from 'zod';

/** Route params for path /report-maps/:id/* representing :id */
export const ReportMapIdParams = z
  .object({
    id: z.string().uuid(),
  })
  .strict();
export type ReportMapIdParams = z.infer<typeof ReportMapIdParams>;
export * from './index-controller';
export * from './create-controller';
