import { z } from 'zod';

export const ParcelFilterExportPayloadDTO = z.object({
  parcel_filter_export__id: z.string().uuid(),
  dissolve_parcels: z.boolean().default(false),
});

export type ParcelFilterExportPayloadDTO = z.infer<
  typeof ParcelFilterExportPayloadDTO
>;

export const ParcelFilterExportChunkPayloadDTO = z.object({
  parcel_filter_export__id: z.string().uuid(),
  parcel_filter_boundary_chunk__id: z.string().uuid(),
  dissolve_parcels: z.boolean(),
});

export type ParcelFilterExportChunkPayloadDTO = z.infer<
  typeof ParcelFilterExportChunkPayloadDTO
>;
