import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { UserFeedbackDTO } from '../user-feedback.dto';

export const CreateUserFeedbackResponseDTO = UserFeedbackDTO.extend({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});
export type CreateUserFeedbackResponseDTO = z.infer<
  typeof CreateUserFeedbackResponseDTO
>;
