import { Collection } from '@mikro-orm/core';
import { z } from 'zod';
import { mikroOrmCollectionToArray } from '../../../utils/mikro-orm-collection-to-array';
import { ReferenceDTO } from '../../reference/reference.dto';
import { SpeciesObject, SpeciesType } from '../../species';
import { TransectFile } from '../../transect-file';
import { ReportPartSpeciesAssessment } from './report-part-species-assessment';

export const ReportPartSpeciesSummary = z.object({
  eoWithinAoi: z.preprocess(
    (val) => val && parseInt(val as string),
    z.number().default(0),
  ),
  eoWithinBuffer: z.preprocess(
    (val) => val && parseInt(val as string),
    z.number().default(0),
  ),
  eoWithinAoiOrBuffer: z.preprocess(
    (val) => val && parseInt(val as string),
    z.number().default(0),
  ),
  eoExternal: z.preprocess(
    (val) => val && parseInt(val as string),
    z.number().default(0),
  ),
  nearestEo: z.preprocess(
    (val) => {
      if (!val) {
        return val;
      }
      if (typeof val === 'string') {
        return JSON.parse(val);
      }
      if (typeof val === 'object') {
        return val;
      }
      return null;
    },
    z
      .object({
        miles: z.number(),
        feet: z.number(),
      })
      .default({
        miles: 0,
        feet: 0,
      })
      .nullable(),
  ),
});

export type ReportPartSpeciesSummary = z.infer<typeof ReportPartSpeciesSummary>;

const SpeciesContent = z.object({
  habitat_requirements: z.string().nullish(),
  commentary_regulatory: z.string().nullish(),
  current_range: z.string().nullish(),
  historic_range: z.string().nullish(),
  life_history: z.string().nullish(),
  elevation_range_min: z.number().nullish(),
  elevation_range_max: z.number().nullish(),
  commentary_tips: z.string().nullish(),
});

const SpeciesSeasonality = z.object({
  type: z.string().optional(),
  notes: z.string().optional(),
  end_day: z.number().optional(),
  end_month: z.number().optional(),
  start_day: z.number().optional(),
  start_month: z.number().optional(),
});

const ReportPartSpeciesChildren = SpeciesObject.pick({
  _id: true,
  description: true,
  geography_listing_status: true,
  geography_statutorily_protected: true,
  spatial_data_sufficient: true,
  spatial_data_sufficient_reason: true,
}).extend({
  content: SpeciesContent.nullable(),
  seasonality: z.array(SpeciesSeasonality).nullable(),
  geography: z
    .object({
      name: z.string().nullable(),
      type: z.string().nullable(),
      abbreviation: z.string().nullable(),
    })
    .nullable(),
  references: z.preprocess(
    (val) => mikroOrmCollectionToArray(val as Collection<object>),
    z.array(ReferenceDTO).nullable(),
  ),
});
const ReportPartSpecies = SpeciesObject.pick({
  _id: true,
  name_common: true,
  name_scientific: true,
  name_combined: true,
  group: true,
  federal_listing_status: true,
  eo_states: true,
  habitat_characteristics: true,
}).merge(
  z.object({
    type: z.nativeEnum(SpeciesType).nullish(),
    source__id: z.string(),
    content: SpeciesContent.optional(),
    seasonality: z.array(SpeciesSeasonality).optional(),
    global_recommendations: z.any().nullish(),
    active_triggers: z.any().nullish(),
    assessment: ReportPartSpeciesAssessment.optional().nullable(),
    summary: ReportPartSpeciesSummary.nullable(),
    references: z.preprocess(
      (val) => mikroOrmCollectionToArray(val as Collection<object>),
      z.array(ReferenceDTO).nullable(),
    ),
    children: z.preprocess(
      (val) => mikroOrmCollectionToArray(val as Collection<object>),
      z.array(ReportPartSpeciesChildren).nullable(),
    ),
    profile_image: TransectFile.nullish(),
  }),
);

export const ReportPartSpeciesDTO = ReportPartSpecies;

export type ReportPartSpeciesDTO = z.infer<typeof ReportPartSpeciesDTO>;

export const ReportPartSpeciesChildrenDTO = ReportPartSpeciesChildren;

export type ReportPartSpeciesChildrenDTO = z.infer<
  typeof ReportPartSpeciesChildrenDTO
>;

export const UpdateReportPartSpeciesDTO = ReportPartSpeciesDTO.partial().omit({
  _id: true,
});

export type UpdateReportPartSpeciesDTO = z.infer<
  typeof UpdateReportPartSpeciesDTO
>;

export const GetReportPartSpeciesQueryDTO = z.object({
  fields: z.preprocess(
    (val) => val && JSON.parse(val as string),
    z.array(z.string()).optional(),
  ),
});

export type GetReportPartSpeciesQueryDTO = z.infer<
  typeof GetReportPartSpeciesQueryDTO
>;

export const GetReportPartSpeciesDTO = z.array(ReportPartSpeciesDTO);

export type GetReportPartSpeciesDTO = z.infer<typeof GetReportPartSpeciesDTO>;

export const GetPartialReportPartSpeciesDTO = z.array(
  ReportPartSpeciesDTO.partial(),
);

export type GetPartialReportPartSpeciesDTO = z.infer<
  typeof GetPartialReportPartSpeciesDTO
>;

export const GetReportPartSpeciesChildrenDTO = z.array(
  ReportPartSpeciesChildren,
);

export type GetReportPartSpeciesChildrenDTO = z.infer<
  typeof GetReportPartSpeciesChildrenDTO
>;
