import { Component, Input } from '@angular/core';

@Component({
  selector: 'ts-skeleton-line-loading',
  templateUrl: './skeleton-line-loading.component.html',
  styleUrls: ['./skeleton-line-loading.component.scss'],
})
export class SkeletonLineLoadingComponent {
  @Input() height = 0.8;
  @Input() count = 1;
  @Input() lineGap = 1.2;
  @Input() width = 100;

  constructor() {}
}
