import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogModalComponent } from './dialog-modal.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransectButtonsModule } from '../transect-buttons/transect-buttons.module';
import { UiTreeModule } from '../tree/ui-tree.module';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  declarations: [DialogModalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    TransectButtonsModule,
    UiTreeModule,
    MatCheckboxModule,
    ReactiveFormsModule,
  ],
  exports: [DialogModalComponent],
})
export class DialogModalModule {}
