import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpgradeRequestType } from '@transect-nx/data-transfer-objects';
import { Observable, take } from 'rxjs';
import { UpgradeRequestDialogComponent } from './components/upgrade-request-dialog.component';
import { UpgradePromptComponent } from './upgrade-prompt.component';

@Injectable()
export class UpgradePromptService {
  constructor(private dialog: MatDialog) {}

  show(
    title: string = 'Shucks! Looks like you need to upgrade',
  ): Observable<void> {
    const dialog = this.dialog.open<
      UpgradePromptComponent,
      { title: string },
      void
    >(UpgradePromptComponent, {
      id: 'upgrade-prompt',
      width: 'auto',
      panelClass: 'upgrade-prompt-modalpanel',
      closeOnNavigation: true,
      data: {
        title,
      },
    });
    return dialog.afterClosed().pipe(take(1));
  }

  showUpgradeRequestDialog(
    type: UpgradeRequestType,
    imageUrl: string,
    description: string,
    reasonPlaceHolder: string,
  ): Observable<void> {
    const dialog = this.dialog.open<
      UpgradeRequestDialogComponent,
      {
        type: UpgradeRequestType;
        imageUrl: string;
        description: string;
        reasonPlaceHolder: string;
      },
      void
    >(UpgradeRequestDialogComponent, {
      id: 'upgrade-prompt',
      width: '60vw',
      panelClass: 'upgrade-prompt-modalpanel',
      autoFocus: false,
      closeOnNavigation: true,
      data: {
        type,
        imageUrl,
        description,
        reasonPlaceHolder,
      },
    });
    return dialog.afterClosed().pipe(take(1));
  }

  showUpgradeMapAccessDialog() {
    return this.showUpgradeRequestDialog(
      UpgradeRequestType.MAP_ACCESS,
      '/assets/images/Rectangle.png',
      'Unlock full Map access to utilize hundreds of data layers and filter down with custom criteria using our siting tools.',
      'I want full access to the Map...',
    );
  }

  showUpgradeParcelFilterExportRequestDialog() {
    return this.showUpgradeRequestDialog(
      UpgradeRequestType.PARCEL_FILTER_EXPORT,
      '/assets/images/upgrade-parcel-filter-export.png',
      'Be able to export lists of land owners in just seconds!',
      'I want a .kml and .csv of all land owners',
    );
  }

  showUpgradeReportRequestDialog(): Observable<void> {
    return this.showUpgradeRequestDialog(
      UpgradeRequestType.REPORT,
      '../../../../assets/images/upgrade-report.png',
      "You've hit your limit on reports. Unlock limitless insights and efficiency with unlimited reports!",
      'I would like to get full access...',
    );
  }

  showCustomMapLayersUpgradeDialog(): Observable<void> {
    return this.showUpgradeRequestDialog(
      UpgradeRequestType.CUSTOM_DATA_LAYER,
      '/assets/images/DataLayerGraphic.png',
      'Unlock your own custom data layers inside Transect and enhance decision-making with personalized overlays',
      'I want full access...',
    );
  }
}
