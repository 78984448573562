import { z } from 'zod';

export const ReportExportKmlPayloadDTO = z.object({
  report_export__id: z.string().uuid(),
  skipNotifications: z.boolean().optional(),
});

export type ReportExportKmlPayloadDTO = z.infer<
  typeof ReportExportKmlPayloadDTO
>;
