import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransectExpansionPanelComponent } from './expansion-panel.component';

@NgModule({
  declarations: [TransectExpansionPanelComponent],
  imports: [CommonModule],
  exports: [TransectExpansionPanelComponent],
})
export class TransectExpansionPanelModule {}
