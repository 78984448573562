import { z } from 'zod';
import { CreateCatalogLocationPayload } from './create-catalog-location';
export const UpdateCatalogLocationPayload =
  CreateCatalogLocationPayload.partial({
    catalog_item__id: true,
  });

export type UpdateCatalogLocationPayload = z.infer<
  typeof UpdateCatalogLocationPayload
>;
