import { z } from 'zod';

/** Body for post / */
export const SpeciesLocationCreateControllerBody = z.object({
  type: z.string(),
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  geometry: z.any().optional().nullable(),
  occurrence_potential: z.string().optional().nullable(),
  exclusion_area: z.boolean().default(false),
  primary: z.boolean().default(false),
  reference__id: z.string().uuid().optional().nullable(),
  species__id: z.string().uuid(),
});

export type SpeciesLocationCreateControllerBody = z.infer<
  typeof SpeciesLocationCreateControllerBody
>;
