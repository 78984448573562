import { ResponseRows } from '../../../utils/response-rows';
import { SolarRegulationDTO } from '../solar-regulation-dto';
import { z } from 'zod';

export const FetchSolarRegulationsDTO = ResponseRows(
  SolarRegulationDTO.omit({
    geometry: true,
    verification_notes: true,
    deleted_at: true,
    content_original_link: true,
    date_term: true,
    extract_date: true,
    id: true,
    regulation_type: true,
    summary: true,
    verification_request_notes: true,
  }),
);

export type FetchSolarRegulationsDTO = z.infer<typeof FetchSolarRegulationsDTO>;
