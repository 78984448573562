import { z } from 'zod';

export const ProjectTypeDTO = z.enum([
  'renewable_energy',
  'utility_aboveground',
  'utility_belowground',
  'residential',
  'commercial',
  'mining',
  'transportation_linear',
  'marina_shoreline_development',
  'other',
]);
export type ProjectTypeDTO = z.infer<typeof ProjectTypeDTO>;
