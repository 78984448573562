import { z } from 'zod';

export const CreateCatalogLocationPayload = z.object({
  catalog_item__id: z.string().uuid().nullable(),
  description: z.string().nullable(),
  geometry: z.any().nullable().optional(),
  name: z.string().nullable(),
  reference__id: z.string().uuid().nullable(),
});

export type CreateCatalogLocationPayload = z.infer<
  typeof CreateCatalogLocationPayload
>;
