import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonLineLoadingComponent } from './components/skeleton-line-loading/skeleton-line-loading.component';
import { SkeletonCircleLoadingComponent } from './components/skeleton-circle-loading/skeleton-circle-loading.component';
import { SkeletonCompositeLoadingComponent } from './components/skeleton-composite-loading/skeleton-composite-loading.component';

@NgModule({
  declarations: [
    SkeletonLineLoadingComponent,
    SkeletonCircleLoadingComponent,
    SkeletonCompositeLoadingComponent,
  ],
  imports: [CommonModule],
  exports: [
    SkeletonLineLoadingComponent,
    SkeletonCircleLoadingComponent,
    SkeletonCompositeLoadingComponent,
  ],
})
export class SkeletonLoadingModule {}
