import { z } from 'zod';
import { DateAsString, DateSchema } from '../../../utils/date-schema';
import {
  FeatureCollectionSchema,
  GeometrySchema,
  PointGeometrySchema,
} from '../../../utils/geometry-object-schema';
import { ReportContext, ReportPlugin, ReportConcernLevel } from '../report.dto';
import { TransectFile } from '../../transect-file/transect-file';
import { Preferences } from '../../projects/project.dto';
import { ReportExportType } from '../../report-exports';
import { ReportPartType } from '../../report-part';

const PassthroughEmptyObject = z.object({}).passthrough();

const ReportAnnotationSchema = z.object({
  _id: z.string().uuid().optional(),
  type: z.string(),
  name: z.string(),
  description: z.string().optional(),
  presence: z.boolean().optional(),
  object__type: z.string().optional(),
  object__id: z.string().optional(),
  active: z.boolean().optional(),
  priority: z.boolean().optional(),
  admin: z.object({
    is_ghost_water: z.boolean().optional(),
    is_exclusion_zone: z.boolean().optional(),
  }),
  geometry: GeometrySchema.optional(),
  creation_type: z.enum(['automated', 'user', 'admin']).optional(),
  validation: z.string().nullish(),
  report__id: z.string().uuid().optional(),
  creator__id: z.string().uuid().optional(),
  created_at: DateAsString.optional(),
  updated_at: DateAsString.optional(),
});

const GeographiesStatesSchema = z.array(
  z.object({
    state: z.string(),
    counties: z.array(z.string()).nullish(),
    abbreviation: z.string(),
    geography__id: z.string().uuid(),
  }),
);

const CustomerSchema = z.object({
  account_name: z.string(),
  terms_accepted: z.boolean(),
  _id: z.string().uuid(),
  chargify_customer_id: z.number().nullish(),
  name: z.string(),
  firstname: z.string(),
  lastname: z.string(),
  domain: z.string().nullable(),
  billing_notes: z.string().nullish(),
  address_billing: PassthroughEmptyObject,
  address_mailing: PassthroughEmptyObject,
  terms_accepted_date: DateSchema.nullish(),
  shared_portfolio: z.boolean(),
  all_collaborator: z.boolean(),
  invoicing_allowed: z.boolean(),
  customer_catalog_enabled: z.boolean(),
  subscription_active: z.boolean(),
  subscription_start_date: DateSchema.nullish(),
  subscription_end_date: DateSchema.nullish(),
  pilot_active: z.boolean(),
  pilot_start_date: DateSchema.nullish(),
  pilot_end_date: DateSchema.nullish(),
  pilot_states: z.array(z.string()).nullish(),
  vision_enabled: z.boolean(),
  vision_plan__id: z.string().uuid().nullish(),
  vision_catalog_items: z.array(z.string().uuid()).nullish(),
  logo_gcs: z
    .object({
      s3: PassthroughEmptyObject,
      _id: z.string().uuid().nullish(),
      gcs: z
        .object({
          url: z.string().nullish(),
          file: z.string().nullish(),
          bucket: z.string().nullish(),
        })
        .passthrough(),
      name: z.string().nullish(),
      type: z.string().nullish(),
      status: z.string().nullish(),
      relations: z
        .object({
          _id: z.string().uuid().nullish(),
          type: z.string().nullish(),
        })
        .nullish(),
      created_at: z.string(),
      properties: z.object({
        size: z.number(),
        purpose: z.string(),
        mimetype: z.string(),
        originalname: z.string(),
      }),
      updated_at: DateAsString,
      creator__id: z.string().uuid().nullish(),
      description: z.string().nullish(),
      reference__id: z.string().uuid().nullish(),
    })
    .nullish(),
  industry__id: z.string().uuid().nullish(),
  created_at: DateSchema.nullish(),
  updated_at: DateSchema.nullish(),
  deleted_at: DateSchema.nullish(),
  transect_plan__id: z.string().uuid().nullish(),
  creator__id: z.string().uuid().nullish(),
  terms_accepted_user__id: z.string().uuid().nullish(),
});

export const ReportShowProjectDTO = z.object({
  service_area: z.string(),
  in_service_area: z.boolean(),
  // in_united_states: z.boolean(),
  // ready_for_report: z.string(),
  _id: z.string().uuid(),
  // type: z.string(),
  client_identifier: z.string().nullish(),
  name: z.string(),
  description: z.string().nullish(),
  geometry: GeometrySchema.nullish(),
  // properties: PassthroughEmptyObject,
  context: ReportContext,
  // active: z.boolean(),
  // is_official_demo: z.boolean(),
  // cleanup_weekly: z.boolean(),
  geographies_states: GeographiesStatesSchema,
  // creator__id: z.string().uuid(),
  // created_from: z.string(),
  // pinned: z.boolean(),
  public: z.boolean(),
  customer__id: z.string().uuid().nullish(),
  // report_count: z.number(),
  customer: CustomerSchema.nullish(),
  reports: z.array(
    z.object({
      isComplete: z.boolean(),
      _id: z.string().uuid(),
    }),
  ),
  users: z.array(
    z.object({
      fullname: z.string().nullish(),
      public: z
        .object({
          firstname: z.string().nullish(),
          lastname: z.string().nullish(),
          fullname: z.string().nullish(),
          role: z.string().nullish(),
        })
        .nullish(),
      token: z
        .object({
          _id: z.string().uuid(),
          role: z.string(),
        })
        .nullish(),
      customer: CustomerSchema.nullish(),
      _id: z.string().uuid(),
      firstname: z.string(),
      lastname: z.string(),
      email: z.string().email(),
      role: z.string(),
      provider: z.string(),
      title: z.string().nullish(),
      photo_gcs: TransectFile.nullable(),
      project_accesses: z.array(
        z.object({
          _id: z.string().uuid(),
          role: z.enum(['viewer', 'collaborator', 'owner', 'tester']),
          created_at: DateSchema,
          updated_at: DateSchema,
          preferences: Preferences.nullish(),
          project__id: z.string().uuid().nullish(),
          user__id: z.string().uuid().nullish(),
          creator__id: z.string().uuid().nullish(),
        }),
      ),
    }),
  ),
  project_role: z
    .enum(['viewer', 'collaborator', 'owner', 'tester'])
    .optional(),
});

export const ReportShowDTO = z.object({
  isComplete: z.boolean(),
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  content: z
    .object({
      executive_summary_v3: z.boolean().nullish(),
      executive_summary_override_enabled: z.boolean().nullish(),
      notes: z.string().nullish(),
      expert_notes: z.string().nullish(),
      expert_reviewer_id: z.string().uuid().nullish(),
      executive_summary_overrides: z.preprocess(
        (val) => (val === null ? [] : val),
        z.array(
          z.object({
            level: z.enum(['high', 'moderate', 'low']),
            section: z.string(),
            additional: z.string().nullish(),
            reason: z.string().nullish(),
          }),
        ),
      ),
    })
    .deepPartial()
    .passthrough()
    .nullish(),
  parent_report_id: z.string().uuid().nullish(),
  name: z.string(),
  type: z.string(),
  aoi: GeometrySchema.nullish(),
  center: PointGeometrySchema.nullish(),
  buffer: GeometrySchema.nullish(),
  drawn_objects: GeometrySchema.nullish(),
  status: z.string(),
  nda: z
    .object({
      _id: z.string().uuid(),
      status: z.string(),
      feature_collection: FeatureCollectionSchema.nullish(),
    })
    .nullish(),
  status_review: z.enum(['no_review', 'complete', 'pending']),
  progress: z.number(),
  report_annotations: z.array(ReportAnnotationSchema).nullish(),
  version: z
    .string()
    .regex(
      /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/,
    ),
  properties: z
    .object({
      aoi: z
        .object({
          area: z
            .object({
              acres: z.number().optional(),
              feet: z.number().optional(),
              miles: z.number().optional(),
            })
            .optional(),
          length: z
            .object({
              feet: z.number().optional(),
              miles: z.number().optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .passthrough()
    .nullish(),
  context: ReportContext,
  settings: z
    .object({
      buffer_width_miles: z.number().nullish(),
    })
    .passthrough(),
  errors: PassthroughEmptyObject.nullish(),
  geographies_states: GeographiesStatesSchema,
  is_customizable: z.boolean(),
  waters_analyzed: z.boolean().nullish(),
  waters_analyzed_date: z.string().nullish(),
  is_sales_demo: z.boolean(),
  state_species_supported: z.boolean().nullish(),
  notification_report_completed: DateSchema.nullish(),
  notification_review_completed: DateSchema.nullish(),
  date_first_completed: DateSchema.nullish(),
  simplified_aoi: z.string().nullish(),
  simplified_aoi_description: z.string().nullish(),
  concern_levels: z
    .object({
      waters: ReportConcernLevel,
      species: ReportConcernLevel,
      state_species: ReportConcernLevel.optional(),
      protected_area: ReportConcernLevel,
      environmental_compliance: ReportConcernLevel,
    })
    .nullish(),
  additional_notes: z.string().nullish(),
  report_sample__id: z.string().uuid().nullish(),
  report_sample: z
    .object({
      _id: z.string().uuid(),
      geographies_states: GeographiesStatesSchema,
      service_area: z.string(),
    })
    .nullish(),
  review_completed_at: DateSchema.nullish(),
  project__id: z.string().uuid().nullish(),
  trim_geography__id: z.string().uuid().nullish(),
  order__id: z.string().uuid().nullish(),
  creator__id: z.string().uuid().nullish(),
  executive_summary_override_enabled: z.boolean(),
  plugins: z.array(ReportPlugin).nullish(),
  creator: z.preprocess(
    (val) => (typeof val === 'string' ? { _id: val } : val),
    z
      .object({
        _id: z.string().uuid(),
        fullname: z.string().nullish(),
        firstname: z.string().nullish(),
        lastname: z.string().nullish(),
        email: z.string().nullable(),
        role: z.enum(['user', 'admin', 'unverified', 'public-user']),
      })
      .nullish(),
  ),
  report_parts: z.array(
    z.object({
      _id: z.string().uuid(),
      type: z.nativeEnum(ReportPartType),
      status: z.string(),
      progress: z.number(),
      summary: z
        .object({
          area: z.object({
            feet: z.number(),
            acres: z.number(),
            miles: z.number(),
            meters: z.number(),
          }),
          count: z.number(),
          center: z.object({
            type: z.literal('Feature'),
            geometry: PointGeometrySchema,
          }),
          custom: PassthroughEmptyObject.nullish(),
          length: z
            .object({
              feet: z.number().nullish(),
              miles: z.number().nullish(),
            })
            .nullish(),
          countAoi: z.number(),
          debugging: PassthroughEmptyObject,
          countBuffer: z.number(),
          count_buffer: z.number(),
          countExternal: z.number(),
          count_external: z.number(),
          countAnonymized: z.number(),
        })
        .deepPartial(),
      created_at: DateAsString,
      updated_at: DateAsString,
    }),
  ),
  assigned_to: z.string().uuid().nullish(),
  project: ReportShowProjectDTO.nullish(),
  exports: z.array(
    z.object({
      _id: z.string().uuid(),
      type: z.nativeEnum(ReportExportType),
      status: z.string(),
      url: z.string().url(),
      created_at: DateAsString,
      url_expires_at: DateAsString,
    }),
  ),
  review_requested_at: DateSchema,
  user_refresh_date: DateSchema.nullish(),
  user_refresh: z.boolean(),
});

export type ReportShowDTO = z.infer<typeof ReportShowDTO>;
export type ReportShowProjectDTO = z.infer<typeof ReportShowProjectDTO>;
