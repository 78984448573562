import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FlyoverModalStateService } from './flyover-modal-state.service';
import { FlyoverComponentTemplate } from './types';
import { NavigationStart, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'transect-nx-flyover-modal',
  templateUrl: './flyover-modal.component.html',
  styleUrls: ['./flyover-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FlyoverModalStateService],
})
export class FlyoverModalComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isOpen = false;
  @Input() minWidth?: string;
  @Input() maxWidth?: string;
  @Input() shouldCloseOnBackdropClick = true;

  @Input() contentTemplate: FlyoverComponentTemplate = null;

  protected template: TemplateRef<HTMLElement> | null = null;
  @Input() closeOnNavigation? = true;

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() backdropClick = new EventEmitter();
  @Output() navigatingAway = new EventEmitter();
  destroy$ = new Subject<void>();

  @ViewChild('flyoverContainer')
  flyoverContainer?: ElementRef<HTMLDivElement>;

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private viewContainerRef: ViewContainerRef,
  ) {}
  ngOnInit(): void {
    if (this.closeOnNavigation) {
      this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.navigatingAway.emit();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasOwnProperty('isOpen')) {
      return;
    }
    if (changes['isOpen'].currentValue === true) {
      this.renderer.addClass(document.body, 'modal-open');
    } else {
      this.renderer.removeClass(document.body, 'modal-open');
    }

    if (changes['contentTemplate']) {
      this.viewContainerRef.clear();
      this.template = null;

      if (this.contentTemplate === null) {
        return;
      } else if ('component' in this.contentTemplate) {
        const componentRef = this.viewContainerRef.createComponent(
          this.contentTemplate.component,
        );

        if (this.contentTemplate.inputs !== null) {
          Object.entries(this.contentTemplate.inputs).forEach(
            ([key, value]) => {
              componentRef.setInput(key, value);
            },
          );
        }

        this.flyoverContainer?.nativeElement.appendChild(
          componentRef?.location.nativeElement,
        );
      } else {
        this.template = this.contentTemplate;
      }
    }
  }

  handleBackdropClicked() {
    this.backdropClick.emit();
  }
}
