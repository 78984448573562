import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ReportOverrideLog } from '@transect-nx/data-transfer-objects';
import {
  OverrideConcernLevelEntity,
  OverridePermitEntity,
  OverrideRegulationEntity,
  OverrideSpeciesConcernLevelEntity,
  SpeciesAssessmentLevel,
} from '@transect/models/report';
import { RemoveUnderscorePipe } from '@transect/shared/pipes/remove-underscore.pipe';

@Pipe({
  name: 'reportOverrideLogToString',
})
export class ReportOverrideLogToStringPipe implements PipeTransform {
  constructor(
    private titleCasePipe: TitleCasePipe,
    private removeUnderscorePipe: RemoveUnderscorePipe,
  ) {}
  reportSectionConcernLevelOverrideToString(
    overrideLog: ReportOverrideLog,
  ): string {
    const userName = overrideLog.overridden_by.fullname;
    const entity = overrideLog.entity as OverrideConcernLevelEntity;
    let operationText = this.titleCasePipe.transform(
      this.removeUnderscorePipe.transform(overrideLog.operation_type),
    );
    if (operationText === 'Protected Area') {
      operationText = 'Protected Areas';
    }
    const concernLevel = this.titleCasePipe.transform(entity.concernLevelName);
    return `${userName} changed concern level for ${operationText} to ${concernLevel}`;
  }

  speciesConcernLevelOverrideToString(overrideLog: ReportOverrideLog): string {
    const entity = overrideLog.entity as OverrideSpeciesConcernLevelEntity;
    const userName = overrideLog.overridden_by.fullname;
    const getEntityLevel = (level: SpeciesAssessmentLevel): number => {
      if (level === SpeciesAssessmentLevel.SPECIES_OF_CONCERN) {
        return 3;
      }
      if (level === SpeciesAssessmentLevel.SPECIES_MAY_OCCUR) {
        return 2;
      }
      if (level === SpeciesAssessmentLevel.SPECIES_NOT_LIKELY_TO_OCCUR) {
        return 1;
      }
      return 0;
    };
    const action = () => {
      const level = getEntityLevel(entity.newLevel as SpeciesAssessmentLevel);
      const previousLevel = getEntityLevel(
        entity.previousLevel as SpeciesAssessmentLevel,
      );
      if (previousLevel > level) {
        return 'downgraded';
      }
      return 'upgraded';
    };
    const speciesName = entity.commonName || entity.scientificName || 'species';
    const concernLevel = this.removeUnderscorePipe.transform(
      this.titleCasePipe.transform(entity.newLevel),
    );
    return `${userName} ${action()} ${speciesName} to ${concernLevel}`;
  }

  regulationVisibilityOverrideToString(overrideLog: ReportOverrideLog): string {
    const entity = overrideLog.entity as OverrideRegulationEntity;
    const userName = overrideLog.overridden_by.fullname;
    const action = entity.effectiveStatus === 'active' ? 'activated' : 'hid';
    const fromOrIn = entity.effectiveStatus === 'active' ? 'in' : 'from';
    return `${userName} ${action} ${entity.name} Regulation ${fromOrIn} report`;
  }

  permitVisibilityOverrideToString(overrideLog: ReportOverrideLog): string {
    const entity = overrideLog.entity as OverridePermitEntity;
    const userName = overrideLog.overridden_by.fullname;
    const action = entity.effectiveStatus === 'active' ? 'activated' : 'hid';
    const fromOrIn = entity.effectiveStatus === 'active' ? 'in' : 'from';
    return `${userName} ${action} ${entity.name} Permit ${fromOrIn} report`;
  }

  transform(value: ReportOverrideLog): string {
    if (
      value.operation_type === 'species' ||
      value.operation_type === 'state_species' ||
      value.operation_type === 'environmental_compliance' ||
      value.operation_type === 'protected_area' ||
      value.operation_type === 'waters'
    ) {
      return this.reportSectionConcernLevelOverrideToString(value);
    }
    if (value.operation_type === 'species-concern-level-override') {
      return this.speciesConcernLevelOverrideToString(value);
    }
    if (value.operation_type === 'regulation-status-override') {
      return this.regulationVisibilityOverrideToString(value);
    }
    if (value.operation_type === 'permit-status-override') {
      return this.permitVisibilityOverrideToString(value);
    }
    return '';
  }
}
