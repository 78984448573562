import { z } from 'zod';

export enum LandownerContactExportStatus {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export enum LandownerContactExportType {
  ALL = 'all',
  SINGLE = 'single',
  REPORT = 'report',
}

export const CreateLandownerContactExportRequestDTO = z.object({
  export_type: z.nativeEnum(LandownerContactExportType),
  landowner_contact_order__id: z.string().uuid().nullish(),
  report__id: z.string().uuid().nullish(),
  report_part__id: z.string().uuid().nullish(),
});

export type CreateLandownerContactExportRequestDTO = z.infer<
  typeof CreateLandownerContactExportRequestDTO
>;

export const CreateLandownerContactExportResponseDTO = z.object({
  export_type: z.nativeEnum(LandownerContactExportType),
  status: z.nativeEnum(LandownerContactExportStatus),
  landowner_contact_order__id: z.string().uuid().nullable(),
});

export type CreateLandownerContactExportResponseDTO = z.infer<
  typeof CreateLandownerContactExportResponseDTO
>;

export const LandownerContactExportPubSubPayloadDTO = z.object({
  landowner_contact_export__id: z.string().uuid(),
  report_part__id: z.string().uuid().nullish(),
  is_report_v3_or_higher: z.boolean(),
});

export type LandownerContactExportPubSubPayloadDTO = z.infer<
  typeof LandownerContactExportPubSubPayloadDTO
>;
