import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  IAfterGuiAttachedParams,
  ICellRendererParams,
} from 'ag-grid-community';

type ICellRendererParamsWithClickHandler = ICellRendererParams & {
  linkClickHandler: (data: any) => void;
  hide: ((data: any) => boolean) | boolean;
  isDisabled?: (data: any) => boolean;
};

@Component({
  selector: 'ts-link-cell-renderer',
  templateUrl: './link-cell-renderer.component.html',
  styleUrls: ['./link-cell-renderer.component.scss'],
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  params?: ICellRendererParamsWithClickHandler;
  hidden = false;
  disabled = false;

  agInit(params: ICellRendererParamsWithClickHandler): void {
    this.params = params;

    if (params.hide instanceof Function) {
      this.hidden = params.hide(params.data);
    } else {
      this.hidden = params.hide || false;
    }

    if (this.params.isDisabled) {
      this.disabled = this.params.isDisabled(params.data) ?? false;
    }
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    return;
  }

  refresh(params: any): boolean {
    return true;
  }
}
