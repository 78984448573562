import { z } from 'zod';

/** Route params for path /user-templates/:id/* representing :id */
export const UserTemplateIdControllerParams = z
  .object({
    id: z.string().uuid(),
  })
  .strict();
export type UserTemplateIdControllerParams = z.infer<
  typeof UserTemplateIdControllerParams
>;

export * from './create-controller';
export * from './update-controller';
