import { z } from 'zod';

const ReportPartPermit = z.object({
  _id: z.string().uuid(),
  name: z.string().nullish(),
});

export const ReportPartPermitDTO = ReportPartPermit;

export type ReportPartPermitDTO = z.infer<typeof ReportPartPermitDTO>;

const ReportPartActivePermit = z.object({
  _id: z.string().uuid(),
  name: z.string().nullish(),
  acronym: z.string().nullish(),
  nameAcronymBack: z.string().nullish(),
  jurisdiction: z.string().nullish(),
  customer__id: z.string().uuid().nullish(),
  content: z
    .object({
      timeline: z.string().nullable(),
      recommendation: z.string().nullable(),
      enabled_language: z.string().nullable(),
    })
    .nullish(),
  links: z
    .array(
      z.object({
        name: z.string().nullish(),
        url: z.string().nullish(),
      }),
    )
    .nullish(),
  assessment: z.any().nullish(),
  active_triggers: z.any().nullish(),
  regulations: z.any().nullish(),
  regulators: z.any().nullish(),
  data_gap: z.boolean().nullable(),
  has_trigger_recommendations: z.boolean(),
});

export const ReportPartActivePermitDTO = ReportPartActivePermit;

export type ReportPartActivePermitDTO = z.infer<
  typeof ReportPartActivePermitDTO
>;
