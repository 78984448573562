import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { TransectButtonsModule } from '../transect-buttons/transect-buttons.module';
import { FilterTextFieldComponent } from './filter-text-field.component';

@NgModule({
  declarations: [FilterTextFieldComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    TransectButtonsModule,
  ],
  exports: [FilterTextFieldComponent],
})
export class FilterTextFieldModule {}
