import { z } from 'zod';

export const FinancialIncentivesTier = z.object({
  isEligible: z.boolean(),
  value: z.number().optional(),
});

export const FinancialIncentivesTotals = z.object({
  value: z.number(),
  savings: z.number().optional(),
});

export const FinancialIncentivesBaseRate = z.object({
  type: z.literal('base'),
  baseCredit: FinancialIncentivesTier,
  domesticContentBonus: FinancialIncentivesTier,
  energyCommunityBonus: FinancialIncentivesTier,
});

export type FinancialIncentivesBaseRate = z.infer<
  typeof FinancialIncentivesBaseRate
>;

export const FinancialIncentivesFullRate = z.object({
  type: z.literal('full'),
  baseCredit: FinancialIncentivesTier,
  domesticContentBonus: FinancialIncentivesTier,
  energyCommunityBonus: FinancialIncentivesTier,
});

export const FinancialIncentivesRate = z.discriminatedUnion('type', [
  FinancialIncentivesFullRate,
  FinancialIncentivesBaseRate,
]);

export type FinancialIncentivesRate = z.infer<typeof FinancialIncentivesRate>;

export type FinancialIncentivesTier = z.infer<typeof FinancialIncentivesTier>;

export const FinancialIncentivesITC = z.object({
  rate: FinancialIncentivesRate,
  lowIncomeBonus: z
    .object({
      sizeOrIndianLand: FinancialIncentivesTier,
      qualifiedLowIncome: FinancialIncentivesTier,
    })
    .optional(),
  totals: FinancialIncentivesTotals,
});

export type FinancialIncentivesITC = z.infer<typeof FinancialIncentivesITC>;

export const FinancialIncentivesPTC = z.object({
  rate: FinancialIncentivesRate,
  totals: FinancialIncentivesTotals,
});

export type FinancialIncentivesPTC = z.infer<typeof FinancialIncentivesPTC>;

export const FinancialIncentives = z.object({
  itc: FinancialIncentivesITC,
  ptc: FinancialIncentivesPTC,
});

export type FinancialIncentives = z.infer<typeof FinancialIncentives>;
