import { z } from 'zod';

export const ParcelBasicInfoDTO = z.object({
  _id: z.string().uuid(),
  parcelnumb: z.string().nullish(),
  center: z.preprocess((val) => {
    if (typeof val === 'string') {
      return JSON.parse(val);
    }

    return val;
  }, z.object({}).passthrough().nullish()),
  mailadd: z.string().nullish(),
  mail_city: z.string().nullish(),
  mail_state2: z.string().nullish(),
  mail_zip: z.string().nullish(),
  mail_country: z.string().nullish(),
});

export type ParcelBasicInfoDTO = z.infer<typeof ParcelBasicInfoDTO>;
