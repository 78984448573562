import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ExpandableBoxComponent } from './expandable-box.component';
import { CommonModule } from '@angular/common';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';

@NgModule({
  imports: [CommonModule, MatCheckboxModule, ReactiveFormsModule],
  declarations: [ExpandableBoxComponent],
  exports: [ExpandableBoxComponent],
})
export class ExpandableBoxModule {}
