import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormModalInputData } from '../../models/form-modal.service';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { filter, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { FormDTO } from '@transect-nx/data-transfer-objects';

@Component({
  selector: 'ts-form-ceii-nda',
  templateUrl: './form-ceii-nda.component.html',
  styleUrls: ['./form-ceii-nda.component.scss'],
})
export class FormCeiiNdaComponent implements OnInit, OnDestroy {
  private readonly destroyAction = new Subject<void>();

  protected ceiiNdaForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    company_title: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.email, Validators.required]),
  });

  protected get formStateService() {
    return this.data.stateService;
  }

  protected canSave$ = this.formStateService.canSave$;
  protected isComplete$ = this.formStateService.isComplete$;
  protected inProgress$ = this.formStateService.inProgress$;
  protected completing$ = this.formStateService.completing$;
  protected isFetchingPdf$ = this.formStateService.isFetchingPdf$;
  protected saveButtonColor$ = this.formStateService.saveButtonColor$;

  constructor(
    private dialogRef: MatDialogRef<FormCeiiNdaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormModalInputData,
  ) {}

  ngOnDestroy(): void {
    this.destroyAction.next();
  }

  ngOnInit(): void {
    if (this.formStateService.stateValue.formValue) {
      this.ceiiNdaForm.patchValue(this.formStateService.stateValue.formValue, {
        emitEvent: false,
      });
      this.formStateService.dispatchFormValidAction(this.ceiiNdaForm.valid);
    } else {
      this.formStateService.dispatchFormValueChangeAction(
        this.ceiiNdaForm.valid,
        this.ceiiNdaForm.value,
        this.data.formTypeId,
      );
    }

    if (this.formStateService.stateValue.isComplete) {
      this.ceiiNdaForm.disable();
    }

    this.formStateService.formValue$
      .pipe(
        take(1),
        tap((formValue) => {
          this.ceiiNdaForm.patchValue(formValue, {
            emitEvent: false,
          });
        }),
      )
      .subscribe();

    this.ceiiNdaForm.valueChanges
      .pipe(
        filter(() => this.ceiiNdaForm.dirty),
        switchMap((formValue: FormDTO['contents']) => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.ceiiNdaForm.valid,
            formValue,
            this.data.formTypeId,
          );
        }),
        takeUntil(this.destroyAction),
      )
      .subscribe();
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleSaveClick() {
    if (!this.ceiiNdaForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}
