import { z } from 'zod';

export const UserFeedbackDTO = z.object({
  user__id: z.string().uuid().optional().nullable(),
  score: z.number().nullish(),
  category: z.string(),
  slug: z.string(),
  feedback: z.string().optional().nullable(),
  metadata: z.object({}).passthrough().default({}).optional().nullable(),
  userId: z.string().optional().nullable(),
});

export type UserFeedbackDTO = z.infer<typeof UserFeedbackDTO>;
