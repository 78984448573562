import { z } from 'zod';

export const PackagePlatformConfigurationPresetsDTO = z.object({
  transect_plan: z.string(),
  platform_configuration_item: z.string(),
  value: z.string(),
});

export type PackagePlatformConfigurationPresetsDTO = z.infer<
  typeof PackagePlatformConfigurationPresetsDTO
>;

export const PackagePlatformConfigurationPresetsArrayDTO = z.array(
  PackagePlatformConfigurationPresetsDTO,
);

export type PackagePlatformConfigurationPresetsArrayDTO = z.infer<
  typeof PackagePlatformConfigurationPresetsArrayDTO
>;
