import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TransectFile } from './../../models/transect-file';

@Component({
  selector: 'ts-user-profile-photo',
  templateUrl: './user-profile-photo.component.html',
  styleUrls: ['./user-profile-photo.component.scss'],
})
export class UserProfilePhotoComponent {
  @Input() transectFile?: TransectFile | null;
  @Input() imgClass?: string;
  @Output() loading = new EventEmitter<boolean>();
  @Output() loaded = new EventEmitter<boolean>();

  myLoading(event: boolean): void {
    this.loading.emit(event);
  }

  handleImageLoaded(): void {
    this.loaded.emit();
  }
}
