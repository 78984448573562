import { z } from 'zod';

export const stringToBoolean = z
  .string()
  .refine((value) => {
    const lowerValue = value.toLowerCase();
    return lowerValue === 'true' || lowerValue === 'false';
  }, 'Invalid boolean string')
  .transform((value) => {
    return value.toLowerCase() === 'true';
  });
