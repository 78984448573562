import { z } from 'zod';

export const CustomerPlatformConfigurationDTO = z.object({
  customer__id: z.string(),
  platform_configuration_item__id: z.string(),
  value: z.string(),
});

export type CustomerPlatformConfigurationDTO = z.infer<
  typeof CustomerPlatformConfigurationDTO
>;

export const UpdateCustomerPlatformConfigurationDTO = z.object({
  customer: z.string(),
  platform_configuration_item: z.string(),
  value: z.string(),
});

export type UpdateCustomerPlatformConfigurationDTO = z.infer<
  typeof UpdateCustomerPlatformConfigurationDTO
>;

export const UpdateCustomerPlatformConfigurationArrayDTO = z.array(
  UpdateCustomerPlatformConfigurationDTO,
);

export type UpdateCustomerPlatformConfigurationArrayDTO = z.infer<
  typeof UpdateCustomerPlatformConfigurationArrayDTO
>;

export const CustomerPlatformConfigurationArrayDTO = z.array(
  CustomerPlatformConfigurationDTO,
);

export type CustomerPlatformConfigurationArrayDTO = z.infer<
  typeof CustomerPlatformConfigurationArrayDTO
>;
