import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartProtectedAreaDTO } from '../models/report-part-protected-area.dto';

export const PaginatedReportPartProtectedAreaFeaturesDTO =
  PaginatedResponseRows(ReportPartProtectedAreaDTO);

export type PaginatedReportPartProtectedAreaFeaturesDTO = z.infer<
  typeof PaginatedReportPartProtectedAreaFeaturesDTO
>;
