import { z } from 'zod';
import { ResponseRows } from '../../utils/response-rows';
import { ReportAddOnOfferingDTO } from './report-add-on-offering.dto';

export enum AddonEntityType {
  REPORT = 'report',
  PROJECT = 'project',
}

export const ReportAddonOfferingIndexDTO = ResponseRows(
  ReportAddOnOfferingDTO.extend({
    sla: z.string().nullish(),
    public_marketplace_enabled: z.boolean(),
    in_app_marketplace_enabled: z.boolean(),
  }),
);
export type ReportAddonOfferingIndexDTO = z.infer<
  typeof ReportAddonOfferingIndexDTO
>;
