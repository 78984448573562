<a
  data-testId="link-cell"
  [innerHTML]="params?.value"
  (click)="go($event)"
  *ngIf="!disabled && route; else plain"
  data-cy="btn-vision-link"
></a>

<ng-template #plain>
  <div [innerHTML]="params?.value"></div>
</ng-template>
