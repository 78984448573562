import { z } from 'zod';
import { Sentiment } from '../../utils';
import { DateSchema } from '../../utils/date-schema';
import { GeometrySchema } from '../../utils/geometry-object-schema';
import { ContestedSolarFarmDTO } from '../contested-solar-farms';

export const SolarFarmDTO = z.object({
  name: z.string().nullish(),
  longitude: z.number(),
  latitude: z.number(),
  size_acres: z.number().nullish(),
  operator: z.string().nullish(),
  capacity: z.number().nullish(),
  year_operation: z.number().nullish(),
  month_operation: z.number().nullish(),
  imagery_url: z.string().url().nullish(),
  distance_in_miles: z.number(),
  project_status: z.string().nullable(),
  permit_type: z.string().nullable(),
  city: z.string().nullable(),
  county: z.string().nullable(),
  state: z.string().nullable(),
  source: z.string().nullable(),
  offtaker_name: z.string().nullable(),
  ohm_geometry: z.boolean().nullish(),
  segment: z.string().nullable(),
  application_date: DateSchema.nullable(),
  geometry: GeometrySchema.nullish(),
  contested_solar_farm__id: z.string().uuid().nullish(),
  _id: z.string().uuid(),
});

export type SolarFarmDTO = z.infer<typeof SolarFarmDTO>;

export const SolarFarmCountyDTO = z.object({
  name: z.string(),
  id: z.string().uuid().nullish(),
  solar_farms_data: z.array(SolarFarmDTO),
  contested_solar_farms_data: z
    .array(ContestedSolarFarmDTO)
    .nullish()
    .default([]),
  sentiment_score: z.number(),
  sentiment: Sentiment,
});
export type SolarFarmCountyDTO = z.infer<typeof SolarFarmCountyDTO>;

export const SolarFarmStateDTO = z.array(
  z.object({
    state: z.string(),
    state_abbreviation: z.string(),
    geography__id: z.string(),
    counties: z.array(SolarFarmCountyDTO),
  }),
);

export type SolarFarmStateDTO = z.infer<typeof SolarFarmStateDTO>;
