import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const FloodHazardProperties = z.object({
  gfid: z.string(),
  depth: z.number(),
  sfha_tf: z.string(),
  v_datum: z.string(),
  dfirm_id: z.string(),
  fld_zone: z.string(),
  len_unit: z.string(),
  objectid: z.number(),
  vel_unit: z.string(),
  velocity: z.number(),
  ar_revert: z.string(),
  ar_subtrv: z.string(),
  dual_zone: z.string(),
  fld_ar_id: z.string(),
  study_typ: z.string(),
  bfe_revert: z.number(),
  dep_revert: z.number(),
  shape_area: z.number(),
  source_cit: z.string(),
  static_bfe: z.number(),
  version_id: z.string(),
  zone_subty: z.string(),
  shape_length: z.number(),
});

export const ReportPartFloodHazardFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: FloodHazardProperties.deepPartial(),
  geometry: GeometrySchema,
  source__id: z.string().uuid(),
  zone_subty: z.string().nullable(),
  fld_zone: z.string().nullable(),
  transect_feature_type: z.literal('flood_hazard'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartFloodHazardFeatureDTO = z.infer<
  typeof ReportPartFloodHazardFeatureDTO
>;
