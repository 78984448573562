import { z } from 'zod';
import { CatalogReference } from '../catalog-reference';

export const CreateCatalogReferenceRequestDTO =
  CatalogReference.deepPartial().extend({
    catalog_item__id: z.string().uuid(),
    reference__id: z.string().uuid(),
  });

export type CreateCatalogReferenceRequestDTO = z.infer<
  typeof CreateCatalogReferenceRequestDTO
>;
