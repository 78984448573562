import { z } from 'zod';
import { CatalogItemRequest } from '../catalog-item-request';

export const CreateCatalogItemRequestDTO = CatalogItemRequest.partial().extend({
  name: z.string(),
});

export type CreateCatalogItemRequestDTO = z.infer<
  typeof CreateCatalogItemRequestDTO
>;
