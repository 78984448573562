import { z } from 'zod';

export const PaginationNdaFeaturesControllerQuery = z
  .object({
    page: z.preprocess((value) => Number(value), z.number().gte(1)),
    pageSize: z.preprocess((value) => Number(value), z.number().gte(1)),
  })
  .strict();

export type PaginationNdaFeaturesControllerQuery = z.infer<
  typeof PaginationNdaFeaturesControllerQuery
>;
