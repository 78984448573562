import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { FreeTrialAgreementService } from '../services/free-trial-agreement.service';

export const freeTrialUserGuard: CanActivateFn = (): Observable<boolean> => {
  const freeTrialAgreementService = inject(FreeTrialAgreementService);
  const authService = inject(AuthService);

  return authService.userOrNull$.pipe(
    switchMap((user) => {
      if (!user) {
        return of(true);
      } else {
        return freeTrialAgreementService.checkFreeTrialAgreement();
      }
    }),
  );
};
