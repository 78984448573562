import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideNavGroupComponent } from './components/side-nav-group/side-nav-group.component';
import { SideNavLinkComponent } from './components/side-nav-link/side-nav-link.component';
import { SideNavSubGroupComponent } from './components/side-nav-sub-group/side-nav-sub-group.component';
import { SideNavComponent } from './side-nav.component';

@NgModule({
  declarations: [
    SideNavComponent,
    SideNavLinkComponent,
    SideNavGroupComponent,
    SideNavSubGroupComponent,
  ],
  imports: [CommonModule, RouterModule],
  exports: [
    SideNavComponent,
    SideNavLinkComponent,
    SideNavGroupComponent,
    SideNavSubGroupComponent,
  ],
})
export class SideNavModule {}
