import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';

export const ProjectControllerPaginatedQueryParams = z.object({
  general: z.string().nullish(),
  sortModel: sortModel.optional(),
  page: pageNumber,
  pageSize: pageSize.default('2500'),
});

export type ProjectControllerPaginatedQueryParams = z.infer<
  typeof ProjectControllerPaginatedQueryParams
>;
