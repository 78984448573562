import { Collection } from '@mikro-orm/core';

export const mikroOrmCollectionToArray = <T extends object>(
  children: Collection<T>,
) => {
  if (children === undefined || children === null) {
    return null;
  }
  if (Boolean(children.getItems) && children.isInitialized?.()) {
    return children.getItems();
  } else if (Boolean(children.getItems) && !children.isInitialized?.()) {
    return null;
  }
  return children;
};
