import { z } from 'zod';
import { TransectFile } from '../../transect-file/transect-file';

export const UserUpdateControllerBody = z.object({
  email: z.string().email().nullish(),
  firstname: z.string().nullish(),
  lastname: z.string().nullish(),
  organization: z.string().nullish(),
  title: z.string().nullish(),
  two_fa_enabled: z.boolean().default(false),
  photo_gcs: TransectFile.nullish(),
  profile: z
    .object({
      phone: z.string().nullish(),
      address1: z.string().nullish(),
      city: z.string().nullish(),
      state: z.string().nullish(),
      zip: z.string().nullish(),
    })
    .nullish(),
});

export type UserUpdateControllerBody = z.infer<typeof UserUpdateControllerBody>;
