import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DialogModalModule,
  FormsModule,
  TransectButtonsModule,
  FormsModule as TransectFormsModule,
} from '@transect-nx/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { DetectScrollToEndModule } from '../detect-scroll-to-end/detect-scroll-to-end.module';
import { SharedModule } from '../../shared/shared.module';
import { BtnLoaderModule } from '../../shared/directives/btn-loader/btn-loader.module';
import { FormUserFreeTrialAgreementComponent } from './form-user-free-trial-agreement.component';
import { FormUserFreeTrialAgreementService } from './form-user-free-trial-agreement.service';

@NgModule({
  declarations: [FormUserFreeTrialAgreementComponent],
  imports: [
    CommonModule,
    FormsModule,
    TransectFormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    DialogModalModule,
    TransectButtonsModule,
    DetectScrollToEndModule,
    SharedModule,
    BtnLoaderModule,
  ],
  providers: [FormUserFreeTrialAgreementService],
  exports: [FormUserFreeTrialAgreementComponent],
})
export class FormUserFreeTrialAgreementModule {}
