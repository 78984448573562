import { z } from 'zod';
import { ReportAnnotationCreationType } from '../annotation-creation-type';

export const GetAnnotationsCountQuery = z.object({
  creationTypes: z
    .string()
    .optional()
    .transform(
      (arg) => arg && (JSON.parse(arg) as ReportAnnotationCreationType[]),
    ),
});

export type GetAnnotationsCountQuery = z.infer<typeof GetAnnotationsCountQuery>;
