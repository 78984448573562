import { z } from 'zod';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { SpeciesObject } from '../species-object';

export const SpeciesGeneratePreviewDTO = SpeciesObject.extend({
  preview: GeometrySchema.nullable(),
  bbox: GeometrySchema.nullable(),
});

export type SpeciesGeneratePreviewDTO = z.infer<
  typeof SpeciesGeneratePreviewDTO
>;
