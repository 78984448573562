import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { TransectFile } from '../transect-file/transect-file';
import { UserDTO } from '../users';

export const CatalogItemRequestStatus = z.enum([
  'submitted',
  'processing',
  'complete',
  'archived',
]);

export type CatalogItemRequestStatus = z.infer<typeof CatalogItemRequestStatus>;

export const CatalogItemRequest = z.object({
  _id: z.string().uuid().optional().nullable(),
  created_at: DateSchema.optional().nullable(),
  updated_at: DateSchema.optional().nullable(),
  deleted_at: DateSchema.optional().nullable(),
  name: z.string(),
  status: CatalogItemRequestStatus,
  description: z.string().optional().nullable(),
  additional_requests: z.string().optional().nullable(),
  creator__id: z.string(),
  attachment: TransectFile.partial(),
  catalog_item__id: z.string().optional().nullable(),
  catalog_item_url: z.string().optional().nullable(),
  creator: UserDTO.partial().optional(),
  proprietary: z.boolean().default(false),
});

export type CatalogItemRequest = z.infer<typeof CatalogItemRequest>;
