import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  Provider,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LegacyTooltipPosition as TooltipPosition } from '@angular/material/legacy-tooltip';

const TOGGLE_BTN_CONTROL_VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ToggleButtonComponent),
  multi: true,
};

@Component({
  selector: 'transect-nx-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
  providers: [TOGGLE_BTN_CONTROL_VALUE_ACCESSOR],
})
export class ToggleButtonComponent implements ControlValueAccessor, OnChanges {
  @Input() togglingTooltip?: string;
  @Input() togglingTooltipPosition?: TooltipPosition;
  @Input() toggling = false;
  @Input() group?: 'center' | 'left' | 'right';
  @Input() toggled? = false;
  @Input() disabled = false;
  @Input() ariaLabel?: string;
  @Input() buttonStyle: 'contained' | 'flat' | 'outlined' = 'contained';
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() disableStateChange = false;
  @Input() customColors?: {
    on?: string;
    off?: string;
  };
  @Input() customSize?: {
    width: number;
    height: number;
    fontSize: number;
  };
  @Input() iconOnly = true;

  onStyleColor: string | undefined;
  offStyleColor: string | undefined;

  onChange = (e: boolean) => {
    return;
  };
  onTouch = () => {
    return;
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (this.customColors?.on) {
      this.onStyleColor = this.customColors.on;
    }

    if (this.customColors?.off) {
      this.offStyleColor = this.customColors.off;
    }
  }

  writeValue(toggled: boolean): void {
    this.toggled = toggled;
  }
  registerOnChange(fn: (e: boolean) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onModelChange(e: boolean): void {
    if (!this.disableStateChange) {
      this.writeValue(e);
    }
    this.onChange(e);
  }
}
