import { z } from 'zod';
import { SafeGeometrySchema } from '../../../utils/geometry-object-schema';
import { safeNumber } from '../../../utils/string-to-number';

export const FetchParcelFilterSpecificParcelsResponseDTO = z.array(
  z.object({
    _id: z.string().uuid(),
    size_acres: safeNumber,
    nda_acres: safeNumber.nullish(),
    geometry: SafeGeometrySchema,
  }),
);
export type FetchParcelFilterSpecificParcelsResponseDTO = z.infer<
  typeof FetchParcelFilterSpecificParcelsResponseDTO
>;
