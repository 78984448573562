import { ShowAgainstPermitQuery } from '@transect-nx/data-transfer-objects';
import { environment } from '../../environments/environment';

const Permit = {
  getAll: () => `${environment.apiUrl}/protected/permits`,
  getById: (permitId: string) =>
    `${environment.apiUrl}/protected/permits/${permitId}`,
  post: () => `${environment.apiUrl}/protected/permits`,
  putById: (permitId: string) =>
    `${environment.apiUrl}/protected/permits/${permitId}`,
  cloneById: (permitId: string) =>
    `${environment.apiUrl}/protected/permits/${permitId}/clone`,
  deleteById: (permitId: string) =>
    `${environment.apiUrl}/protected/permits/${permitId}`,
};

const PermitReference = {
  post: () => `${environment.apiUrl}/permit-references`,
  getByPermitId: (permitId: string) =>
    `${environment.apiUrl}/permit-references?permit__id=${permitId}`,
  deleteByPermitReferenceId: (permitReferenceId: string) =>
    `${environment.apiUrl}/permit-references/${permitReferenceId}`,
};

const RegulatorPermit = {
  post: () => `${environment.apiUrl}/regulator-permits`,
  getRegulatorsByPermitId: (permitId: string) =>
    `${environment.apiUrl}/regulator-permits/permits/${permitId}/regulators`,
  deleteById: (id: string) => `${environment.apiUrl}/regulator-permits/${id}`,
  getByPermitId: (permitId: string) =>
    `${environment.apiUrl}/regulator-permits/permits/${permitId}`,
};

const ReportPart = {
  getById: (id: string) => `${environment.apiUrl}/report-parts/${id}`,
  lastUpdateById: (id: string) =>
    `${environment.apiUrl}/report-parts/${id}/last-update`,
  getReportParts: () => `${environment.apiUrl}/report-parts`,
  generateReportPart: () => `${environment.apiUrl}/report-parts`,
  saveReportPart: (id: string) => `${environment.apiUrl}/report-parts/${id}`,
  refreshReportPartsByUser: (reportId: string) =>
    `${environment.apiUrl}/report-parts/refresh-by-user?report-id=${reportId}`,
  deleteReportPart: (id: string) => `${environment.apiUrl}/report-parts/${id}`,
};

const VisionProject = {
  post: () => '/api/vision-projects',
  deleteById: (id: string) => `/api/vision-projects/${id}`,
};

const CustomerUser = {
  patch: (id: string) => `${environment.apiUrl}/customer-users/${id}`,
};

const CustomerInvite = {
  post: () => `${environment.apiUrl}/customer-invites`,
  delete: (id: string) => `${environment.apiUrl}/customer-invites/${id}`,
};

const ReportExport = {
  post: () => '/api/report-exports',
};

const Reference = {
  create: `${environment.apiUrl}/protected/references`,
  show: (id: string) => {
    return `${environment.apiUrl}/protected/references/${id}`;
  },
  index: `${environment.apiUrl}/protected/references`,
  update: (id: string) => {
    return `${environment.apiUrl}/protected/references/${id}`;
  },
  destroy: (id: string) => {
    return `${environment.apiUrl}/protected/references/${id}`;
  },
  associations: (id: string) =>
    `${environment.apiUrl}/protected/references/${id}/associations`,
};

const Regulator = {
  base: (isProtected = true) => {
    return `${environment.apiUrl}/${isProtected ? 'protected/' : ''}regulators`;
  },
};

export const ApiUrls = {
  Permit,
  PermitReference,
  RegulatorPermit,
  ReportPart,
  VisionProject,
  CustomerUser,
  CustomerInvite,
  ReportExport,
  Reference,
  Regulator,
};
