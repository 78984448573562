import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import {
  MatLegacySnackBarModule as MatSnackBarModule,
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { FilterTextFieldModule, TransectButtonsModule } from '@transect-nx/ui';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MomentModule } from 'ngx-moment';
import { QuillModule } from 'ngx-quill';
import { SkeletonLoadingModule } from '../modules/skeleton-loading/skeleton-loading.module';
import { UserProfilePhotoModule } from '../modules/user-profile-photo/user-profile-photo.module';
import { AsyncSearchSelectComponent } from './components/async-search-select/async-search-select.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CommentBoxV2Component } from './components/comment-box-v2/comment-box-v2.component';
import { CommentBoxComponent } from './components/comment-box/comment-box.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { DeleteForbiddenProjectReportWithAddonsModalComponent } from './components/delete-forbidden-project-report-with-addons-modal/delete-forbidden-project-report-with-addons-modal.component';
import { FileLinkCellRendererComponent } from './components/file-link-cell-renderer/file-link-cell-renderer.component';
import { HelpdocLinkComponent } from './components/helpdoc-link/helpdoc-link.component';
import { LinkCellRendererComponent } from './components/link-cell-renderer/link-cell-renderer.component';
import { LinkFieldComponent } from './components/link-field/link-field.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ProgressComponent } from './components/progress/progress.component';
import { SelectInputCellRendererComponent } from './components/select-input-cell-renderer/select-input-cell-renderer.component';
import { SnackbarAlertComponent } from './components/snackbar-alert/snackbar-alert.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UiRouterLinkCellRendererComponent } from './components/ui-router-link-cell-renderer/ui-router-link-cell-renderer.component';
import { DisableDirective } from './directives/disable/disable.directive';
import { DraggableWindowDirective } from './directives/draggable-window/draggable-window.directive';
import { FormLoaderDirective } from './directives/form-loader/form-loader.directive';
import { FormatInputNumberWithCommasDirective } from './directives/format-input-number-comma/format-input-number-comma';
import { CurrentYearPipe } from './pipes/current-year.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { IsEqualOrBeforePipe } from './pipes/is-equal-or-before.pipe';
import { IsNullishPipe } from './pipes/is-nullish.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PluralizeDaysPipe } from './pipes/pluralize-days.pipe';
import { RemoveUnderscorePipe } from './pipes/remove-underscore.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { ReportOverrideLogToStringPipe } from './pipes/report-override-log-to-string.pipe';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { SpeciesAssessmentPipe } from './pipes/species-assessment.pipe';
import { SpeciesNotLikelyToOccurReasonsPipe } from './pipes/species-not-likely-to-occur-reasons.pipe';
import { SpeciesStateListingStatusPipe } from './pipes/species-state-listing-status.pipe';
import { SpeciesTransectAssessmentPipe } from './pipes/species-transect-assessment.pipe';
import { WithoutPipe } from './without.pipe';

@NgModule({
  declarations: [
    ReportOverrideLogToStringPipe,
    ConfirmationDialogComponent,
    SpinnerComponent,
    LinkCellRendererComponent,
    FileLinkCellRendererComponent,
    AsyncSearchSelectComponent,
    DisableDirective,
    SnackbarAlertComponent,
    BreadcrumbComponent,
    LinkFieldComponent,
    CommentBoxComponent,
    UiRouterLinkCellRendererComponent,
    ReplacePipe,
    FilterPipe,
    OrderByPipe,
    WithoutPipe,
    SelectInputCellRendererComponent,
    ProgressComponent,
    ProgressSpinnerComponent,
    RemoveUnderscorePipe,
    HelpdocLinkComponent,
    SpeciesStateListingStatusPipe,
    SpeciesTransectAssessmentPipe,
    SpeciesAssessmentPipe,
    SpeciesNotLikelyToOccurReasonsPipe,
    FormLoaderDirective,
    DraggableWindowDirective,
    SearchHighlightPipe,
    CurrentYearPipe,
    IsNullishPipe,
    IsEqualOrBeforePipe,
    FormatInputNumberWithCommasDirective,
    CommentBoxV2Component,
    PluralizeDaysPipe,
    DeleteForbiddenProjectReportWithAddonsModalComponent,
  ],
  imports: [
    CommonModule,
    AgGridModule.withComponents([
      UiRouterLinkCellRendererComponent,
      LinkCellRendererComponent,
      FileLinkCellRendererComponent,
      SelectInputCellRendererComponent,
    ]),
    QuillModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatExpansionModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSliderModule,
    OverlayModule,
    MatCheckboxModule,
    MatIconModule,
    MatTabsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatChipsModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCardModule,
    MomentModule,
    MatMenuModule,
    SkeletonLoadingModule,
    TransectButtonsModule,
    UserProfilePhotoModule,
    FilterTextFieldModule,
    MatTableModule,
  ],
  exports: [
    FormLoaderDirective,
    ProgressComponent,
    ProgressSpinnerComponent,
    ConfirmationDialogComponent,
    SpinnerComponent,
    DisableDirective,
    AgGridModule,
    QuillModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatSlideToggleModule,
    MatExpansionModule,
    AsyncSearchSelectComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatSliderModule,
    OverlayModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatTooltipModule,
    SnackbarAlertComponent,
    MatChipsModule,
    LinkFieldComponent,
    MatCardModule,
    BreadcrumbComponent,
    CommentBoxComponent,
    ReplacePipe,
    FilterPipe,
    OrderByPipe,
    MomentModule,
    WithoutPipe,
    RemoveUnderscorePipe,
    HelpdocLinkComponent,
    SpeciesStateListingStatusPipe,
    SpeciesTransectAssessmentPipe,
    SpeciesAssessmentPipe,
    SpeciesNotLikelyToOccurReasonsPipe,
    DraggableWindowDirective,
    SearchHighlightPipe,
    ReportOverrideLogToStringPipe,
    CurrentYearPipe,
    IsNullishPipe,
    IsEqualOrBeforePipe,
    FormatInputNumberWithCommasDirective,
    FilterTextFieldModule,
    MatTableModule,
    CommentBoxV2Component,
    PluralizeDaysPipe,
  ],
  providers: [
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      },
    },
    MatDatepickerModule,
    DecimalPipe,
    RemoveUnderscorePipe,
    ReportOverrideLogToStringPipe,
  ],
})
export class SharedModule {}
