import { z } from 'zod';
import { DistributionLinesDTO } from './distribution-line.dto';

export const CreateDistributionLineRequestDTO = z.object({
  notes: z.string().nullable(),
  distribution_lines: DistributionLinesDTO,
});

export type CreateDistributionLineRequestDTO = z.infer<
  typeof CreateDistributionLineRequestDTO
>;
