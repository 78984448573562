import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { FormDTO } from '@transect-nx/data-transfer-objects';
import {
  debounceTime,
  EMPTY,
  filter,
  map,
  Observable,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import { FormModalInputData } from '../../../../models/form-modal.service';
import { AddOnThreatenedAndEndangeredSpeciesStepDetailsComponent } from '../add-on-threatened-and-endangered-species-step-details/add-on-threatened-and-endangered-species-step-details.component';

@Component({
  templateUrl:
    './form-threatened-and-endangered-species-survey-scope-of-work.component.html',
  styleUrls: [
    './form-threatened-and-endangered-species-survey-scope-of-work.component.scss',
  ],
  selector: 'ts-threatened-and-endangered-species-survey-scope-of-work-form',
})
export class FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkComponent
  implements AfterViewInit
{
  @ViewChild(AddOnThreatenedAndEndangeredSpeciesStepDetailsComponent)
  formComponent?: AddOnThreatenedAndEndangeredSpeciesStepDetailsComponent;

  get formStateService() {
    return this.data.stateService;
  }

  view = {
    state: {
      canSave$: this.formStateService.canSave$.pipe(debounceTime(100)),
      completing$: this.formStateService.completing$,
      inProgress$: this.formStateService.inProgress$,
      isComplete$: this.formStateService.isComplete$,
      isFetchingPdf$: this.formStateService.isFetchingPdf$,
      saveButtonColor$: this.formStateService.saveButtonColor$,
      loadingPlaceholderText: 'Loading information',
      viewedOption: 1,
    },
    actions: [] as Observable<void>[],
  };

  constructor(
    private dialogRef: MatDialogRef<FormThreatenedAndEndangeredSpeciesSurveyScopeOfWorkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FormModalInputData,
  ) {}

  ngAfterViewInit(): void {
    const dispatchFormValueChanges$ =
      this.formComponent.scopeOfWorkForm?.valueChanges.pipe(
        filter(() => this.formComponent.scopeOfWorkForm?.dirty),
        switchMap(() => {
          return this.formStateService.dispatchFormValueChangeAction(
            this.formComponent.scopeOfWorkForm.valid,
            this.formComponent.scopeOfWorkForm.getRawValue() as FormDTO['contents'],
            this.data.formTypeId,
            this.data.addOnId,
          );
        }),
        map(() => {
          return;
        }),
      );

    this.view.actions.push(dispatchFormValueChanges$ ?? EMPTY);

    if (this.formStateService.stateValue.formValue) {
      this.formComponent.scopeOfWorkForm.patchValue(
        this.formStateService.stateValue.formValue,
      );

      const updateViewedOption$ = timer(0).pipe(
        tap(() => {
          this.view.state.viewedOption = this.formComponent.scopeOfWorkForm.get(
            'selectedOption',
          ).value as number;
        }),
        map(() => {
          return;
        }),
      );

      this.view.actions.push(updateViewedOption$);

      this.formStateService.dispatchFormValidAction(
        !this.formComponent.scopeOfWorkForm.invalid,
      );
    }

    if (this.formStateService.stateValue.isComplete || this.data.readOnly) {
      this.formComponent.scopeOfWorkForm.disable();
    }
  }

  handleScrollToEnd() {
    this.formStateService.dispatchScrolledToEndAction();
  }

  handleSaveClick() {
    if (!this.formComponent.scopeOfWorkForm.valid) {
      return;
    }
    this.formStateService.dispatchCompleteFormAction().subscribe(() => {
      this.dialogRef.close();
    });
  }

  handleDownloadPdfClick() {
    this.formStateService.dispatchPdfDownloadAction().subscribe();
  }
}
