import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfilePhotoComponent } from './user-profile-photo.component';
import { TransectFileImageModule } from '../../modules/transect-file-image/transect-file-image.module';

@NgModule({
  declarations: [UserProfilePhotoComponent],
  imports: [CommonModule, TransectFileImageModule],
  exports: [UserProfilePhotoComponent],
})
export class UserProfilePhotoModule {}
