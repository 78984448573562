import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';

export const ReferencesAssociationsQueryDTO = z.object({
  page: pageNumber,
  pageSize: pageSize.default('100'),
  sortBy: sortModel.optional(),
});

export type ReferencesAssociationsQueryDTO = z.infer<
  typeof ReferencesAssociationsQueryDTO
>;
