import { z } from 'zod';
import { DateFilter, TextFilter } from '../../../utils/aggrid-filters';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { jsonSchema } from '../../../utils/string-to-json';

export const CatalogLocationPaginatedFilterModel = jsonSchema(
  z.object({
    name: TextFilter.optional(),
    description: TextFilter.optional(),
    created_at: DateFilter.optional(),
    updated_at: DateFilter.optional(),
  }),
);

export type CatalogLocationPaginatedFilterModel = z.infer<
  typeof CatalogLocationPaginatedFilterModel
>;

export const CatalogLocationControllerPaginatedQueryParams = z.object({
  catalogItemId: z.string().uuid().optional(),
  geometryOnly: z.preprocess((val) => val === 'true', z.boolean()),
  page: pageNumber,
  pageSize: pageSize.default('200'),
  sortModel: sortModel.optional(),
  filterModel: CatalogLocationPaginatedFilterModel.optional(),
});

export type CatalogLocationControllerPaginatedQueryParams = z.infer<
  typeof CatalogLocationControllerPaginatedQueryParams
>;
