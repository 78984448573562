import { z } from 'zod';
import { stringToBoolean } from '../../../utils/string-to-boolean';
export const GetProblematicLCIParcelsCountOnMapQueryDTO = z.object({
  combineParcelsByOwner: stringToBoolean.default('false'),
});
export type GetProblematicLCIParcelsCountOnMapQueryDTO = z.infer<
  typeof GetProblematicLCIParcelsCountOnMapQueryDTO
>;

export const GetProblematicLCIParcelCountResponseDTO = z.object({
  count: z.number(),
});

export type GetProblematicLCIParcelCountResponseDTO = z.infer<
  typeof GetProblematicLCIParcelCountResponseDTO
>;
