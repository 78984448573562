import { z } from 'zod';
import { ReportAnnotationDTO } from '../report-annotation-dto';
export const CreateAnnotationPayload = ReportAnnotationDTO.partial({
  _id: true,
  created_at: true,
  updated_at: true,
  creator__id: true,
  object__id: true,
  object__type: true,
});
export type CreateAnnotationPayload = z.infer<typeof ReportAnnotationDTO>;
