import { z } from 'zod';

export const TransectSummarySchema = z
  .object({
    location: z.enum(['aoi', 'buffer', 'external', 'anonymized']),
    bearing: z.number().optional(),
    distance: z
      .object({
        miles: z.number(),
        feet: z.number(),
      })
      .optional(),
    area: z
      .object({
        meters: z.number(),
        feet: z.number(),
        miles: z.number(),
        acres: z.number(),
      })
      .optional(),
    length: z
      .object({
        miles: z.number(),
        feet: z.number(),
      })
      .optional(),
    center: z
      .object({
        type: z.literal('Feature'),
        geometry: z
          .object({
            type: z.literal('Point'),
          })
          .passthrough(),
      })
      .passthrough()
      .optional()
      .nullable(),
  })
  .passthrough();
