import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const WatershedProperties = z.object({
  huc8: z.string(),
  name: z.string(),
  tnmid: z.string(),
  states: z.string(),
  areasqkm: z.string(),
  globalid: z.string(),
  loaddate: z.string(),
  objectid: z.number(),
  areaacres: z.string(),
  shape_area: z.number(),
  metasourceid: z.string(),
  shape_length: z.number(),
  sourcedatadesc: z.string(),
  sourcefeatureid: z.string(),
  sourceoriginator: z.string(),
  referencegnis_ids: z.string(),
});

export const ReportPartWatershedFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: WatershedProperties.deepPartial(),
  has_intersection_aoi: z.boolean(),
  has_intersection_buffer: z.boolean(),
  geometry: GeometrySchema,
  source__id: z.string().uuid(),
  transect_feature_type: z.literal('watershed'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartWatershedFeatureDTO = z.infer<
  typeof ReportPartWatershedFeatureDTO
>;
