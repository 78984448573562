import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { TriggerGroupDTO } from '../trigger';
import { UserDTO } from '../users';
export const SpeciesCharacteristicsDTO = z.object({
  value: z.string(), // could have provided an enum here, not providing to avoid any unexpected errors
  operator: z.union([z.literal('includes'), z.literal('eq')]),
  attribute: z.union([
    z.literal('type'),
    z.literal('habitat_characteristics'),
    z.literal('federal_listing_status'),
    z.literal('family'),
    z.literal('group'),
  ]),
});

export type SpeciesCharacteristicsDTO = z.infer<
  typeof SpeciesCharacteristicsDTO
>;

export const SpeciesTriggerDTO = z.object({
  name: z.string().nullable(),
  description: z.string().nullable(),
  properties: z.any().nullable(),
  species_characteristics: z
    .array(SpeciesCharacteristicsDTO)
    .nullable()
    .default([]),
  active: z.boolean().default(true),
  triggers: z.any().nullable().default(null),
  placeholder_only: z.boolean().default(false),
  creator: UserDTO.nullable(),
  created_at: DateSchema.optional().nullable().default(null),
  updated_at: DateSchema.optional().nullable().default(null),
  deleted_at: DateSchema.optional().nullable().default(null),
});

export type SpeciesTriggerDTO = z.infer<typeof SpeciesTriggerDTO>;

export const CreateSpeciesTriggerDTO = SpeciesTriggerDTO.deepPartial()
  .omit({
    creator: true,
  })
  .extend({
    name: z.string(),
  });

export type CreateSpeciesTriggerDTO = z.infer<typeof CreateSpeciesTriggerDTO>;

export const UpdateSpeciesTriggerDTO = CreateSpeciesTriggerDTO.extend({
  name: z.string().nullish(),
});
