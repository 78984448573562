<div
  *ngIf="shouldCloseOnBackdropClick"
  (click)="handleBackdropClicked()"
  [class.open]="isOpen"
  class="flyover-backdrop"
></div>
<div
  [class.open]="isOpen"
  class="flyover-container"
  [ngStyle]="{ minWidth: minWidth, maxWidth: maxWidth }"
  #flyoverContainer
>
  <ng-container *ngIf="template">
    <ng-container *ngTemplateOutlet="template"></ng-container>
  </ng-container>
</div>
