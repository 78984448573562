import { z } from 'zod';

export const CustomerUserDTO = z.object({
  _id: z.string().uuid(),
  email: z.string().email(),
  role: z.string(),
  fullname: z.string().nullable(),
  firstname: z.string().nullable(),
  lastname: z.string().nullable(),
  organization: z.string().nullable(),
  public: z.object({
    role: z.string(),
    fullname: z.string().nullable(),
    firstname: z.string().nullable(),
    lastname: z.string().nullable(),
  }),
  title: z.string().nullable(),
  customer_user: z.object({
    user__id: z.string().uuid(),
    customer__id: z.string().uuid(),
    role: z.string(),
    _id: z.string().uuid(),
  }),
  active: z.boolean(),
});

export type CustomerUserDTO = z.infer<typeof CustomerUserDTO>;
