import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, TransectButtonsModule } from '@transect-nx/ui';
import { EventStreamService } from '../../services/event-stream.service';
import { UpgradeRequestDialogComponent } from './components/upgrade-request-dialog.component';
import { UpgradePromptComponent } from './upgrade-prompt.component';
import { UpgradePromptService } from './upgrade-prompt.service';

@NgModule({
  declarations: [UpgradePromptComponent, UpgradeRequestDialogComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    TransectButtonsModule,
  ],
  providers: [EventStreamService, UpgradePromptService],
})
export class UpgradePromptModule {}
