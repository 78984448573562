import { z } from 'zod';
import { PaginatedResponseRows } from '../../../utils/paginated-response-rows';
import { ReportPartLandcoverFeatureDTO } from '../models/report-part-landcover-feature.dto';

export const PaginatedReportPartLandcoverFeaturesDTO = PaginatedResponseRows(
  ReportPartLandcoverFeatureDTO,
);

export type PaginatedReportPartLandcoverFeaturesDTO = z.infer<
  typeof PaginatedReportPartLandcoverFeaturesDTO
>;
