import { z } from 'zod';
import { LandownerBatchDataRequestDTO } from '../landowner-batch-data-request';

export const LandownerBatchDataMetaDTO = z
  .object({
    apiVersion: z.string().optional(),
    performance: z
      .object({
        startTime: z.string().optional(),
        endTime: z.string().optional(),
        totalRequestTime: z.number().optional(),
      })
      .optional(),
    results: z.object({
      requestCount: z.number(),
      matchCount: z.number(),
      noMatchCount: z.number(),
      errorCount: z.number(),
    }),
    requestId: z.string().optional(),
  })
  .passthrough();

export type LandownerBatchDataMetaDTO = z.infer<
  typeof LandownerBatchDataMetaDTO
>;

export const LandownerBatchDataPhoneNumberDTO = z
  .object({
    number: z.string().optional(),
    dnc: z.boolean().optional(),
  })
  .passthrough();

export type LandownerBatchDataPhoneNumberDTO = z.infer<
  typeof LandownerBatchDataPhoneNumberDTO
>;

export const LandownerBatchDataPropertyOwnerNameDTO = z.object({
  first: z.string().optional(),
  middle: z.string().optional(),
  last: z.string().optional(),
});

export type LandownerBatchDataPropertyOwnerNameDTO = z.infer<
  typeof LandownerBatchDataPropertyOwnerNameDTO
>;

export const LandownerBatchDataPersonDTO = z
  .object({
    _id: z.string().optional(),
    dnc: z
      .object({
        tcpa: z.boolean(),
      })
      .optional()
      .default({
        tcpa: false,
      }),
    litigator: z.boolean().optional().default(false),
    emails: z.array(z.object({ email: z.string() })).min(0),
    name: LandownerBatchDataPropertyOwnerNameDTO,
    phoneNumbers: z.array(LandownerBatchDataPhoneNumberDTO).min(0),
    request: LandownerBatchDataRequestDTO,
    meta: z
      .object({
        matched: z.boolean().optional(),
        error: z.boolean().optional(),
        errorMessage: z.string().optional(),
      })
      .passthrough(),
  })
  .passthrough();

export type LandownerBatchDataPersonDTO = z.infer<
  typeof LandownerBatchDataPersonDTO
>;

export const LandownerBatchDataResponseDTO = z.object({
  persons: z.array(LandownerBatchDataPersonDTO),
  meta: LandownerBatchDataMetaDTO.optional(),
});

export type LandownerBatchDataResponseDTO = z.infer<
  typeof LandownerBatchDataResponseDTO
>;
