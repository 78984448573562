import { z } from 'zod';
import { stringToNumber } from '../../utils/string-to-number';
import { ProjectTypeDTO } from '../projects/project-type.dto';

export const ReportConcernLevel = z.object({
  note: z.string().nullish(),
  public: z.enum(['high', 'moderate', 'low', 'unknown']).nullish(),
  automated: z.enum(['high', 'moderate', 'low', 'unknown']).nullish(),
  overriden: z.boolean().nullish(),
  user_overridden_by: z.string().uuid().nullish(),
  user_overridden_date: z.string().nullish(),
  user_overridden_note: z.string().nullish(),
  user_overridden_value: z.enum(['low', 'moderate', 'high']).nullish(),
});

export type ReportConcernLevel = z.infer<typeof ReportConcernLevel>;

export const ReportContext = z.object({
  expert_review: z.enum(['y', 'n']).optional(),
  federal_funds: z.enum(['y', 'n', 'unknown']).optional(),
  project_type: ProjectTypeDTO.optional(),
  project_subtype: z.string().optional(),
  mixed_use_subtype: z
    .union([
      z.array(z.string()),
      z
        .string()
        .refine((val) => val === '')
        .transform(() => []),
      z.null(),
    ])
    .nullish(),
  project_type_description: z.string().optional(),
  construction_type: z.string().optional(),
  plan_to_impact_waters: z.enum(['y', 'n', 'unknown']).optional(),
  project_size_megawatts: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .optional(),
  construction_year: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .optional(),
  meets_labor_requirements: z.enum(['y', 'n']).optional(),
  meets_domestic_content_requirements: z.enum(['y', 'n']).optional(),
  total_project_cost: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .nullish(),
  estimated_power_output: z
    .preprocess((val) => stringToNumber(val as string), z.number())
    .nullish(),
  buffer_width_feet: z.number().nullish(),
});
export type ReportContext = z.infer<typeof ReportContext>;

export const ReportPlugin = z.object({
  data: z.string(),
  html: z.string().nullish(),
  type: z.string().nullish(),
  enabled: z.preprocess((val) => (val === null ? false : val), z.boolean()),
  frameUrl: z.string().url().nullish(),
  imageUrl: z.string().url().nullish(),
  layers: z
    .array(z.object({ name: z.string(), geojson: z.string() }))
    .nullish(),
});
