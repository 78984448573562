import { z } from 'zod';
import {
  ElectricGeneratorDataCategory,
  ElectricGeneratorDataTechnology,
} from '@transect-nx/models';
import { Sentiment } from '../../utils';
import { GeometrySchema } from '../../utils/geometry-object-schema';

export const GetElectricGeneratorStateDataParams = z.object({
  projectId: z.string().uuid().nullish(),
  parcelFilterId: z.string().uuid().nullish(),
  geojsonString: z.string().nullish(),
  fetchExpiredMoratoriums: z.boolean().default(false),
  technology: z.nativeEnum(ElectricGeneratorDataTechnology),
});

export type GetElectricGeneratorStateDataParams = z.infer<
  typeof GetElectricGeneratorStateDataParams
>;

export const ElectricGeneratorStateDataDTO = z.object({
  _id: z.string().uuid(),
  distance_in_miles: z.number(),
  entity_id: z.number().nullish(),
  entity_name: z.string().nullish(),
  plant_id: z.number().nullish(),
  plant_name: z.string().nullish(),
  plant_state: z.string().nullish(),
  plant_county: z.string().nullish(),
  balancing_authority_code: z.string().nullish(),
  sector: z.string().nullish(),
  nameplate_capacity_mw: z.number().nullish(),
  operating_month: z.number().nullish(),
  operating_year: z.number().nullish(),
  planned_operation_month: z.number().nullish(),
  planned_operation_year: z.number().nullish(),
  status: z.string().nullish(),
  latitude: z.number().nullish(),
  longitude: z.number().nullish(),
  maintenance_flag: z.boolean().nullish(),
  category: z.nativeEnum(ElectricGeneratorDataCategory).nullish(),
  technology: z.nativeEnum(ElectricGeneratorDataTechnology).nullish(),
  source: z.string().nullish(),
  contested_project__id: z.string().uuid().nullable(),
  geometry: GeometrySchema.nullish(),
});

export type ElectricGeneratorStateDataDTO = z.infer<
  typeof ElectricGeneratorStateDataDTO
>;

export const ContestedProjectsDTO = z.object({
  _id: z.string().uuid(),
  project_name: z.string(),
  developer: z.string().nullable(),
  estimated_capacity: z.number().nullable(),
  estimated_size: z.number().nullable(),
  description: z.string().nullable(),
  sources: z.array(z.string()).nullable().default([]),
});

export type ContestedProjectsDTO = z.infer<typeof ContestedProjectsDTO>;

export const ElectricGeneratorStateCountyDTO = z.object({
  name: z.string(),
  id: z.string().uuid().nullish(),
  electric_generator_data: z.array(ElectricGeneratorStateDataDTO),
  contested_projects: z.array(ContestedProjectsDTO).nullish().default([]),
  sentiment_score: z.number(),
  sentiment: Sentiment,
});

export type ElectricGeneratorStateCountyDTO = z.infer<
  typeof ElectricGeneratorStateCountyDTO
>;

export const GetElectricGeneratorStateDataResponse = z.array(
  z.object({
    state: z.string(),
    state_abbreviation: z.string(),
    geography__id: z.string(),
    counties: z.array(ElectricGeneratorStateCountyDTO),
  }),
);

export type GetElectricGeneratorStateDataResponse = z.infer<
  typeof GetElectricGeneratorStateDataResponse
>;
