import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { take } from 'rxjs/operators';
import {
  FormModalInputData,
  IFormModalService,
} from '../../models/form-modal.service';
import { FormMarketplaceAgreementComponent } from './form-marketplace-agreement.component';

@Injectable()
export class FormMarketplaceAgreementService implements IFormModalService {
  private marketplaceAgreementModalRef: MatDialogRef<FormMarketplaceAgreementComponent>;

  constructor(private dialog: MatDialog) {}

  open(data: FormModalInputData) {
    if (this.marketplaceAgreementModalRef) {
      this.marketplaceAgreementModalRef.close({});
    }

    this.marketplaceAgreementModalRef = this.dialog.open<
      FormMarketplaceAgreementComponent,
      FormModalInputData
    >(FormMarketplaceAgreementComponent, {
      width: '36vw',
      height: '70vh',
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'form-marketplace-agreement-modal',
      data,
    });

    return this.marketplaceAgreementModalRef.afterClosed().pipe(take(1));
  }
}
