import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, map } from 'rxjs';

@Component({
  selector: 'ts-add-on-threatened-and-endangered-species-step-details',
  templateUrl:
    './add-on-threatened-and-endangered-species-step-details.component.html',
  styleUrls: [
    './add-on-threatened-and-endangered-species-step-details.component.scss',
  ],
})
export class AddOnThreatenedAndEndangeredSpeciesStepDetailsComponent
  implements AfterViewInit, OnChanges
{
  private optionTitles = [
    'Desktop Habitat Assessment Review and Technical Memo',
    'Onsite Habitat Assessment and Reporting',
    'Presence / Absence Survey and Reporting',
  ];

  /**
   * The scope of work option to display. (Not selected just viewed)
   */
  @Input() displayOption = 1;

  /**
   * The scope of work option display changed. (Not selected just view change)
   */
  @Output() displayOptionChange = new EventEmitter<number>();
  @Input() disabled = false;

  /**
   * The scope of work option selected by the user.
   */
  @Input() selectedOption: { number: number; title: string } | null = null;

  /**
   * The scope of work option selected by the user changed.
   */
  @Output() selectedOptionChange = new EventEmitter<{
    number: number;
    title: string;
  } | null>();

  scopeOfWorkForm = new UntypedFormGroup({
    selectedOption: new UntypedFormControl(null, [Validators.required]),
  });

  selectedOption$ = this.scopeOfWorkForm.valueChanges.pipe(
    debounceTime(100),
    map((value: { selectedOption: number }) => value.selectedOption),
  );

  ngAfterViewInit(): void {
    if (this.selectedOption?.number) {
      this.scopeOfWorkForm.patchValue({
        selectedOption: this.selectedOption.number,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displaySelectedOption) {
      this.displayOption = this.displayOption ?? 1;
      this.displayOptionChange.emit(this.displayOption);
    }
  }

  navigateLeft() {
    if (this.displayOption > 1) {
      this.displayOption--;
      this.displayOptionChange.emit(this.displayOption);
    }
  }

  navigateRight() {
    if (this.displayOption < 3) {
      this.displayOption++;
      this.displayOptionChange.emit(this.displayOption);
    }
  }

  selectOption() {
    this.scopeOfWorkForm.markAsDirty();
    this.scopeOfWorkForm.patchValue({
      selectedOption: this.displayOption,
    });

    this.selectedOptionChange.emit({
      number: this.displayOption,
      title: this.optionTitles[this.displayOption - 1],
    });
  }
}
