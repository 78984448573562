import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TransectButtonsModule } from '../transect-buttons/transect-buttons.module';
import { SimpleSectionHeaderComponent } from './simple-section-header.component';

@NgModule({
  imports: [CommonModule, TransectButtonsModule],
  declarations: [SimpleSectionHeaderComponent],
  exports: [SimpleSectionHeaderComponent],
})
export class SimpleSectionHeaderModule {}
