<div
  class="map-control-accordion"
  [ngStyle]="{
    width: width + 'px',
    top: position.top,
    left: position.left,
    right: position.right,
    bottom: position.bottom
  }"
>
  <ng-content></ng-content>
</div>
