import { Component, inject, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';

export enum SnackbarAlertType {
  SUCCESS,
  ERROR,
  WARN,
}
@Component({
  templateUrl: './snackbar-alert.component.html',
  styleUrls: ['./snackbar-alert.component.scss'],
  selector: 'ts-snackbar-alert',
  encapsulation: ViewEncapsulation.None,
})
export class SnackbarAlertComponent {
  message = '';
  title = '';
  actionLinkText: string;

  alertType: SnackbarAlertType;
  dismissible: boolean;
  onDismiss: () => void;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    data: {
      title: string;
      message: string;
      actionLinkText: string;
      alertType: SnackbarAlertType;
      dismissible: boolean;
      onDismiss: () => void;
    },
    public readonly snackbarRef: MatSnackBarRef<SnackbarAlertComponent>,
  ) {
    this.dismissible = data.dismissible;
    this.message = data.message;
    this.actionLinkText = data.actionLinkText;
    this.alertType = data.alertType;
    this.onDismiss = data.onDismiss;
    this.title = data.title;
  }
  get isError(): boolean {
    return this.alertType === SnackbarAlertType.ERROR;
  }
  get isSuccess(): boolean {
    return this.alertType === SnackbarAlertType.SUCCESS;
  }
  get isWarn(): boolean {
    return this.alertType === SnackbarAlertType.WARN;
  }

  handleDismiss(): void {
    if (this.onDismiss) {
      this.onDismiss();
    }
  }
}
