import { z } from 'zod';

export const SpeciesLocationsControllerQueryParams = z
  .object({
    fields_essentials: z.preprocess(
      (val) => val === 'true',
      z.boolean().optional(),
    ),
    skip_ecos: z.preprocess((val) => val === 'true', z.boolean().optional()),
    species__id: z.string().uuid(),
    geometry_only: z.preprocess(
      (val) => val === 'true',
      z.boolean().optional(),
    ),
    species_location_ids: z
      .preprocess(
        (val) => (typeof val === 'string' ? [val] : val),
        z.array(z.string().uuid()),
      )
      .optional()
      .nullable(),
  })
  .strict();

export type SpeciesLocationsControllerQueryParams = z.infer<
  typeof SpeciesLocationsControllerQueryParams
>;
