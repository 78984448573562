import { z } from 'zod';

const ConcernLevelValue = z.enum(['low', 'moderate', 'high']);

export const ReportUpdateConcernLevelBodyDTO = z
  .object({
    public: ConcernLevelValue,
    automated: ConcernLevelValue,
    overriden: z.boolean(),
    note: z.string(),
    user_overridden_value: z.string().optional(),
    user_overridden_note: z.string().optional(),
  })
  .deepPartial();

export type ReportUpdateConcernLevelBodyDTO = z.infer<
  typeof ReportUpdateConcernLevelBodyDTO
>;
