import { z } from 'zod';
import { RegulationDTO } from '../regulation-dto';

export const RegulationControllerCreateUpdateBody = RegulationDTO.omit({
  _id: true,
  nameAcronymBack: true,
  created_at: true,
  updated_at: true,
  deleted_at: true,
  geography: true,
  regulator: true,
  regulation_locations: true,
});

export type RegulationControllerCreateUpdateBody = z.infer<
  typeof RegulationControllerCreateUpdateBody
>;
