import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { ButtonComponent } from './components/button/button.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@NgModule({
  declarations: [ButtonComponent, ToggleButtonComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    FormsModule,
    OverlayModule,
    MatTooltipModule,
  ],
  exports: [ButtonComponent, ToggleButtonComponent],
})
export class TransectButtonsModule {}
