import { z } from 'zod';
import { PatchUserSelectedCatalogItemDTO } from '../user-selected-catalog-item/user-selected-catalog-item.dto';

export const PatchUserSelectedCatalogItemsDTO = z.object({
  changes: PatchUserSelectedCatalogItemDTO,
  catalogItemIds: z.array(z.string()),
});

export type PatchUserSelectedCatalogItemsDTO = z.infer<
  typeof PatchUserSelectedCatalogItemsDTO
>;
