import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { UserDTO } from '../../users';
import { CommentObjectType } from '../controller-inputs';
export const CommentsDTO = z.object({
  _id: z.string().uuid(),
  properties: z.object({ text: z.string().optional() }),
  status: z.string().default('new'),
  active: z.boolean().default(true),
  object__type: z.nativeEnum(CommentObjectType),
  object__id: z.string().uuid(),
  context__type: z.string(),
  context__id: z.string().uuid().nullable(),
  created_at: DateSchema,
  updated_at: DateSchema,
  creator: UserDTO.optional(),
  report: z.object({}).passthrough().nullable().optional(),
});
