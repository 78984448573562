import { z } from 'zod';

export const SpeciesLocationIdControllerQueryParams = z.object({
  no_geometry: z.preprocess((val) => val === 'true', z.boolean().optional()),
  geometry_only: z.preprocess((val) => val === 'true', z.boolean().optional()),
});

export type SpeciesLocationIdControllerQueryParams = z.infer<
  typeof SpeciesLocationIdControllerQueryParams
>;
