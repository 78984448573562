import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { take } from 'rxjs';
import { FormModalInputData } from '../../models/form-modal.service';
import { FormMarketplaceUserQuestionnaireComponent } from './form-marketplace-user-questionnaire.component';

@Injectable()
export class FormMarketplaceUserQuestionnaireModalService {
  constructor(private dialog: MatDialog) {}

  open(data: FormModalInputData) {
    const ref = this.dialog.open<
      FormMarketplaceUserQuestionnaireComponent,
      FormModalInputData
    >(FormMarketplaceUserQuestionnaireComponent, {
      width: '1040px',
      height: '90vh',
      panelClass: 'form-marketplace-user-questionnaire-modal',
      data,
    });
    return ref.afterClosed().pipe(take(1));
  }
}
