import { z } from 'zod';

export const DownloadReportPartFeaturesPayload = z.object({
  fileName: z
    .string()
    .transform((val) => val.replace(/ /g, '_').replace(/:/g, '')),
  layerName: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val && encodeURIComponent(val)),
});

export const DownloadSoilFeaturesPayload =
  DownloadReportPartFeaturesPayload.extend({
    type: z.enum(['farmland', 'soil']),
  });

export const DownloadSpeciesFeaturesPayload =
  DownloadReportPartFeaturesPayload.extend({
    type: z.enum(['federal', 'state']),
  });

export type DownloadReportPartFeaturesPayload = z.infer<
  typeof DownloadReportPartFeaturesPayload
>;

export type DownloadSoilFeaturesPayload = z.infer<
  typeof DownloadSoilFeaturesPayload
>;

export type DownloadSpeciesFeaturesPayload = z.infer<
  typeof DownloadSpeciesFeaturesPayload
>;
