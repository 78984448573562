import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiPillInputComponent } from './ui-pill-input/ui-pill-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
export * from './interfaces';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
  ],
  declarations: [UiPillInputComponent, SearchHighlightPipe],
  exports: [UiPillInputComponent],
})
export class UiPillInputModule {}
