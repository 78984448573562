import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ResponseRows } from '../../../utils/response-rows';
import { RelationSchema } from '../../../utils/relation';

export const MyParcelFilterDTO = z.object({
  _id: z.string().uuid(),
  name: z.string(),
  created_at: DateSchema,
  creator: z.object({
    _id: z.string().uuid(),
    fullname: z.string(),
    active: z.boolean(),
  }),
  boundary_acres: z.number().nullable(),
  customer_shared: z.boolean().default(false),
  status: z
    .enum(['requested', 'processing', 'completed', 'failed', 'cancelled'])
    .nullish(),
  pinned: z.boolean().default(false),
});
export type MyParcelFilterDTO = z.infer<typeof MyParcelFilterDTO>;

export const MineParcelFilterResponseDTO = ResponseRows(MyParcelFilterDTO);
export type MineParcelFilterResponseDTO = z.infer<
  typeof MineParcelFilterResponseDTO
>;
