import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class FlyoverModalStateService {
  closeAction = new Subject<void>();

  close$ = this.closeAction.asObservable();

  dispatchCloseAction() {
    this.closeAction.next();
  }
}
