import {
  ConnectionPositionPair,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ElementRef,
  Injectable,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { delay, fromEvent, merge, Subject, takeUntil, tap } from 'rxjs';

@Injectable()
export class MenuContainerStrategy implements OnDestroy {
  private destroy$ = new Subject<void>();

  private overlayRef?: OverlayRef | null;

  constructor(private overlay: Overlay) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  toggleMenu<T extends HTMLElement = HTMLButtonElement>(
    menuTemplate: TemplateRef<ElementRef>,
    viewContainerRef: ViewContainerRef,
    elementRef: ElementRef<T>,
  ) {
    if (this.overlayRef) {
      this.disposeOverlay();
      return;
    }

    if (!menuTemplate) {
      return;
    }

    const positions = [
      new ConnectionPositionPair(
        { originX: 'start', originY: 'bottom' },
        { overlayX: 'start', overlayY: 'top' },
      ),
      new ConnectionPositionPair(
        { originX: 'start', originY: 'top' },
        { overlayX: 'start', overlayY: 'bottom' },
      ),
      new ConnectionPositionPair(
        {
          originX: 'end',
          originY: 'bottom',
        },
        { overlayX: 'end', overlayY: 'top' },
      ),
    ];

    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(elementRef)
      .withPositions(positions);
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.close(),
      hasBackdrop: false,
      positionStrategy,
    });
    const templatePortal = new TemplatePortal(menuTemplate, viewContainerRef);
    this.overlayRef.attach(templatePortal);

    const listItems = this.overlayRef.overlayElement.querySelectorAll(
      '[transect-nx-menu-item]',
    );

    const clicksOnItems$ = Array.from(listItems).map((el) => {
      return fromEvent(el, 'click').pipe(
        tap(() => {
          this.disposeOverlay();
        }),
      );
    });

    const keyDownEvents$ = this.overlayRef.keydownEvents().pipe(
      delay(20),
      tap((event) => {
        if (event.key === 'Escape' || event.key === 'Enter') {
          this.disposeOverlay();
        }
      }),
    );

    const outsidePointerEvents$ = this.overlayRef.outsidePointerEvents().pipe(
      tap((event) => {
        if (event.target instanceof HTMLElement) {
          const closestTag = event.target?.closest(
            elementRef.nativeElement.tagName,
          );

          if (closestTag === elementRef.nativeElement && closestTag !== null) {
            event.stopPropagation();
          }
        }

        this.disposeOverlay();
      }),
    );

    const detachments$ = this.overlayRef.detachments().pipe(
      tap(() => {
        this.disposeOverlay();
      }),
    );

    merge(
      ...clicksOnItems$,
      keyDownEvents$,
      outsidePointerEvents$,
      detachments$,
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  private disposeOverlay() {
    this.overlayRef?.dispose();
    this.overlayRef = null;
    this.destroy$.next();
  }
}
