import { z } from 'zod';

export const DistributionLinesDTO = z.array(z.string()).default([]);

export type DistributionLinesDTO = z.infer<typeof DistributionLinesDTO>;

export const GetDistributionLinesParams = z.object({
  search: z.string(),
  page: z.number(),
  pageSize: z.number(),
});

export type GetDistributionLinesParams = z.infer<
  typeof GetDistributionLinesParams
>;

export const GetDistributionLinesDTO = z.object({
  distributionLines: DistributionLinesDTO,
  count: z.number(),
});

export type GetDistributionLinesDTO = z.infer<typeof GetDistributionLinesDTO>;
