import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';

export const TransectFileGCS = z.object({
  url: z.string().optional().nullable(),
  file: z.string().optional().nullable(),
  bucket: z.string().optional().nullable(),
});

export type TransectFileGCS = z.infer<typeof TransectFileGCS>;

export const TransectFile = z.object({
  _id: z.string().uuid().optional().nullable(),
  created_at: DateSchema.optional().nullable(),
  updated_at: DateSchema.optional().nullable(),
  deleted_at: DateSchema.optional().nullable(),
  name: z.string(),
  type: z.string(),
  description: z.string().optional().nullable(),
  status: z.string(),
  properties: z
    .object({
      size: z.number().optional().nullable(),
      purpose: z.string().optional().nullable(),
      mimetype: z.string().optional().nullable(),
      originalname: z.string().optional().nullable(),
    })
    .optional()
    .nullable(),
  gcs: TransectFileGCS,
  relations: z.any().optional().nullable(),
  s3: z.object({}).passthrough().nullish(),
  creator__id: z.string().uuid().nullish(),
  reference__id: z.string().uuid().nullish(),
  signedUploadUrl: z.string().nullish(),
});

export type TransectFile = z.infer<typeof TransectFile>;
