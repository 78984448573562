import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable, take } from 'rxjs';
import {
  FormModalInputData,
  IFormModalService,
} from '../../models/form-modal.service';
import { FormMarketplacePhase1EsaAmendmentComponent } from './form-marketplace-phase1esa-amendment.component';

@Injectable()
export class FormMarketPlacePhase1EsaAmendmentModalService
  implements IFormModalService
{
  constructor(private dialog: MatDialog) {}
  open<T = unknown>(data: FormModalInputData): Observable<T> {
    const ref = this.dialog.open<
      FormMarketplacePhase1EsaAmendmentComponent,
      FormModalInputData,
      T
    >(FormMarketplacePhase1EsaAmendmentComponent, {
      width: '1040px',
      height: '90vh',
      panelClass: 'form-marketplace-phase1esa-amendment-modal',
      data,
    });
    return ref.afterClosed().pipe(take(1));
  }
}
