import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { TransectFile } from '../transect-file/transect-file';

export type PlatformRole = 'user' | 'admin' | 'unverified' | 'public-user';

export const UserDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  firstname: z.string().optional(),
  lastname: z.string().optional(),
  email: z.string().optional(),
  verified: z.boolean(),
  verification_code: z.string().optional(),
  fullname: z.string(),
  preferences: z.object({}).passthrough().optional(),
  provider: z.string(),
  role: z.string(),
  customer_users: z.any().optional(),
  photo_gcs: TransectFile.nullable(),
});
export type UserDTO = z.infer<typeof UserDTO>;
