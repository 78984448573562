import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { GeographyState } from '../geographies';

export const SpeciesLocationObject = z.object({
  name: z.string().optional().nullable(),
  description: z.string().optional().nullable(),
  geometry: z.any(),
  properties: z.object({}).passthrough().optional().nullable(),
  active: z.boolean().default(true),
  species__id: z.string().uuid().optional().nullable(),
  type: z.string().optional().nullable(),
  reference__id: z.string().uuid().optional().nullable(),
  imported_location__id: z.string().uuid().optional().nullable(),
  exclusion_area: z.boolean().default(false),
  eo: z.object({}).passthrough().default({}).optional().nullable(),
  geometry_original: z.any().optional().nullable(),
  admin: z.object({}).passthrough().default({}).optional().nullable(),
  geographies_states: z.array(GeographyState).optional().nullable(),
  occurrence_potential: z.string().optional().nullable(),
  primary: z.boolean().default(false),
  import__id: z.string().uuid().optional().nullable(),
  date_superseded: DateSchema.optional().nullable(),
  temp_flag: z.string().default('').optional().nullable(),
});
