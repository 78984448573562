import { z } from 'zod';

export const RegulatorPermitCreateBody = z.object({
  permit__id: z.string(),
  regulator__id: z.string(),
});

export type RegulatorPermitCreateBody = z.infer<
  typeof RegulatorPermitCreateBody
>;

export const ShowAgainstPermitQuery = z.object({
  regulator__id: z.string(),
});

export type ShowAgainstPermitQuery = z.infer<typeof ShowAgainstPermitQuery>;
