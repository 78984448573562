import { z } from 'zod';
import { FormTypeKeyDTO } from '../form-type';

export const FormLegalAgreementExemptionResponseDTO = z.object({
  exempted: z.boolean(),
});

export type FormLegalAgreementExemptionResponseDTO = z.infer<
  typeof FormLegalAgreementExemptionResponseDTO
>;

export const FormLegalAgreementAllExemptionsResponseDTO = z.object({
  exemptedFormTypes: z.array(
    z.object({
      _id: z.string().uuid(),
      key: FormTypeKeyDTO,
    }),
  ),
});

export type FormLegalAgreementAllExemptionsResponseDTO = z.infer<
  typeof FormLegalAgreementAllExemptionsResponseDTO
>;
