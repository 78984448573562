import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ObjectService {
  constructor() {}

  flattenObject(obj: {}): {} {
    const toReturn = {};

    for (const i in obj) {
      if (!obj.hasOwnProperty(i)) {
        continue;
      }

      if (typeof obj[i] === 'object') {
        const flatObject = this.flattenObject(obj[i]);
        for (const x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) {
            continue;
          }

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else if (obj[i]) {
        toReturn[i] = obj[i];
      }
    }
    return toReturn;
  }
}
