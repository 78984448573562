/// <reference path="../types/tokml.d.ts" />
import tokml from '@maphubs/tokml';
import {
  Feature,
  FeatureCollection,
  Geometry,
  GeometryCollection,
  Properties,
} from '@turf/turf';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

export type KmlStyleOptions = {
  markerColor?: string;
  markerSymbol?: string;
  stroke?: string;
  fill?: string;
  fillOpacity?: number;
  strokeWidth?: number;
  strokeOpacity?: number;
};

export type KmlOptions = {
  simplestyle?: boolean;
  name?: string;
  documentName?: string;
  documentDescription?: string;
  description?: string;
};

const defaultKmlStyleOptions: KmlStyleOptions = {
  markerColor: '#ab0a0a',
  markerSymbol: '',
  stroke: '#ab0a0a',
  fill: '#555555',
  fillOpacity: 0.6,
  strokeWidth: 4,
  strokeOpacity: 1.0,
};

/**
 * Convert a feature collection to KML format.
 * @param featureCollection The feature collection to convert.
 * @param kmlOpts The KML options of type {@link KmlOptions}.
 * @param styleOpts The KML style options of type {@link KmlStyleOptions}.
 * @returns The KML string.
 */
export function convertToKml(
  featureCollection:
    | FeatureCollection<Geometry | GeometryCollection, Properties>
    | MapboxGeoJSONFeature,
  kmlOpts: KmlOptions = {
    simplestyle: true,
  },
  styleOpts: KmlStyleOptions = defaultKmlStyleOptions,
): string {
  if (featureCollection.type === 'Feature') {
    const styledFeature = setFeatureProperties(featureCollection, styleOpts);
    return tokml(
      { type: 'FeatureCollection', features: [styledFeature] },
      kmlOpts,
    );
  }

  if (featureCollection.type === 'FeatureCollection') {
    const features = featureCollection.features.map((feature) =>
      setFeatureProperties(feature, styleOpts),
    );
    return tokml({ ...featureCollection, features }, kmlOpts);
  }

  throw new Error('Invalid GeoJSON object');
}

/**
 * Set properties for a GeoJSON feature.
 * @param feature The feature to set properties for.
 * @returns The feature with properties set.
 */
export function setFeatureProperties(
  feature:
    | Feature<Geometry | GeometryCollection, Properties>
    | MapboxGeoJSONFeature,
  styleOpts: KmlStyleOptions = defaultKmlStyleOptions,
) {
  feature.properties = flattenObject(feature.properties);
  feature.properties['marker-symbol'] =
    styleOpts.markerSymbol ?? defaultKmlStyleOptions.markerSymbol;
  feature.properties['marker-color'] =
    styleOpts.markerColor ?? defaultKmlStyleOptions.markerColor;
  feature.properties['stroke'] =
    styleOpts.stroke ?? defaultKmlStyleOptions.stroke;
  feature.properties['stroke-opacity'] =
    styleOpts.strokeOpacity ?? defaultKmlStyleOptions.strokeOpacity;
  feature.properties['stroke-width'] =
    styleOpts.strokeWidth ?? defaultKmlStyleOptions.strokeWidth;
  feature.properties['fill'] = styleOpts.fill ?? defaultKmlStyleOptions.fill;
  feature.properties['fill-opacity'] =
    styleOpts.fillOpacity ?? defaultKmlStyleOptions.fillOpacity;

  feature.properties = removeNonAsciiCharacters(feature.properties);
  return feature;
}

/**
 * Flatten a nested object.
 * @param obj The object to flatten.
 * @returns The flattened object.
 */
export function flattenObject(obj: Properties): Record<string, unknown> {
  const toReturn: Record<string, unknown> = {};

  if (!obj) {
    return toReturn;
  }

  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }

    if (typeof obj[i] === 'object') {
      const flatObject = flattenObject(obj[i] as Properties);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) {
          continue;
        }

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else if (obj[i]) {
      toReturn[i] = obj[i];
    }
  }
  return toReturn;
}

/**
 * Remove non-ASCII characters from an object.
 * @param props The object to remove non-ASCII characters from.
 * @returns The cleaned object.
 */
export function removeNonAsciiCharacters(props: Properties): Properties {
  return JSON.parse(
    JSON.stringify(props).replace(/[^\x20-\x7E]/g, ''),
  ) as Properties;
}
