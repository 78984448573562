<ng-container *ngIf="recommendation">
  <div class="recommendation-info-panel">
    <div class="recommendation-info-heading">
      <img src="/assets/images/apple-icon-57x57.png" />
      <p class="label-medium text-brand-colors-brand-primary">
        Recommendations
      </p>
    </div>
    <div
      #recommendationDiv
      class="body-small text-brand-colors-brand-primary recommendation-info"
      [ngClass]="{ truncate: !isExpanded }"
      [innerHTML]="recommendation"
    ></div>
    <a
      *ngIf="isTruncated"
      class="label-medium text-brand-colors-brand-primary"
      (click)="isExpanded = !isExpanded"
    >
      {{ isExpanded ? 'Show less' : 'Show more' }}
    </a>
  </div>
</ng-container>
