import { z } from 'zod';

export const PlatformConfigurationItemsDTO = z.object({
  _id: z.string(),
  name: z.string(),
  type: z.enum(['boolean', 'number']),
  key: z.string(),
});

export type PlatformConfigurationItemsDTO = z.infer<
  typeof PlatformConfigurationItemsDTO
>;

export const PlatformConfigurationItemsArrayDTO = z.array(
  PlatformConfigurationItemsDTO,
);

export type PlatformConfigurationItemsArrayDTO = z.infer<
  typeof PlatformConfigurationItemsArrayDTO
>;
