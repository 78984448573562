import { z } from 'zod';
import { DateSchema } from '../../utils/date-schema';
import { PermitDTO } from '../permit/permit.dto';

export const RegulationPermitDTO = z.object({
  _id: z.string().uuid(),
  type: z.string().default(''),
  description: z.string().default(''),
  name: z.string().default(''),
  permit: z.union([PermitDTO.optional(), z.string()]),
  regulation: z.preprocess(
    (value) => (typeof value === 'string' ? { _id: value } : value),
    z.object({ _id: z.string().uuid() }).passthrough(),
  ),
  created_at: DateSchema,
  updated_at: DateSchema,
});
export type RegulationPermitDTO = z.infer<typeof RegulationPermitDTO>;
