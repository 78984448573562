import { Injectable } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ForcedUpdateDialogComponent } from '../components/unrecoverable-state-dialog/forced-update-dialog.component';
import { ForcedUpdateDialogModule } from '../forced-update-dialog.module';

@Injectable({
  providedIn: ForcedUpdateDialogModule,
})
export class ForcedUpdateDialogService {
  private updateDialogRef: MatDialogRef<ForcedUpdateDialogComponent, void>;

  constructor(private dialog: MatDialog) {}

  open(): void {
    if (!this.updateDialogRef) {
      this.updateDialogRef = this.dialog.open(ForcedUpdateDialogComponent, {
        id: 'forced-update-dialog',
        width: '49.6rem',
        disableClose: true,
      });
    }
  }
}
