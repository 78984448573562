import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { CatalogItem } from '../catalog-item';

export const ShowCatalogItemDTO = CatalogItem.extend({
  catalog_group: z.object({
    _id: z.string().uuid(),
    name: z.string().optional(),
    master: z.boolean(),
    active: z.boolean(),
    bbox: GeometrySchema.nullable(),
    created_at: DateSchema,
    updated_at: DateSchema,
    description: z.string().nullable(),
    preview: GeometrySchema.nullable(),
    properties: z.object({}).passthrough().nullable(),
  }),
  geography: z
    .object({
      _id: z.string().uuid(),
    })
    .nullable(),
  owner: z.object({ _id: z.string().uuid() }).nullable(),
  catalog_location_count: z.number(),
});

export type ShowCatalogItemDTO = z.infer<typeof ShowCatalogItemDTO>;
