import { z } from 'zod';
import { GeometrySchema } from '../../../utils/geometry-object-schema';

export const NdaFeatureDTO = z.object({
  geometry: GeometrySchema,
  properties: z.object({
    selected: z.boolean(),
    _id: z.string().uuid(),
    size_acres: z.number(),
    transect_feature_type: z.string(),
  }),
  type: z.string(),
});

export type NdaFeatureDTO = z.infer<typeof NdaFeatureDTO>;
