import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';

const ReportPartRegulator = z.object({
  _id: z.string().uuid(),
  name: z.string().nullish(),
  acronym: z.string().nullable(),
  jurisdiction: z.string(),
  profile: z
    .object({
      zip: z.string(),
      city: z.string(),
      phone: z.string(),
      state: z.string(),
      twitter: z.string(),
      linkedin: z.string(),
      facebook: z.string(),
      instagram: z.string(),
      website: z.string(),
      youtube: z.string(),
      address1: z.string(),
      address2: z.string(),
      logo_url: z.string(),
      fax: z.string(),
      email: z.string(),
    })
    .deepPartial()
    .nullable(),
  source__id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
});

export const ReportPartRegulatorDTO = ReportPartRegulator.extend({
  parent: ReportPartRegulator.optional().nullable(),
});
export type ReportPartRegulatorDTO = z.infer<typeof ReportPartRegulatorDTO>;
