import { z } from 'zod';

export const UpdateProductNotifyAvailabilityBodyDTO = z.object({
  email: z.string().email(),
  notifyMe: z.boolean(),
  type: z.enum(['in-app', 'public']),
});

export type UpdateProductNotifyAvailabilityBodyDTO = z.infer<
  typeof UpdateProductNotifyAvailabilityBodyDTO
>;
