<div
  class="chat-panel"
  *ngIf="(isLoadingComments$ | async) === false; else skeletonLoading"
>
  <div
    *ngIf="(commentsList$ | async)?.length > 0; else noCommentSection"
    class="comments-section"
    #commentsSection
  >
    <div *ngFor="let comment of commentsList$ | async" class="comments">
      <div class="comment">
        <div class="comment-avatar">
          <ts-user-profile-photo
            [transectFile]="comment.creator?.photo_gcs"
            imgClass="comments-image"
          ></ts-user-profile-photo>
        </div>
        <div class="comment-details">
          <div class="comment-header">
            <div class="comment-author label-medium">
              {{ comment.creator?.fullname }}
            </div>
            <div class="comment-time body-small">
              {{ getFromNow(comment.created_at) }}
            </div>
          </div>
          <div class="comment-textarea-container">
            <div *ngIf="!comment.isEditing" class="comment-text">
              <span *ngIf="comment.readMore">
                {{ comment.properties?.text }}
              </span>
              <span *ngIf="!comment.readMore">{{
                comment.properties?.text | slice : 0 : defaultLength
              }}</span>
              <span *ngIf="comment?.properties?.text?.length > defaultLength">
                <span *ngIf="!comment.readMore">... </span>
                <a *ngIf="!comment.readMore" (click)="comment.readMore = true"
                  >[more...]</a
                >
                <a *ngIf="comment.readMore" (click)="comment.readMore = false"
                  >[less...]</a
                >
              </span>
            </div>
            <textarea
              *ngIf="comment.isEditing"
              class="comment-textarea"
              [(ngModel)]="comment.updatedComment"
              [disabled]="comment.updatingComment === true"
              type="text"
            ></textarea>
          </div>
          <ng-container *ngIf="userOrNull$ | async as user">
            <div
              *ngIf="
                !readonly &&
                (user?._id === comment.creator__id || user?.role === 'admin')
              "
              class="comment-actions"
            >
              <mat-spinner
                *ngIf="comment.updatingComment || comment.deletingComment"
                [diameter]="20"
                [strokeWidth]="2"
              ></mat-spinner>
              <ng-container
                *ngIf="!comment.updatingComment && !comment.deletingComment"
              >
                <a *ngIf="!comment.isEditing" (click)="editComment(comment)">
                  Edit
                </a>
                <a *ngIf="comment.isEditing" (click)="updateComment(comment)">
                  Update
                </a>
                <a
                  *ngIf="comment.isEditing"
                  (click)="cancelEditComment(comment)"
                >
                  Cancel
                </a>
                <a *ngIf="!comment.isEditing" (click)="deleteComment(comment)">
                  Delete
                </a>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="commentTemplateButtonsTemplate"
      [ngTemplateOutlet]="commentTemplateButtonsTemplate"
    ></ng-container>
  </div>
  <div *ngIf="!readonly" class="post-comment">
    <div class="input-with-button">
      <textarea
        #commentInput
        class="body-large text-shades-gray-dark comment-textarea"
        placeholder="Post a comment..."
        [(ngModel)]="currentCommentText"
        [disabled]="(isSavingComment$ | async) === true"
        type="text"
        (keydown.enter)="handleEnterClickOnComment($event)"
        cdkTextareaAutosize="true"
        [cdkAutosizeMaxRows]="5"
      >
      </textarea>
      <mat-divider [vertical]="true"></mat-divider>
      <mat-spinner
        *ngIf="isSavingComment$ | async"
        [diameter]="18"
        [strokeWidth]="2"
      ></mat-spinner>
      <button
        transect-nx-button
        [iconOnly]="true"
        size="xxs"
        class="btn-send"
        *ngIf="(isSavingComment$ | async) === false"
        [disabled]="!currentCommentText"
        (click)="saveComment()"
      >
        <mat-icon>send</mat-icon>
      </button>
    </div>
  </div>
</div>
<ng-template #skeletonLoading>
  <div class="loading-container">
    <ts-spinner class="spinner"></ts-spinner>
  </div>
</ng-template>
<ng-template #noCommentSection>
  <div class="no-comments">There are no comments at this time.</div>
  <ng-container
    *ngIf="commentTemplateButtonsTemplate"
    [ngTemplateOutlet]="commentTemplateButtonsTemplate"
  ></ng-container>
</ng-template>
