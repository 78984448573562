import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { ParcelFilterDTO } from '../parcel-filter.dto';

export const UpdateParcelFilterDTO = ParcelFilterDTO.omit({ creator: true })
  .extend({
    deleted_at: DateSchema,
  })
  .deepPartial();
export type UpdateParcelFilterDTO = z.infer<typeof UpdateParcelFilterDTO>;
