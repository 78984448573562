import { z } from 'zod';
import { DateSchema } from '../../../utils/date-schema';
import { GeometrySchema } from '../../../utils/geometry-object-schema';
import { TransectSummarySchema } from '../../../utils/transect-summary-schema';

const WetlandProperties = z.object({
  acres: z.number(),
  globalid: z.string(),
  objectid: z.number(),
  attribute: z.string(),
  qaqc_code: z.string(),
  wetland_ty: z.string(),
});

export const ReportPartWetlandFeatureDTO = z.object({
  _id: z.string().uuid(),
  created_at: DateSchema,
  updated_at: DateSchema,
  deleted_at: DateSchema.optional().nullable(),
  properties: WetlandProperties.deepPartial(),
  geometry: GeometrySchema,
  source__id: z.string().uuid(),
  name: z.string().nullable(),
  classification: z.string().nullable(),
  transect_feature_type: z.literal('wetland'),
  transect_summary: TransectSummarySchema,
});

export type ReportPartWetlandFeatureDTO = z.infer<
  typeof ReportPartWetlandFeatureDTO
>;
