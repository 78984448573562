import { z } from 'zod';

export const SpeciesLocationIdControllerParams = z
  .object({
    id: z.string().uuid(),
  })
  .strict();

export type SpeciesLocationIdControllerParams = z.infer<
  typeof SpeciesLocationIdControllerParams
>;

export const SpeciesLocationIdsControllerParams = z
  .object({
    ids: z.array(z.string().uuid()),
  })
  .strict();

export type SpeciesLocationIdsControllerParams = z.infer<
  typeof SpeciesLocationIdsControllerParams
>;
