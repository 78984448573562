import { z } from 'zod';
import {
  pageNumber,
  pageSize,
  sortModel,
} from '../../../utils/paginated-response-rows';
import { stringToBoolean } from '../../../utils/string-to-boolean';

export const ReferencesIndexQueryDTO = z.object({
  _id: z.string().uuid().nullable().optional(),
  title: z.string().nullable().optional(),
  primary_source_active: stringToBoolean.nullable().optional(),
  page: pageNumber.optional(),
  pageSize: pageSize.default('100').optional(),
  sortBy: sortModel.optional(),
  excluded_id: z.string().uuid().nullish(),
});

export type ReferencesIndexQueryDTO = z.infer<typeof ReferencesIndexQueryDTO>;
