import { z } from 'zod';

/** Query params for path /species/search */
export const SpeciesSearchControllerQuery = z
  .object({
    _id: z.string().uuid().optional(),
    name: z.string().optional(),
  })
  .strict();
export type SpeciesSearchControllerQuery = z.infer<
  typeof SpeciesSearchControllerQuery
>;
