import { z } from 'zod';
export const ReportExportsListParams = z.object({
  page: z
    .string()
    .default('1')
    .transform((val, ctx) => {
      const parsedAsInt = parseInt(val);
      if (isNaN(parsedAsInt))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Page must be an integer`,
        });
      return parsedAsInt;
    }),
  pageSize: z
    .string()
    .default('100')
    .transform((val, ctx) => {
      const parsedAsInt = parseInt(val);
      if (isNaN(parsedAsInt))
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Page Size must be an integer`,
        });
      return parsedAsInt;
    }),
  report__id: z.string(),
  type: z.string(),
});

export type ReportExportsListParams = z.infer<typeof ReportExportsListParams>;
