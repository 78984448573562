import { z } from 'zod';
import { GeometrySchema } from '../../../utils/geometry-object-schema';

export const PatchReportDrawnObjectsBodyDTO = z.object({
  drawn_objects: GeometrySchema.nullish(),
});

export type PatchReportDrawnObjectsBodyDTO = z.infer<
  typeof PatchReportDrawnObjectsBodyDTO
>;
