import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ts-unrecoverable-state-dialog',
  templateUrl: './unrecoverable-state-dialog.component.html',
  styleUrls: ['./unrecoverable-state-dialog.component.scss'],
})
export class UnrecoverableStateDialogComponent {
  reason: string | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { reason: string },
  ) {
    this.reason = this.data.reason;
  }

  refreshApp(): void {
    window.location.reload();
  }
}
