import { z } from 'zod';
import { ProjectDTO } from '../project.dto';

export const ProjectControllerUpdateBody = ProjectDTO.omit({
  _id: true,
  type: true,
  created_from: true,
  created_at: true,
  updated_at: true,
  deleted_at: true,
}).extend({
  public: ProjectDTO.shape.public.optional(),
  pinned: ProjectDTO.shape.pinned.optional(),
  is_official_demo: ProjectDTO.shape.is_official_demo.optional(),
  cleanup_weekly: ProjectDTO.shape.cleanup_weekly.optional(),
  active: ProjectDTO.shape.active.optional(),
  excluded_parcel_ids: z.array(z.string().uuid()).optional(),
  parcel_filter_id: z.string().uuid().nullish(),
});

export type ProjectControllerUpdateBody = z.infer<
  typeof ProjectControllerUpdateBody
>;
