import { z } from 'zod';
import { SolarRegulationDTO } from '../solar-regulation-dto';

export const CreateSolarRegulationPayload = SolarRegulationDTO.pick({
  document_title: true,
  regulation_type: true,
  technology: true,
});

export type CreateSolarRegulationPayload = z.infer<
  typeof CreateSolarRegulationPayload
>;
