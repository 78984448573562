import { NotificationPayloadDTO } from './notification-payload';
import { InProgressRequestDTO } from './in-progress-request.dto';
import { z } from 'zod';
import { ParcelCollectionStatusPayloadDTO } from './parcel-collection-status-payload';

export enum PusherEvents {
  NOTIFICATION = 'notification',
  IN_PROGRESS_REQUEST = 'in-progress-request',
  PARCEL_COLLECTION_STATUS = 'parcel-collection-status',
}

export const PusherEventDTOMap: { [key in PusherEvents]: z.ZodSchema } = {
  [PusherEvents.NOTIFICATION]: NotificationPayloadDTO,
  [PusherEvents.IN_PROGRESS_REQUEST]: InProgressRequestDTO,
  [PusherEvents.PARCEL_COLLECTION_STATUS]: ParcelCollectionStatusPayloadDTO,
};

export type PusherEventDTOMap = {
  [T in PusherEvents]: {
    [PusherEvents.NOTIFICATION]: NotificationPayloadDTO;
    [PusherEvents.IN_PROGRESS_REQUEST]: InProgressRequestDTO;
    [PusherEvents.PARCEL_COLLECTION_STATUS]: ParcelCollectionStatusPayloadDTO;
  }[T];
};

export type TransectChannels = 'user' | 'customer' | 'public';
