<div class="profile-image">
  <ng-container *ngIf="!!transectFile; else elseBlock">
    <ts-transect-file-image
      [transectFile]="transectFile"
      [imgClass]="imgClass"
      (loading)="myLoading($event)"
      (loaded)="handleImageLoaded()"
    ></ts-transect-file-image>
  </ng-container>
  <ng-template #elseBlock>
    <img
      (load)="handleImageLoaded()"
      [ngClass]="imgClass"
      src="https://storage.googleapis.com/app.transect.com/assets_mg/transect-user-default.png"
    />
  </ng-template>
</div>
