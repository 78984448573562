import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiTreeContainerComponent } from './ui-tree-container/ui-tree-container.component';
import { UiTreeNodeComponent } from './ui-tree-node/ui-tree-node.component';

@NgModule({
  imports: [CommonModule],
  declarations: [UiTreeContainerComponent, UiTreeNodeComponent],
  exports: [UiTreeContainerComponent, UiTreeNodeComponent],
})
export class UiTreeModule {}
