<div
  class="transect-nx-species-list-item"
  [class.blink]="blink"
  [class.open]="isOpen"
>
  <div class="species-list-item-container">
    <i
      class="fa nx-species-list-item-expander"
      [class.fa-chevron-right]="!isOpen"
      [class.fa-chevron-down]="isOpen"
      [class.hidden]="!recommendation"
      (click)="toggleOpen()"
    ></i>
    <ng-container *ngIf="image$ | async as image; else speciesDefaultImage">
      <img
        *ngIf="image"
        class="nx-species-list-item-image"
        [src]="image"
        loading="lazy"
      />
    </ng-container>
    <div class="nx-species-list-item-details">
      <p class="label-large" [class.missing-species]="!commonName">
        {{ commonName || 'Missing species name' }}
      </p>
      <p
        class="label-medium"
        [ngClass]="{
          'text-danger-warnings-brand-danger-dark':
            assessmentLevel === 'species_of_concern',
          'text-secondary-colors-secondary-color':
            assessmentLevel === 'likely_to_occur',
          'text-secondary-colors-secondary-color-dark':
            assessmentLevel === 'may_occur',
          'text-success-brand-success':
            assessmentLevel === 'not_likely_to_occur'
        }"
      >
        {{ getReadableAssessmentLevel() }}
      </p>
    </div>
    <div class="nx-species-list-item-right-rail">
      <div class="nx-species-list-item-state-status">
        <p class="label-medium">State Status</p>
        <p class="body-small" *ngIf="stateListingStatuses.length === 0">N/A</p>
        <p class="body-small" *ngFor="let item of stateListingStatuses">
          {{ item.state }}:
          <span [ngClass]="item.status | listStatusClass">{{
            item.status
          }}</span>
        </p>
      </div>
      <div class="divider"></div>
      <div class="nx-species-list-item-federal-status">
        <p class="label-medium">Federal Status</p>
        <p
          class="body-small"
          [ngClass]="federalListingStatus | listStatusClass"
        >
          {{ federalListingStatus || 'N/A' }}
        </p>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isOpen && this.recommendation">
    <transect-nx-recommendation
      [recommendation]="recommendation"
    ></transect-nx-recommendation>
  </ng-container>
</div>

<ng-template #speciesDefaultImage>
  <div class="nx-species-list-item-default-image">
    <i class="fal" [class]="iconClass"></i>
  </div>
</ng-template>
