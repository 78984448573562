import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
  IAfterGuiAttachedParams,
  ICellRendererParams,
} from 'ag-grid-community';
import { FileUploadService } from '../../../services/file-upload.service';

@Component({
  selector: 'ts-file-link-cell-renderer',
  templateUrl: './file-link-cell-renderer.component.html',
  styleUrls: ['./file-link-cell-renderer.component.scss'],
})
export class FileLinkCellRendererComponent implements ICellRendererAngularComp {
  params: ICellRendererParams = {} as ICellRendererParams;

  constructor(private fileService: FileUploadService) {}

  refresh(params: any): boolean {
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  afterGuiAttached?(params?: IAfterGuiAttachedParams): void {
    return;
  }

  getSignedUrl(data: { _id?: string; signedUrl?: string }) {
    if (!data._id) {
      return;
    }

    this.fileService.getFileUrl(data._id).subscribe((response) => {
      data.signedUrl = response['signedUrl'];
    });
  }
}
