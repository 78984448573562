import { z } from 'zod';
import { CatalogItem } from '../catalog-item';
import { ResponseRows } from '../../../utils/response-rows';

export const ListCatalogItemDTO = CatalogItem.extend({
  creator: z
    .object({
      _id: z.string().uuid(),
      firstname: z.string().default('Unknown'),
      lastname: z.string().default('Unknown'),
    })
    .nullable()
    .optional(),
  owner: z
    .object({
      _id: z.string().uuid(),
      fullname: z.string().default('Unknown'),
    })
    .nullable()
    .optional(),
  geography: z
    .object({
      _id: z.string().uuid(),
      name: z.string(),
      type: z.string(),
    })
    .nullable()
    .optional(),
  catalog_group: z
    .object({
      _id: z.string().uuid(),
      name: z.string().optional(),
      master: z.boolean(),
      active: z.boolean(),
    })
    .optional()
    .nullable(),
});

export type ListCatalogItemDTO = z.infer<typeof ListCatalogItemDTO>;

export const ListCatalogItemsDTO = ResponseRows(ListCatalogItemDTO);

export type ListCatalogItemsDTO = z.infer<typeof ListCatalogItemsDTO>;
